import { FC } from "react";
import { v4 as uuidv4 } from "uuid";

export const ReportClaimErrorMessages: FC<{ errors?: string[] }> = ({ errors }) => {
  const numErrors = errors?.length ?? 0;

  return numErrors > 0 ? (
    <ul>
      {errors?.map((error) => (
        <li key={uuidv4()}>- {error} !</li>
      ))}
    </ul>
  ) : null;
};
