import axiosClient from "clientProvider/axiosConfig";
import qs from "qs";

type ReferentielsPiecesJustificatives = {
  garantie: {
    crmId: string;
    nom: string;
  };
  piecesJustificatives: {
    code: string;
    crmId: string;
    description: string;
    nom: string;
  }[];
};
export const referentielsPiecesJustificativesApi = {
  getReferentielsPiecesJustificatives: async (
    garantiesIds: string[],
    typeEvenementId: string,
    isVehiculeRetrouve: boolean,
    isVehiculeReparable: boolean,
  ) => {
    const result = await axiosClient.get(`ReferentielsPiecesJustificatives`, {
      params: {
        garantiesIds,
        typeEvenementId,
        isVehiculeRetrouve,
        isVehiculeReparable,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });

    return result.data as ReferentielsPiecesJustificatives[];
  },
};
