import { FC } from "react";
import { Text } from "@roole/components-library";

import { CommonProps } from "utils/CommonProps";

interface NumericTextProps extends CommonProps {
  value?: number | null;
  unit?: string;
  addonText?: string;
}

export const NumericText: FC<NumericTextProps> = ({
  className,
  value,
  unit = "km",
  addonText = "Résultat :",
}) => {
  if (value === null || value === undefined) {
    return null;
  }

  const text = [addonText, `${Math.round(value)}`, unit].filter((item) => !!item).join("\u00a0");

  return <Text className={className}>{text}</Text>;
};
