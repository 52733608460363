import { useState } from "react";
import { Garantie } from "domain/api-schemas";
import { sortByAscending } from "utils/sortTemplate";
import { useTracking } from "hooks/useTracking";

export const useGarantiesList = (list: Garantie[] | null) => {
  const [selectedItem, setSelectedItem] = useState<Garantie | null>(null);
  const orderGaranties = () => {
    if (list) {
      const listSorting = list.slice();
      const sorted = [...listSorting].sort((a, b) => sortByAscending(a.ordre, b.ordre));
      return sorted.reduce((acc: Array<Array<Garantie>>, garantie: Garantie) => {
        const { ordreCategorie } = garantie || {};
        acc[ordreCategorie] = [...(acc[ordreCategorie] || []), garantie];
        return acc;
      }, []);
    }
    return [];
  };
  const [orderAndGroupedGaranties, _] = useState<Array<Array<Garantie>>>(() => orderGaranties());
  const { sendToDataLayer } = useTracking();
  const setItemAndAnalytics = (selectedCategorie: number, selectedGarantie: number) => {
    const currentItem =
      selectedCategorie && selectedGarantie !== undefined && selectedGarantie !== null
        ? orderAndGroupedGaranties[selectedCategorie]?.[selectedGarantie]
        : null;

    const { code } = currentItem || {};

    if (code) {
      sendToDataLayer(`garanties-${code}`);
    }

    setSelectedItem(currentItem);
  };

  const onAbort = () => setSelectedItem(null);

  return {
    onAbort,
    orderAndGroupedGaranties,
    setItemAndAnalytics,
    selectedItem,
  };
};
