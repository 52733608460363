import { FC, useEffect, useState } from "react";
import { Text } from "@roole/components-library";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { RoutePaths } from "routes/routePaths";
import { reportClaimStepState } from "context/reportClaimState/atom";
import { GarantieProduit } from "domain/api-schemas";
import { classNames } from "utils/classNames";
import { text_to_id } from "utils/textToId";
import { useTracking } from "hooks/useTracking";

import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";
import { ButtonTile } from "components/atoms/ButtonTile/ButtonTile";
import { ReactComponent as CrossIcon } from "images/icons/cross.svg";

import commonStyles from "../Page.module.scss";
import styles from "./ReportClaimGuaranteesStep.module.scss";

export const ReportClaimGuaranteesStep: FC = () => {
  const [reportClaimStepContextState, setReportClaimStepContextState] =
    useRecoilState(reportClaimStepState);
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [currentGuarantee, setCurrentGuarantee] = useState<GarantieProduit | null>(
    reportClaimStepContextState?.claimGuarantee || null,
  );
  const { sendToDataLayer } = useTracking();

  const guarantees = reportClaimStepContextState?.guaranteesAvailable || [];

  useEffect(() => {
    if (reportClaimStepContextState.claimDate === null) {
      navigate(RoutePaths.DECLARER_SINISTRE_DATE);
    }
  }, []);

  const onClickTile = (guarantee: GarantieProduit) => {
    setReportClaimStepContextState({
      ...reportClaimStepContextState,
      claimGuarantee: guarantee,
    });
    setError(false);
    setCurrentGuarantee(guarantee);
  };

  const handleNextClick = () => {
    if (currentGuarantee) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        activeStep: 4,
        claimGuarantee: currentGuarantee,
      });
      sendToDataLayer(
        `declarer-sinistre-${text_to_id(currentGuarantee.libelleGarantie || "")}-garanties`,
      );
      navigate(RoutePaths.DECLARER_SINISTRE_FILES);
      sendToDataLayer("declarer-sinistre-garanties-bouton-suivant");
    } else {
      setError(true);
    }
  };

  const handlePrevClick = () => {
    setReportClaimStepContextState({
      ...reportClaimStepContextState,
      activeStep: 2,
      claimGuarantee: currentGuarantee,
    });
    navigate(RoutePaths.DECLARER_SINISTRE_TYPE);
    sendToDataLayer("declarer-sinistre-garanties-bouton-precedent");
  };

  const variantToUse = (currentGuaranteeProp: string, toCompareTo: string) =>
    currentGuaranteeProp === toCompareTo ? "primary" : "radio-not-selected";

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Sélection des garanties
        </Text>
        <br />
      </div>
      <Card noMargin className={styles.contactUsTypeStep__Container}>
        <Text as="h2" textVariant="heading-4">
          Quelle garantie souhaitez-vous activer ?
        </Text>
        <Text className={styles.reportClaimGuaranteesStep__Text}>
          Sélectionner ci-dessous la garantie que vous souhaitez activer. Si vous avez un doute sur
          votre contrat, rendez-vous sur la page "Mes garanties" ou retrouvez le détails des
          garanties dans vos conditions générales.
        </Text>
        <form>
          <div className={classNames("", error && styles.reportClaimGuaranteesStep__Form__Error)}>
            {guarantees.map((guarantee) => (
              <ButtonTile
                type="button"
                variant={variantToUse(
                  currentGuarantee?.libelleGarantie || "",
                  guarantee.libelleGarantie || "",
                )}
                onClick={() => onClickTile(guarantee)}
                className={styles.contactUsTypeStep__ButtonTile}
                key={guarantee.garantieId}
                style={{ padding: "16px 32px" }}
              >
                <Text as="h3" textVariant="heading-5">
                  {guarantee.libelleGarantie}
                </Text>
                {guarantee.listeRefus && guarantee.listeRefus?.length > 0 && (
                  <Text
                    as="h5"
                    textVariant="body"
                    className={styles.reportClaimGuaranteesStep__Warning}
                  >
                    Évènements non couverts par cette garantie :
                  </Text>
                )}
                {guarantee.listeRefus?.map((exception) => (
                  <Text as="h5" textVariant="body" key={uuidv4()}>
                    <div className={styles.reportClaimGuaranteesStep__Line}>
                      <CrossIcon className={styles.reportClaimGuaranteesStep__Warning__Icon} />
                      {exception}
                    </div>
                  </Text>
                ))}
              </ButtonTile>
            ))}
          </div>
          {error && (
            <div className={commonStyles.contactUsStep__Error}>
              <Text>Veuillez séléctionner une garantie</Text>
            </div>
          )}

          <NavigationButtons
            prevProps={{
              onClick: () => {
                handlePrevClick();
              },
            }}
            nextProps={{
              type: "button",
              onClick: () => {
                handleNextClick();
              },
            }}
          />
        </form>
      </Card>
    </>
  );
};
