import axiosClient from "clientProvider/axiosConfig";

export const showPjApi = {
  fetchShowPj: async (id: string) => {
    const result = await axiosClient.get<string>(`/Document/Contenu`, {
      params: {
        id,
      },
    });

    return result.data;
  },
};
