import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { RoutePaths } from "routes/routePaths";
import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import {
  reportClaimStepContextStateInitValue,
  reportClaimStepState,
} from "context/reportClaimState/atom";
import { useRefreshSelector } from "hooks/useRefreshSelector";
import { listMonitoredClaimsQuery } from "context/reportClaimState/selectors";
import { ReportClaimErrorMessages } from "./ReportClaimErrorMessages";

const MainText: FC<{ errors?: string[] }> = ({ errors }) => (
  <>
    <ReportClaimErrorMessages errors={errors} />
    <p>
      Nous vous invitons à réessayer de nous contacter en cliquant sur le bouton ci-dessous ou en
      nous appelant directement au 01 46 90 37 00 du lundi au vendredi de 9h à 18h (appel gratuit)
    </p>
  </>
);

export const ReportClaimErrorStep: FC = () => {
  const [{ errors }, setReportClaimStepContextState] = useRecoilState(reportClaimStepState);
  const [claimErrors] = useState<string[]>(errors?.claimPiecesJointes ?? []);
  useRefreshSelector(listMonitoredClaimsQuery);

  useEffect(() => {
    setReportClaimStepContextState({ ...reportClaimStepContextStateInitValue, activeStep: 5 });
  }, []);

  return (
    <EndPathLayout
      headerTitle="Déclaration de sinistre"
      subTitle="Votre déclaration n’a pas pu être prise en compte !"
      mainText={<MainText errors={claimErrors} />}
      id="declarer-sinistre-erreur"
      redirectionUrl={RoutePaths.DECLARER_SINISTRE_DATE}
      buttonText="Nouvelle déclaration de sinistre"
      closeButtonId="declaration-sinistre-croix-quitter"
    />
  );
};
