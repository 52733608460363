import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { useWizard, WizardStepStatus } from "components/molecules/Wizard";

export const RenewalStepGuard: FC<PropsWithChildren> = ({ children }) => {
  const { activeStep, steps } = useWizard();

  if (activeStep?.status === WizardStepStatus.SUCCEEDED) {
    return <Navigate to={`../${steps[activeStep.index + 1].path}`} replace />;
  }

  return <>{children}</>;
};
