import { HTMLAttributes, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/atoms/Button/Button";
import { classNames } from "utils/classNames";
import { useMatchMedia } from "hooks/useMatchMedia";
import { RoutePaths } from "routes/routePaths";
import { ReactComponent as IconPhone } from "images/icons/phone.svg";

import cssVariables from "styles/variables.module.scss";

import styles from "./CallCard.module.scss";

type CallCardProps = {
  variant?:
    | "secondary"
    | "primary"
    | "secondary-light"
    | "outlined"
    | "outlined-dark"
    | "outlined-light";
  sendToDataLayer?: () => void;
  onClick?: () => void;
  text?: string;
  phoneIcon?: boolean;
  phoneNumber?: string;
} & HTMLAttributes<HTMLDivElement>;

export const CallCard: FC<CallCardProps> = ({
  className,
  variant = "secondary",
  id,
  sendToDataLayer,
  onClick,
  text,
  phoneIcon,
  phoneNumber = "0102030405",
  ...props
}) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState(text ?? "Contactez-nous");

  return (
    <div className={classNames(styles.callCard, className)} {...props}>
      <Button
        icon={phoneIcon && <IconPhone />}
        variant={variant}
        size={!isDesktop ? "small" : "large"}
        onClick={() => {
          sendToDataLayer?.();
          if (onClick) {
            onClick();
          } else if (text === "Appelez-nous" && isDesktop) {
            setButtonText(phoneNumber);
          } else if (text === "Appelez-nous") {
            window.location.href = `tel:${phoneNumber}`;
          } else if (text === "Une question ?") {
            window.open(RoutePaths.AIDE, "_blank");
          } else {
            navigate(RoutePaths.CONTACT_REASON);
          }
        }}
        id={id}
      >
        {buttonText}
      </Button>
    </div>
  );
};
