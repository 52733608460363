import React, { FC, useEffect, useState } from "react";
import { initializeSTFAQ, handleCookiebotAccept } from "SmartTribune";
import { Footer } from "components/molecules/Footer/Footer";
import { useRecoilValueLoadable } from "recoil";
import { currentContratQuery } from "context/currentContratState/selector";
import { HeaderHelp } from "./HeaderHelp";
import { useLocation } from "react-router-dom";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { OverlayLoader, Modal } from "@roole/components-library";
import { RoutePaths } from "routes/routePaths";
import { useZendeskContext } from "ZendeskProvider";
import { useBodyEventDelegation } from "hooks/useBodyEventDelegation";

export const Page: FC = () => {
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const location = useLocation();
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isTablet = useMatchMedia(cssVariables.breakpointTablet);
  // Extract hideHeaderFooter parameter from URL
  const queryParams = new URLSearchParams(location.search);
  const hideHeaderFooter = queryParams.get("hideHeaderFooter") === "true";
  const { showZendeskChat } = useZendeskContext();
  const [faqLoaded, setFaqLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const style = isMobile
    ? { paddingTop: 75, paddingLeft: 20, paddingRight: 20 }
    : isTablet
    ? { paddingTop: 76, paddingLeft: 20 }
    : { paddingTop: 103, paddingLeft: 24 };

  useEffect(() => {
    const handleSTFAQLoaded = () => {
      setFaqLoaded(true);
    };

    window.addEventListener("STFAQLoaded", handleSTFAQLoaded);

    initializeSTFAQ();
    handleCookiebotAccept();

    // Injecting the Smart Tribune GTM script into the <body>
    const addSmartTribuneGTMToHead = () => {
      const scriptAsync = document.createElement("script");
      scriptAsync.async = true;
      scriptAsync.src = "https://www.googletagmanager.com/gtag/js?id=G-FSD9CVQ6M3";
      scriptAsync.id = "smart-tribune-gtm-async";
      document.body.appendChild(scriptAsync);

      const script = document.createElement("script");
      script.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', 'G-FSD9CVQ6M3', {
                'cookie_domain': '${window.location.hostname}',
                'cookie_flags': 'SameSite=None;Secure',
                'send_page_view': false
              });
            `;
      script.id = "smart-tribune-gtm-inline";
      document.body.appendChild(script);
    };

    addSmartTribuneGTMToHead();

    return () => {
      window.removeEventListener("STFAQLoaded", handleSTFAQLoaded);

      const asyncScript = document.getElementById("smart-tribune-gtm-async");
      const inlineScript = document.getElementById("smart-tribune-gtm-inline");

      if (asyncScript) {
        document.body.removeChild(asyncScript);
      }
      if (inlineScript) {
        document.body.removeChild(inlineScript);
      }
    };
  }, []);

  useEffect(() => {
    // Force a re-render when the user navigates to the "/aide" route
    if (location.pathname === "/aide" && faqLoaded) {
      window.dispatchEvent(new CustomEvent("STFAQLoaded", {}));
    }
  }, [location.pathname, faqLoaded]);

  useBodyEventDelegation("#st-faq", (event, targetElement) => {
    const openChatBtn = (event.target as Element).closest(
      ".contact-button-name-contact-livechat-1079",
    );
    if (openChatBtn) {
      showZendeskChat();
    }
  });

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {!hideHeaderFooter && <HeaderHelp handleClickLogo={openModal} />}
      <div id="st-faq"></div>
      {faqLoaded ? (
        <>
          <Modal
            title="Que voulez-vous faire ?"
            content={<p>Vous êtes sur le point de quitter le centre d’aide Roole.</p>}
            button={{
              url: "",
              label: "Accéder au site roole.fr",
              handleButton: () => {
                window.location.href = "https://roole.fr/";
              },
            }}
            secondaryButton={{
              url: RoutePaths.AIDE,
              label: "Revenir au centre d’aide",
              handleButton: () => {},
            }}
            isOpen={showModal}
            handleClose={() => setShowModal(false)}
            isStart={true}
            withBlur={false}
            style={style}
          />
          {isMobile === false && !hideHeaderFooter && (
            <Footer
              contrat={monContrat.contents || null}
              idAdhesion={monContrat.contents?.idAdhesion || null}
            />
          )}
        </>
      ) : (
        <OverlayLoader />
      )}
    </>
  );
};
