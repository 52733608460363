import {
  WizardStep,
  useWizard,
  WizardStepLayout,
  WizardNav,
  Text,
} from "@roole/components-library";
import { Box } from "components/atoms/Box/Box";
import { NO_BTN } from "components/molecules/Wizard";
import { useNavigate } from "react-router-dom";
import styles from "./Page.module.scss";
import { useRecoilState } from "recoil";
import { dateEffetState } from "./Context/DateEffetState";

export const ConfirmationStep: WizardStep = () => {
  const navigate = useNavigate();
  const { submitStep } = useWizard();
  const [dateEffet] = useRecoilState(dateEffetState);

  const nextStepHandler = () => submitStep(null, () => navigate("/", { replace: true }));

  return (
    <WizardStepLayout className={styles.w50} title="">
      <Box className={styles.textCenter}>
        <Text textVariant="body-em" as="p">
          Votre demande de résiliation a bien été prise en compte.
        </Text>
        <Text textVariant="body-em" as="p">
          Date d'effet: {dateEffet}
        </Text>
        <Text textVariant="p" as="p">
          Vous restez membre Roole et pouvez bénéficier de vos garanties et Bons Plans jusqu'à cette
          date.
        </Text>
        <Text textVariant="p" as="p">
          Vous recevrez d'ici quelques instants un email de confirmation.
        </Text>
      </Box>
      <WizardNav nextStepHandler={nextStepHandler} buttons={NO_BTN} />
    </WizardStepLayout>
  );
};
