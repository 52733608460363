import { createContext, FC, useCallback, useContext, useEffect, useReducer, Dispatch } from "react";
import { matchPath, NavigateOptions, useLocation, useNavigate } from "react-router-dom";

import {
  WizardInitHandler,
  WizardNavHandler,
  WizardStepHandler,
  WizardStepObject,
} from "./Wizard.types";
import { WizardActionType } from "./WizardAction";
import { initialState, initWizardReducer, wizardReducer, WizardState } from "./wizardReducer";

interface WizardContextProps {
  indexPath: string;
  activeStep: WizardStepObject | null;
  nextStep(): void;
  prevStep(): void;
  gotoStep(indexOrPath: number | string, options?: NavigateOptions): void;
  submitStep<T>(
    handler?: WizardStepHandler<T> | null,
    onSubmitted?: WizardNavHandler<T> | null,
    stepSucceededDescription?: string | null,
  ): void;
  dispatch: Dispatch<any>;
}

interface WizardContextProviderProps {
  children?: React.ReactNode;
  basePath: string;
  indexPath: string;
  steps: Partial<WizardStepObject>[];
  initHandler?: WizardInitHandler;
}

const WizardContext = createContext<WizardContextProps & WizardState>({
  ...initialState,
  indexPath: "/",
  activeStep: null,
  nextStep: () => {},
  prevStep: () => {},
  gotoStep: () => {},
  submitStep: () => {},
  dispatch: () => {},
});

export const useWizard = () => useContext(WizardContext);

export const WizardContextProvider: FC<WizardContextProviderProps> = ({
  children,
  basePath,
  indexPath,
  steps,
  initHandler,
}) => {
  const navigate = useNavigate();
  const { pathname: activePath } = useLocation();
  const [state, dispatch] = useReducer(
    wizardReducer,
    {
      ...initialState,
      steps,
    } as WizardState,
    initWizardReducer,
  );
  const activeStepIndex = (state.steps || []).findIndex((step) =>
    matchPath(`${basePath}/${step.path}`, activePath),
  );
  const activeStep = activeStepIndex !== -1 ? state.steps[activeStepIndex] : null;

  const nextStep = () => {
    if (!activeStep || activeStep.isLast) {
      return;
    }

    gotoStep(activeStep.index + 1);
  };

  const prevStep = () => {
    if (!activeStep || activeStep.isFirst) {
      return;
    }

    gotoStep(activeStep.index - 1);
  };

  const gotoStep = (indexOrPath: number | string, options?: NavigateOptions) => {
    const to = typeof indexOrPath === "number" ? state.steps[indexOrPath].path : indexOrPath;
    navigate(`${basePath}/${to}`, options);
  };

  const submitStep = async <T,>(
    handler?: WizardStepHandler<T> | null,
    onSubmitted: WizardNavHandler<T> | null = nextStep,
    stepSucceededDescription?: string,
  ) => {
    if (activeStepIndex === -1) {
      return;
    }

    try {
      dispatch({ type: WizardActionType.SUBMIT_STEP, stepIndex: activeStepIndex });

      const result = await handler?.();

      dispatch({
        type: WizardActionType.SUBMIT_STEP_SUCCEEDED,
        stepIndex: activeStepIndex,
        description: stepSucceededDescription,
      });

      onSubmitted?.(result);

      return result;
    } catch (err) {
      const error = err as string;

      dispatch({
        type: WizardActionType.SUBMIT_STEP_FAILED,
        stepIndex: activeStepIndex,
        error,
        errorNextLabel: "Ok",
      });

      throw error;
    }
  };

  const initWizard = useCallback(
    async (handler?: WizardInitHandler) => {
      if (state.loading || state.initialized) {
        return;
      }

      try {
        dispatch({ type: WizardActionType.INIT_WIZARD });

        const stepsByPath = (await handler?.()) ?? {};

        dispatch({ type: WizardActionType.INIT_WIZARD_SUCCEEDED, stepsByPath });

        return steps;
      } catch (err) {
        const error = err as string;

        dispatch({ type: WizardActionType.INIT_WIZARD_FAILED, error });

        throw error;
      }
    },
    [state.loading, state.initialized, dispatch, steps],
  );

  useEffect(() => {
    initWizard(initHandler);
  }, [initWizard, initHandler]);

  return (
    <WizardContext.Provider
      value={{
        ...state,
        indexPath,
        activeStep,
        nextStep,
        prevStep,
        gotoStep,
        submitStep,
        dispatch,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
