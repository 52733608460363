import {
  NEXT_BTN,
  WizardInitHandler,
  WizardStepObject,
  WizardStepStatus,
} from "components/molecules/Wizard";
import { RenewalRoutePath } from "./RenewalRoutePath";
import { DossierReconductionModel } from "domain/api-schemas";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import { ContratStatus, ESignStatus, PaymentStatus } from "./Renewal.types";
import { UniversignStatus } from "../Components/ESignature/universign-types";

export const initRenewalWizard: WizardInitHandler = () =>
  Promise.resolve({
    [RenewalRoutePath.ESIGN_STEP]: {
      label: "Signature de votre contrat",
      description: "",
      status: WizardStepStatus.STARTED,
    },
    [RenewalRoutePath.PAYMENT_STEP]: {
      label: "Paiement de votre cotisation",
      description: "",
      status: WizardStepStatus.UNSTARTED,
    },
    [RenewalRoutePath.CONFIRMATION_STEP]: {
      label: "Confirmation de reconduction",
      description: "",
      status: WizardStepStatus.UNSTARTED,
    },
  });

export const getNavProps = (steps: WizardStepObject[], goNext = true) => {
  const [, eSignStep, paymentStep] = steps;

  if (
    eSignStep.status === WizardStepStatus.FAILED ||
    eSignStep.status === WizardStepStatus.UNSTARTED
  ) {
    return { nextStepDisabled: true, nextStepLabel: "Commencer", buttons: NEXT_BTN };
  }

  if (paymentStep.status === WizardStepStatus.FAILED) {
    return {
      nextStepLabel: "Reprendre ma souscription",
      nextStepDisabled: true,
      buttons: NEXT_BTN,
    };
  }

  if (!goNext) {
    return { nextStepDisabled: true, nextStepLabel: "Continuer", buttons: NEXT_BTN };
  }

  if (paymentStep.status === WizardStepStatus.STARTED) {
    return { nextStepLabel: "Reprendre ma souscription", buttons: NEXT_BTN };
  }

  if (paymentStep.status === WizardStepStatus.SUCCEEDED) {
    return { nextStepLabel: "Continuer", buttons: NEXT_BTN };
  }

  return { nextStepLabel: "Commencer", buttons: NEXT_BTN };
};

const checkEsignStatus = async (
  dossierReconduction: DossierReconductionModel,
  steps: WizardStepObject[],
  signatureState: UniversignStatus | null,
) => {
  const [, eSignStep, paymentStep, confirmStep] = steps;
  const dateSignature = dossierReconduction.dateSignature
    ? " le " + format(Date.parse(dossierReconduction.dateSignature), "PPP", { locale: fr })
    : "";

  if (dossierReconduction.etatSignature === ESignStatus.EXPIRED) {
    eSignStep.description =
      "La page de signature a expiré . Veuillez nous contacter pour plus d'informations.";
    eSignStep.status = WizardStepStatus.FAILED;
    return false;
  } else if (
    dossierReconduction.etatSignature === ESignStatus.REFUSED ||
    signatureState === UniversignStatus.CANCELED
  ) {
    const dateToShow = dossierReconduction.dateSignature
      ? dateSignature
      : " le " + format(new Date(), "PPP", { locale: fr });
    eSignStep.description = "Vous avez refusé de signer le contrat de reconduction" + dateToShow;
    eSignStep.status = WizardStepStatus.FAILED;
    return false;
  } else if (
    dossierReconduction.etatSignature === ESignStatus.SIGNED ||
    signatureState === UniversignStatus.SIGNED
  ) {
    eSignStep.description = "Votre contrat a bien été signé" + dateSignature;
    eSignStep.status = WizardStepStatus.SUCCEEDED;
    paymentStep.status = WizardStepStatus.STARTED;
    confirmStep.status = WizardStepStatus.UNSTARTED;
    return true;
  } else {
    return true;
  }
};

const checkPaymentStatus = async (
  dossierReconduction: DossierReconductionModel,
  steps: WizardStepObject[],
) => {
  const [, , paymentStep, confirmStep] = steps;

  if (dossierReconduction.statutPaiement === PaymentStatus.VALID) {
    const datePaiement = dossierReconduction.datePaiement
      ? " le " + format(Date.parse(dossierReconduction.datePaiement), "PPP", { locale: fr })
      : "";
    paymentStep.description = "Votre paiement a bien été effectué" + datePaiement;
    paymentStep.status = WizardStepStatus.SUCCEEDED;
    confirmStep.status = WizardStepStatus.SUCCEEDED;
    if (dossierReconduction.etatContrat === ContratStatus.RENOUVELE) {
      const dateModificationEtat = dossierReconduction.dateModificationEtat
        ? " le " +
          format(Date.parse(dossierReconduction.dateModificationEtat), "PPP", { locale: fr })
        : "";
      confirmStep.description = "Votre contrat a bien été reconduit" + dateModificationEtat;
    }
  }
};

const checkEtatContrat = async (
  dossierReconduction: DossierReconductionModel,
  steps: WizardStepObject[],
) => {
  const [, eSignStep, paymentStep, confirmStep] = steps;
  if (dossierReconduction.etatContrat === ContratStatus.RENOUVELE) {
    eSignStep.description = "Votre contrat a déjà été renouvelé";
    eSignStep.status = WizardStepStatus.SUCCEEDED;
    paymentStep.description = "Votre contrat a déjà été renouvelé";
    paymentStep.status = WizardStepStatus.SUCCEEDED;
    confirmStep.description = "Votre contrat a déjà été renouvelé";
    confirmStep.status = WizardStepStatus.SUCCEEDED;
    return false;
  } else {
    return true;
  }
};

export const setStepsStatus = async (
  steps: WizardStepObject[],
  dossierReconduction: DossierReconductionModel,
  signatureState: UniversignStatus | null,
) => {
  await checkPaymentStatus(dossierReconduction, steps);
  const eSignResult = await checkEsignStatus(dossierReconduction, steps, signatureState);
  const etatContratResult = await checkEtatContrat(dossierReconduction, steps);
  return etatContratResult && eSignResult;
};
