import { FC, useState } from "react";
import { classNames } from "utils/classNames";
import { useTracking } from "hooks/useTracking";

import styles from "./BurgerMenu.module.scss";

interface BurgerMenuProps {
  isOpen: boolean;
  toggleOpen: (value: boolean) => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, toggleOpen }) => {
  const [isPristine, setIsPristine] = useState(true);
  const { sendToDataLayer } = useTracking();

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type
    <button
      id={isOpen ? "header-croix-menu-burger" : "header-menu-burger"}
      className={classNames(
        styles.BurgerMenu,
        isOpen && styles.isOpen,
        !isPristine && !isOpen && styles.isClosed,
      )}
      onClick={(e) => {
        toggleOpen(!isOpen);
        setIsPristine(false);
        sendToDataLayer(e.currentTarget.id, true);
      }}
    />
  );
};

export default BurgerMenu;
