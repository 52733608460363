import { FC } from "react";
import { Wizard } from "components/molecules/Wizard";
import { InfoStep } from "./InfoStep";
import { RegulStep } from "./RegulStep";
import { ConfirmationStep } from "./ConfirmationStep";
import { StatusStep } from "./StatusStep";
import { RegularisationRoutePath } from "./RegularisationRoutePath";
import { initWizard } from "./useRegularisation";
import { TunnelHeader } from "components/molecules/Header/TunnelHeader";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";

export const Page: FC = () => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(RoutePaths.CONTRAT);
  };

  return (
    <>
      <TunnelHeader title={""} disableLogoLink={true} phoneNumber={""} onCloseCallback={onClose} />
      <Wizard initHandler={initWizard}>
        <StatusStep path="index" />
        <InfoStep path={RegularisationRoutePath.INFO_STEP} label="Informations" />
        <RegulStep path={RegularisationRoutePath.REGUL_STEP} label="Régularisation" />
        <ConfirmationStep path={RegularisationRoutePath.CONFIRMATION_STEP} label="Confirmation" />
      </Wizard>
    </>
  );
};
