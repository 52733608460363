import { CODE_POSTAL, MOBILE_PHONE_NUMBER, PHONE_NUMBER } from "utils/regex";

export const FORM_DATA = [
  {
    key: "phoneFix",
    mapping: "telephoneFixe",
    label: "Téléphone fixe :",
    maxLength: 10,
    type: "tel",
    pattern: PHONE_NUMBER,
    size: 10,
    errorMessage: "Veuillez saisir un numéro teléphone fixe valide (10 chiffres).",
    gtmId: "info-perso-tel",
  },
  {
    key: "primaryAddress",
    mapping: "rue",
    label: "Adresse (n° et rue)* :",
    required: true,
    maxLength: 250,
    errorMessage: "L'adresse est une information obligatoire. Veuillez la renseigner.",
    gtmId: "info-perso-adresse",
  },
  {
    key: "secondaryAddress",
    mapping: "batimentResidence",
    label: "Bâtiment, résidence :",
    maxLength: 250,
    gtmId: "info-perso-bat",
  },
  {
    key: "doorNumber",
    mapping: "infoEscPorteRemise",
    label: "Infos esc, porte, remise :",
    maxLength: 250,
    gtmId: "info-perso-esc",
  },
  {
    key: "lastAddress",
    mapping: "lieuditBpHameau",
    label: "Lieu-dit / BP / Hameau :",
    maxLength: 250,
    gtmId: "info-perso-lieudit",
  },
  {
    key: "postalCode",
    mapping: "codePostal",
    label: "Code Postal* :",
    maxLength: 5,
    pattern: CODE_POSTAL,
    required: true,
    errorPatternMessage: "Veuillez saisir les 5 chiffres du code postale.",
    errorMessage: " Le code postal est une information obligatoire. Veuillez la renseigner",
    gtmId: "info-perso-cp",
  },
  {
    key: "city",
    mapping: "ville",
    label: "Ville* :",
    maxLength: 80,
    required: true,
    errorMessage: "La ville est une information obligatoire. Veuillez la renseigner.",
    gtmId: "info-perso-ville",
  },
];
export const STE_CIVILITE = "STE";
export const FORM_UNMODIFIABLE_DATA = [
  {
    key: "civilite",
    label: "Civilité :",
    gtmId: "info-perso-civilite",
    gtmTooltipId: "info-perso-lien-civilite",
  },
  {
    key: "completeName",
    label: "Prénom et nom :",
    hideForSte: true,
    gtmId: "info-perso-nom",
    gtmTooltipId: "info-perso-lien-nom",
  },
  {
    key: "raisonSociale",
    label: "Raison Sociale:",
    hideForPerso: true,
    gtmId: "info-perso-raison-sociale",
    gtmTooltipId: "info-perso-lien-raison-sociale",
  },
  {
    key: "email",
    label: "Email :",
    gtmId: "info-perso-email",
    gtmTooltipId: "info-perso-lien-email",
  },
  {
    key: "telephoneMobile",
    label: "Téléphone portable :",
    maxLength: 10,
    type: "tel",
    pattern: MOBILE_PHONE_NUMBER,
    errorMessage: "Veuillez saisir un numéro teléphone portable valide (10 chiffres).",
    gtmId: "info-perso-tel-perso",
  },
];
