import { FC } from "react";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { TunnelHeader } from "components/molecules/Header/TunnelHeader";
import { Button } from "components/atoms/Button/Button";

import styles from "pages/Reconduction/Reconduction.module.scss";

export const PageConfirmationCRM: FC = () => {
  return (
    <section>
      <TunnelHeader
        id={"crm-reconduction-success"}
        title={"Payer ma cotisation"}
        onCloseCallback={undefined}
        closeButtonId={"CRM-reconduction-confirmation"}
        displayClose={undefined}
        displayMobileCallBlock={false}
        disableLogoLink={true}
        phoneNumber={""}
      />
      <EndPage title="Votre reconduction a bien été prise en compte">
        <p>
          Merci de votre confiance ! Vous recevrez une confirmation par e-mail prochainement.
          <br />
          <br />
          Nous sommes heureux de vous accompagner sur la route, en toutes circonstances. <br />
        </p>
        <p>A très bientôt !</p>
        <p>L'équipe Roole</p>
        <div className={styles.CRMReconduction_RetourButton}>
          <Button
            variant="primary"
            size="large"
            onClick={() => window.location.replace(window.location.origin)}
          >
            Retour sur site mon compte
          </Button>
        </div>
      </EndPage>
    </section>
  );
};
