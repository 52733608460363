import { TunnelHeader } from "components/molecules/Header/TunnelHeader";
import { FC, useEffect, useState } from "react";
import { initWizard } from "./useResiliation";
import { ImmatStep } from "./ImmatStep";
import { ResiliationRoutePath } from "./ResiliationRoutePath";
import { Wizard } from "components/molecules/Wizard";
import { ConfirmationStep } from "./ConfirmationStep";
import { StatusStep } from "./StatusStep";
import { useParams } from "react-router-dom";
import ErrorPage from "pages/Error/ErrorPage";
import { OverlayLoader } from "@roole/components-library";
import { useSelfcareRequest } from "hooks/useSelfcareRequest";
import {
  DossierStateType,
  checkDossierIdSelfcareRequest,
  useGetSelfcareRequest,
} from "domain/service/Member/useSelfcareRequest";

export const Page: FC = () => {
  const params = useParams();
  const [dossierState, setDossierState] = useState<DossierStateType>();
  const [expiresIn, setExpiresIn] = useState<number>();
  const { setToken } = useSelfcareRequest();
  let interval: any = null;
  const SCOPE = "resiliation";

  const { data: selfcareRequest, isLoading: loading } = useGetSelfcareRequest({
    id: params.id ?? "",
    type: SCOPE,
  });

  useEffect(() => {
    if (!params.id || loading) {
      return;
    }
    selfcareRequest &&
      checkDossierIdSelfcareRequest(selfcareRequest).then((result) => {
        setDossierState({
          code: result.code,
          message: result.displayMessage
            ? "Votre lien de résiliation a expiré, merci de nous contacter pour en obtenir un nouveau."
            : undefined,
          tokenExpiration: result.token,
        });
      });
  }, [params.id, loading]);

  const callSetToken = () => {
    if (params.id) {
      setToken(params.id, SCOPE);
    }
  };

  useEffect(() => {
    dossierState?.tokenExpiration && setExpiresIn(dossierState?.tokenExpiration);
  }, [dossierState]);

  useEffect(() => {
    if (interval == null && expiresIn) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        callSetToken();
      }, expiresIn * 1000);
    }

    return () => clearInterval(interval);
  }, [expiresIn]);

  return (
    <>
      {loading ? (
        <OverlayLoader />
      ) : (
        <>
          {dossierState?.tokenExpiration ? (
            <>
              <TunnelHeader
                title={""}
                disableLogoLink={true}
                phoneNumber={""}
                displayClose={false}
              />
              <Wizard initHandler={initWizard}>
                <StatusStep path="index" />
                <ImmatStep
                  path={ResiliationRoutePath.IMMAT_STEP}
                  label="Ma demande de résiliation"
                />
                <ConfirmationStep
                  path={ResiliationRoutePath.CONFIRMATION_STEP}
                  label="Confirmation"
                />
              </Wizard>
            </>
          ) : (
            <ErrorPage errorMsg={dossierState?.message} errorNumber={dossierState?.code} />
          )}
        </>
      )}
    </>
  );
};
