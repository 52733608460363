import React, { FC, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { globalState } from "context/globalState/atom";
import { userListContratsState } from "context/contratsState/atom";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { Box } from "components/atoms/Box/Box";
import { Title } from "components/atoms/Title/Title";
import { classNames } from "utils/classNames";
import { getInactifContractsCount, getFirstValidContractIdAdhesion } from "utils/nbExpiredContract";
import { RoutePaths } from "routes/routePaths";
import ContractItem from "./ContractItem";
import { ValiditeContrat } from "domain/service/Contract/useContract";
import { useTracking } from "hooks/useTracking";
import { v4 as uuidv4 } from "uuid";

import { formatISO } from "date-fns";

import { ReactComponent as RoundCross } from "images/icons/roundCross.svg";
import styles from "./Page.module.scss";
import { refreshMonContratTriggerState } from "context/currentContratWithEcheanceState/atom";

export const Page: FC = () => {
  const { sendToDataLayer } = useTracking();
  const contracts = useRecoilValue(userListContratsState);
  const setRefreshMonContratTrigger = useSetRecoilState(refreshMonContratTriggerState);
  const setGlobalState = useSetRecoilState(globalState);
  const navigate = useNavigate();
  const expiredContracts = getInactifContractsCount(contracts);
  const { state } = useLocation() as {
    state: { returnUrl?: string; targetIdAdhesion?: string } | null;
  };

  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Mes contrats",
      displayCloseButton: false,
    });
  }, []);

  useEffect(() => {
    const allContractsExpiredOrNoContract =
      contracts.length === 0 || expiredContracts === contracts.length;
    const singleValidContract = contracts.length - expiredContracts === 1;
    const hasTargetIdAdhesion = Boolean(state?.targetIdAdhesion);

    if (allContractsExpiredOrNoContract) {
      navigate(RoutePaths.CONTRAT_AJOUTER, { replace: true });
    } else if (singleValidContract || hasTargetIdAdhesion) {
      const newIdAdhesion = state?.targetIdAdhesion ?? getFirstValidContractIdAdhesion(contracts);
      setGlobalState((gState) => ({
        ...gState,
        idAdhesion: newIdAdhesion,
      }));

      let url = RoutePaths.CONTRAT_HOME as string;
      if (hasTargetIdAdhesion) {
        const targetUrl = state?.returnUrl
          ? new URL(window.location.origin + state.returnUrl)
          : new URL(window.location.href);
        targetUrl.search = "";
        url = targetUrl.pathname;
      }

      navigate(url, {
        replace: true,
        state: { ...state, targetIdAdhesion: "" },
      });
    }
  }, [contracts, expiredContracts, navigate, setGlobalState, state]);

  const handleSelectAdhesion = useRecoilCallback(({ set }) => (idAdhesion: string) => {
    set(globalState, (state) => ({ ...state, idAdhesion }));
    // forces monContract query to refetch data
    setRefreshMonContratTrigger((prev) => prev + 1);
    navigate(RoutePaths.CONTRAT_HOME, { replace: true });
    localStorage.setItem("refreshCount", "0");
    localStorage.setItem("refreshDate", formatISO(new Date()));
  });

  return (
    <>
      {contracts && !state?.targetIdAdhesion && (
        <div className={styles.container}>
          <Title className={styles.title}>Je souhaite accéder au contrat :</Title>
          <div className={styles.tuiles}>
            {contracts.map(
              (contract) =>
                contract.validiteContrat !== ValiditeContrat.Inactif && (
                  <ContractItem
                    key={uuidv4()}
                    onClick={() => handleSelectAdhesion(contract.idAdhesion)}
                    contract={contract}
                  />
                ),
            )}
            <Box className={classNames(styles.tuile, styles.addContract)}>
              <NavLink
                className={styles.addContractLink}
                to={RoutePaths.CONTRAT_AJOUTER}
                onClick={() => sendToDataLayer("ajout-contrat")}
              >
                <RoundCross />
                <p className={styles.link}>Associer un nouveau contrat</p>
              </NavLink>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};
