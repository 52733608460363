import { useEffect } from "react";

import {
  useWizard,
  WizardNav,
  WizardStep,
  WizardStepLayout,
  PREV_BTN,
} from "components/molecules/Wizard";
import { Loader } from "@roole/components-library";
import { useESignature } from "../Components/ESignature/useSignature";
import { PortraitLayout } from "./PortraitLayout";
import { useRecoilState } from "recoil";
import { renewalState } from "context/renewalState/atom";
import { eSignatureState } from "../Components/ESignature/eSignatureState";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { UniversignStatus } from "../Components/ESignature/universign-types";
import { useTracking } from "hooks/useTracking";

const E_SIGNATURE_BLOCK_ID = "eSignatureContent";

export const RenewalEsignStep: WizardStep = () => {
  const { submitStep, nextStep, gotoStep, indexPath } = useWizard();
  const { setup, completed } = useESignature();

  const [renewal] = useRecoilState(renewalState);
  const [signatureState, setSignatureState] = useRecoilState(eSignatureState);

  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    const splittedUrl = renewal?.lienSignature?.split("#/?id=");
    if (splittedUrl) {
      const url = splittedUrl ? splittedUrl[0] : "";
      const signerId = splittedUrl ? splittedUrl[1] : "";
      const teardown = setup(E_SIGNATURE_BLOCK_ID, url, signerId);

      return () => teardown();
    }
  }, [setup, renewal?.lienSignature]);

  useEffect(() => {
    if (completed) {
      setSignatureState((state) => ({ ...state, completed: true }));
    }
  }, [completed, setSignatureState]);

  useEffect(() => {
    const date = format(Date.now(), "PPP", { locale: fr });
    if (signatureState.status === UniversignStatus.SIGNED) {
      sendToDataLayer("reconduction-signature", false, renewal.id);
      submitStep(
        async () => null,
        () => nextStep(),
        `Votre contrat a bien été signé le ${date}`,
      );
    } else if (signatureState.status === UniversignStatus.CANCELED) {
      gotoStep(indexPath);
    }
  }, [signatureState]);

  return (
    <WizardStepLayout
      title="Signature de votre contrat"
      subtitle={`
      Nous vous invitons à lire attentivement votre contrat ci-dessous.
      Après avoir accepté les conditions en cochant la case en bas du contrat, vous recevrez un SMS au numéro <strong>${renewal.mobilePhone}</strong>.
      Ce SMS contient un code de validation qui vous permettra de signer électroniquement.`}
    >
      <PortraitLayout id={E_SIGNATURE_BLOCK_ID}>
        <Loader />
      </PortraitLayout>
      <WizardNav buttons={PREV_BTN} />
    </WizardStepLayout>
  );
};
