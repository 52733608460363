export enum ContratStatus {
  RENOUVELE = "Renouvelé",
}

export enum ESignStatus {
  EXPIRED = "Expirée",
  REFUSED = "Refusée",
  SIGNED = "Signée",
}

export enum PaymentStatus {
  VALID = "Valide",
}
