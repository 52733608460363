import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { dispatcherState, reconductionState } from "context/reconductionState/Atoms";
import { RoutePaths } from "routes/routePaths";
import GarantiesDescription from "pages/Reconduction/description/GarantiesDescription";
import { GarantiesList } from "components/molecules/Garanties/GarantiesList/GarantiesList";
import ReconductionCotisationBlock from "./ReconductionCotisationBlock";
import { ICodePromo } from "pages/Reconduction/Reconduction.types";
import { OutletContextType } from "pages/Reconduction/Page";
import { currentContratQuery } from "context/currentContratState/selector";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { ValiditeContrat } from "domain/api-schemas";

import styles from "pages/Reconduction/Reconduction.module.scss";
import { HealthCheckGroups } from "SignalRModels";
import { useHealthCheckContext } from "HealthCheckProvider";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";
import { ContactBlockTunnel } from "components/molecules/ContactBlockTunnel/ContactBlockTunnel";

const Garanties: React.FC = () => {
  const reconductionStateValues = useRecoilValue(reconductionState);
  const monContrat = useRecoilValue(currentContratQuery);
  const { isDesktop, infosReconduction } = useOutletContext<OutletContextType>();
  const { listeGaranties, infosContratReconduction } = infosReconduction || {};
  const { packServicesReconduction, optionsPack } = infosContratReconduction || {};
  const navigate = useNavigate();
  const dispatcher = useRecoilValue(dispatcherState);
  const { promo, promoError, codePromo, currentStep } = reconductionStateValues;

  const [disableGroup, setDisableGroup] = useState(false);
  const { getHealthCheckByGroupName } = useHealthCheckContext();

  useEffect(() => {
    setDisableGroup(getHealthCheckByGroupName(HealthCheckGroups.RenouvellementContrat));
  }, [getHealthCheckByGroupName]);

  useEffect(() => {
    if (promoError) {
      dispatcher?.handleCodePromoValidation({ promo, hasError: promoError }, codePromo);
    }
  }, [codePromo, dispatcher, promo, promoError]);

  const handleNextStep = useCallback(() => {
    dispatcher?.initReconductionState(
      infosReconduction,
      isDesktop,
      monContrat?.membre?.telephoneMobile || null,
      currentStep + 1,
    );

    navigate(RoutePaths.RECONDUCTION_IBAN);
  }, [
    currentStep,
    dispatcher,
    infosReconduction,
    isDesktop,
    monContrat?.membre?.telephoneMobile,
    navigate,
  ]);

  const handleSetCodePromo = useCallback(
    ({ promo, codePromo, hasError }: ICodePromo, code: string | null) => {
      dispatcher?.handleCodePromoValidation({ promo, hasError }, code);
    },
    [dispatcher],
  );

  return (
    <>
      <GarantiesDescription
        isExpired={monContrat ? monContrat.validiteContrat == ValiditeContrat.Expire : false}
      />
      {!disableGroup ? (
        <div className={styles.Reconduction__titleAndContent}>
          <h2 className={styles.Reconduction__title}>Les garanties de votre futur contrat.</h2>
          <div className={styles.Reconduction__content}>
            <div className={styles.Garanties}>
              {listeGaranties && packServicesReconduction?.codePack && (
                <GarantiesList
                  list={listeGaranties}
                  codepack={packServicesReconduction?.codePack}
                  optionspack={optionsPack}
                  forceMobileDisplay
                  hideTitle
                />
              )}
              <div className={styles.Reconduction__GarantiesStep}>
                <NavigationButtons
                  prevProps={{
                    onClick: () => {
                      dispatcher?.cleanState();
                      navigate(RoutePaths.CONTRAT_HOME);
                    },
                    text: "Annuler",
                    gtmElementId: "reconduction-btn-annuler",
                  }}
                  nextProps={{
                    onClick: () => handleNextStep(),
                    gtmElementId: "reconduction-btn-suivant",
                  }}
                />
              </div>
            </div>

            <div className={styles.Reconduction__contentRight}>
              <ReconductionCotisationBlock
                price={infosReconduction?.tarifMensuel}
                handleChangePromo={handleSetCodePromo}
                handleNextStep={handleNextStep}
                monthFree={promo?.echeancesOffertes}
                promoCode={codePromo || undefined}
                promoError={promoError}
                isDisplayPromoCode
                nextButtonLabel="Suivant"
              />
              {isDesktop && (
                <>
                  <SocialBlock className={styles.Callblock}>
                    {[<ContactBlockTunnel idTracking="reconduction-telephone" />]}
                  </SocialBlock>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ErrorBlock
          phoneNumber={monContrat?.numeroTelephoneContact}
          variants={[ErrorBlockVariant.PhoneCall]}
        />
      )}
    </>
  );
};

export default Garanties;
