import { format, setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";

type FormatDateOptions = Intl.DateTimeFormatOptions & {
  locales?: string | Array<string>;
  fallback?: string;
};
export const formatDate = (
  date?: string | Date | null,
  { locales = "fr-FR", fallback = "?", ...options }: FormatDateOptions = {
    dateStyle: "short",
  },
) => {
  if (!date) {
    return "";
  }
  try {
    return new Intl.DateTimeFormat(locales, {
      dateStyle: "medium",
      ...options,
    }).format(new Date(date));
  } catch (e) {
    return fallback;
  }
};

export const formatDateDayMonth = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return `${("0" + date?.getDate()).slice(-2)}/${("0" + (date?.getMonth() + 1)).slice(-2)}`;
};

export const formatDateDayMonthYear = (date?: Date | null) => {
  if (!date) {
    return "";
  }
  return formatDateDayMonth(date) + `/${date?.getFullYear()}`;
};

export const formatDateRFC3339 = (date?: Date | null) => {
  if (!date) {
    return "";
  }

  return [
    date.toLocaleString("default", { year: "numeric" }),
    date.toLocaleString("default", { month: "2-digit" }),
    date.toLocaleString("default", { day: "2-digit" }),
  ].join("-");
};

export const formatToFrenchDate = (date: string | null) =>
  new Date(date || "").toLocaleDateString("fr-FR");

export const formatToFrenchLongDate = (date: Date) => {
  setDefaultOptions({ locale: fr });
  return format(date, date.getDate() === 1 ? "do MMMM yyyy" : "PPP");
};
