import { Box } from "components/atoms/Box/Box";
import { MonContrat } from "domain/api-schemas";
import { FC } from "react";
import { Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import styles from "./RegularisationCard.module.scss";
import EcheancierBox from "pages/Regularisation/EcheancierBox";
import { useRecoilValue } from "recoil";
import { echeancierState } from "pages/Regularisation/Context/EcheancierState/atom";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AlertIcon } from "images/alert.svg";
import { formatDate } from "utils/formatDate";
import { getMaskedIban } from "utils/MaskedIban";

const RegularisationCard: FC<{
  contract: MonContrat | null;
  className?: string;
}> = ({ contract, className }) => {
  const iban = contract?.coordonneesBancaires.iban ?? "";
  const maskedIban = getMaskedIban(iban);
  const echeancier = useRecoilValue(echeancierState);
  const dateFirstEcheance = new Date(
    (echeancier?.echeances && echeancier?.echeances[0]?.datePrevue) ?? "",
  );
  const resiliationDate = new Date(dateFirstEcheance.setMonth(dateFirstEcheance.getMonth() + 3));
  const navigate = useNavigate();

  return (
    <Box className={className}>
      <span className={styles.iconWrap}>
        <span className={styles.icon}>
          <AlertIcon />
        </span>
        <span className={styles.text}>
          <Text textVariant="heading-6" as="span">
            Votre contrat est en situation d'impayé
          </Text>
        </span>
      </span>
      <Text textVariant="body-em" as="p" className={styles.cardSubTitle}>
        Les prélèvements suivants n'ont pu aboutir :
      </Text>

      <EcheancierBox echeancier={echeancier}></EcheancierBox>
      <Text textVariant="body" as="p" className={styles.iban}>
        Moyen de paiement actuel: IBAN {maskedIban}
      </Text>
      <span className={styles.iconWrap}>
        <span className={styles.icon}>
          <AlertIcon />
        </span>
        <span className={styles.text}>
          <Text textVariant="body-em" as="span">
            {`Sans action de votre part, votre contrat sera résilié le ${formatDate(
              resiliationDate,
            )}`}
          </Text>
        </span>
      </span>
      <div className={styles.buttonCenter}>
        <Button
          variant="primary"
          onClick={(e) => {
            navigate(RoutePaths.REGULARISATION);
          }}
          size="large"
        >
          Régulariser ma situation
        </Button>
      </div>
    </Box>
  );
};

export default RegularisationCard;
