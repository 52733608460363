import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FileUploader,
  Text,
  FileUploaderRef,
  UploadState,
  OverlayLoader,
} from "@roole/components-library";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { monContratState } from "context/currentContratState/atom";
import {
  ErrorClaimPiecesJointesUpload,
  ErrorTypeClaimPiecesJointes,
  reportClaimStepState,
  reportClaimStepContextStateInitValue,
} from "context/reportClaimState/atom";
import { useReportClaim } from "domain/service/ReportClaim/useReportClaim";
import { classNames } from "utils/classNames";
import { RoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";

import { Card } from "components/atoms/Card/Card";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { ModalInfo } from "components/molecules/Modal/ModalInfo";

import commonStyles from "pages/ReportClaim/Page.module.scss";
import styles from "./ReportClaimFilesStep.module.scss";

export const ReportClaimFilesStep: FC = () => {
  const [reportClaimStepContextState, setReportClaimStepContextState] =
    useRecoilState(reportClaimStepState);
  const { loading, postReportClaim, editReportClaim } = useReportClaim();
  const monContrat = useRecoilValue(monContratState);
  const { membre } = monContrat || {};
  const navigate = useNavigate();
  const fileUploaderRef = useRef<FileUploaderRef>(null);
  const { sendToDataLayer } = useTracking();
  const [error, setError] = useState<string>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (reportClaimStepContextState.claimDate === null) {
      navigate(RoutePaths.DECLARER_SINISTRE_DATE);
    }
    if (reportClaimStepContextState.isEditing) {
      localStorage.setItem("editReportClaim", "true");
    }
  }, []);

  const handleExit = () => {
    sendToDataLayer("declarer-sinistre-type-modal-bouton-sortie");
    setShowModal(false);
  };

  const prevStepHandler = () => {
    if (reportClaimStepContextState.isEditing) {
      navigate(RoutePaths.SINISTRES);
    } else {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        activeStep: 3,
      });
      navigate(RoutePaths.DECLARER_SINISTRE_GUARANTEES);
    }
  };

  const uploadAndSendReportClaim = async () => {
    let nextRoute: RoutePaths = RoutePaths.DECLARER_SINISTRE_ERROR;

    try {
      await (fileUploaderRef.current?.files.length
        ? fileUploaderRef.current?.uploadFiles()
        : reportClaimStepContextState.isEditing
        ? editReportClaim([])
        : postReportClaim([]));
      if (reportClaimStepContextState.isEditing) {
        nextRoute = RoutePaths.DECLARER_SINISTRE_ATTACHMENT;
      } else {
        nextRoute = RoutePaths.DECLARER_SINISTRE_SUCCESS;
      }
    } catch (err: any) {
      let arrayFilesError: ErrorClaimPiecesJointesUpload[] = [];
      if (err?.codeError === 422) {
        setError(err.codeDetail);
        arrayFilesError = [{ error: err.codeDetail, name: "File" }];
        setReportClaimStepContextState({
          ...reportClaimStepContextState,
          activeStep: 5,
          claimFiles: [],
          errors: {
            ...reportClaimStepContextState.errors,
            claimPiecesJointesUpload: arrayFilesError,
          },
        });
        return;
      }
      nextRoute = RoutePaths.DECLARER_SINISTRE_ERROR;
      arrayFilesError = err?.files;
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        errors: {
          ...reportClaimStepContextState.errors,
          claimPiecesJointesUpload: arrayFilesError,
        },
      });
      if (!!fileUploaderRef.current?.files.length) {
        sendToDataLayer("declarer-sinistre-fichier-uploader");
      }
      return;
    }
    setReportClaimStepContextState({
      ...reportClaimStepContextStateInitValue,
      activeStep: 5,
    });
    localStorage.removeItem("editReportClaim");
    navigate(nextRoute);
  };

  const fileChange = (state: UploadState) => {
    setReportClaimStepContextState({
      ...reportClaimStepContextState,
      errors: {
        ...reportClaimStepContextState.errors,
        claimPiecesJointesUpload: state.files
          .filter((file) => file.errors?.length > 0)
          .map((file) => ({ name: file.ref.name, error: file.errors?.[0] })),
      },
    });
  };

  const nextStepHandler = async () => {
    const arrayFilesError: ErrorClaimPiecesJointesUpload[] = [];

    if (!reportClaimStepContextState.isEditing && !fileUploaderRef.current?.files.length) {
      setError(ErrorTypeClaimPiecesJointes.NO_FILE);
      return;
    } else if (
      reportClaimStepContextState.isEditing &&
      reportClaimStepContextState.claimFiles?.length === fileUploaderRef.current?.files.length
    ) {
      setError(ErrorTypeClaimPiecesJointes.NO_NEW_FILE);
      return;
    } else {
      setError(undefined);
    }
    fileUploaderRef.current?.files.forEach((file) => {
      if (file.errors.length > 0) {
        arrayFilesError.push({ name: file.ref.name, error: file.errors[0] });
      }
    });

    if (arrayFilesError.length > 0) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        errors: {
          ...reportClaimStepContextState.errors,
          claimPiecesJointesUpload: arrayFilesError,
        },
      });
      setShowModal(true);
      return;
    } else {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        errors: {
          ...reportClaimStepContextState.errors,
          claimPiecesJointesUpload: undefined,
        },
      });
    }
    sendToDataLayer("declarer-sinistre-fichier-bouton-envoyer");
    uploadAndSendReportClaim();
  };

  return (
    <>
      {loading && <OverlayLoader />}
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Envoi des pièces justificatives
        </Text>
      </div>
      <Card noMargin className={styles.contactUsTypeStep__Container}>
        <Text as="h2" textVariant="heading-4" className={styles.reportClaimGuaranteesStep__NewLine}>
          Quelles pièces justificatives ?
        </Text>
        <Text className={styles.reportClaimGuaranteesStep__NewLine}>
          Vous trouverez ci-dessous la liste des pièces justificatives à nous transmettre. Veillez à
          vous assurer que tous les documents soient entièrement visibles (toute la page) et
          exploitables (bonne lisibilité).
        </Text>
        <Text as="h3" textVariant="heading-5" className={styles.reportClaimGuaranteesStep__NewLine}>
          Justificatifs à fournir
        </Text>
        <Text
          as="p"
          className={classNames(
            styles.reportClaimGuaranteesStep__Warning,
            styles.reportClaimGuaranteesStep__NewLine,
          )}
        >
          Important : les pièces justificatives obligatoires annotées d’une (*) sont indispensables
          à l’étude de votre dossier.
        </Text>
        <ul>
          {reportClaimStepContextState?.claimGuarantee?.listePiece?.map((piece) => (
            <div key={uuidv4()}>
              <Text
                as="li"
                textVariant="heading-6"
                className={classNames(
                  styles.reportClaimGuaranteesStep__NewLine,
                  styles.reportClaimGuaranteesStep__ListElement,
                )}
              >
                {piece.titrePiece}
              </Text>
              <Text
                as="p"
                className={classNames(
                  styles.reportClaimGuaranteesStep__NewLine,
                  styles.reportClaimGuaranteesStep__ListSubElement,
                )}
              >
                {piece.descriptifPiece}
              </Text>
            </div>
          ))}
          {reportClaimStepContextState?.clientIban &&
            reportClaimStepContextState?.clientIban !== "" && (
              <>
                <Text
                  as="li"
                  textVariant="heading-6"
                  className={classNames(
                    styles.reportClaimGuaranteesStep__NewLine,
                    styles.reportClaimGuaranteesStep__ListElement,
                  )}
                >
                  (Facultatif) RIB
                </Text>
                <Text
                  as="p"
                  className={classNames(
                    styles.reportClaimGuaranteesStep__NewLine,
                    styles.reportClaimGuaranteesStep__ListSubElement,
                  )}
                >
                  Si vous souhaitez être indemnisé par virement, nous vous invitons à nous joindre
                  ci-dessous un RIB à votre nom. Dans le cas contraire, un chèque sera envoyé par
                  défaut à l'adresse suivante : <br />
                  {membre?.rue || ""} {membre?.codePostal || ""} {membre?.ville || ""}
                </Text>
              </>
            )}
        </ul>
        <form>
          <div className={styles.reportClaimGuaranteesStep__NewLine}>
            <FileUploader
              uploadedFiles={reportClaimStepContextState.claimFiles || undefined}
              maxTotalSize={10000000}
              maxSize={10000000000}
              subtitle={`La taille maximum globale de tous les fichiers est de 10Mo. Les formats supportés sont .jpg, .png et .pdf`}
              onUploadFiles={
                reportClaimStepContextState.isEditing ? editReportClaim : postReportClaim
              }
              ref={fileUploaderRef}
              dropZoneLabel="Cliquez-ici pour ajouter vos fichiers"
              onChange={fileChange}
            />
          </div>
          {showModal && (
            <ModalInfo
              title="Attention !"
              exitText="Retour"
              onExit={handleExit}
              exitTag="annuler_etape2"
              btnGroupTag="actionDemande"
              className={styles.reportClaimFileStep__Modal}
            >
              <p>
                Les fichiers suivants comportent des erreurs et ne seront pas envoyés.
                <br />
                Veuillez les corriger avant de valider votre déclaration de sinistre.
                <br />
              </p>
              <ul className={styles.reportClaimFileStep__List}>
                {reportClaimStepContextState?.errors?.claimPiecesJointesUpload?.map((file) => (
                  <li key={file.name}>
                    <div className={styles.reportClaimFileStep__FileLine}>
                      <>
                        <div className={styles.reportClaimFileStep__FileName}>{file.name}</div>:{" "}
                        {file.error}
                      </>
                    </div>
                  </li>
                ))}
              </ul>
            </ModalInfo>
          )}
          {!!error && (
            <p
              className={classNames(
                styles.reportClaimGuaranteesStep__NewLine,
                styles.reportClaimGuaranteesStep__Warning,
              )}
            >
              {error}
            </p>
          )}
          <NavigationButtons
            prevProps={{
              onClick: prevStepHandler,
              gtmElementId: "declarer-sinistre-fichier-bouton-precedent",
              text: reportClaimStepContextState.isEditing ? "Annuler" : undefined,
            }}
            nextProps={{
              type: "button",
              onClick: nextStepHandler,
              text: "Envoyer",
            }}
          />
        </form>
      </Card>
    </>
  );
};
