import { USER_ID_IN_LOCALSTORAGE, USER_ID_TOTAL_WASH } from "context/global.types";
import { useLocalStorage } from "./useLocalStorage";
import { useAuthenticationContext } from "AuthenticationProvider";

export const useSignOut = () => {
  const { logout } = useAuthenticationContext();
  const { deleteValue } = useLocalStorage();

  const signOut = () => {
    deleteValue(USER_ID_IN_LOCALSTORAGE);
    deleteValue(USER_ID_TOTAL_WASH);
    logout();
  };

  return { signOut };
};
