import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalState } from "context/globalState/atom";
import { dispatcherState, reconductionState } from "context/reconductionState/Atoms";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { RoutePaths } from "routes/routePaths";
import { useReconduction } from "domain/service/Contract/useReconduction";
import { OverlayLoader } from "@roole/components-library";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

import { useESignature } from "pages/Reconduction/Components/ESignature/useSignature";
import { UniversignStatus } from "pages/Reconduction/Components/ESignature/universign-types";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";

import styles from "./SignatureContrat.module.scss";

const E_SIGNATURE_BLOCK_ID = "eSignatureArea";

const SignatureContrat: React.FC = () => {
  const [urlContrat, setUrlContrat] = useState<string>();
  const { userEmail } = useRecoilValue(globalState);
  const {
    infosReconduction,
    iban,
    titulaire,
    isNewBankInfos,
    coordonneesBancaireId,
    phone,
    promo,
    currentStep,
  } = useRecoilValue(reconductionState);

  const navigate = useNavigate();
  const { pending: signaturePending, completed, status, setup, error } = useESignature();
  const dispatcher = useRecoilValue(dispatcherState);
  const { idAdhesion } = infosReconduction?.infosContratReconduction || {};
  const {
    mutateAsync: postReconduction,
    isLoading: reconductionLoading,
    error: reconductionError,
  } = useReconduction();
  useEffect(() => {
    if (infosReconduction === null) {
      navigate(RoutePaths.RECONDUCTION_HOME);
    }
  }, []);

  const pending = reconductionLoading || signaturePending;
  const hadError = (error || reconductionError) && !pending;

  const handlePreviousStep = () => {
    dispatcher?.updateStep(currentStep - 1);
    navigate(RoutePaths.RECONDUCTION_INFOPERSO);
    document.querySelector("body")?.scrollTo(0, 0);
  };

  const onSubmit = useCallback(async () => {
    const reconductionResult = `${window.location.origin}${RoutePaths.RECONDUCTION_RESULT_SUCCESS}`;
    const returnvalue = await postReconduction({
      email: userEmail,
      IdAdhesion: idAdhesion,
      iban: iban || "",
      coordonneesBancaireId: isNewBankInfos ? undefined : coordonneesBancaireId,
      titulaire: titulaire || "",
      telephone: phone || undefined,
      offrePromotionnelleId: promo?.offrePromotionnelleId,
      successUrl: reconductionResult,
      cancelUrl: reconductionResult,
      failUrl: reconductionResult,
      IsReconductionCb: false,
    });
    return returnvalue;
  }, [
    idAdhesion,
    coordonneesBancaireId,
    iban,
    isNewBankInfos,
    phone,
    postReconduction,
    promo?.offrePromotionnelleId,
    titulaire,
  ]);

  useEffect(() => {
    if (!iban || !titulaire) {
      navigate(RoutePaths.RECONDUCTION_IBAN);
    }
    onSubmit().then((url) => {
      if (url?.urlContrat) {
        setUrlContrat(url.urlContrat);
      }
    });
  }, [iban, navigate, onSubmit, titulaire]);

  useEffect(() => {
    if (urlContrat) {
      const urlESignature = urlContrat.split("#/?id=");
      const teardown = setup(E_SIGNATURE_BLOCK_ID, urlESignature[0], urlESignature[1]);
      return () => teardown();
    }
  }, [setup, urlContrat]);

  useEffect(() => {
    if (completed) {
      if (status === UniversignStatus.SIGNED) {
        navigate(RoutePaths.RECONDUCTION_RESULT_SUCCESS, { replace: true });
      } else if (status === UniversignStatus.CANCELED) {
        navigate(RoutePaths.RECONDUCTION_RESULT_CANCELED, { replace: true });
      }
    }
  }, [completed, navigate, status]);

  return (
    <>
      {pending && <OverlayLoader />}
      {hadError && (
        <ErrorBlock
          phoneNumber={formatPhoneNumber(
            infosReconduction?.infosContratReconduction?.numeroTelephoneContact,
          )}
          variants={[ErrorBlockVariant.PhoneCall]}
          location="reconduction"
        />
      )}
      {urlContrat && (
        <>
          <div id={E_SIGNATURE_BLOCK_ID} className={styles.signatureContrat_Div} />
          <NavigationButtons
            prevProps={{
              onClick: () => handlePreviousStep(),
            }}
            nextProps={{
              onClick: () => {},
              isHidden: true,
            }}
          />
        </>
      )}
    </>
  );
};

export default SignatureContrat;
