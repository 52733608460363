import axiosClient from "clientProvider/axiosConfig";
import { SignalRParams } from "domain/api-schemas";

export const authentificationApi = {
  linkUser: async () => {
    await axiosClient.post("Membre/ReconciliationCompteAuth0");
  },
  signalRNegociate: async () => {
    const response = await axiosClient.get<SignalRParams>(
      `${process.env.REACT_APP_API_ROOT}/HealthCheckHub/negotiate`,
    );
    return response.data;
  },
};
