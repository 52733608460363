import { FC, HTMLAttributes } from "react";

import { classNames } from "utils/classNames";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";

import styles from "./Alert.module.scss";

export const Alert: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children }) => (
  <div className={classNames(styles.bandeau, className)}>
    <WarningIcon />
    {children}
  </div>
);
