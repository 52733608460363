import { FC } from "react";
import { Loader } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { ReactComponent as FileIcon } from "images/icons/file.svg";
import { ReactComponent as DownloadIcon } from "images/icons/telechargement.svg";

import styles from "./AttestationCard.module.scss";

import { Download, useDocument } from "domain/service/Communication/useDocument";
import { GetDocumentData, MonContrat } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";

const DownloadDocs: FC<{
  title: string;
  contract: MonContrat | null;
  codeCourier: string;
  numeroLigneContrat?: number | null;
  filename: string;
  trackingId: string;
}> = ({ title, contract, codeCourier, numeroLigneContrat, filename, trackingId }) => {
  const { mutateAsync: downloadDoc, isLoading } = useDocument();
  const data: GetDocumentData = {
    adhesionId: contract?.idAdhesion ?? null,
    codeCourrier: codeCourier,
    numeroLigneContrat: numeroLigneContrat,
  };
  const { sendToDataLayer } = useTracking();

  const downloadPdf = async () => {
    sendToDataLayer(trackingId);
    const base64String = await downloadDoc(data);
    Download(base64String, filename);
  };

  return (
    <div className={styles.cardFile}>
      <div className={styles.attestationTitle}>
        <span className={styles.icon}>
          <FileIcon />
        </span>
        <span className={styles.text}>{title}</span>
      </div>
      <div className={styles.loaderContainer}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Button
            size="small"
            variant="outlined-light"
            onClick={downloadPdf}
            style={{ borderRadius: 1000, height: 42, width: 47 }}
          >
            <DownloadIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export default DownloadDocs;
