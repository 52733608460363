import { FC, useEffect, useRef } from "react";

import { preventDefault } from "utils/preventDefault";
import { useKeyPress } from "hooks/useKeyPress";
import { useUniqueId } from "hooks/useUniqueId";
import { ModalWithControl, ModalWithControlProps, ModalAPI } from "./ModalWithControl";
import { ButtonGroup } from "components/atoms/Button/ButtonGroup";
import { Button } from "components/atoms/Button/Button";
import { Title } from "components/atoms/Title/Title";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";

import styles from "./Modal.module.scss";

type InfoModalProps = {
  title: string;
  onExit?: () => void;
  exitText?: string;
  exitTag?: string;
  btnGroupTag?: string;
  displayClose?: boolean;
} & ModalWithControlProps;

export const ModalInfo: FC<InfoModalProps> = ({
  title,
  onExit,
  exitText = "Annuler",
  exitTag,
  btnGroupTag,
  children,
  open,
  control,
  className,
  displayClose = true,
}) => {
  const titleId = useUniqueId("modal-title");
  const contentId = useUniqueId("modal-content");
  const modalRef = useRef<ModalAPI>(null);
  const _onExit = () => modalRef.current?.toggle(false, onExit);
  useKeyPress("Escape", _onExit);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    modalRef?.current && control?.(modalRef.current);
  }, [control]);

  return (
    <ModalWithControl
      ref={modalRef}
      open={open}
      className={className}
      onOverlayClick={_onExit}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      role="alertdialog"
    >
      <div className={styles.confirmationMessage}>
        <header className={styles.header}>
          <Title as="h3" className={styles.title} id={titleId}>
            {title}
          </Title>
          {displayClose && (
            <a href="#close" onClick={preventDefault(_onExit)}>
              <CloseIcon title="Fermer et annuler" />
            </a>
          )}
        </header>
        <div id={contentId} className={styles.content}>
          {children}
        </div>
        <ButtonGroup data-selector={btnGroupTag} className={styles.buttonGroup}>
          <Button data-selector={exitTag} variant={"outlined-light"} onClick={_onExit}>
            {exitText}
          </Button>
        </ButtonGroup>
      </div>
    </ModalWithControl>
  );
};
