import { formatDate } from "utils/formatDate";
import { Alert } from "./Alert";
import PayerCotisationButton from "components/molecules/CotisationButton/PayerCotisationButton";

type AlertRenewProps = {
  contract: {
    dateFin: string;
    isRenouvelable?: boolean;
  };
};
export const AlertRenew = ({ contract }: AlertRenewProps) =>
  contract.isRenouvelable ? (
    <Alert>
      <div>
        Votre contrat arrive à échéance le <strong>{formatDate(contract.dateFin)}</strong>.
        N'oubliez pas de régler votre cotisation pour continuer de bénéficier de vos garanties et
        avantages.
      </div>
      <PayerCotisationButton isRenouvellementContrat />
    </Alert>
  ) : null;
