import React, { FC } from "react";

import { Box } from "components/atoms/Box/Box";
import styles from "./GarantiesDesactivatedCard.module.scss";
import { IconCarBrake } from "images/icons";
import { CallCard } from "components/molecules/CallCard/CallCard";
import { Text } from "@roole/components-library";

import { MonContrat } from "domain/api-schemas";

import { useTracking } from "hooks/useTracking";

type GarantiesDesactivatedCardProps = {
  contract?: MonContrat;
};

export const GarantiesDesactivatedCard: FC<GarantiesDesactivatedCardProps> = ({ contract }) => {
  const { sendToDataLayer } = useTracking();

  return (
    <Box className={styles.container}>
      <Text textVariant="subheading-em">Vos garanties sont désactivées</Text>
      <div className={styles.content}>
        <Text textVariant="body" className={styles.content}>
          Suite à votre résiliation, vous êtes désormais membre Roole Free. Vous bénéficiez ainsi
          d'une offre restreinte et n'êtes plus couvert par vos garanties en cas de sinistres.
        </Text>
      </div>
      <div className={styles.action}>
        <div className={styles.icon}>
          <IconCarBrake />
        </div>
        <div className={styles.containerText}>
          <div className={styles.text}>
            <Text textVariant="body">
              Vous souhaitez réactiver l'ensemble des garanties et services de votre complémentaire
              auto ? Contactez-nous gratuitement, nous nous occupons de tout !
            </Text>
          </div>
          <CallCard
            text="Appelez-nous"
            phoneNumber={contract?.numeroTelephoneContact || "01 46 90 37 00"}
            className={styles.phoneNumber}
            variant={"secondary"}
            sendToDataLayer={() => sendToDataLayer("garantie-telephone")}
          />
        </div>
      </div>
    </Box>
  );
};
