import { selector, selectorFamily } from "recoil";
import { communicationApi } from "domain/api/Communication/communicationApi";

export const categoriesMotifsQuery = selectorFamily({
  key: "CategoriesMotifsQuery",
  get: () => async () => {
    const response = await communicationApi.fetchCategoriesMotifs();
    return response;
  },
});

export const listCategoriesMotifsQuery = selector({
  key: "listCategoriesMotifsQuery",
  get: ({ get }) => get(categoriesMotifsQuery(null)),
});
