import { PropsWithChildren, useEffect, useState, FC } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { OverlayLoader } from "@roole/components-library";
import PageLayoutView from "./PageLayoutView";
import { LoadableState } from "context/state.types";
import { currentContratQuery } from "context/currentContratState/selector";
import { useMatchMedia } from "hooks/useMatchMedia";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { globalState } from "context/globalState/atom";
import { RoutePaths } from "routes/routePaths";

import cssVariables from "styles/variables.module.scss";
import { getReturnUrl } from "utils/returnUrl";

const PageLayout: FC<PropsWithChildren<{}>> = () => {
  const { idAdhesion, listContratAhesion } = useRecoilValue(globalState);
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isTablet = useMatchMedia(cssVariables.breakpointTablet);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: { returnUrl?: string; targetIdAdhesion?: string } | null;
  };
  const [searchParams] = useSearchParams();

  const [returnUrl] = useState(() => {
    const url = state?.returnUrl;
    return url && url !== RoutePaths.CONTRAT_CHOISIR ? url : getReturnUrl();
  });

  const [targetIdAdhesion] = useState(() => {
    const id = state?.targetIdAdhesion;
    return id && state?.returnUrl !== RoutePaths.CONTRAT_CHOISIR
      ? id
      : searchParams.get("targetId");
  });

  useEffect(() => {
    if (
      (listContratAhesion.length >= 1 && !idAdhesion) ||
      (targetIdAdhesion && listContratAhesion.includes(targetIdAdhesion))
    ) {
      navigate(RoutePaths.CONTRAT_CHOISIR, {
        state: {
          returnUrl,
          targetIdAdhesion,
        },
        replace: true,
      });
    }
  }, []);

  const loading = monContrat.state === LoadableState.Loading;

  return (
    <>
      {loading ? (
        <OverlayLoader />
      ) : (
        <PageLayoutView
          monContrat={monContrat.contents}
          isMobile={isMobile}
          isTablet={isTablet}
          formatPhoneNumber={formatPhoneNumber}
          isMobileNavOpen={isMobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
          error={monContrat.state === LoadableState.Has_Error}
        />
      )}
    </>
  );
};
export default PageLayout;
