import { selector, selectorFamily } from "recoil";
import { globalState } from "context/globalState/atom";
import { contractApi } from "domain/api/Contract/contractApi";
import { garantiesApi } from "domain/api/Garanties/GarantiesApi";

export const monGarantiesQuery = selectorFamily({
  key: "MonGarantiesQuery",
  get: (idAdhesion: string | null) => async () => {
    if (!idAdhesion) {
      return null;
    }

    const response = await contractApi.fetchGarantie(idAdhesion);
    return response;
  },
});

export const contratGarantiesQuery = selector({
  key: "ContratGarantiesQuery",
  get: ({ get }) => get(monGarantiesQuery(get(globalState).idAdhesion || null)),
});

export const garantieCategorieQuery = selectorFamily({
  key: "GarantieCategorieQuery",
  get: (idAdhesion: string | null) => async () => {
    if (!idAdhesion) {
      return null;
    }

    const response = await garantiesApi.fetchGarantieCategories(idAdhesion);
    return response;
  },
});

export const contratGarantieCategoriesQuery = selector({
  key: "ContratGarantieCategoriesQuery",
  get: ({ get }) => get(garantieCategorieQuery(get(globalState).idAdhesion || null)),
});
