import { useEffect, FC, useCallback, useState, useRef, PropsWithChildren } from "react";
import { Text } from "@roole/components-library";
import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";

import styles from "./SeeMore.module.scss";

interface SeeMoreProps extends CommonProps {
  collapsedHeight?: number;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  onDisclosure?(expanded: boolean): void;
  id?: string;
}

export const SeeMore: FC<PropsWithChildren<SeeMoreProps>> = ({
  children,
  className,
  collapsedHeight = 48,
  seeMoreLabel = "Voir plus",
  seeLessLabel = "Voir moins",
  onDisclosure,
  id,
}) => {
  const [expandable, setExpandable] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const disclosureHandler = useCallback(() => {
    setExpanded((value) => {
      onDisclosure?.(!value);
      return !value;
    });
  }, [setExpanded, onDisclosure]);

  const contentResizeHandler = useCallback(() => {
    if (!panelRef.current || !contentRef.current) {
      return;
    }

    panelRef.current.style.height = `${
      expanded ? contentRef.current?.scrollHeight : collapsedHeight
    }px`;

    setExpandable(contentRef.current.scrollHeight > collapsedHeight);
  }, [collapsedHeight, expanded, setExpandable]);

  useEffect(() => {
    if (!contentRef.current) {
      return () => undefined;
    }

    const target = contentRef.current;
    const observer = new ResizeObserver(contentResizeHandler);

    observer.observe(target);

    return () => observer.unobserve(target);
  }, [contentResizeHandler]);

  useEffect(() => {
    if (!expandable && expanded) {
      setExpanded(false);
    }
  }, [expandable, expanded, setExpanded]);

  const panelStyle = {
    height: expanded ? contentRef.current?.scrollHeight : collapsedHeight,
  };
  const disclosureBtnLabel = expanded ? seeLessLabel : seeMoreLabel;

  return (
    <div className={classNames(styles.seeMore, className)}>
      <div ref={panelRef} className={styles.seeMore__panel} style={panelStyle}>
        <div ref={contentRef}>{children}</div>
      </div>
      {expandable && (
        <Text
          role="button"
          className={styles.seeMore__disclosureBtn}
          textVariant="ui-1"
          onClick={disclosureHandler}
          id={id}
        >
          {disclosureBtnLabel}
        </Text>
      )}
    </div>
  );
};
