import axios, { AxiosResponse } from "axios";
import axiosClient from "clientProvider/axiosConfig";
import {
  ContratSinistre,
  DeclarationSinistre,
  GarantieProduit,
  HistoDemande,
  TypeEvenement,
} from "domain/api-schemas";

interface TypeEventRequest {
  idAdhesion: string;
  dateDeclarationSinistre: string;
  dateFinAncienContrat: string;
  dateDebut: string;
  dateSouscription: string;
}

interface TypeEventResponse {
  evenements: TypeEvenement[];
}

interface GuaranteeListRequest {
  idAdhesion: string;
  typeEvenement: TypeEvenement;
  productId: string;
  ageContratNum: number;
}

export interface GuaranteeListResponse {
  garanties: GarantieProduit[];
}

export interface ReportClaim {
  typeEvenementId: string | null;
  garantieId?: string;
  dateSinistre?: string;
  email: string;
  message?: string | null;
  contratId: string | null;
  claimId: string | null;
  piecesJointes: File[];
}

export interface EditableReportClaim {
  demandeId: string | null;
  piecesJointes: File[];
}

interface ReportClaimListRequest {
  idAdhesion: string;
}

interface ReportClaimListResponse {
  declarations: HistoDemande[];
}

interface ReportClaimReportRequest {
  idAdhesion: string;
  demandeId: string;
}

export interface ErrorType {
  codeError: string;
  codeDetails: string[];
  files?: { name: string; error: string };
}

export const reportClaimApi = {
  fetchTypeEvents: async (
    idAdhesion: string,
    dateDeclarationSinistre: string,
    dateDebut: string,
  ) => {
    try {
      const { data } = await axiosClient.get<TypeEventRequest, AxiosResponse<TypeEventResponse>>(
        `DeclarationSinistre/Evenements/${idAdhesion}/${dateDeclarationSinistre}/${dateDebut}`,
      );

      if (data) {
        return data.evenements;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error);
        return error;
      } else {
        console.error(error);
        return null;
      }
    }
  },

  fetchGuaranteesList: async (
    idAdhesion: string,
    typeEvenement: TypeEvenement | null,
    productId: string,
    dateDeclarationSinistre: string,
  ) => {
    try {
      const result = await axiosClient.get<
        GuaranteeListRequest,
        AxiosResponse<GuaranteeListResponse>
      >(
        `DeclarationSinistre/Garanties/${idAdhesion}/${typeEvenement?.typeEvenementId}/${productId}/${dateDeclarationSinistre}`,
      );
      return result.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  fetchInfoContrat: async (idAdhesion: string, dateDeclarationSinistre: string) => {
    try {
      const result = await axiosClient.get<GuaranteeListRequest, AxiosResponse<ContratSinistre>>(
        `DeclarationSinistre/Contrat/${idAdhesion}/${dateDeclarationSinistre}`,
      );
      return result.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  postReportClaim: (claim: ReportClaim, onUploadProgress?: (e: ProgressEvent) => void) => {
    const { piecesJointes, claimId: _, ...props } = claim;
    const payload = (Object.keys(props) as (keyof typeof props)[]).reduce((data, key) => {
      data.set(key, props[key] as string | Blob);
      return data;
    }, new FormData());

    piecesJointes?.forEach((file) => payload.append("piecesJointes", file));

    return axiosClient
      .post<string[], AxiosResponse<string[]>>(`DeclarationSinistre/CreerDeclaration`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then(({ data }) => data)
      .catch(({ response }) => {
        return Promise.reject<ErrorType>({
          codeError: response.status,
          codeDetails: response.data?.errorDetails || [],
          files: response.data?.errors.map((error: any) => ({
            name: error.filename,
            error: error.errorDescription,
          })),
        });
      });
  },

  editReportClaim: (claim: EditableReportClaim, onUploadProgress?: (e: ProgressEvent) => void) => {
    const { piecesJointes, demandeId } = claim;
    const payload = new FormData();

    piecesJointes?.forEach((file) => payload.append("piecesJointes", file));
    payload.append("demandeId", demandeId || "");

    return axiosClient
      .post<string[], AxiosResponse<string[]>>(`DeclarationSinistre/AddAttachment`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then(({ data }) => data)
      .catch(({ response }) => {
        return Promise.reject<ErrorType>({
          codeError: response.status,
          codeDetails: response.data?.errorDetails || [],
          files: response.data?.errors.map((error: any) => ({
            name: error.filename,
            error: error.errorDescription,
          })),
        });
      });
  },

  fetchReportClaims: async (idAdhesion: string) => {
    try {
      const result = await axiosClient.get<
        ReportClaimListRequest,
        AxiosResponse<ReportClaimListResponse>
      >(`DeclarationSinistre/Declarations/${idAdhesion}`);
      return result.data?.declarations;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  fetchReportClaim: async (idAdhesion: string, demandeId: string) => {
    try {
      const result = await axiosClient.get<
        ReportClaimReportRequest,
        AxiosResponse<DeclarationSinistre>
      >(`DeclarationSinistre/Declarations/GetById/${idAdhesion}/${demandeId}`);
      return result.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};
