import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Echeancier } from "domain/api-schemas";
import { FC } from "react";
import { classNames } from "utils/classNames";
import { Text } from "@roole/components-library";
import styles from "./InfoStep.module.scss";

const EcheancierBox: FC<{
  echeancier: Echeancier | null;
}> = ({ echeancier }) => {
  return (
    <div className={classNames(styles.echeancesBox, styles.greyBox)}>
      <div className={styles.echeances}>
        <div>
          <Text textVariant="body-em" as="p">
            Echéances
          </Text>
        </div>
        <div>
          <Text textVariant="body-em" as="p">
            Montant
          </Text>
        </div>
      </div>
      {echeancier?.echeances?.map((echeance, index) => (
        <div className={styles.echeances} key={index}>
          <div>
            <Text textVariant="body" as="p">
              {format(Date.parse(echeance.datePrevue), "PPP", { locale: fr })}
            </Text>
          </div>
          <div>
            <Text textVariant="body" as="p">
              {echeance.montant}€
            </Text>
          </div>
        </div>
      ))}
      <hr />
      <div className={styles.echeances}>
        <div></div>
        <div>
          <Text>Total: {echeancier?.montantTotal}€</Text>
        </div>
      </div>
    </div>
  );
};

export default EcheancierBox;
