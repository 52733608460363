import React, { useState, useEffect, createContext, useContext, useMemo } from "react";
import * as signalR from "@microsoft/signalr";
import { authentificationApi } from "domain/api/Authentification/Authentification";
import { HealthCheck, HealthCheckGroups, SignalRTarget } from "SignalRModels";
import { SignalRParams } from "domain/api-schemas";

interface HealthCheckContextType {
  healthCheckStatus: HealthCheck[];
  liveCheckActive: boolean;
  getHealthCheckByGroupName: (groupName: HealthCheckGroups) => boolean;
}

const HealthCheckContext = createContext<HealthCheckContextType | undefined>(undefined);
const HealthCheckStatusStorageToken = "HealthCheckStatus";

export function useHealthCheckContext() {
  const context = useContext(HealthCheckContext);
  if (context === undefined) {
    throw new Error("useHealthCheckContext must be used within an AppContextProvider");
  }
  return context;
}

const HealthCheckProvider = ({
  children,
  enableHealthCheck = true,
}: {
  children: JSX.Element;
  enableHealthCheck?: boolean;
}) => {
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection | null>(null);
  const [signalRParams, setSignalRParams] = useState<SignalRParams | null>(null);
  const [healthCheckStatus, setHealthCheckStatus] = useState<HealthCheck[]>([]);
  const [liveCheckActive, setLiveCheckActive] = useState<boolean>(false);

  const getHealthCheckByGroupName = (groupName: HealthCheckGroups): boolean => {
    return (
      liveCheckActive &&
      healthCheckStatus?.some &&
      healthCheckStatus?.some((res) => res.groupName === groupName && !res.isHealthy)
    );
  };

  const setParams = async () => {
    try {
      const response: SignalRParams = await authentificationApi.signalRNegociate();
      if (response.active !== false) {
        setSignalRParams(response);
      }
    } catch (error) {
      console.error("Failed to set SignalR parameters: ", error);
    }
  };

  useEffect(() => {
    enableHealthCheck &&
      (async () => {
        const healthCheckStatus = localStorage.getItem(HealthCheckStatusStorageToken);
        if (healthCheckStatus) {
          const parsedHealthCheckStatus = JSON.parse(healthCheckStatus);
          setHealthCheckStatus(parsedHealthCheckStatus);
        }
        await setParams();
      })();
  }, []);

  useEffect(() => {
    const connectToSignalR = async () => {
      if (!signalRParams) {
        return;
      }

      try {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(signalRParams.url, {
            accessTokenFactory: () => signalRParams.accessToken,
          })
          .withAutomaticReconnect()
          .build();

        connection.on(SignalRTarget.HealthCheckResult, (message: HealthCheck[]) => {
          const dataAsString = JSON.stringify(message);
          localStorage.setItem(HealthCheckStatusStorageToken, dataAsString);
          setHealthCheckStatus(message);
        });

        connection.onclose((error) => {
          setLiveCheckActive(false);
          console.error("Connection closed: ", error);
        });

        await connection.start();
        setLiveCheckActive(true);
        setHubConnection(connection);
      } catch (error) {
        setLiveCheckActive(false);
        console.error("Failed to connect: ", error);
      }
    };

    (async () => {
      if (!hubConnection && enableHealthCheck) {
        await connectToSignalR();
      }
    })();

    return () => {
      // Cleanup function
      (async () => {
        if (hubConnection) {
          hubConnection.off(SignalRTarget.HealthCheckResult);
          try {
            await hubConnection.stop();
          } catch (err) {
            console.error("Error stopping SignalR connection:", err);
          }
        }
      })();
    };
  }, [signalRParams, hubConnection]);

  const value = useMemo(
    () => ({
      healthCheckStatus,
      liveCheckActive,
      getHealthCheckByGroupName,
    }),
    [healthCheckStatus, liveCheckActive],
  );

  return <HealthCheckContext.Provider value={value}>{children}</HealthCheckContext.Provider>;
};

export default HealthCheckProvider;
