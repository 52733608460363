import { FC, AnchorHTMLAttributes, ReactNode } from "react";
import { ReactComponent as IconExternalLink } from "images/icons/externalLink.svg";
import { classNames } from "utils/classNames";
import styles from "./ExternalLink.module.scss";

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: ReactNode;
  id?: string;
  newTab?: boolean;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  id,
  icon = <IconExternalLink />,
  children,
  newTab = true,
  className,
  ...props
}) => (
  <a
    id={id}
    className={classNames(icon && styles.ExternalLink, className)}
    target={newTab ? "_blank" : "_self"}
    rel={newTab ? "noopener noreferrer" : ""}
    {...props}
  >
    {children} {icon}
  </a>
);
