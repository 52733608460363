export const DEFAULT_IDADHESION = "";
export const USER_ID_IN_LOCALSTORAGE = "cpIdAdhesion";
export const USER_ID_TOTAL_WASH = "cpTWIdAdhesion";

export enum APPLICATIONSTATES {
  GlobalState = "globalState",
  ContratState = "contratState",
  ContratStateWithEcheance = "contratStateWithEcheance",
  ListContratsState = "listContratsState",
  NavigationState = "navigationState",
  DeclareSinistreState = "DeclareSinistreState",
  GarantiesState = "garantiesState",
  BonPlansMobilitesState = "bonPlansMobilitesState",
  tunnelLayoutState = "tunnelLayoutState",
  StepState = "contactUsStepState",
  CategoriesMotifsState = "categoriesMotifsState",
  RenewalState = "renewalState",
  HostedTokenizationUrlState = "hostedTokenizationUrlState",
  HostedTokenizationIdState = "hostedTokenizationIdState",
  RegularisationState = "regularisationState",
}
