import { useMutation, useQuery } from "react-query";
import {
  InfosResiliation,
  Resiliation,
  ResiliationContract,
  ResiliationResult,
} from "domain/api-schemas";
import { contractApi } from "domain/api/Contract/contractApi";

export const useResiliation = () =>
  useMutation<ResiliationResult, Error, Resiliation>(
    async (data) => await contractApi.postResiliation(data),
  );

export const useResilitationContract = (idAdhesion: string) =>
  useQuery<ResiliationContract, Error>(
    "query-ResiliationContract",
    async () => await contractApi.queryResiliationContract(idAdhesion),
  );

export const useInfosResiliation = (dossierResiliationId: string) =>
  useQuery<InfosResiliation, Error>(
    "query-InfosResiliation",
    async () => await contractApi.getInfosResiliation(dossierResiliationId),
  );

export const useConfirmResiliation = () =>
  useMutation<ResiliationResult, Error, string>(async (data) =>
    contractApi.confirmResiliation(data),
  );
