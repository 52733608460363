import { FC } from "react";
import { v4 as uuidv4 } from "uuid";

import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import cssVariables from "styles/variables.module.scss";

import styles from "./SocialBlock.module.scss";

interface SocialBlockProps {
  children?: React.ReactNode[];
  className?: string;
}

export const SocialBlock: FC<SocialBlockProps> = ({ children, className }: SocialBlockProps) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return (
    <div className={classNames(styles.SocialBlock, className)}>
      {!isDesktop && <>{children?.[0]}</>}
      {isDesktop &&
        children &&
        children.map((child, index) => {
          return (
            <div key={uuidv4()}>
              {index !== 0 && <hr className={styles.hr} />}
              <div className={styles.SocialBlock__content}>{child}</div>
            </div>
          );
        })}
    </div>
  );
};
