import { FC } from "react";
import { CalendarContainer, ReactDatePickerProps } from "react-datepicker";

import { classNames } from "utils/classNames";
import styles from "./CustomCalendarContainer.module.scss";

export const CustomCalendarContainer: FC<ReactDatePickerProps> = ({ className, children }) => {
  return (
    <CalendarContainer className={classNames(styles.customCalendarContainer, className)}>
      {children}
    </CalendarContainer>
  );
};
