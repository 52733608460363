import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;
export const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE_GATEWAY;
export const DOMAIN = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN; // "dev-auth.roole.fr";
export const SCOPE = process.env.REACT_APP_AUTH0_SCOPE; // "openid profile email offline_access";

export const Auth0ProviderWithHistory = ({
  children,
}: PropsWithChildren<any>): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isBot, setIsBot] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("bot")) {
      setIsBot(true);
    }
  }, [location]);

  if (isBot) {
    // If it's a bot, render the page without redirecting to Auth0
    return <>{children}</>;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={DOMAIN!}
      clientId={CLIENTID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUDIENCE,
        scope: SCOPE,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation={"localstorage"}
      legacySameSiteCookie={false}
    >
      {children}
    </Auth0Provider>
  );
};
