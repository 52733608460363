import { FC, PropsWithChildren, ReactNode } from "react";

import { classNames } from "utils/classNames";
import { useMatchMedia } from "hooks/useMatchMedia";
import { CallCTA } from "components/molecules/CallCTA/CallCTA";
import { CarInfo } from "components/molecules/Header/CarInfo/CarInfo";
import { MonContrat } from "domain/api-schemas";

import cssVariables from "styles/variables.module.scss";
import styles from "./ContactBlock.module.scss";
import { HelpCard } from "../HelpCard/HelpCard";

type ContactBlockProps = {
  phoneNumber: string;
  phoneBlockText?: ReactNode;
  phoneButtonLabel?: ReactNode;
  className?: string;
  id?: string;
  contract?: MonContrat | null;
  sendToDataLayer?: () => void;
};

const ContactBlockMobile: FC<ContactBlockProps> = ({
  className,
  id,
  sendToDataLayer,
  contract = null,
}) => (
  <div className={classNames(className, styles.container, styles.bloc, styles.mobile)}>
    <div className={styles.contactBlockText}>
      <CarInfo contract={contract} className={styles.contactBlock__carInfo} />
    </div>
    <HelpCard
      id={id}
      variant="outlined-dark"
      sendToDataLayer={sendToDataLayer}
      text="Centre d'aide"
    />
  </div>
);

const ContactBlockDesktop: FC<PropsWithChildren<ContactBlockProps>> = ({
  children,
  phoneNumber,
  phoneBlockText,
  className,
  id,
  sendToDataLayer,
}) => (
  <div className={classNames(className, styles.container)}>
    {children && <div className={styles.content}>{children}</div>}
    <div className={styles.bloc}>
      <p>{phoneBlockText}</p>
      <CallCTA
        id={id}
        onClick={sendToDataLayer}
        phoneNumber={phoneNumber}
        className={styles.cta}
        href={`tel:${phoneNumber}`}
      >
        {phoneNumber}
      </CallCTA>
      <p>du lundi au vendredi, de 9h à 18h</p>
    </div>
  </div>
);

export const ContactBlock: FC<PropsWithChildren<ContactBlockProps>> = ({
  phoneNumber,
  phoneBlockText = (
    <>
      Si vous souhaitez nous contacter, nous serons ravis
      <br /> de répondre à toutes vos questions au :
    </>
  ),
  phoneButtonLabel = phoneNumber,
  children,
  className,
  id,
  contract,
  sendToDataLayer,
}) => {
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isTablet = useMatchMedia(cssVariables.breakpointTablet);
  if (isMobile || isTablet) {
    return ContactBlockMobile({
      phoneNumber,
      className,
      phoneButtonLabel: isTablet ? phoneButtonLabel : "Appeler",
      id,
      contract,
      sendToDataLayer,
    });
  }
  return ContactBlockDesktop({
    children,
    phoneBlockText,
    phoneButtonLabel,
    phoneNumber,
    id,
    className,
    sendToDataLayer,
  });
};
