import { FC, useState } from "react";
import { classNames } from "utils/classNames";
import { ReactComponent as VectorDown } from "images/icons/vectorDown.svg";
import { ReactComponent as VectorUp } from "images/icons/vectorUp.svg";

import styles from "./DropDown.module.scss";
import { Text } from "@roole/components-library";

type DropDownProps = {
  title: string;
  description: string;
  className?: string;
  htmlContent?: boolean;
  id?: string;
  onClick?: () => void;
};

export const DropDown: FC<DropDownProps> = ({
  title,
  description,
  className,
  htmlContent,
  id,
  onClick,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false as boolean);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={classNames(styles.container, className)} id={id} onClick={onClick} {...props}>
      <div className={styles.header} onClick={toggle}>
        <Text textVariant="body-em" as="h5" className={styles.title}>
          {title}
        </Text>
        {isOpen ? <VectorUp /> : <VectorDown />}
      </div>
      {isOpen && htmlContent ? (
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : isOpen ? (
        <p className={styles.description}>{description}</p>
      ) : (
        <></>
      )}
    </div>
  );
};
