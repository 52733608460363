import { HTMLAttributes, FC } from "react";
import { classNames } from "utils/classNames";
import { formatCurrency } from "utils/formatCurrency";

import styles from "./PriceBox.module.scss";

type PriceBoxProps = {
  price: number;
} & HTMLAttributes<HTMLParagraphElement>;

export const PriceBox: FC<PriceBoxProps> = ({ price, className, ...props }) => (
  <p className={classNames(styles.price, className)} {...props}>
    <b>
      <span className={styles.accentuate}>{formatCurrency(price)}</span>
      /mois
    </b>
    <br />
    hors remise ou code promo
  </p>
);
