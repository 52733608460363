import axiosClient from "clientProvider/axiosConfig";

interface addPjProps {
  idAdhesion?: number;
  TypeId: string;
  DossierGarantieId: string;
  EvenementId: string;
  Nom: string;
  Contenu: string;
  Extension: string;
  Categorie: string;
  Source: "RoolePremium" | "EspaceMembre";
}

export const addPj = async (props: addPjProps) => {
  const result = await axiosClient.post("/Document/CreatePieceDossier", {
    ...props,
  });

  return result.data;
};
