import axiosClient from "clientProvider/axiosConfig";
import { CreatePaymentData } from "domain/api-schemas";

export const paiementCbApi = {
  getHostedTokenizationUrl: async (dossierId: string) => {
    const result = await axiosClient.get<string>(`Paiement/GetHostedTokenizationUrl/${dossierId}`);
    return result.data;
  },
  createPayment: async (data: CreatePaymentData, dossierReconductionId: string) => {
    const result = await axiosClient.post<string>(
      `Paiement/CreerPaiement/${dossierReconductionId}`,
      data,
    );
    return result.data;
  },
};
