import { selectorFamily } from "recoil";
import { bonsPlansMobilitesApi } from "domain/api/BonPlansMobilites/bonsPlansMobilitesApi";
import { BonPlanCategory } from "domain/api-schemas";

export const bonPlansMobilitesQuery = selectorFamily({
  key: "bonPlansMobilitesQuery",
  get: (adhesionId: string | null) => async () => {
    if (!adhesionId) {
      return [];
    }
    const response = await bonsPlansMobilitesApi.fetchBonPlans(adhesionId);
    return response;
  },
});

export const listBonPlansMobilitesQuery = selectorFamily({
  key: "listBonPlansMobilitesQuery",
  get:
    (adhesionId: string | null) =>
    ({ get }) => {
      if (!adhesionId) {
        return [];
      }
      return get(bonPlansMobilitesQuery(adhesionId));
    },
});

export const bonPlanByIdQuery = selectorFamily({
  key: "fetchBonPlanByIdQuery",
  get:
    ({ adhesionId, bonPlanId }: { adhesionId: string | null; bonPlanId: string | null }) =>
    async () => {
      if (!adhesionId || !bonPlanId) {
        return null;
      }
      return await bonsPlansMobilitesApi.fetchBonPlanById(adhesionId, bonPlanId);
    },
});

export const bonPlanByCategoryIdQuery = selectorFamily({
  key: "fetchBonPlanByCategoryId",
  get:
    ({ adhesionId, categoryId }: { adhesionId: string | null; categoryId: string | null }) =>
    async () => {
      if (!adhesionId || categoryId === "0") {
        return [];
      }
      return await bonsPlansMobilitesApi.fetchBonPlanByCategoryId(adhesionId, categoryId);
    },
});

export const bonPlanCategoriesQuery = selectorFamily<BonPlanCategory[] | null, string | null>({
  key: "fetchCategories",
  get: (adhesionId) => async () => {
    if (!adhesionId) {
      return null;
    }
    return await bonsPlansMobilitesApi.fetchCategories(adhesionId);
  },
});

export const bonPlanHomepageQuery = selectorFamily({
  key: "fetchHomepage",
  get: (adhesionId: string | null) => async () => {
    if (!adhesionId) {
      return null;
    }
    return await bonsPlansMobilitesApi.fetchHomepage(adhesionId);
  },
});
