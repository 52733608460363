import { PropsWithChildren, useEffect, useState, FC } from "react";
import { MutableSnapshot, RecoilRoot } from "recoil";
import ApiHandler from "./ApiHandler";
import { globalState } from "context/globalState/atom";
import { DEFAULT_IDADHESION } from "context/global.types";
import { accessTokenOptions, getClaimsFromToken } from "./auth0.util";
import { addAccessTokenInterceptor } from "./axiosConfig";
import { useSignOut } from "hooks/useSignOut";
import { useAuthenticationContext } from "AuthenticationProvider";
import { getReturnUrl } from "utils/returnUrl";

const TokenProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const {
    isAuth0Authenticated,
    isAuth0Loading,
    isJwtAuthenticated,
    jwtToken,
    defaultIdAdhesion,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuthenticationContext();

  const { signOut } = useSignOut();
  const [idAhesions, setIdAhesions] = useState<string[] | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [auth0UserId, setAuth0UserId] = useState<string | null>(null);

  useEffect(() => {
    if (isAuth0Authenticated && !isAuth0Loading && !isJwtAuthenticated) {
      getAccessTokenSilently({ authorizationParams: accessTokenOptions })
        .then((value) => {
          setIdAhesions(getClaimsFromToken(value)["id_adhesions"]);
          setAccessToken(value);
          setAuth0UserId(getClaimsFromToken(value)["sub"] || null);
        })
        .catch((ex) => {
          console.error("=== failed get token ==", ex);
          signOut();
        });

      addAccessTokenInterceptor(getAccessTokenSilently);
    } else if (isJwtAuthenticated) {
      setIdAhesions([defaultIdAdhesion]);
      setAccessToken(jwtToken);
      setAuth0UserId(getClaimsFromToken(jwtToken)["sub"] ?? null);
    } else {
      loginWithRedirect({
        appState: {
          returnTo: getReturnUrl("/"),
        },
        authorizationParams: {
          prompt: "login",
        },
      });
    }
  }, [getAccessTokenSilently, isAuth0Authenticated, isAuth0Loading, jwtToken, isJwtAuthenticated]);

  const initGlobalState = async ({ set }: MutableSnapshot) => {
    const defaultIdAhesion =
      idAhesions && idAhesions.length === 1 ? idAhesions[0] : DEFAULT_IDADHESION;
    set(globalState, {
      idAdhesion: defaultIdAhesion,
      userEmail: user?.email || "",
      listContratAhesion: idAhesions || [],
      auth0UserId,
    });
    window.localStorage.setItem("confirmedEmail", "true");
    window.DT_EMAIL = user?.email || "";
  };

  return (
    <>
      {((accessToken && user?.email_verified) || (isJwtAuthenticated && idAhesions)) && (
        <ApiHandler>
          <RecoilRoot initializeState={initGlobalState}>{children}</RecoilRoot>
        </ApiHandler>
      )}
    </>
  );
};

export default TokenProvider;
