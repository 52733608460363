import { FC, HTMLAttributes } from "react";

import { classNames } from "utils/classNames";

import styles from "./TextInputContainer.module.scss";

/**
 * ATTENTION : les input doivent avoir la propriété required pour que le css fonctionne correctement
 * @param children
 * @param props
 * @returns stylisation for input
 */
export const TextInputContainer: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames(className, styles.input)} {...props}>
    {children}
  </div>
);
