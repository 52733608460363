import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { WizardStepStatus } from "./Wizard.types";
import { useWizard } from "./WizardContext";

export const WizardStepGuard: FC<PropsWithChildren> = ({ children }) => {
  const { indexPath, activeStep, initialized } = useWizard();

  if (!initialized || !activeStep || activeStep.status === WizardStepStatus.UNSTARTED) {
    return <Navigate to={`../${indexPath}`} replace />;
  }

  return <>{children}</>;
};
