import React, { Component, ErrorInfo, ReactNode } from "react";
import { useRecoilValueLoadable } from "recoil";
import { OverlayLoader } from "@roole/components-library";

import { currentContratQuery } from "context/currentContratState/selector";
import { LoadableState } from "context/state.types";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorBlockWrapper = () => {
  const monContrat = useRecoilValueLoadable(currentContratQuery);

  return monContrat.state === LoadableState.Has_Value ? (
    <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
  ) : (
    <OverlayLoader />
  );
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendCloseWebViewEvent({ hasError: true, errorDetails: { error } });
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorBlockWrapper />;
    }

    return children;
  }
}

export default ErrorBoundary;
