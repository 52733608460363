import { Text } from "@roole/components-library";
import styles from "./Banniere.module.scss";
import { Button } from "components/atoms/Button/Button";
import { BonPlanMobilite } from "domain/api-schemas";

import { useTracking } from "hooks/useTracking";
import { openWindowNoCache } from "utils/openWindowNoCache";
import { useRecoilValue } from "recoil";
import { globalState } from "context/globalState/atom";
import { bonsPlansMobilitesApi } from "domain/api/BonPlansMobilites/bonsPlansMobilitesApi";

export interface BonPlanCardProps {
  bonPlan: BonPlanMobilite;
  bonPlanIndex: number;
}

function getDaysDifference(date1: Date, date2: Date) {
  const oneDayMilliseconds = 24 * 60 * 60 * 1000;

  const date1Obj = typeof date1 === "string" ? new Date(date1) : date1;
  const date2Obj = typeof date2 === "string" ? new Date(date2) : date2;

  const timeDifference = Math.abs(date2Obj.getTime() - date1Obj.getTime());

  const daysDifference = Math.floor(timeDifference / oneDayMilliseconds);

  return daysDifference;
}

const Banniere: React.FC<BonPlanCardProps> = ({
  bonPlan: {
    banner,
    partner,
    expirationDate,
    customLabels,
    subPlans,
    hasCustomLink,
    id,
    customLink,
  },
  bonPlanIndex,
}) => {
  const { sendToDataLayer } = useTracking();
  const { idAdhesion } = useRecoilValue(globalState);

  const calculateDaysText = (expirationDate: Date) => {
    const daysDiff = getDaysDifference(expirationDate, new Date());
    const dayWord = daysDiff === 1 ? "jour" : "jours";
    return `${daysDiff} ${dayWord}`;
  };

  const renderBannerText = () => {
    if (expirationDate?.date) {
      const daysText = calculateDaysText(expirationDate.date);
      const mapText = banner?.map ? `• ${banner.map}` : "";
      return (
        <Text textVariant="ui-1">
          {expirationDate.text.replace("{date}", "")}{" "}
          <span className={styles.highlightExpirationDate}>{daysText}</span> {mapText}
        </Text>
      );
    } else if (banner?.map) {
      return <Text textVariant="ui-1">• {banner.map} •</Text>;
    }
    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.BackBannerWrapper}
        style={{
          backgroundImage: `url("${banner?.picture?.url}")`,
          // borderRadius: 12,
          // borderTopRightRadius: 16
        }}
      >
        <div className={styles.companyLogoWrapper}>
          <img src={partner?.logo?.url} alt={partner?.logo?.alt} className={styles.companyLogo} />
        </div>
        {customLabels && customLabels.length > 0 && (
          <Text
            textVariant="subheading"
            as="span"
            className={styles.offerType}
            style={{
              backgroundColor: customLabels[0].backgroundColor,
              color: customLabels[0].color,
            }}
          >
            {customLabels[0].text}
          </Text>
        )}
      </div>

      <div
        className={`${styles.OverBanner} ${
          !subPlans || subPlans.length === 0 || !subPlans[0].ctaLink
            ? styles.OverBanner__NoSubPlan
            : ""
        }`}
      >
        <Text textVariant="body-em" dangerouslySetInnerHTML={{ __html: banner?.text }} />
        {renderBannerText()}
        {(hasCustomLink || (subPlans && subPlans[bonPlanIndex].ctaLink)) &&
          (customLink?.hasError ? (
            <>{customLink?.errorMessage}</>
          ) : (
            <Button
              id={`bons-plans-JenProfite1-${partner?.name}`}
              variant="primary"
              onClick={async (e) => {
                sendToDataLayer(e.currentTarget.id, false);
                if (hasCustomLink) {
                  const customLink = await bonsPlansMobilitesApi.generateCustomLink(
                    id!,
                    idAdhesion,
                  );
                  customLink && openWindowNoCache(customLink.link!);
                } else {
                  subPlans && subPlans[0].ctaLink && openWindowNoCache(subPlans[0].ctaLink);
                }
              }}
              className={styles.OverBanner__EnProfiter}
            >
              <b>En profiter</b>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default Banniere;
