import { OffrePromoResult } from "domain/api-schemas";
import { RoutePaths } from "routes/routePaths";

export interface ICodePromo {
  promo?: OffrePromoResult | null;
  hasError?: boolean;
  codePromo?: string;
}

export const StepsReconduction = [
  "Mes garanties",
  "Mes infos de paiement",
  "Mes infos personnelles",
  "Signature",
  "Confirmation",
];

export const STEPS_ROUTES = [
  RoutePaths.RECONDUCTION_HOME,
  RoutePaths.RECONDUCTION_IBAN,
  RoutePaths.RECONDUCTION_INFOPERSO,
  RoutePaths.RECONDUCTION_SIGNATURE,
  RoutePaths.RECONDUCTION_RESULT_SUCCESS,
  RoutePaths.RECONDUCTION_RESULT_CANCELED,
].map((e) => e.toString());
