import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { OverlayLoader } from "@roole/components-library";

import { RoutePaths } from "routes/routePaths";
import { navigationState } from "context/navigationState/atom";
import { LoadableState } from "context/state.types";

import { currentContratQuery } from "context/currentContratState/selector";

import { Footer } from "components/molecules/Footer/Footer";
import {
  reportClaimStepContextStateInitValue,
  reportClaimStepState,
} from "context/reportClaimState/atom";
import { Page } from "./Page";

import styles from "./Page.module.scss";

export const ReportClaimGuard = () => {
  const { contents: navState, state } = useRecoilValueLoadable(navigationState);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [returnValue, setReturnValue] = useState<JSX.Element | null>(null);
  const monContrat = useRecoilValueLoadable(currentContratQuery);

  const setReportClaimStepContextState = useSetRecoilState(reportClaimStepState);
  useEffect(() => {
    const editReportClaim = localStorage.getItem("editReportClaim");

    if (state === LoadableState.Loading) {
      return;
    }
    if (navState?.displayRoutes?.[RoutePaths.DECLARER_SINISTRE]) {
      if (editReportClaim === "true") {
        localStorage.removeItem("editReportClaim");
        setReportClaimStepContextState(reportClaimStepContextStateInitValue);
        setReturnValue(<Navigate to={RoutePaths.SINISTRES} />);
      } else {
        setReturnValue(<Page />);
      }
    } else {
      setReportClaimStepContextState(reportClaimStepContextStateInitValue);
      setReturnValue(<Navigate to={RoutePaths.CONTRAT_HOME} />);
    }
    setIsLoaded(true);
  }, [navState]);

  return isLoaded ? (
    <>
      {returnValue}
      <Footer
        contrat={monContrat?.contents || null}
        idAdhesion={monContrat?.contents?.idAdhesion || null}
      />
    </>
  ) : (
    <div className={styles.reportClaim__Loader}>
      <OverlayLoader />
    </div>
  );
};
