import axiosClient from "clientProvider/axiosConfig";

import { ContactCategoriesMotifs, ContactCreateActivityData } from "domain/api-schemas";

export const communicationApi = {
  fetchDoc: async (
    adhesionId: string | null,
    codeCourrier: string,
    numeroLigneContrat?: number | null,
  ) => {
    if (!adhesionId) {
      throw new Error("missing adhesion id");
    }
    const result = await axiosClient.get<string>("CommunicationSortante/Document/Send", {
      params: {
        idAdhesion: adhesionId,
        codeCourrier: codeCourrier,
        numeroLigneContrat: numeroLigneContrat,
      },
    });
    return result.data;
  },

  fetchArgos: async (adhesionId: string) => {
    const result = await axiosClient.post<boolean>(
      "CommunicationSortante/AttestationArgos/Send",
      `"${adhesionId}"`,
      {
        timeout: 50000,
      },
    );
    return result.data;
  },

  fetchFacture: async (adhesionId?: string | null) => {
    if (!adhesionId) {
      throw new Error("missing adhesion id");
    }

    const result = await axiosClient.post<boolean>(
      "CommunicationSortante/Facture/Send",
      `"${adhesionId}"`,
      {
        timeout: 50000,
      },
    );
    return result.data;
  },

  fetchCategoriesMotifs: async () => {
    const result = await axiosClient.get<ContactCategoriesMotifs[]>(`Contact/CategoriesMotifs/`);
    return result.data;
  },

  postContactUs: async (data: ContactCreateActivityData) => {
    const { status } = await axiosClient.post(`Contact/CreateActivityAsync`, data);
    return status;
  },
};
