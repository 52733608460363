import { monContratState } from "context/currentContratState/atom";
import { regularisationApi } from "domain/api/Regularisation/regularisationApi";
import { selector, selectorFamily } from "recoil";

export const echeancesQuery = selectorFamily({
  key: "echeancesQuery",
  get: (contratId: string | null) => async () => {
    if (!contratId) {
      return null;
    }
    const response = await regularisationApi.getInfos(contratId);
    return response;
  },
});

export const listEcheancesQuery = selector({
  key: "listEcheancesQuery",
  get: ({ get }) => get(echeancesQuery(get(monContratState)?.contratId ?? null)),
});
