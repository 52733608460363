import { FC, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainHeader from "components/molecules/Header/MainHeader";
import { NavSidebarMobile } from "components/molecules/NavSidebar/NavSidebarMobile";
import { NavSidebarLayout } from "components/molecules/NavSidebar/NavSidebarLayout";
import { Footer } from "components/molecules/Footer/Footer";
import ErrorPage from "pages/Error/ErrorPage";
import { MonContrat } from "domain/api-schemas";

import styles from "./PageLayout.module.scss";

interface PageLayoutViewProps {
  monContrat: MonContrat | null;
  isMobile: boolean;
  isTablet: boolean;
  isMobileNavOpen: boolean;
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formatPhoneNumber: (contractPhoneNumber?: string | null | undefined) => string;
  error?: boolean;
}

const ContentPage = ({ monContrat }: { monContrat: MonContrat }) =>
  monContrat && (
    <NavSidebarLayout contract={monContrat}>
      <div className={styles.PageLayout__content}>
        <Outlet />
      </div>
    </NavSidebarLayout>
  );

const PageLayoutView: FC<PageLayoutViewProps> = ({
  monContrat,
  isMobile,
  isTablet,
  formatPhoneNumber,
  isMobileNavOpen,
  setMobileNavOpen,
  error = false,
}) => {
  const phoneNumber = formatPhoneNumber(monContrat?.numeroTelephoneContact);
  const location = useLocation();
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    setKey(Math.random());
  }, [location]);

  return (
    <div className={styles.PageLayout} key={key}>
      {monContrat && (
        <>
          {!error && (
            <MainHeader
              contract={monContrat || null}
              phoneNumber={phoneNumber}
              toggleMenu={setMobileNavOpen}
              isOpen={isMobileNavOpen}
              error={error}
            />
          )}
          {(isMobile || isTablet) && !error && (
            <NavSidebarMobile
              isOpen={isMobileNavOpen}
              onClose={() => setMobileNavOpen(false)}
              contract={monContrat}
            />
          )}
          {error ? <ErrorPage /> : <ContentPage monContrat={monContrat} />}
          <Footer contrat={monContrat || null} idAdhesion={monContrat?.idAdhesion || null} />
        </>
      )}
    </div>
  );
};

export default PageLayoutView;
