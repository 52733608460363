import { Text } from "@roole/components-library";
import { DetailedHTMLProps, FC, TextareaHTMLAttributes, useState } from "react";
import { classNames } from "utils/classNames";

import styles from "./InputArea.module.scss";

type InputAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  error?: boolean;
  setError?: (error: boolean) => void;
  errorMessage?: string;
  textAreaClassName?: string;
};

const regexScript = /<script\b[^>]*>([\s\S]*?)<\/script\s*>/gi;

export const InputArea: FC<InputAreaProps> = ({
  className = "",
  textAreaClassName = "",
  rows = 10,
  error = false,
  setError = () => undefined,
  onChange = () => undefined,
  required,
  errorMessage = "",
  ...props
}) => {
  const [scriptError, setScriptError] = useState(false);
  return (
    <>
      <div
        className={classNames(
          styles.InputArea__Wrapper,
          (error || scriptError) && styles.InputArea__Wrapper__Error,
          className,
        )}
      >
        <textarea
          {...props}
          className={classNames(
            styles.InputArea__Input,
            error && styles.InputArea__Input__Error,
            textAreaClassName,
          )}
          rows={rows}
          onChange={(item) => {
            if (regexScript.test(item.target.value)) {
              setScriptError(true);
              setError(true);
            } else {
              setScriptError(false);
              item.target.value.length > 0 && setError(false);
            }
            onChange(item);
          }}
        />
      </div>
      {scriptError && (
        <div className={styles.InputArea__Error__Wrapper}>
          <Text>Veuillez enlever les balises script.</Text>
        </div>
      )}
      {required && error && !scriptError && (
        <div className={styles.InputArea__Error__Wrapper}>
          <Text>{errorMessage}</Text>
        </div>
      )}
    </>
  );
};
