import styles from "./BonPlanToComeCard.module.scss";
import { Text } from "@roole/components-library";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { BonPlanMobilite } from "domain/api-schemas";

interface BonPlanToComeCardProps {
  bonPlan: BonPlanMobilite;
}

const BonPlanToComeCard: React.FC<BonPlanToComeCardProps> = ({ bonPlan }) => {
  return (
    bonPlan && (
      <div
        className={styles.BonPlanToComeCard}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.71) 0%, rgba(0, 0, 0, 0.71) 100%), url(${bonPlan.banner?.picture?.url}), lightgray 50% / cover no-repeat`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Text as="h4" textVariant="subheading-em" className={styles.title}>
          {bonPlan?.startingDate?.text.replace(
            "{date}",
            format(new Date(bonPlan?.startingDate?.date!), "dd MMMM yyyy", { locale: fr }),
          )}
        </Text>

        <Text
          as="p"
          textVariant="body"
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: bonPlan.banner?.text }}
        />

        <div className={styles.footer}>
          <div className={styles.companyLogoWrapper}>
            <img
              src={bonPlan.partner?.logo?.url}
              alt="companyLogo"
              className={styles.companyLogo}
            />
          </div>

          {bonPlan.banner?.map && (
            <>
              <Text as="p" textVariant="ui-1">
                •
              </Text>
              <Text as="p" textVariant="ui-1">
                {bonPlan.banner?.map}
              </Text>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default BonPlanToComeCard;
