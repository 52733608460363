import { atom } from "recoil";
import { UniversignStatus } from "./universign-types";

export interface ESignatureState {
  loading: boolean;
  loaded: boolean;
  embeding: boolean;
  embeded: boolean;
  signing: boolean;
  status: UniversignStatus;
  completed: boolean;
  error: string | null;
}

export const initialESignatureState: ESignatureState = {
  loading: false,
  loaded: false,
  embeding: false,
  embeded: false,
  signing: false,
  status: UniversignStatus.PENDING_VALIDATION,
  completed: false,
  error: null,
};

export const eSignatureState = atom<ESignatureState>({
  key: "eSignatureState",
  default: initialESignatureState,
});
