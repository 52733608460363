import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { dispatcherState } from "context/reconductionState/Atoms";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { Button } from "components/atoms/Button/Button";
import { RoutePaths } from "routes/routePaths";
import {
  eSignatureState,
  initialESignatureState,
} from "pages/Reconduction/Components/ESignature/eSignatureState";
import { globalState } from "context/globalState/atom";
import { monContratQuery } from "context/currentContratState/selector";
import { listContratsInfoQuery } from "context/contratsState/selector";
import { useLocalStorage } from "hooks/useLocalStorage";

import styles from "pages/Reconduction/Reconduction.module.scss";
import { useTracking } from "hooks/useTracking";

const ReconductionConfirmation: FC = () => {
  const dispatcher = useRecoilValue(dispatcherState);
  const navigate = useNavigate();
  const [globalStateValue, setGlobalStateValue] = useRecoilState(globalState);
  const setSignatureState = useSetRecoilState(eSignatureState);
  const refreshMonContrat = useRecoilRefresher_UNSTABLE(
    monContratQuery(globalStateValue.idAdhesion),
  );
  const { deleteValue } = useLocalStorage();
  const { sendToDataLayer } = useTracking();

  const refreshlistContrats = useRecoilRefresher_UNSTABLE(
    listContratsInfoQuery(globalStateValue.listContratAhesion),
  );
  useEffect(() => {
    setSignatureState((state) => ({ ...state, ...initialESignatureState }));
  }, [setSignatureState]);

  useEffect(() => {
    dispatcher?.cleanState();
    deleteValue("initReconduction");
  }, [dispatcher, setGlobalStateValue]);

  const returnButtonClickHandle = useCallback(async () => {
    refreshMonContrat();
    refreshlistContrats();
    navigate(RoutePaths.CONTRAT, { replace: true });
  }, [navigate, refreshMonContrat, refreshlistContrats]);

  return (
    <EndPage title="Votre reconduction a bien été prise en compte">
      <p>
        Merci de votre confiance ! Vous recevrez une confirmation par e-mail prochainement.
        <br />
        <br />
        Nous sommes heureux de vous accompagner sur la route, en toutes circonstances. <br />
      </p>
      <p>A très bientôt !</p>
      <p>L'équipe Roole</p>
      <Button
        variant="outlined-light"
        className={styles.Reconduction_RetourButton}
        onClick={() => {
          returnButtonClickHandle();
          sendToDataLayer("reconduction-confirmation");
        }}
      >
        Retour à l'accueil
      </Button>
    </EndPage>
  );
};

export default ReconductionConfirmation;
