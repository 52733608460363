import { GetDocumentData } from "domain/api-schemas";
import { communicationApi } from "domain/api/Communication/communicationApi";
import { useMutation } from "react-query";

export const useDocument = () =>
  useMutation<string, Error, GetDocumentData>(
    async (data) =>
      await communicationApi.fetchDoc(data.adhesionId, data.codeCourrier, data.numeroLigneContrat),
  );

export const Download = async (base64String: string, filename: string) => {
  const byteArray = new Uint8Array(
    window
      .atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0)),
  );
  const file = new Blob([byteArray], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  let link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";
  link.href = fileURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
