import { descriptionPjApi } from "domain/api/Sinistre/DescriptionPjApi";
import { selectorFamily, atom } from "recoil";

export const descriptionPjQuery = selectorFamily({
  key: "descriptionPjQuery",
  get: (ids: string[]) => async () => {
    return await descriptionPjApi.fetchDescriptionPj(ids);
  },
});

export const listDescriptionPjQuery = selectorFamily({
  key: "listDescriptionPjQuery",
  get:
    (ids: string[]) =>
    ({ get }) =>
      get(descriptionPjQuery(ids)),
});

export const descriptionPjState = atom({
  key: "descriptionPjState",
  default: null,
});
