function addSpaces(number: number) {
  let str = number.toString();
  let reversedStr = str.split("").reverse().join("");
  let spacedReversedStr = reversedStr.replace(/(\d{3})/g, "$1 ");
  let finalStr = spacedReversedStr.split("").reverse().join("");
  return finalStr.trim();
}

export const formatNumberDigits = (number: number | null | undefined) => {
  if (number !== null && number !== undefined) {
    if (number <= 10) {
      return number;
    }
    let strNumber = number.toString();
    let newNumber;

    if (strNumber.length <= 3) {
      newNumber = Math.floor(number / 10) * 10;
    } else if (strNumber.length === 4) {
      newNumber = Math.floor(number / 100) * 100;
    } else {
      newNumber = Math.floor(number / 1000) * 1000;
    }
    return "plus de " + addSpaces(newNumber);
  }
  return 0;
};
