import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/atoms/Button/Button";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { useTracking } from "hooks/useTracking";

import { useSetRecoilState } from "recoil";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";

import styles from "./EndPathLayout.module.scss";
import { TunnelHeader } from "../Header/TunnelHeader";
import { useAuthenticationContext } from "AuthenticationProvider";

interface EndPathLayoutProps {
  headerTitle: string;
  subTitle: string;
  buttonText: string;
  id: string;
  redirectionUrl: string;
  redirectionUrlButton?: string;
  mainText: ReactNode;
  closeButtonId?: string;
  displayTunnelHeader?: boolean;
}

export const EndPathLayout: FC<EndPathLayoutProps> = ({
  headerTitle,
  subTitle,
  mainText,
  buttonText,
  id,
  redirectionUrl,
  redirectionUrlButton = "",
  closeButtonId,
  displayTunnelHeader = false,
}: EndPathLayoutProps) => {
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const { isAuth0Authenticated } = useAuthenticationContext();

  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: headerTitle,
      onCloseCallback: handleClickCross,
      disableLogoLink: true,
      displayFooter: true,
      closeButtonId,
      displayTunnelHeader,
    });
  }, []);

  const handleClickCross = () => {
    navigate(redirectionUrl);
  };

  const handleClickButton = () => {
    sendToDataLayer(`${id}-bouton-endpath`);
    navigate(redirectionUrlButton !== "" ? redirectionUrlButton : redirectionUrl);
  };

  return (
    <>
      {displayTunnelHeader && (
        <TunnelHeader title={""} disableLogoLink={true} phoneNumber={""} displayClose={false} />
      )}
      <EndPage title={subTitle}>
        {mainText}
        {isAuth0Authenticated && (
          <Button
            variant="primary"
            className={styles.EndPathLayout__Button}
            onClick={handleClickButton}
          >
            {buttonText}
          </Button>
        )}
      </EndPage>
    </>
  );
};
