import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "components/atoms/Logo/Logo";
import { Header } from "./Header";
import { RoutePaths } from "routes/routePaths";
import CallBlock from "components/molecules/CallBlock/CallBlock";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useTracking } from "hooks/useTracking";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";
import styles from "./TunnelHeader.module.scss";
import cssVariables from "styles/variables.module.scss";

interface TunnelHeaderProps {
  title: string;
  displayClose?: boolean;
  onCloseCallback?: () => void;
  id?: string;
  closeButtonId?: string;
  displayMobileCallBlock?: boolean;
  disableLogoLink: boolean;
  phoneNumber: string;
  text?: string;
}

export const TunnelHeader: FC<TunnelHeaderProps> = ({
  title,
  onCloseCallback,
  displayClose = true,
  id,
  closeButtonId,
  displayMobileCallBlock,
  disableLogoLink,
  text,
}) => {
  const isMobileOrTablet = useMatchMedia(cssVariables.breakpointMobileAndTablet);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  const onCloseHandle = () => {
    if (closeButtonId) {
      sendToDataLayer(closeButtonId);
    }
    if (onCloseCallback) {
      onCloseCallback();
    } else {
      navigate(-1);
    }
  };

  return (
    <Header className={styles.tunnelHeader}>
      <Logo id={id} url={RoutePaths.HOME} isDisabledLink={true} />
      <h1>{title}</h1>
      {displayClose && (
        <a
          onClick={onCloseHandle}
          title={`Fermer et annuler "${title}"`}
          className={styles.close}
          id={closeButtonId}
        >
          <CloseIcon />
        </a>
      )}
      {displayMobileCallBlock && isMobileOrTablet && (
        <CallBlock className={styles.tunnelHeader__callBlock} text={text} />
      )}
    </Header>
  );
};
