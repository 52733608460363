import { FC } from "react";
import { useRecoilValue } from "recoil";

import { USER_ID_TOTAL_WASH } from "context/global.types";
import { globalState } from "context/globalState/atom";

import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import { RoutePaths } from "routes/routePaths";
import { useLocation } from "react-router-dom";

const MainText: FC = () => (
  <>
    <p>
      Merci de votre confiance ! Vous recevrez prochainement une confirmation par e-mail comportant
      votre code promo Total Wash.
      <br />
      Si vous ne le voyez pas, pensez à vérifier dans vos spams.
    </p>
    <p>A très bientôt !</p>
    <p>L'équipe Roole</p>
  </>
);

export const PageConfirmationTW: FC = () => {
  const { idAdhesion } = useRecoilValue(globalState);
  const location = useLocation();

  window.localStorage.setItem(
    USER_ID_TOTAL_WASH,
    idAdhesion || (location.state as any).idAdhesion || "",
  );
  return (
    <EndPathLayout
      headerTitle="Confirmation de la commande"
      subTitle="Votre commande a bien été enregistrée"
      mainText={<MainText />}
      id="page-confirmation-tw"
      buttonText="Retour vers mes bons plans"
      redirectionUrl={RoutePaths.BON_PLANS_HOME}
      displayTunnelHeader
    />
  );
};
