import { useEffect, useRef, useState } from "react";

const FOCUSABLE_ELEMENTS = [
  "a[href]",
  "area[href]",
  "input:not([disabled]):not([type=hidden]):not([aria-hidden])",
  "select:not([disabled]):not([aria-hidden])",
  "textarea:not([disabled]):not([aria-hidden])",
  "button:not([disabled]):not([aria-hidden])",
  "iframe",
  "object",
  "embed",
  "[contenteditable]",
  "[tabindex]:not([tabindex^='-'])",
].join(",");

const focusTrap: EventListener = ({ target, currentTarget }) => {
  const focused = target as HTMLElement;
  const parent = currentTarget as HTMLElement;
  const focusable = parent.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
  if (!focusable.length) {
    return;
  }

  const first = focusable[0];
  const last = focusable[focusable.length - 1];

  setTimeout(() => {
    // cycle focusable elements, but allow focus jump outside document
    const out =
      document.activeElement !== document.body && !parent.contains(document.activeElement);
    if (out && focused === first) {
      last.focus();
    } else if (out && focused === last) {
      first.focus();
    }
  });
};

const enforceFocusIn = (target: HTMLElement) => () => {
  setTimeout(() => {
    // enforce focus inside target, default to first focusable element
    if (!target.contains(document.activeElement)) {
      target.querySelector<HTMLElement>(FOCUSABLE_ELEMENTS)?.focus();
    }
  });
};

export const useFocusTrap = <T extends HTMLElement = HTMLElement>(restoreFocus = true) => {
  const ref = useRef<T>(null);
  const [previous] = useState(() => document.activeElement as HTMLElement);

  useEffect(() => {
    const { current } = ref;
    if (!current) {
      return;
    }

    const focusIn = enforceFocusIn(current);
    focusIn();

    document.addEventListener("focusin", focusIn);
    current.addEventListener("focusout", focusTrap);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("focusin", focusIn);
      current.removeEventListener("focusout", focusTrap);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      restoreFocus && previous?.focus();
    };
  }, [restoreFocus, previous]);

  return ref;
};
