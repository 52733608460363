import { FC } from "react";

import styles from "./IbanDescription.module.scss";

const IbanDescription: FC = () => (
  <div className={styles.IbanDescription}>
    <div className={styles.IbanDescription__title}>Indiquez votre moyen de paiement</div>

    <div className={styles.IbanDescription__subTitle}>
      Vous êtes sur une plateforme sécurisée. Munissez-vous de votre Relevé d'Identité Bancaire
      (RIB) sur lequel figure votre IBAN et renseignez-le ci dessous. Tant que vous n'avez pas signé
      votre contrat vous ne serez pas prélevé.
    </div>
  </div>
);

export default IbanDescription;
