import { FC } from "react";
import { EtatContrat, MonContrat } from "domain/api-schemas";
import Badge from "components/atoms/Badge/Badge";
import { capitalizeFirstLetter } from "utils/CapitalizeFirstLetter";
import { formatDate } from "utils/formatDate";
import styles from "./InfoCard.module.scss";
import { Text } from "@roole/components-library";
import { getNomPack } from "domain/service/Contract/useMoncontrat";

const FreeMemberInfoCard: FC<{
  contract: MonContrat;
}> = ({ contract }) => {
  return (
    <div>
      <div className={styles.ContractInfo__cardTitleWrapper}>
        <Text textVariant="heading-6" as="h2" className={styles.ContractInfo__cardTitle}>
          Pack {capitalizeFirstLetter(getNomPack(contract))}
        </Text>
        {contract?.etatContrat === EtatContrat.Resilie && <Badge variant="danger">Résilié</Badge>}
      </div>
      <div className={styles.FreeDates}>
        <div>
          <Text textVariant="body" as="p">
            Début de couverture
          </Text>
          <Text textVariant="body-em" as="p">
            {formatDate(contract?.dateSouscription)}
          </Text>
        </div>
        <div>
          <Text textVariant="body" as="p">
            Date effet résiliation
          </Text>
          <Text textVariant="body-em" as="p">
            {formatDate(contract?.dateFin)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default FreeMemberInfoCard;
