import { FC } from "react";
import { classNames } from "utils/classNames";

import styles from "./CarInfo.module.scss";

type VehicleProps = {
  modele?: string;
  marque?: string;
  immatriculation?: string;
};

type ContractProps = {
  vehicule: VehicleProps;
};

type CarInfoProps = {
  className?: string;
  contract: ContractProps | null;
};

export const CarInfo: FC<CarInfoProps> = ({ className, contract }) => {
  const { vehicule = {} } = contract || {};
  const { modele, marque, immatriculation }: VehicleProps = vehicule;

  return (
    <div>
      <div className={classNames(styles.carInfo, className)}>
        {marque && (
          <div className={styles.carBrandModelWrapper}>
            <span className={styles.carBrand}>{marque}</span>
            &nbsp;
            <span className={styles.carModel}>{modele}</span>
          </div>
        )}
        {immatriculation && <span className={styles.immatriculation}>{immatriculation}</span>}
      </div>
    </div>
  );
};
