import { useState } from "react";
import { useRecoilValue } from "recoil";

import { ErrorType, reportClaimApi } from "domain/api/ReportClaim/reportClaimApi";
import { reportClaimQuery } from "context/reportClaimState/selectors";

interface ReportClaimState {
  loading: boolean;
  errors: ErrorType | string[] | null;
}

const initialState: ReportClaimState = {
  loading: false,
  errors: null,
};

export const useReportClaim = () => {
  const claim = useRecoilValue(reportClaimQuery);
  const [state, setState] = useState<ReportClaimState>({ ...initialState });

  const postReportClaim = async (files: File[], onProgress?: (e: ProgressEvent) => void) => {
    try {
      setState({ loading: true, errors: null });

      const result = await reportClaimApi.postReportClaim(
        {
          ...claim,
          piecesJointes: files,
        },
        onProgress,
      );

      setState({ loading: false, errors: result || null });

      return result;
    } catch (err) {
      setState({ loading: false, errors: err as ErrorType });

      return Promise.reject(err as ErrorType);
    }
  };

  const editReportClaim = async (files: File[], onProgress?: (e: ProgressEvent) => void) => {
    try {
      setState({ loading: true, errors: null });

      const result = await reportClaimApi.editReportClaim(
        { demandeId: claim.claimId, piecesJointes: files },
        onProgress,
      );

      setState({ loading: false, errors: result || null });

      return result;
    } catch (err) {
      setState({ loading: false, errors: err as ErrorType });

      return Promise.reject(err as ErrorType);
    }
  };

  return {
    ...state,
    postReportClaim,
    editReportClaim,
  };
};
