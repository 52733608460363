import { FC } from "react";

import { Title } from "components/atoms/Title/Title";
import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import { RoutePaths } from "routes/routePaths";

import styles from "./PageErrorTW.module.scss";

const MainText: FC = () => (
  <>
    <Title className={styles.title}>Nous avons rencontré un problème lors de votre commande.</Title>
    <p>
      Nous vous invitons à retenter dans quelques minutes. Vous pouvez nous contacter via le chat,
      ou par téléphone du lundi au vendredi de 9h à 18h au 01 46 90 37 00.
    </p>
    <p>A très bientôt !</p>
    <p>L'équipe Roole</p>
  </>
);

const PageErrorTW: FC = () => (
  <EndPathLayout
    headerTitle="Echec de la commande"
    subTitle="Désolé !"
    mainText={<MainText />}
    id="page-error-tw"
    buttonText="Retour vers mes bons plans"
    redirectionUrl={RoutePaths.BON_PLANS_HOME}
    displayTunnelHeader
  />
);

export default PageErrorTW;
