import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { InputText, Text } from "@roole/components-library";
import { validateIBan } from "utils/iban";
import { classNames } from "utils/classNames";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";

import styles from "./Iban.module.scss";

export type IbanFormInputs = {
  iban: string;
  titulaire: string;
};

export interface IBanProps {
  iban: string | null;
  titulaire: string | null;
  isNewIban: boolean;
  handleIsNewIban: () => void;
  previousAction: () => void;
  nextAction: (newIban: string, newTitle: string) => void;
}

const validations = {
  iban: {
    required: {
      value: true,
      message: "Ce champ est obligatoire",
    },
    validate: {
      iban: (value: string) =>
        validateIBan(value.trim()) || "Ce champ doit être saisi au bon format",
    },
  },
  titulaire: {
    required: {
      value: true,
      message: "Ce champ est obligatoire",
    },
  },
};

const IbanForm: FC<IBanProps> = ({
  iban,
  titulaire,
  isNewIban,
  handleIsNewIban,
  previousAction,
  nextAction,
}) => {
  const [resetIban, setNewIban] = useState<boolean>(isNewIban);
  const { control, handleSubmit } = useForm<IbanFormInputs>();

  useEffect(() => {
    if (isNewIban || resetIban) {
      handleIsNewIban();
    }
  }, [resetIban, isNewIban]);

  const onSubmit: SubmitHandler<IbanFormInputs> = (data) => nextAction(data.iban, data.titulaire);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        <div className={styles.iban__box}>
          <Text textVariant="heading-6" as="h6">
            Prélèvement mensuel
          </Text>
          <p className={styles.iban__boxIntro}>
            Veuillez entrer vos informations de paiement ci-dessous
          </p>
          <div className={styles.iban__boxContent}>
            <Controller
              control={control}
              name="titulaire"
              defaultValue={titulaire || ""}
              rules={{ ...validations.titulaire }}
              render={({ field, fieldState: { error } }) => (
                <InputText
                  label="Titulaire du compte *"
                  required
                  disabled={!resetIban}
                  error={error?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className={classNames(styles.iban__boxContent, styles["iban__boxContent--padding"])}>
            <Controller
              control={control}
              name="iban"
              defaultValue={iban?.trim().toUpperCase() || ""}
              rules={{ ...validations.iban }}
              render={({ field, fieldState: { error } }) => (
                <InputText
                  label="Numéro d'IBAN *"
                  placeholder={"FR 74XX XXXX XXXX XXXX"}
                  {...field}
                  minLength={error?.type === "iban" ? (field.value || "").length + 1 : -1}
                  required
                  disabled={!resetIban}
                  error={error?.message}
                />
              )}
            />
          </div>
          {titulaire && iban && !isNewIban && (
            <a
              className={styles.iban__newIban}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setNewIban(true);
              }}
            >
              Saisir un nouvel IBAN
            </a>
          )}
        </div>
        <NavigationButtons
          prevProps={{
            onClick: () => previousAction(),
          }}
          nextProps={{
            onClick: () => undefined,
            type: "submit",
            text: "Suivant",
          }}
        />
      </Card>
    </form>
  );
};

export default IbanForm;
