import {
  Avatar,
  ButtonV2,
  DrawerMobile,
  InputSelect,
  Message,
  ModalV2,
  RadioButtonGroup,
  Tag,
  TextV2,
} from "@roole/components-library";
import { declarationDataState } from "context/declarationState/atom";
import { GarantieV2, Couverture, EligibiliteGarantie } from "domain/api-schemas";
import { FC, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilCallback } from "recoil";
import styles from "./GarantiesStep.module.scss";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import { DeclarationParams, evenementApi } from "domain/api/Sinistre/EvenementApi";
import { useStepperContext } from "./StepperContext";
import { listExclusionsGarantieQuery } from "context/exclusionGarantieState/selector";
import { GarantiesModals } from "./GarantiesModal";
import ModalContent from "./ModalContent";

const antiMalusId = "b43a8739-aab4-e711-9ac5-005056a41d51";
const aideAuxReparation = "1d0a1ca0-396a-e811-9acb-0050568cd8df";
const rachatDeFranchise = "bf3a8739-aab4-e711-9ac5-005056a41d51";

export const GarantiesStep: FC = () => {
  const { setActions, prevStep, nextStep } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [openModal, setOpenModal] = useState(false);
  const [selectedGarantie, setSelectedGarantie] = useState<GarantieV2 | null>();
  const [needOneEligible, setNeedOneEligible] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [garantieExclusions, setGarantieExclusions] = useState<EligibiliteGarantie[]>([]);
  const [responses, setResponses] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [initializeEvenementLoading, setInitializeEvenementLoading] = useState(false);
  const [isEligibleModalOpen, setIsEligibleModalOpen] = useState(false);
  const [isNotEligibleModalOpen, setIsNotEligibleModalOpen] = useState(false);

  const garantiesEligiblesTmp = useRef<string[]>();

  const {
    isVolExpire,
    isVehiculeRetrouve,
    isVehiculeReparable,
    garantiesEligibles,
    garantiesNonEligibles,
  } = declarationData as DeclarationParams;

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const garanties = declarationData?.typeEvenement?.garanties?.filter((garantie) => {
    if (declarationData?.typeEvenement?.code === "VOL") {
      if (
        (!isVolExpire &&
          isVehiculeRetrouve &&
          !isVehiculeReparable &&
          garantie.code === "AIDREP") ||
        (!isVolExpire && isVehiculeRetrouve && isVehiculeReparable && garantie.code === "IF") ||
        (isVolExpire && garantie.code === "AIDREP")
      ) {
        return false;
      }
    }

    return true;
  });

  const handleEligibleModalClose = () => {
    setIsEligibleModalOpen(false);
    setOpenModal(false);
  };

  const handleNotEligibleModalClose = () => {
    setErrors({});
    setResetError(false);
    setIsNotEligibleModalOpen(false);
  };

  const checkGarantie = useRecoilCallback(
    ({ snapshot }) =>
      async (garantie: GarantieV2) => {
        setSelectedGarantie(garantie);

        if (garantie && declarationData?.typeEvenement?.code) {
          const selectorInstance = listExclusionsGarantieQuery({
            garantieCode: garantie.code,
            evenementCode: declarationData?.typeEvenement?.code || "",
          });

          try {
            const result = await snapshot.getPromise(selectorInstance);
            setGarantieExclusions(result);
          } catch (error) {
            console.error("Erreur lors de la récupération des exclusions :", error);
          }
        }

        setOpenModal(true);
      },
    [declarationData],
  );

  const handleClose = () => {
    setOpenModal(false);
    setErrors({});
    setResponses({});
    setResetError(false);
  };

  const checkEligible = (isEligible: boolean, responses: any) => {
    if (selectedGarantie) {
      const _garantiesEligibles = new Set(garantiesEligibles ?? []);
      const _garantiesNonEligibles = new Set(garantiesNonEligibles ?? []);
      const crmId = selectedGarantie.crmId;

      if (isEligible) {
        _garantiesEligibles.add(crmId);
        _garantiesNonEligibles.delete(crmId);
      } else {
        _garantiesNonEligibles.add(crmId);
        _garantiesEligibles.delete(crmId);
      }

      setDeclarationData({
        ...(declarationData as DeclarationParams),
        garantiesEligibles: Array.from(_garantiesEligibles),
        garantiesNonEligibles: Array.from(_garantiesNonEligibles),
        responses,
      });

      setOpenModal(false);
    }
  };

  useEffect(() => {
    garantiesEligiblesTmp.current = declarationData?.garantiesEligibles;
  }, []);

  useEffect(() => {
    if (!declarationData) {
      return;
    }

    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        disabledLight: initializeEvenementLoading,
        children: "Continuer",
        async onClick() {
          if (initializeEvenementLoading) {
            return;
          }
          try {
            const garantiesNonEligiblesLength = declarationData.garantiesNonEligibles?.length ?? 0;
            const garantiesLength = garanties?.length ?? 0;
            const antiMalusOffset =
              garanties?.some((garantie) => garantie.crmId === antiMalusId) &&
              declarationData.hasMalus
                ? 0
                : 1;
            if (
              !!declarationData.garantiesEligibles?.length ||
              garantiesNonEligiblesLength === garantiesLength - antiMalusOffset
            ) {
              let deletedGarantieEligible = false;

              garantiesEligiblesTmp.current?.forEach((g) => {
                if (!garantiesEligibles?.includes(g)) {
                  deletedGarantieEligible = true;
                }
              });

              if (deletedGarantieEligible || !declarationData.evenementId) {
                setInitializeEvenementLoading(true);

                const evenementId = await evenementApi.initializeEvenement({
                  dateEvenement: declarationData.dateEvenement,
                  typeEvenementId: declarationData.typeEvenement?.crmId!,
                });

                if (!evenementId) {
                  throw new Error("No evenementID returned on initializeEvenement");
                }

                setDeclarationData({
                  ...declarationData,
                  evenementId,
                  garanties: [],
                });
              }

              nextStep();
              setNeedOneEligible(false);
              setResetError(false);
            } else {
              const hasNoGaranties = !declarationData.garantiesEligibles?.length;
              if (
                hasNoGaranties
                  ? declarationData.hasMalus === true ||
                    [null, false].includes(declarationData.hasMalus ?? null)
                  : antiMalusOffset || declarationData.hasMalus === true
              ) {
                if (garantiesNonEligiblesLength + antiMalusOffset >= garantiesLength) {
                  nextStep();
                }
              }
              setNeedOneEligible(true);
              setResetError(false);
            }
          } catch (error) {
            setInitializeEvenementLoading(false);
            console.log("Une erreur s'est produite", error);
          }
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        disabledLight: initializeEvenementLoading,
        children: "Retour",
        onClick: () => {
          if (initializeEvenementLoading) {
            return;
          }

          prevStep();
        },
      },
    });
  }, [declarationData, prevStep, nextStep, setActions, initializeEvenementLoading]);

  const modal = (
    <ModalContent
      garantieExclusions={garantieExclusions}
      checkEligible={checkEligible}
      handleClose={handleClose}
      responses={responses}
      setResponses={setResponses}
      errors={errors}
      setErrors={setErrors}
      resetError={resetError}
      setResetError={setResetError}
      setIsEligibleModalOpen={setIsEligibleModalOpen}
      setIsNotEligibleModalOpen={setIsNotEligibleModalOpen}
    />
  );

  const onChangeHasMalus = (v: string | number) => {
    if (v === "Oui" || v === 0) {
      setDeclarationData({
        ...declarationData,
        hasMalus: true,
      } as DeclarationParams);
    } else {
      setDeclarationData({
        ...declarationData,
        hasMalus: v === "Je ne sais pas" || v === 2 ? null : false,
        garantiesEligibles: declarationData?.garantiesEligibles?.filter(
          (garantie) => garantie !== antiMalusId,
        ),
      } as DeclarationParams);
    }
  };

  return (
    <>
      <TextV2 textVariant="heading-2" as="h2">
        Vos garanties
      </TextV2>

      <TextV2>
        Vérifiez votre éligibilité aux garanties dont vous souhaitez{" "}
        <span style={{ textWrap: "nowrap" }}>bénéficier :</span>{" "}
      </TextV2>

      <div className={styles.garanties}>
        {garanties?.map((garantie) => {
          const isNotEligibiliteVerified =
            !garantiesEligibles?.includes(garantie.crmId) &&
            !garantiesNonEligibles?.includes(garantie.crmId);
          const isEligibleSelected = !!garantiesEligibles?.includes(garantie.crmId);

          const isEligible = isNotEligibiliteVerified ? undefined : isEligibleSelected;

          const avatarNotEligible = isEligible === false ? "shield-off" : "shield";
          const avatarIcon = isEligible ? "shield-checked" : avatarNotEligible;

          if (
            garantie.crmId === antiMalusId ||
            (garantie.crmId === aideAuxReparation &&
              Couverture.TousRisques === declarationData?.couvertureAssurance) ||
            (garantie.crmId === rachatDeFranchise &&
              Couverture.AuTiers === declarationData?.couvertureAssurance)
          ) {
            return <></>;
          }

          return (
            <div
              key={garantie.crmId}
              className={classNames(
                styles.tileGarantie,
                isEligible && styles.eligible,
                isEligible === false && styles.nonEligible,
                needOneEligible && isEligible === undefined && !resetError && styles.needEligible,
              )}
            >
              <div>
                <Avatar
                  type="icon"
                  icon={avatarIcon}
                  size="L"
                  variant={isEligible ? "validation" : "neutral"}
                />
              </div>

              <div style={{ width: "100%" }}>
                <div
                  className={classNames(
                    styles.tileGarantieContent,
                    isEligible === false && styles.nonEligible,
                  )}
                >
                  <div className={styles.tileGarantieTitle}>
                    <TextV2
                      textVariant="heading-4"
                      as="h4"
                      color={isEligible === false && "secondary"}
                    >
                      {garantie.nom}
                    </TextV2>

                    {isEligible !== undefined && (
                      <div className={styles.tileGarantieTag}>
                        <Tag
                          label={isEligible ? "Éligible" : "Non éligible"}
                          type={isEligible ? "validation" : "neutral"}
                        />
                      </div>
                    )}
                  </div>

                  <TextV2 textVariant="body-medium-em" color={isEligible === false && "secondary"}>
                    {garantie.plafondMessage}
                  </TextV2>

                  <p
                    className={classNames(isEligible === false && styles.nonEligible)}
                    dangerouslySetInnerHTML={{ __html: garantie.description }}
                  />
                </div>

                {isEligible === undefined ? (
                  <ButtonV2
                    variant="primary-brand"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Vérifier mon éligibilité
                  </ButtonV2>
                ) : (
                  <ButtonV2
                    variant="secondary-dark"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Modifier mes informations
                  </ButtonV2>
                )}
              </div>
            </div>
          );
        })}

        {garanties?.map((garantie) => {
          const isNotEligibiliteVerified =
            !garantiesEligibles?.includes(garantie.crmId) &&
            !garantiesNonEligibles?.includes(garantie.crmId);
          const isEligibleSelected = !!garantiesEligibles?.includes(garantie.crmId);

          const isEligible = isNotEligibiliteVerified ? undefined : isEligibleSelected;

          const avatarNotEligible = isEligible === false ? "shield-off" : "shield";
          const avatarIcon = isEligible ? "shield-checked" : avatarNotEligible;
          const isAntiMalus = garantie.crmId === antiMalusId;

          return isAntiMalus ? (
            <div style={{ marginTop: "32px" }}>
              <TextV2 textVariant="heading-4" as="h2">
                Avez-vous eu un malus suite à cet accident ?
              </TextV2>
              <TextV2 textVariant="body-medium" as="p">
                Le malus est une pénalité appliquée en cas d’accident responsable, pouvant entrainer
                l’augmentation de votre prime d’assurance<sup>1</sup>.
              </TextV2>
              <div style={{ marginTop: "32px", marginBottom: "20px" }}>
                {isMobile ? (
                  <InputSelect
                    label="Sélectionnez une réponse"
                    value={
                      declarationData?.hasMalus === undefined
                        ? undefined
                        : JSON.stringify(declarationData?.hasMalus)
                    }
                    setValue={onChangeHasMalus}
                    values={["Oui", "Non", "Je ne sais pas"]}
                  />
                ) : (
                  <RadioButtonGroup
                    direction="row"
                    elements={[
                      { label: "Oui", value: "Oui" },
                      { label: "Non", value: "Non" },
                      { label: "Je ne sais pas", value: "Je ne sais pas" },
                    ]}
                    checked={[
                      declarationData?.hasMalus === true,
                      declarationData?.hasMalus === false,
                      declarationData?.hasMalus === null,
                    ]}
                    name="malus"
                    onChange={onChangeHasMalus}
                    variant="tile"
                  />
                )}
              </div>

              {declarationData?.hasMalus && (
                <div
                  key={garantie.crmId}
                  className={classNames(
                    styles.tileGarantie,
                    isEligible && styles.eligible,
                    isEligible === false && styles.nonEligible,
                  )}
                >
                  <div>
                    <Avatar
                      type="icon"
                      icon={avatarIcon}
                      size="L"
                      variant={isEligible ? "validation" : "neutral"}
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <div
                      className={classNames(
                        styles.tileGarantieContent,
                        isEligible === false && styles.nonEligible,
                      )}
                    >
                      <div className={styles.tileGarantieTitle}>
                        <TextV2
                          textVariant="heading-4"
                          as="h4"
                          color={isEligible === false && "secondary"}
                        >
                          {garantie.nom}
                        </TextV2>

                        {isEligible !== undefined && (
                          <div className={styles.tileGarantieTag}>
                            <Tag
                              label={isEligible ? "Éligible" : "Non éligible"}
                              type={isEligible ? "validation" : "neutral"}
                            />
                          </div>
                        )}
                      </div>

                      <TextV2
                        textVariant="body-medium-em"
                        color={isEligible === false && "secondary"}
                      >
                        {garantie.plafondMessage}
                      </TextV2>

                      <p
                        className={classNames(isEligible === false && styles.nonEligible)}
                        dangerouslySetInnerHTML={{ __html: garantie.description }}
                      />
                    </div>

                    {isEligible === undefined ? (
                      <ButtonV2
                        variant="primary-brand"
                        onClick={() => checkGarantie(garantie)}
                        size="small"
                      >
                        Vérifier mon éligibilité
                      </ButtonV2>
                    ) : (
                      <ButtonV2
                        variant="secondary-dark"
                        onClick={() => checkGarantie(garantie)}
                        size="small"
                      >
                        Modifier mes informations
                      </ButtonV2>
                    )}
                  </div>
                </div>
              )}

              {declarationData?.hasMalus === null && (
                <div>
                  <TextV2 textVariant="body-small">
                    Nous vous conseillons de contacter votre assurance principale pour obtenir
                    l’information.
                  </TextV2>
                  <Message
                    message={
                      <TextV2 textVariant="body-small">
                        En cas de malus, nous pouvons couvrir jusqu’à 500 €, l’augmentation de votre
                        prime annuelle d’assurance grâce à la garantie anti-malus.
                      </TextV2>
                    }
                    type="information"
                    icon="info"
                  />
                </div>
              )}
              <div style={{ marginTop: "16px" }}>
                <TextV2 textVariant="body-small" color="secondary">
                  <sup>1</sup> La prime d’assurance est le montant total annuel que vous payez à
                  votre assurance principale pour bénéficier d’une couverture en cas de sinistre.
                </TextV2>
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
      {needOneEligible && !resetError && (
        <div className={styles.needOneEligible}>
          <Message
            icon="info"
            message={
              <TextV2 textVariant="body-small" color="error">
                Aucune des garanties que vous avez vérifiées n'est éligible. Veuillez vérifier les
                garanties restantes pour continuer.
              </TextV2>
            }
            type="error"
          />
        </div>
      )}
      {isMobile ? (
        <DrawerMobile
          title={selectedGarantie?.nom ?? ""}
          content={modal}
          isOpen={openModal}
          handleClose={handleClose}
        />
      ) : (
        <ModalV2
          title={selectedGarantie?.nom ?? ""}
          content={modal}
          isOpen={openModal}
          handleClose={handleClose}
        />
      )}

      <GarantiesModals
        isMobile={isMobile}
        isEligibleModalOpen={isEligibleModalOpen}
        isNotEligibleModalOpen={isNotEligibleModalOpen}
        handleEligibleModalClose={handleEligibleModalClose}
        handleNotEligibleModalClose={handleNotEligibleModalClose}
        selectedGarantieName={selectedGarantie?.nom}
        garantieExclusions={garantieExclusions}
        errors={errors}
      />
    </>
  );
};
