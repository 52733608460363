import { selector, selectorFamily } from "recoil";

import { RoutePaths } from "routes/routePaths";
import { MonContrat, ValiditeContrat } from "domain/api-schemas";
import { monContratState } from "context/currentContratState/atom";

const CodePackAntille = "AN";
const CodeContractNotAccessible = ["RX"];

export const getfeatureToggleByContrat = selectorFamily({
  key: "getfeatureToggleByContrat",
  get: (contract: MonContrat | null) => async () => {
    const isContractExpired = contract?.validiteContrat === ValiditeContrat.Expire;
    const isAccessible = !!contract?.packServices?.codePack
      ? !CodeContractNotAccessible.includes(contract?.packServices?.codePack)
      : true;

    const defaultRoutesDisplay: Partial<Record<RoutePaths, boolean>> = {
      [RoutePaths.MON_COMPTE]: true,
      [RoutePaths.BON_PLANS_HOME]:
        contract?.packServices?.codePack !== CodePackAntille && !isContractExpired,
      [RoutePaths.DECLARER_SINISTRE]: isAccessible,
    };

    return { displayRoutes: defaultRoutesDisplay };
  },
});

export const navigationMenuQuery = selector({
  key: "NavigationMenuQuery",
  get: ({ get }) => {
    return get(getfeatureToggleByContrat(get(monContratState) || null));
  },
});
