export interface Deffered<T> {
  resolve(value: T | PromiseLike<T>): void;
  reject(reason?: unknown): void;
  promise: Promise<T>;
}

export const defer = <T>(): Deffered<T> => {
  const deferred: Partial<Deffered<T>> = {};
  const promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  deferred.promise = promise;
  return deferred as Deffered<T>;
};
