import { components } from "./api-types";

// Hacks to fix API typings & avoid typing hell in ts
type NonNullable<T> = T extends null | undefined ? never : T;
type FixNullablePropsAPI<T> = {
  // redefine all props & sub objects props not to be null (but may be undefined)
  [P in keyof T]: NonNullable<T[P]> extends object ? FixNullablePropsAPI<T[P]> : NonNullable<T[P]>;
};
type ForceDefinedPropsAPI<T> = {
  // force all props & sub objects props to be defined & not null
  [P in keyof T]-?: NonNullable<T[P]> extends object
    ? ForceDefinedPropsAPI<T[P]>
    : NonNullable<T[P]>;
};

export enum EtatContrat {
  Null = 0,
  Abandonne = 1,
  Annule = 2,
  Enregistre = 3,
  Expire = 4,
  Inconnu = 5,
  PreEnregistre = 6,
  Renouvele = 7,
  Resilie = 8,
  Suspendu = 9,
}

export enum ValiditeContrat {
  Actif = 0,
  ExpireBientot = 1,
  Expire = 2,
  Inactif = 3,
}

export enum BonplanType {
  National,
  Local,
}

export enum SourceModification {
  SiteEm = 3,
  RoolePremium = 7,
}

export enum StatutDossierGarantie {
  EnCours = 100000003,
  EnEtude = 100000006,
  Incomplet = 100000007,
  Refuse = 100000001,
  Accorde = 100000005,
}

export enum EtatReglementDossierGarantie {
  Regle = 100000002,
}

export enum StatutPiece {
  NonRecu = 1,
  Recu = 2,
  Conforme = 3,
  NonConforme = 4,
}

export enum GarantieAction {
  Appeler = 1,
  Declarer = 2,
  AppelerMutuaide = 3,
}

export enum Couverture {
  TousRisques,
  AuTiers,
}

export type SignalRParams = ForceDefinedPropsAPI<components["schemas"]["SignalRParams"]>;

export type Contrat = ForceDefinedPropsAPI<components["schemas"]["InfosContrat"]>;

export type MonContrat = ForceDefinedPropsAPI<components["schemas"]["InfosContratMonContrat"]>;

export type Echeance = ForceDefinedPropsAPI<components["schemas"]["InfosEcheance"]>;

export type InfosReconduction = ForceDefinedPropsAPI<components["schemas"]["InfosReconduction"]>;

export type ResiliationContract = ForceDefinedPropsAPI<
  components["schemas"]["InfosContratResiliation"]
>;

export type InfosResiliation = ForceDefinedPropsAPI<components["schemas"]["InfosResiliation"]>;

export type ValidationOffrePromoData = components["schemas"]["ValidationOffrePromoCommand"];

export type ParrainageData = components["schemas"]["CreationFilleulsCommand"];

export type MotifResiliation = ForceDefinedPropsAPI<components["schemas"]["MotifResiliationDto"]>;

export type Resiliation = ForceDefinedPropsAPI<components["schemas"]["ResiliationCommand"]>;
export type ResiliationResult = ForceDefinedPropsAPI<components["schemas"]["ResiliationResult"]>;

export type ReconductionData = FixNullablePropsAPI<components["schemas"]["ReconductionCommand"]>;
export type ReconductionCbData = components["schemas"]["ReconductionCommand"];
export type ReconductionResult = ForceDefinedPropsAPI<components["schemas"]["ReconductionResult"]>;

export type OffrePromoResult = ForceDefinedPropsAPI<components["schemas"]["OffrePromoResult"]>;

export type Garantie = ForceDefinedPropsAPI<components["schemas"]["GarantieDto"]>;

export type PlafondIndemniteFinanciere = components["schemas"]["PlafondIndemniteFinanciereDto"];

export type UpdateInfosMembreRequest = ForceDefinedPropsAPI<
  components["schemas"]["UpdateInfosMembreRequest"]
>;
export type Membre = ForceDefinedPropsAPI<components["schemas"]["MembreDto"]>;

export type Member = ForceDefinedPropsAPI<components["schemas"]["MembreDto"]>;

export type MembreAcces = ForceDefinedPropsAPI<components["schemas"]["MembreAcces"]>;

export type InfosContratReconduction = components["schemas"]["InfosContratReconduction"];

export type AddContractRequest = components["schemas"]["AddContractRequest"];

export type Concession = components["schemas"]["Concession"];

export type BonPlan = components["schemas"]["BonPlan"];

export type MonGarageViewModel = components["schemas"]["MonGarageViewModel"];

export type BonPlanMobilite = components["schemas"]["BonPlanMobilite"];
export type BonPlanCategory = components["schemas"]["BonPlanCategory"];
export type BonPlanHomepage = components["schemas"]["BonPlanHomepage"];
export type BonPlanReview = components["schemas"]["BonPlanReview"];
export type BonPlanCustomLink = components["schemas"]["BonPlanCustomLink"];

export type ConcessionsGroupeViewModel = components["schemas"]["ConcessionsGroupeViewModel"];

export type UpdateUserEmailRequest = components["schemas"]["UpdateUserEmailRequest"];

export type ContactCreateActivityData = components["schemas"]["CreateActivityCommand"];
export type GarantieProduit = components["schemas"]["GarantieProduitEmDto"];
export type ContratSinistre = components["schemas"]["ContratSinistreViewModel"];
export type TypeEvenement = components["schemas"]["TypeEvenementDto"];
export type HistoDemande = components["schemas"]["HistoDemandeDto"];
export type DeclarationSinistre = components["schemas"]["DeclarationSinistreViewModel"];
export type Document = components["schemas"]["DocumentDto"];
export type ContactCategoriesMotifs = ForceDefinedPropsAPI<
  components["schemas"]["ContactCategorieDto"]
>;
export type ActiviteEspaceConnecteRequest = components["schemas"]["ActiviteEspaceConnecteRequest"];

export type DossierReconductionModel = components["schemas"]["DossierReconductionModel"];
export type LogModel = components["schemas"]["LogModel"];

export type CreatePaymentData = components["schemas"]["CreatePaymentData"];

export type Echeancier = components["schemas"]["Echeancier"];

export type CoordonneesBancairesData = components["schemas"]["CoordonneesBancairesData"];

export type GetDocumentData = components["schemas"]["GetDocumentData"];

export type SelfcareRequest = ForceDefinedPropsAPI<components["schemas"]["SelfcareRequest"]>;

export type Evenement = components["schemas"]["Evenement"];

export type EvenementType = components["schemas"]["TypeEvenement"];

export type RepriseEvenementType = components["schemas"]["RepriseEvenementType"];

export type DossierGarantie = components["schemas"]["DossierGarantie"];

export type GarantieCategory = components["schemas"]["GarantieCategory"];

export type GarantieV2 = components["schemas"]["Garantie"];

export type EligibiliteGarantie = components["schemas"]["EligibiliteGarantie"];

export type PieceDossier = components["schemas"]["PieceDossier"];

export type PjDescription = components["schemas"]["PjDescription"];

export type ReferentielsPiecesJustificatives =
  components["schemas"]["ReferentielsPiecesJustificatives"];
