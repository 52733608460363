import { useAuthenticationContext } from "AuthenticationProvider";
import { useEffect, createContext, useState, useMemo } from "react";

const GTMContext = createContext({});

const GTMProvider = ({ children }: { children: JSX.Element }) => {
  const { isJwtAuthenticated, isJwtLoading, isAuth0Loading } = useAuthenticationContext();
  const [isScriptsInjected, setIsScriptsInjected] = useState(false);

  useEffect(() => {
    // Injecting the GTM script into the <head>
    const addGTMToHead = () => {
      const script = document.createElement("script");
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NKTDRPM');
      `;
      document.head.appendChild(script);
    };

    // Injecting the noscript tag and additional scripts into the <body> for production
    const addGTMToBody = () => {
      if (process.env.NODE_ENV === "production") {
        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NKTDRPM" height="0" width="0" style="display: none; visibility: hidden"></iframe>
        `;
        document.body.insertBefore(noscript, document.body.firstChild);
      }
    };

    if (!isAuth0Loading && !isJwtLoading && !isJwtAuthenticated && !isScriptsInjected) {
      addGTMToHead();
      addGTMToBody();
      setIsScriptsInjected(true);
    }
    // Cleanup function if needed
    return () => {
      // Remove scripts or perform other cleanup tasks
    };
  }, [isAuth0Loading, isJwtAuthenticated, isJwtLoading, isScriptsInjected]);

  const providerValue = useMemo(() => ({}), []);

  return <GTMContext.Provider value={providerValue}>{children}</GTMContext.Provider>;
};

export default GTMProvider;
