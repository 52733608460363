import { Link } from "react-router-dom";
import { FC, HTMLAttributes } from "react";

import { Box } from "components/atoms/Box/Box";
import { Button } from "components/atoms/Button/Button";
import { ButtonGroup } from "components/atoms/Button/ButtonGroup";
import { Title } from "components/atoms/Title/Title";
import { RoutePaths } from "routes/routePaths";

import styles from "./EndPage.module.scss";

type EndPageProps = {
  title: string;
  btnLabel?: string;
} & HTMLAttributes<HTMLDivElement>;

export const EndPage: FC<EndPageProps> = ({ title, btnLabel, children }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <Box className={styles.box}>
        {title && <Title className={styles.title}>{title}</Title>}
        {children}
        {btnLabel && (
          <ButtonGroup className={styles.btn}>
            <Link to={RoutePaths.CONTRAT_HOME}>
              <Button variant="primary">{btnLabel}</Button>
            </Link>
          </ButtonGroup>
        )}
      </Box>
    </div>
  </div>
);
