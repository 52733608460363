import { useState, useImperativeHandle, forwardRef } from "react";

import { Modal, ModalProps } from "./Modal";

export type ModalAPI = {
  isOpen: boolean;
  toggle: (open?: boolean, callback?: () => void) => void;
};

export type ModalWithControlProps = ModalProps & {
  open?: boolean;
  control?: (api: ModalAPI) => void;
};

export const ModalWithControl = forwardRef<ModalAPI, ModalWithControlProps>(
  ({ open = true, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(open);

    useImperativeHandle(ref, () => ({
      isOpen,
      toggle: (open = !isOpen, callback?) => {
        setIsOpen(open);
        callback?.();
      },
    }));

    return isOpen ? <Modal {...props} /> : null;
  },
);
