import { FC } from "react";
import { Text } from "@roole/components-library";

import { ReactComponent as CarteMembre } from "images/help-carte-membre.svg";
import { ReactComponent as Courrier } from "images/help-courrier.svg";

import styles from "./Page.module.scss";

const NumberContractHelp: FC = () => (
  <div className={styles.help__content}>
    <Text as="p" textVariant="ui-1" className={styles.help__subheading}>
      Retrouvez votre numéro de contrat ou de commande composé de 2 caractères suivi de 7 ou 8
      chiffres :
    </Text>
    <Text as="h3" textVariant="subheading">
      Sur votre carte membre
    </Text>
    <CarteMembre />
    <Text as="h3" textVariant="subheading">
      Sur tous vos courriers
    </Text>
    <Courrier />
  </div>
);

export default NumberContractHelp;
