import { forwardRef, useRef } from "react";
import { ControllerRenderProps } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

import { TimeSlot } from "./Appointment";
import { TimeSlots } from "./TimeSlots";
import { CustomCalendarHeader } from "./CustomCalendarHeader";
import { CustomCalendarContainer } from "./CustomCalendarContainer";
import { CustomCalendarInput } from "./CustomCalendarInput";
import { ReactComponent as CalendarIcon } from "images/icons/calendar.svg";
import { classNames } from "utils/classNames";
import styles from "./AppointmentDate.module.scss";
interface AppointmentDateProps extends ControllerRenderProps {
  errorMessage?: string;
}

registerLocale("fr", fr);

const isWeekday = (date: Date) => {
  const sunday = 0;
  const saturday = 6;
  const day = date.getDay();

  return day !== sunday && day !== saturday;
};

const getNextBusinessDay = () => {
  const current = new Date().getDay();
  const offsetExtended = current === 6 ? 2 : 1;
  const offset = current === 5 ? 3 : offsetExtended;
  const next = new Date();

  next.setTime(next.getTime() + offset * 1000 * 60 * 60 * 24);
  return next;
};

export const AppointmentDate = forwardRef<HTMLInputElement, AppointmentDateProps>(
  ({ value, onChange, errorMessage, ...props }, ref) => {
    const datePickerRef = useRef<DatePicker>(null);

    const onValueChange = (date: string, timeSlot: TimeSlot) => {
      onChange({
        date,
        timeSlot,
      });

      if (!!date && !!timeSlot) {
        datePickerRef.current?.setOpen(false);
      }
    };

    return (
      <div
        className={classNames(
          styles.appointmentDate,
          errorMessage && styles["appointmentDate--error"],
        )}
      >
        <DatePicker
          {...props}
          locale="fr"
          dateFormat="dd/MM/yyyy"
          selected={value?.date}
          onChange={(date) => onValueChange(date as unknown as string, value?.timeSlot)}
          filterDate={isWeekday}
          minDate={getNextBusinessDay()}
          placeholderText="Sélectionner un créneau de rappel souhaité *"
          dayClassName={() => styles.appointmentDate__day}
          className={styles.ouss}
          shouldCloseOnSelect={false}
          calendarContainer={CustomCalendarContainer}
          renderCustomHeader={CustomCalendarHeader}
          customInput={
            <CustomCalendarInput
              date={value?.date}
              timeSlot={value?.timeSlot}
              error={errorMessage}
              ref={ref}
            />
          }
          children={
            <TimeSlots
              value={value?.timeSlot}
              onChange={(slot) => onValueChange(value?.date, slot)}
            />
          }
          ref={datePickerRef}
          required
        />

        <CalendarIcon className={styles.calendarIcon} />
      </div>
    );
  },
);
