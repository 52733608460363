import { Suspense, useEffect, FunctionComponent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { OverlayLoader } from "@roole/components-library";
import ErrorBoundary from "../ErrorBoundary";
import { PublicRoutePaths } from "./routePaths";
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from "recoil";
import { currentContratQuery } from "context/currentContratState/selector";
import { useAuthenticationContext } from "AuthenticationProvider";
import { isBefore, startOfToday, formatISO } from "date-fns";

interface ProtectedRouteProps {
  component: React.ComponentType;
  path?: string;
}

const hasAccess = (accessObject: any, path?: string): boolean => {
  if (!path) {
    return true;
  }

  for (const key in accessObject) {
    if (typeof accessObject[key] === "object" && accessObject[key] !== null) {
      for (const subKey in accessObject[key]) {
        if (subKey === path) {
          return Boolean(accessObject[key][subKey]);
        }
      }
    } else {
      if (key === path) {
        return Boolean(accessObject[key]);
      }
    }
  }

  return true;
};

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ component, path }) => {
  const { isLoading, isAuthenticated, isJwtAuthenticated } = useAuthenticationContext();
  const navigate = useNavigate();
  const monContratLoadable = useRecoilValueLoadable(currentContratQuery);
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const refreshMonContrat = useRecoilRefresher_UNSTABLE(currentContratQuery);

  const membreAcces =
    monContratLoadable.state === "hasValue" ? monContratLoadable.contents?.membreAcces : null;
  const { contents } = monContrat;
  const statutMembre = contents?.membre?.statutMembre;

  const refreshCount = useRef<number>(0);
  const prevStatutMembreRef = useRef(statutMembre);

  useEffect(() => {
    const isPreviousDay = (storedDate: string) => {
      const today = startOfToday();
      const lastDate = new Date(storedDate);

      return isBefore(lastDate, today);
    };

    const storedCount = localStorage.getItem("refreshCount");
    const storedDate = localStorage.getItem("refreshDate");

    if (storedCount && storedDate && !isPreviousDay(storedDate)) {
      refreshCount.current = parseInt(storedCount, 10);
    } else {
      refreshCount.current = 0;
      localStorage.setItem("refreshCount", "0");
      localStorage.setItem("refreshDate", formatISO(new Date()));
    }

    const interval = setInterval(() => {
      if (refreshCount.current < 2) {
        refreshCount.current += 1;
        localStorage.setItem("refreshCount", refreshCount.current.toString());
        localStorage.setItem("refreshDate", formatISO(new Date()));
        refreshMonContrat();
      } else {
        clearInterval(interval);
      }
    }, Number(process.env.REACT_APP_INACTIVITY_TIME));

    return () => clearInterval(interval);
  }, [refreshMonContrat]);

  useEffect(() => {
    let inactivityTimer: ReturnType<typeof setTimeout>;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);

      if (prevStatutMembreRef.current !== statutMembre) {
        prevStatutMembreRef.current = statutMembre;
        inactivityTimer = setTimeout(
          () => window.location.reload(),
          Number(process.env.REACT_APP_INACTIVITY_TIME),
        );
      }
    };

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
    };
  }, [statutMembre]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(PublicRoutePaths.SIGNIN, { replace: true });
      return;
    }

    if (path && !hasAccess(membreAcces, path)) {
      navigate(PublicRoutePaths.Error);
      return;
    }
  }, [isAuthenticated, isLoading, navigate, path, membreAcces]);

  const ComponentWithJwt = component;
  const ComponentWithAuth0 = withAuthenticationRequired(component, {
    onRedirecting: () => <OverlayLoader />,
  });

  return (
    <ErrorBoundary>
      <Suspense fallback={<OverlayLoader />}>
        {isJwtAuthenticated ? <ComponentWithJwt /> : <ComponentWithAuth0 />}
      </Suspense>
    </ErrorBoundary>
  );
};

export default ProtectedRoute;
