import { FC } from "react";
import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import { RoutePaths } from "routes/routePaths";

import styles from "./ContactSuccessStep.module.scss";

const MainText: FC = () => (
  <>
    <p className={styles.contactSuccessStep__MainParagraph}>
      Votre demande a bien été prise en compte et sera traitée dans les meilleurs délais par notre
      relation client.
    </p>
    <p>A très bientôt !</p>
    <p>L'équipe Roole</p>
  </>
);

export const ContactSuccessStep: FC = () => {
  return (
    <EndPathLayout
      headerTitle="Contactez nous"
      subTitle="Votre demande a bien été envoyée"
      mainText={<MainText />}
      id="page-confirmation-contactez-nous"
      redirectionUrl={RoutePaths.CONTRAT_HOME}
      buttonText="Retour à l'accueil"
      closeButtonId="contactez-nous-croix-quitter"
    />
  );
};
