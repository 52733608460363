import { useEffect, useState } from "react";

export const windowGlobal = typeof window !== "undefined" && window;

export const useMatchMedia = (mediaQuery: string) => {
  // @ts-ignore
  const [matches, setMatches] = useState(windowGlobal.matchMedia(mediaQuery).matches);
  const isBrowser = () => typeof window !== "undefined" && window;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isBrowser()) {
      const media = window.matchMedia(mediaQuery);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      if (media?.addEventListener) {
        media.addEventListener("change", listener);
      } else if (media?.addListener) {
        // @ts-ignore
        media.addListener(listener);
      }

      return () => {
        if (media?.removeEventListener) {
          media.removeEventListener("change", listener);
        } else if (media?.removeListener) {
          media.removeListener(listener);
        }
      };
    }
  }, [matches, mediaQuery]);

  return matches;
};
