import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { globalState } from "context/globalState/atom";
import { myGarageService } from "domain/api/MyGarage/myGarageService";

const queries = {
  getMyGarage: "get-my-garage",
};

export const useMyFavoriteGarage = () => {
  const { idAdhesion } = useRecoilValue(globalState);

  const { isLoading: isMyGarageLoading, data: myGarage } = useQuery(
    [queries.getMyGarage, idAdhesion],
    () => myGarageService.getMyGarage(idAdhesion),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      useErrorBoundary: true,
      retryOnMount: false,
      retry: 1,
      enabled: !!idAdhesion,
    },
  );

  return {
    isMyGarageLoading,
    myDealership: myGarage?.maConcession,
  };
};
