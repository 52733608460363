import { ExclusionGarantie, exclusionGarantieApi } from "domain/api/Sinistre/ExclusionGarantieApi";
import { selectorFamily } from "recoil";

export const listExclusionsGarantieQuery = selectorFamily({
  key: "listExclusionsGarantieQuery",
  get: (params: ExclusionGarantie | null) => async () => {
    if (params == null) {
      return [];
    }
    return await exclusionGarantieApi.fetchExclusionsGarantie(params);
  },
});
