import { FC, MouseEventHandler } from "react";
import { ExternalLink } from "components/atoms/ExternalLink/ExternalLink";
import { useLocation } from "react-router-dom";
import { classNames } from "utils/classNames";
import { URLS } from "routes/urls";
import { MonContrat } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";
import { RoutePaths } from "routes/routePaths";
import { ReactComponent as Twitter } from "images/icons/twitter.svg";
import { ReactComponent as Facebook } from "images/icons/facebook.svg";
import { ReactComponent as YouTube } from "images/icons/youtube.svg";
import { ReactComponent as Instagram } from "images/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "images/icons/linkedin.svg";
import { ReactComponent as Tiktok } from "images/icons/tiktok.svg";

import styles from "./Footer.module.scss";

interface FooterProps {
  contrat: MonContrat | null;
  idAdhesion: string | null;
}

export const Footer: FC<FooterProps> = ({ contrat, idAdhesion }) => {
  const { sendToDataLayer } = useTracking();
  const location = useLocation();
  const { packServices } = contrat || {};
  const cgvUrl = packServices?.urlCgv;

  const handleClick = (id: string): MouseEventHandler => {
    return (e: React.MouseEvent) => {
      sendToDataLayer(id, true);
    };
  };

  const iconsStyles = {
    color: "#0D0D0D",
  };
  const socialLinks = [
    { id: "footer-facebook", href: URLS.FACEBOOK, icon: <Facebook style={iconsStyles} /> },
    { id: "footer-instagram", href: URLS.INSTAGRAM, icon: <Instagram style={iconsStyles} /> },
    { id: "footer-twitter", href: URLS.TWITTER, icon: <Twitter style={iconsStyles} /> },
    { id: "footer-linkedin", href: URLS.LINKEDIN, icon: <LinkedIn style={iconsStyles} /> },
    { id: "footer-youtube", href: URLS.YOUTUBE, icon: <YouTube style={iconsStyles} /> },
    { id: "footer-tiktok", href: URLS.TIKTOK, icon: <Tiktok style={iconsStyles} /> },
  ];

  const linkItems = [
    { id: "footer-faq", href: RoutePaths.AIDE, text: "Centre d'aide" },
    { id: "footer-mentions-legales", href: URLS.MENTIONS_LEGALES, text: "Mentions légales" },
    {
      id: "footer-politique-confidentialite",
      href: URLS.POLITIQUE_CONFIDENTIALITE,
      text: "Charte de confidentialité",
    },
    { id: "footer-charte-cookies", href: URLS.CHARTE_COOKIES, text: "Cookies" },
  ];

  if (cgvUrl) {
    linkItems.push({ id: "footer-cg", href: cgvUrl as any, text: "CGU" });
  }

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerLightContainer}>
        <div className={styles.footerLightContainerContent}>
          <div className={styles.textContainer}>
            <p className={styles.footerOrias}>
              Roole est une marque d'IDENTICAR SAS, société de courtage en assurance, immatriculée à
              l'ORIAS sous le numéro N° 09.052.611 : registre des intermédiaires d'assurance
              accessible sur{" "}
              <ExternalLink
                id="footer-mentions-orias"
                href={URLS.ORIAS}
                onClick={handleClick("footer-mentions-orias")}
                icon={<></>}
                className={styles.oriasLink}
              >
                https://www.orias.fr
              </ExternalLink>
            </p>
            <div className={styles.containerLink}>
              {linkItems.map((link) => (
                <ExternalLink
                  key={link.id}
                  id={link.id}
                  href={link.href}
                  onClick={handleClick(link.id)}
                  icon={<></>}
                  className={styles.redirectionLink}
                  newTab={!(link.id === "footer-faq" && RoutePaths.AIDE === location.pathname)}
                >
                  {link.text}
                </ExternalLink>
              ))}
            </div>
          </div>
          <div className={styles.iconeContainer}>
            <ul>
              {socialLinks.map((link) => (
                <li key={link.id}>
                  <ExternalLink
                    id={link.id}
                    href={link.href}
                    icon={link.icon}
                    className={classNames(styles.socialLink)}
                    onClick={handleClick(link.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
