import { selector, selectorFamily } from "recoil";
import { monContratState } from "context/currentContratState/atom";
import { reportClaimApi, ReportClaim } from "domain/api/ReportClaim/reportClaimApi";

import { reportClaimStepState } from "./atom";
import { globalState } from "context/globalState/atom";
import { formatDateRFC3339 } from "utils/formatDate";

export const reportClaimQuery = selector<ReportClaim>({
  key: "reportClaimQuery",
  get: ({ get }) => {
    const { userEmail: email } = get(globalState);
    const {
      claimGuarantee,
      claimTypeEventId,
      claimDescription: message,
      claimDate,
      claimContractId,
      claimId,
    } = get(reportClaimStepState);

    return {
      typeEvenementId: claimTypeEventId,
      garantieId: claimGuarantee?.garantieId,
      dateSinistre: formatDateRFC3339(claimDate),
      email,
      message,
      contratId: claimContractId,
      claimId,
      piecesJointes: [],
    };
  },
});

export const monitoredClaimsQuery = selectorFamily({
  key: "monitoredClaimsQuery",
  get: (idAdhesion: string | null) => async () => {
    if (!idAdhesion) {
      return [];
    }

    const response = await reportClaimApi.fetchReportClaims(idAdhesion);
    return response;
  },
});

export const listMonitoredClaimsQuery = selector({
  key: "listMonitoredClaimsQuery",
  get: ({ get }) => get(monitoredClaimsQuery(get(monContratState)?.idAdhesion || null)),
});

export const infoClaimsQuery = selectorFamily({
  key: "infoClaimsQuery",
  get:
    (demandeId: string | null) =>
    async ({ get }) => {
      if (!demandeId) {
        return null;
      }

      const response = await reportClaimApi.fetchReportClaim(
        get(monContratState)?.idAdhesion || "",
        demandeId,
      );
      return response;
    },
});

export const getInfoClaimsQuery = selector({
  key: "getInfoClaimsQuery",
  get: ({ get }) => get(infoClaimsQuery(get(reportClaimStepState)?.claimId || null)),
});
