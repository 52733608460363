import { AnchorHTMLAttributes, FC } from "react";

import { classNames } from "utils/classNames";

import styles from "./CallCTA.module.scss";

type CallCTAProps = {
  phoneNumber: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const CallCTA: FC<CallCTAProps> = ({ phoneNumber, className, href, children, ...props }) => (
  <a href={href} data-selector="tel" {...props} className={classNames(styles.cta, className)}>
    {children}
  </a>
);
