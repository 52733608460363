import { FC } from "react";
import { useRecoilValueLoadable } from "recoil";
import { currentContratQuery } from "context/currentContratState/selector";
import { Text } from "@roole/components-library";
import { CallCard } from "components/molecules/CallCard/CallCard";
import { useTracking } from "hooks/useTracking";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import styles from "./ContactBlockTunnel.module.scss";

interface ContactBlockTunnelProps {
  idTracking: string;
}

export const ContactBlockTunnel: FC<ContactBlockTunnelProps> = ({ idTracking }) => {
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const { sendToDataLayer } = useTracking();

  return (
    <>
      <div key="Doubt">
        <span className={styles.SocialBlock__content}>
          <Text as="h3" textVariant="subheading-em" className={styles.contactUs__SocialsTitle}>
            Vous avez un doute ?
          </Text>
          <Text as="h4" className={styles.ContactUs__SmallerHeader}>
            Le club à l'écoute est là pour vous aider !
          </Text>
          <p>Nous sommes joignables du lundi au vendredi de 9h à 18h</p>
        </span>
        <div className={styles.SocialBlock__callCardContainer}>
          <CallCard
            text="Appelez-nous"
            phoneNumber={monContrat.contents.numeroTelephoneContact || "01 46 90 37 00"}
            className={styles.SocialBlock__callCardButton}
            variant={isDesktop ? "secondary" : "outlined-dark"}
            sendToDataLayer={() => sendToDataLayer(idTracking)}
          />
        </div>
      </div>
    </>
  );
};
