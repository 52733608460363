import { DropDownItem } from "@roole/components-library";
import { GarantieProduit, TypeEvenement } from "domain/api-schemas";
import { atom } from "recoil";
import { APPLICATIONSTATES } from "context/global.types";

export interface ReportClaimStepState {
  activeStep: number;
  isEditing: boolean;
  claimDate: Date | null;
  typeEvents: TypeEvenement[];
  claimType: DropDownItem | null;
  claimDescription: string | null;
  claimGuarantee: GarantieProduit | null;
  claimTypeEventId: string | null;
  claimContractId: string | null;
  claimContractAnciennete: number | null;
  clientIban: string | null;
  guaranteesAvailable: GarantieProduit[] | null;
  claimFiles: { name: string }[] | null;
  claimId: string | null;
  errors?: {
    claimDate?: string | null;
    claimType?: string | null;
    claimDescription?: string | null;
    claimGuarantee?: string | null;
    claimPiecesJointes?: string[] | null;
    claimPiecesJointesUpload?: ErrorClaimPiecesJointesUpload[] | null;
  };
}

export interface ErrorClaimPiecesJointesUpload {
  name: string;
  error: string;
}

export const ErrorTypeClaimPiecesJointes = {
  NO_FILE: "Veuillez ajouter au moins une pièce justificative pour créer la demande de sinistre",
  NO_NEW_FILE:
    "Veuillez ajouter au moins une pièce justificative pour modifier la demande de sinistre",
};

export const reportClaimStepContextStateInitValue: ReportClaimStepState = {
  activeStep: 1,
  isEditing: false,
  claimDate: null,
  typeEvents: [],
  claimType: null,
  claimDescription: null,
  claimGuarantee: null,
  claimTypeEventId: null,
  claimContractId: null,
  claimContractAnciennete: null,
  clientIban: null,
  guaranteesAvailable: null,
  claimFiles: null,
  claimId: null,
  errors: {
    claimDate: null,
    claimType: null,
    claimDescription: null,
    claimPiecesJointesUpload: null,
  },
};

export const reportClaimStepState = atom<ReportClaimStepState>({
  key: APPLICATIONSTATES.DeclareSinistreState,
  default: reportClaimStepContextStateInitValue,
});
