import { Text } from "@roole/components-library";
import { useNavigate } from "react-router-dom";

import {
  NEXT_BTN,
  useWizard,
  WizardNav,
  WizardStep,
  WizardStepLayout,
} from "components/molecules/Wizard";

export const RenewalConfirmationStep: WizardStep = () => {
  const navigate = useNavigate();
  const { submitStep } = useWizard();

  const nextStepHandler = () => submitStep(null, () => navigate("/", { replace: true }));

  return (
    <WizardStepLayout title="Bien reçu !">
      <Text textVariant="heading-6" as="h2">
        Votre contrat a été reconduit avec succès.
      </Text>
      <Text textVariant="body" as="p">
        Vous pouvez vous connecter à votre compte personnel afin de consulter le détail de vos
        garanties et services, profiter de vos bons plans, déclarer un sinistre...
      </Text>
      <WizardNav nextStepHandler={nextStepHandler} buttons={NEXT_BTN} />
    </WizardStepLayout>
  );
};
