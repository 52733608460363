import axiosClient from "clientProvider/axiosConfig";
import { Couverture, Evenement, EvenementType, RepriseEvenementType } from "domain/api-schemas";

export type TypesEvenementsParams = {
  ageContrat: number;
  code: string;
  dateEvenement: string;
};

export type InitializeEvenementParams = {
  dateEvenement: string;
  typeEvenementId: string;
};

export type TypesInfoRepriseParams = {
  idAdhesion: string;
  dateEvenement: string;
  typeEvenementId: string;
};

export type DeclarationParams = {
  evenementId?: string;
  dateEvenement: string;
  isVolExpire?: boolean;
  typeEvenement?: EvenementType;
  description?: string;
  isVehiculeRetrouve?: boolean | null;
  isVehiculeReparable?: boolean | null;
  isDeclareAssuranceAccident?: boolean;
  couvertureAssurance?: Couverture;
  garantiesEligibles?: string[];
  garantiesNonEligibles?: string[];
  hasMalus?: boolean | null;
  iban?: string;

  recap?: {
    evenement: {
      id: string;
      nom: string;
      typeId: string;
      date: string;
    };
    dossiersGarantie: {
      id: string;
      nom: string;
      garantieNom: string;
      statut: string;
    }[];
  };
  garanties?: {
    id: string;
    isDossierComplet: boolean;
    piecesJointes?: {
      typeId?: string;
      nom?: string;
      blobNom?: string;
      size?: number;
      date?: string;
      base64?: string;
    }[];
  }[];

  responses?: Record<string, string>;
};

export const evenementApi = {
  fetchEvenements: async (idAdhesion: string) => {
    const result = await axiosClient.get<Evenement[]>(`Evenement/Membre/${idAdhesion}`);
    return result.data;
  },

  fetchTypesEvenements: async (params: TypesEvenementsParams) => {
    const result = await axiosClient.get<EvenementType[]>(`Evenement/Types/Produit/`, {
      params,
    });
    return result.data;
  },

  initializeEvenement: async ({ dateEvenement, typeEvenementId }: InitializeEvenementParams) => {
    const result = await axiosClient.post<string>(`DeclarationSinistre/InitializeEvenement`, {
      dateEvenement,
      typeEvenementId,
    });

    return result.data;
  },

  fetchInfoRepriseEvenement: async ({
    idAdhesion,
    dateEvenement,
    typeEvenementId,
  }: TypesInfoRepriseParams) => {
    const result = await axiosClient.get<RepriseEvenementType>(
      `Evenement/InfosRepriseEvenement/${idAdhesion}/${dateEvenement}/${typeEvenementId}`,
    );

    return result.data;
  },
};
