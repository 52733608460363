import axiosClient from "clientProvider/axiosConfig";
import { LogModel } from "domain/api-schemas";

export const endpoints = {
  postLog: "Log",
};

export const logApi = {
  logActions: async (logModel: LogModel) => {
    const response = await axiosClient.post<LogModel>(
      `${endpoints.postLog}/${logModel.dossierId ?? ""}?actionTag=${
        logModel.actionTag
      }&idAdhesion=${logModel.idAdhesion}`,
      {
        ...logModel,
      },
    );
    return response.data;
  },
};
