import { FC } from "react";
import { v4 as uuidv4 } from "uuid";

import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import { Card } from "components/atoms/Card/Card";

import styles from "./HelpBlock.module.scss";
import cssVariables from "styles/variables.module.scss";

interface HelpBlockProps {
  children?: React.ReactNode[];
  className?: string;
}

export const HelpBlock: FC<HelpBlockProps> = ({ children, className }: HelpBlockProps) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return (
    <div className={classNames(styles.HelpBlock, className)}>
      <Card>
        {!isDesktop && <>{children?.[0]}</>}
        {isDesktop &&
          children &&
          children.map((child, index) => {
            return (
              <div key={uuidv4()}>
                {index !== 0 && <hr className={styles.hr} />}
                <div className={styles.HelpBlock__content}>{child}</div>
              </div>
            );
          })}
      </Card>
    </div>
  );
};
