import { Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { FC } from "react";

import { POSSIBLE_TIME_SLOTS, TimeSlot } from "./Appointment";
import { classNames } from "utils/classNames";
import styles from "./TimeSlots.module.scss";

interface TimeSlotsProps {
  value?: TimeSlot;
  onChange?(timeSlot: TimeSlot): void;
}

export const TimeSlots: FC<TimeSlotsProps> = ({ value, onChange }) => {
  return (
    <div className={styles.timeSlots}>
      <Text className={styles.timeSlots__title} textVariant="body">
        Choisissez une plage horaire
      </Text>
      {POSSIBLE_TIME_SLOTS.map((slot) => (
        <Button
          key={slot.id}
          variant={slot.id === value?.id ? "primary" : "outlined-light"}
          type="button"
          className={classNames(
            slot.id === value?.id && styles["timeSlots--selected"],
            styles.timeSlot,
          )}
          onClick={() => onChange?.(slot)}
        >
          <span>{slot.name}</span>
          {slot.description && <span>{slot.description}</span>}
        </Button>
      ))}
    </div>
  );
};
