import { FC } from "react";

import { useWizard } from "./WizardContext";
import { WizardStepIcon, WizardStepObject, WizardStepStatus } from "./Wizard.types";
import { MediaItem } from "components/molecules/MediaItem/MediaItem";
import { ReactComponent as IconCheck } from "images/icons/check-circle-outline.svg";
import { ReactComponent as IconCreditCard } from "images/icons/credit-card.svg";
import { ReactComponent as IconEsign } from "images/icons/file-open.svg";
import { ReactComponent as IconInfo } from "images/icons/information.svg";
import { classNames } from "utils/classNames";
import { CommonProps } from "utils/CommonProps";
import styles from "./WizardStatus.module.scss";

const iconsByKeys: Record<WizardStepIcon, FC> = {
  check: IconCheck,
  creditCard: IconCreditCard,
  eSign: IconEsign,
  info: IconInfo,
};

const getIconByKey = (key: WizardStepIcon) => {
  const Icon = iconsByKeys[key] || iconsByKeys.info;
  return <Icon />;
};

export const WizardStatus: FC<CommonProps> = ({ className }) => {
  const { steps, initialized } = useWizard();
  const activeSteps: Partial<WizardStepObject>[] = initialized
    ? (steps || []).filter((step) => step.status !== WizardStepStatus.LOCKED)
    : [{}, {}, {}];

  return activeSteps.length > 0 ? (
    <ul className={classNames(styles.wizardStatus, className)}>
      {activeSteps.map((step, index) => (
        <MediaItem
          key={step.path ? step.path : index}
          className={classNames(
            styles.wizardStatus__item,
            styles[`wizardStatus__item--${step.status?.toLowerCase()}`],
          )}
          parentStyles={styles}
          title={step.label}
          description={
            step.description && (
              <>
                {step.status === WizardStepStatus.STARTED && <strong>En cours - </strong>}
                {step.description}
              </>
            )
          }
          icon={step.icon && getIconByKey(step.icon)}
          step={step.index}
        />
      ))}
    </ul>
  ) : null;
};
