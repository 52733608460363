import { useEffect } from "react";

type Tags = string[];

const useSmartPushFilters = (tags: Tags = [], isOr: Boolean = false): void => {
  useEffect(() => {
    const eventDetail = {
      filters: {
        thematics: [],
        ...(isOr ? { tagsOr: tags } : { tags: tags }),
      },
    };
    window.dispatchEvent(new CustomEvent("STPUSHUpdate", { detail: eventDetail }));
  }, [tags, isOr]);
};

export default useSmartPushFilters;
