import { Text } from "@roole/components-library";

import { Box } from "components/atoms/Box/Box";
import { ReactComponent as IconCheck } from "images/icons/check-circle-outline.svg";
import { classNames } from "utils/classNames";
import styles from "./ReminderBox.module.scss";

export const ReminderBox = () => {
  const todos = ["Votre téléphone portable", "Votre carte de crédit"];

  return (
    <Box className={classNames(styles.reminderBox, "custom-box")}>
      <Text textVariant="heading-6" as="h3">
        Pensez à vous munir de&nbsp;:
      </Text>
      <ul className={styles.todoList}>
        {todos.map((todo) => (
          <li key={todo} className={styles.reminderBox__todo}>
            <IconCheck />
            <Text textVariant="body">{todo}</Text>
          </li>
        ))}
      </ul>
    </Box>
  );
};
