import { useEffect, useState } from "react";

export const useMobileOsDetection = () => {
  const [isAndroid, setIsAndroid] = useState<boolean | null>(null);
  const [isIos, setIsIos] = useState<boolean | null>(null);

  useEffect(() => {
    const userAgent = typeof window.navigator === "undefined" ? "SSR" : navigator.userAgent;
    setIsAndroid(Boolean(/Android/i.exec(userAgent)));
    setIsIos(Boolean(/iPhone|iPad|iPod/i.exec(userAgent)));
  }, []);

  return { isAndroid, isIos, isMobile: isAndroid || isIos };
};
