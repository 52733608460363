import { WizardStep, WizardStepLayout, useWizard } from "components/molecules/Wizard";
import { useEffect } from "react";

export const StatusStep: WizardStep = () => {
  const { nextStep } = useWizard();
  useEffect(() => {
    nextStep();
  });
  return (
    <>
      <WizardStepLayout title=""></WizardStepLayout>
    </>
  );
};
