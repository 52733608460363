export const getMaskedEmail = (email: string) => {
  if (!email) {
    return undefined;
  }

  let skipFirstChars = 3;
  let firstThreeChar = email.slice(0, skipFirstChars);

  let domainIndexStart = email.lastIndexOf("@");
  let maskedEmail = email.slice(skipFirstChars, domainIndexStart);
  maskedEmail = maskedEmail.replace(/./g, "*");
  let domain = email.slice(domainIndexStart, email.length);

  return firstThreeChar.concat(maskedEmail).concat(domain);
};
