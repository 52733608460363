import { Skeleton, Text } from "@roole/components-library";
import { FC, ReactNode } from "react";

import { classNames } from "utils/classNames";
import { CommonProps } from "utils/CommonProps";
import styles from "./MediaItem.module.scss";

interface ParentStyles {
  description: string;
  icon: string;
}

interface MediaItemProps extends CommonProps {
  title: ReactNode;
  description: ReactNode;
  icon: ReactNode;
  parentStyles: Partial<ParentStyles>;
  step?: number;
}

export const MediaItem: FC<MediaItemProps> = ({
  className,
  title,
  description,
  icon,
  parentStyles,
  step,
}) => (
  <li className={classNames(styles.mediaItem, className)}>
    <span className={classNames(styles.mediaItem__icon, parentStyles.icon)}>
      {icon ?? <Text textVariant="subheading-em">{step}</Text>}
    </span>
    <div className={styles.mediaItem__body}>
      <Text textVariant="subheading-em" as="p">
        {title ?? <Skeleton width="60%" />}
      </Text>
      <Text className={parentStyles.description} textVariant="ui-1" as="p">
        {description ?? <Skeleton width="80%" />}
      </Text>
    </div>
  </li>
);
