import { referentielsPiecesJustificativesApi } from "domain/api/ReferentielsPiecesJustificatives/referentielsPiecesJustificatives";
import { selectorFamily } from "recoil";

export const listReferentielsPiecesJustificativesQuery = selectorFamily({
  key: "listTypesEvenementsQuery",
  get:
    (params: {
      garantiesIds: string[];
      typeEvenementId: string;
      isVehiculeRetrouve: boolean;
      isVehiculeReparable: boolean;
    }) =>
    async () => {
      return await referentielsPiecesJustificativesApi.getReferentielsPiecesJustificatives(
        params.garantiesIds,
        params.typeEvenementId,
        params.isVehiculeRetrouve,
        params.isVehiculeReparable,
      );
    },
});
