import { FC, HTMLAttributes } from "react";
import { Box } from "components/atoms/Box/Box";
import { classNames } from "utils/classNames";

import styles from "./Card.module.scss";

type CardProps = {
  isSmall?: boolean;
  noMargin?: boolean;
  center?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Card: FC<CardProps> = ({
  className,
  children,
  id,
  isSmall = false,
  noMargin = false,
  center = false,
}) => {
  const content = (
    <div
      className={classNames(styles.cardContent, isSmall && styles.small, center && styles.center)}
    >
      {children}
    </div>
  );

  return (
    <Box key={id} className={classNames(styles.container, noMargin && styles.noMargin, className)}>
      {content}
    </Box>
  );
};
