import { useEffect, createContext, useContext, useMemo, useCallback } from "react";
import { handleCookiebotAccept, initializeSTPUSH } from "SmartTribune";
import { useLocation } from "react-router-dom";

interface SmartTribuneContextType {
  hideSmartPush: () => void;
  showSmartPush: () => void;
}

const SmartTribuneContext = createContext<SmartTribuneContextType | undefined>(undefined);

export function useSmartTribuneContext() {
  const context = useContext(SmartTribuneContext);
  if (context === undefined) {
    throw new Error("useSmartTribuneContext must be used within an AppContextProvider");
  }
  return context;
}

const SmartTribuneProvider = ({
  children,
  isEnabled = true,
}: {
  children: JSX.Element;
  isEnabled?: boolean;
}) => {
  const hideSmartPush = useCallback(() => {
    if (window.__STPUSHInit) {
      window.__STPUSHInit.off();
      window.__STPUSHInit.hide();
    }
  }, []);

  const showSmartPush = useCallback(() => {
    if (window.__STPUSHInit) {
      window.__STPUSHInit.on();
      window.__STPUSHInit.show();
    }
  }, []);

  useEffect(() => {
    isEnabled && initializeSTPUSH(location.pathname);
    handleCookiebotAccept();
  }, [isEnabled]);

  const value = useMemo(
    () => ({
      hideSmartPush,
      showSmartPush,
    }),
    [hideSmartPush, showSmartPush],
  );

  const location = useLocation();

  useEffect(() => {
    isEnabled && initializeSTPUSH(location.pathname);
  }, [isEnabled, location.pathname]);

  return <SmartTribuneContext.Provider value={value}>{children}</SmartTribuneContext.Provider>;
};

export default SmartTribuneProvider;
