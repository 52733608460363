import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { DropDownItem } from "@roole/components-library";

import { globalState } from "context/globalState/atom";
import { Concession } from "domain/api-schemas";
import { myGarageService } from "domain/api/MyGarage/myGarageService";
import { Appointment } from "components/molecules/AppointmentSchedule/Appointment";
import * as MyGarageAdapter from "./myGarageAdapter";

const queries = {
  getMyGarage: "get-my-garage",
  getNextDealerships: "get-next-dealerships",
  getBrands: "get-brands",
};

const defaultQueryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  useErrorBoundary: true,
  retryOnMount: false,
  retry: 1,
};

export const useMyGarage = () => {
  const queryClient = useQueryClient();
  const { idAdhesion } = useRecoilValue(globalState);
  const [selectedFilters, setSelectedFilters] = useState<DropDownItem | null>(null);
  const [partnerDealership, setPartnerDealership] = useState<Concession | null>(null);

  const { isLoading: isMyGarageLoading, data: myGarage } = useQuery(
    [queries.getMyGarage, idAdhesion],
    () => myGarageService.getMyGarage(idAdhesion),
    {
      ...defaultQueryOptions,
      enabled: !!idAdhesion,
    },
  );

  const {
    isFetching: isDealershipsLoading,
    data: otherDealerships,
    hasNextPage: hasMoreDealerships,
    fetchNextPage: refetchNextDealerships,
  } = useInfiniteQuery(
    [queries.getNextDealerships, myGarage?.maConcession?.id, selectedFilters?.name],
    ({ pageParam }) =>
      myGarageService.getNextDealerships(idAdhesion, selectedFilters?.name, pageParam),
    {
      ...defaultQueryOptions,
      enabled: !!myGarage?.maConcession?.id,
      select: MyGarageAdapter.selectMyDealerships,
      getNextPageParam: MyGarageAdapter.getNextDealershipsParam,
    },
  );

  const { isLoading: isBrandsLoading, data: brands } = useQuery(
    [queries.getBrands, idAdhesion],
    () => myGarageService.getBrands(idAdhesion),
    {
      ...defaultQueryOptions,
      enabled: !!idAdhesion,
      select: MyGarageAdapter.selectBrands,
    },
  );

  const { isLoading: isActivating, mutate: mutateHotDeal } = useMutation(
    ({ idBonPlan, idConcession }: { idBonPlan: string; idConcession: string }) =>
      myGarageService.activateHotDeal(idBonPlan, idConcession, idAdhesion),
    {
      useErrorBoundary: true,
      onSuccess: ({ idBonPlan, idConcession }) => {
        if (idConcession === myGarage?.maConcession?.id) {
          queryClient.setQueryData(
            [queries.getMyGarage, idAdhesion],
            MyGarageAdapter.mapHotDealActivationFromMyGarage(idBonPlan, myGarage),
          );
        } else if (!!otherDealerships) {
          queryClient.setQueryData(
            [queries.getNextDealerships, myGarage?.maConcession?.id, selectedFilters?.name],
            MyGarageAdapter.mapHotDealActivationFromOtherDealerships(
              idBonPlan,
              idConcession,
              otherDealerships,
            ),
          );
        }
      },
    },
  );

  const { isLoading: isConnecting, mutate: mutateMyGarage } = useMutation(
    ({ dealership }: { dealership: Concession }) =>
      myGarageService.connectDealership(dealership, idAdhesion),
    {
      useErrorBoundary: true,
      onSuccess: (dealership) => {
        setSelectedFilters(null);
        queryClient.setQueryData([queries.getMyGarage, idAdhesion?.toString()], {
          ...myGarage,
          maConcession: {
            ...myGarage?.maConcession,
            ...dealership,
          },
        });
      },
    },
  );

  const { isLoading: isScheduling, mutate: scheduleAppointment } = useMutation(
    ({ dealership, appointment }: { dealership: Concession; appointment?: Appointment }) =>
      myGarageService.scheduleAppointment(idAdhesion, dealership, appointment),
    {
      useErrorBoundary: true,
    },
  );

  const { isLoading: isSchedulingPartner, mutate: schedulePartnerAppointment } = useMutation(
    (kilometrage: number) =>
      myGarageService
        .schedulePartnerAppointment(idAdhesion, partnerDealership?.id, kilometrage)
        .catch(() => {
          return partnerDealership!.urlRDV!;
        }),
  );

  const changeFilters = useCallback(
    (item: DropDownItem) => {
      setSelectedFilters(item.id > 1 ? item : null);
    },
    [setSelectedFilters],
  );

  return {
    isLoading: isMyGarageLoading || isDealershipsLoading || isBrandsLoading || isConnecting,
    isActivating,
    isConnecting,
    isScheduling,
    isSchedulingPartner,
    isMyGarageLoading,
    isDealershipsLoading,
    myDealership: myGarage?.maConcession,
    otherDealerships: (otherDealerships as MyGarageAdapter.InfiniteDealerships)?.dealerships,
    filters: brands,
    selectedFilters,
    hasMoreDealerships,
    setPartnerDealership,
    activateHotDeal: mutateHotDeal,
    getNextDealerships: refetchNextDealerships,
    changeFilters,
    connectDealership: mutateMyGarage,
    scheduleAppointment,
    schedulePartnerAppointment,
  };
};
