import { useEffect } from "react";

export const useLockScroll = () =>
  useEffect(() => {
    const { body } = document;
    const { style } = body;
    const { overflow, marginRight } = style;
    const { scrollX, scrollY } = window;
    const lockScroll = () => window.scrollTo(scrollX, scrollY);
    window.addEventListener("scroll", lockScroll);
    if (window.innerWidth > document.body.clientWidth) {
      style.marginRight = "16px";
    }
    style.overflow = "hidden";

    return () => {
      window.removeEventListener("scroll", lockScroll);
      style.overflow = overflow;
      style.marginRight = marginRight;
    };
  }, []);
