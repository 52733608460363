import useDataLayer from "./useDataLayer";
import { useCallback } from "react";
import { globalState } from "context/globalState/atom";
import { useRecoilValue } from "recoil";
import { logApi } from "domain/api/Log/logService";

export const useTracking = () => {
  const { pushToDataLayer } = useDataLayer();
  const { idAdhesion } = useRecoilValue(globalState);
  const sendToDataLayer = useCallback(
    (elementId: string, apiOnly?: boolean, dossierId?: string) => {
      if (!apiOnly) {
        pushToDataLayer({
          event: "gtm.click",
          data: {
            "gtm.elementId": elementId,
          },
        });
      }
      (async () => {
        await logApi.logActions({
          idAdhesion: idAdhesion?.toString() ?? encodeURIComponent(idAdhesion ?? ""),
          actionTag: encodeURIComponent(elementId),
          dossierId: dossierId,
        });
      })();
    },
    [pushToDataLayer, idAdhesion],
  );

  return { sendToDataLayer };
};
