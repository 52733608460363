import { useEffect, createContext, useContext, useMemo, useState } from "react";
import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
  useAuth0,
} from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import axiosClient, { addNewAccessTokenInterceptor } from "clientProvider/axiosConfig";

interface AuthenticationContextType {
  isJwtAuthenticated: boolean;
  isJwtLoading: boolean;
  isAuth0Authenticated: boolean;
  isAuth0Loading: boolean;
  user: User | undefined;
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>;
  jwtToken: string;
  logout: (options?: LogoutOptions | undefined) => void;
  error: Error | undefined;
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>;
  isAuthenticated: boolean;
  isLoading: boolean;
  defaultIdAdhesion: string;
}

const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined);

export function useAuthenticationContext() {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error("useAuthenticationContext must be used within an AppContextProvider");
  }
  return context;
}

const AuthenticationProvider = ({ children }: { children: JSX.Element }) => {
  const {
    isAuthenticated: isAuth0Authenticated,
    user,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    logout,
    error,
    loginWithRedirect,
  } = useAuth0();
  const [isJwtAuthenticated, setIsJwtAuthenticated] = useState(false);
  const [isJwtLoading, setIsJwtLoading] = useState(true);
  const [jwtToken, setJwtToken] = useState("");
  const [defaultIdAdhesion, setDefaultIdAdhesion] = useState("");
  const [searchParams] = useSearchParams();

  const token = useMemo(() => searchParams.get("token"), [searchParams]);
  const idAdhesion = useMemo(() => searchParams.get("idAdhesion"), [searchParams]);

  useEffect(() => {
    if (token && idAdhesion) {
      addNewAccessTokenInterceptor(token).then(() => {
        if (!isAuth0Loading) {
          verifyToken().then((isValid) => {
            setIsJwtAuthenticated(isValid);
            setIsJwtLoading(false);
            if (isValid) {
              setJwtToken(token);
              setDefaultIdAdhesion(idAdhesion);
            }
          });
        }
      });
    } else {
      setIsJwtLoading(false);
    }
  }, [token, isAuth0Loading]);

  const value = useMemo(
    () => ({
      isAuth0Authenticated,
      user,
      isAuth0Loading,
      isJwtAuthenticated,
      isJwtLoading,
      token: jwtToken,
      defaultIdAdhesion,
      getAccessTokenSilently,
      jwtToken,
      logout,
      error,
      loginWithRedirect,
      isAuthenticated: isAuth0Authenticated || isJwtAuthenticated,
      isLoading: isJwtLoading || isAuth0Loading,
    }),
    [
      isAuth0Authenticated,
      user,
      isAuth0Loading,
      isJwtAuthenticated,
      isJwtLoading,
      jwtToken,
      defaultIdAdhesion,
      getAccessTokenSilently,
      logout,
      error,
      loginWithRedirect,
    ],
  );

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

const verifyToken = async (): Promise<boolean> => {
  try {
    const result = await axiosClient.get<boolean>(`Membre/CheckUserHasAccess`);
    return result.data;
  } catch (error) {
    return false;
  }
};

export default AuthenticationProvider;
