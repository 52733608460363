import { FC } from "react";
import { NavLink } from "react-router-dom";

import { Title } from "components/atoms/Title/Title";
import { ButtonGroup } from "components/atoms/Button/ButtonGroup";
import { Button } from "components/atoms/Button/Button";
import { Box } from "components/atoms/Box/Box";
import { PriceBox } from "components/molecules/PriceBox/PriceBox";
import { ResiliationContract } from "domain/api-schemas";
import { RoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";

import { ReactComponent as BackButtonIcon } from "images/icons/arrowBack.svg";
import styles from "./Form.module.scss";

type RetentionMessageProps = {
  contract: ResiliationContract;
  onConfirm: () => void;
  onAbort: () => void;
};
export const RetentionMessage: FC<RetentionMessageProps> = ({ contract, onConfirm, onAbort }) => {
  const { sendToDataLayer } = useTracking();

  return (
    <>
      <Title>
        Savez-vous qu'il existe une solution pour être mieux couvert en payant moins cher votre
        assurance principale&nbsp;?
      </Title>
      <Box>
        <p>
          En tant que complémentaire auto,&nbsp;
          <b>nous prenons en charge les frais non couverts par votre assurance principale.</b>
        </p>
        <p>
          Avec nos partenaires GROUPAMA, ALLIANZ et OPTEVEN ASSURANCES,&nbsp;
          <b>
            nous sommes en mesure de vous proposer un ensemble de garanties exclusives et très
            compétitives&nbsp;
          </b>
          que vous ne pourrez pas trouver au même prix chez votre assureur. En étudiant votre
          contrat d’assurance, vous pouvez ainsi modifier les options et les niveaux de franchises
          pour réduire vos frais et rester couvert grâce à votre contrat Roole.
        </p>
        <p>
          <b>Contactez nous ou demandez à être rappelé</b>, nous serons ravis de vous expliquer
          toutes nos astuces !
        </p>
      </Box>
      <Box>
        <h3>Rappel de vos garanties</h3>
        <ul>
          <li>
            <b className={styles.accentuate}>Rachat de franchise</b> : prise en charge de votre
            franchise, notamment en cas d’accident responsable
          </li>
          <li>
            <b className={styles.accentuate}>Assistance 0 km</b> : prise en charge du dépannage
            et/ou du remorquage de votre véhicule
          </li>
          <li>
            <b className={styles.accentuate}>SOS permis</b> : financement à 100 % d’un stage de
            récupération de points
          </li>
        </ul>
        <p>
          Cette liste n’est pas exhaustive. Pour connaître l’intégralité de vos garanties, nous vous
          invitons à les consulter dans la rubrique : &nbsp;
          <NavLink className={styles.accentuate} to={RoutePaths.GARANTIES}>
            <b>Vos garanties</b>
          </NavLink>
        </p>
      </Box>
      {contract.packServices?.tarifAnnuelTac ? (
        <Box className={styles.cotisationMessage}>
          <div className={styles.cotisationMessageContainer}>
            <h3>Montant de votre cotisation</h3>
            <span className="">Sans&nbsp;engagement</span>
          </div>
          <PriceBox price={contract.packServices.tarifAnnuelTac / 12} />
        </Box>
      ) : null}
      <ButtonGroup data-selector="actionDemande">
        <Button
          data-selector="retour_etape2"
          className={styles.backButton}
          onClick={() => {
            sendToDataLayer("resil-btn-retour");
            onAbort();
          }}
          icon={<BackButtonIcon style={{ width: "12px" }} />}
        >
          Retour
        </Button>
        <Button data-selector="confirmation_etape2" variant="outlined-light" onClick={onConfirm}>
          Finaliser la résiliation
        </Button>
      </ButtonGroup>
    </>
  );
};
