import { FC, ReactNode } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { RoutePaths } from "routes/routePaths";
import { absoluteURL } from "utils/absoluteURL";
import { classNames } from "utils/classNames";

import { useSignOut } from "hooks/useSignOut";
import { ExternalLink } from "components/atoms/ExternalLink/ExternalLink";
import { Button } from "components/atoms/Button/Button";
import illustrationDownloadAppleApp from "images/button-app-app-store-ios.png";
import illustrationDownloadAndroidApp from "images/button-app-google-play-android.png";
import { MonContrat } from "domain/api-schemas";

import {
  IconHome,
  IconGarage,
  IconContract,
  IconDiscount,
  IconGarantie,
  IconRepairCar,
  IconSponsorship,
  IconProfil,
  IconHomeBlack,
  IconGarageBlack,
  IconContractBlack,
  IconDiscountBlack,
  IconGarantieBlack,
  IconRepairCarBlack,
  IconSponsorshipBlack,
  IconProfilBlack,
} from "images/icons";

import styles from "./NavSidebar.module.scss";
import { useTracking } from "hooks/useTracking";
import { Text } from "@roole/components-library";

type NavItemProps = {
  id: string;
  to: string;
  children: ReactNode;
  onClose: (() => void) | undefined;
  icon: ReactNode;
  activeIcon: ReactNode;
};

const NavItem: FC<NavItemProps> = ({ id, to, children, onClose, icon, activeIcon }) => {
  const location = useLocation();
  const { sendToDataLayer } = useTracking();

  const isActive = location.pathname === to;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onClose?.();
    sendToDataLayer(e.currentTarget.id, true);
  };

  return (
    <div className={styles.menu}>
      {isActive ? activeIcon : icon}
      <NavLink id={id} end className={activeClassName} to={to} onClick={handleClick}>
        {children}
      </NavLink>
    </div>
  );
};

const activeClassName: NavLinkProps["className"] = ({ isActive }) =>
  isActive ? styles.selected : "";

type NavSidebarProps = {
  className?: string;
  displayRoutes: Partial<Record<RoutePaths, boolean>>;
  onClose?: () => void;
  contract?: MonContrat;
};

export const NavSidebar: FC<NavSidebarProps> = ({
  className,
  displayRoutes,
  onClose,
  contract,
}) => {
  const { signOut } = useSignOut();
  const { sendToDataLayer } = useTracking();

  return (
    <div className={classNames(className, styles.container)}>
      <nav>
        <NavItem
          id="navsidebar-accueil"
          to={RoutePaths.CONTRAT_HOME}
          onClose={onClose}
          icon={<IconHome />}
          activeIcon={<IconHomeBlack />}
        >
          Accueil
        </NavItem>
        <NavItem
          id="menu-mon-contrat"
          to={RoutePaths.CONTRAT}
          onClose={onClose}
          icon={<IconContract />}
          activeIcon={<IconContractBlack />}
        >
          Mon contrat
        </NavItem>
        <NavItem
          id="navsidebar-mes-garanties"
          to={RoutePaths.GARANTIES}
          onClose={onClose}
          icon={<IconGarantie />}
          activeIcon={<IconGarantieBlack />}
        >
          Mes garanties
        </NavItem>
        {(contract?.membreAcces?.mesBonsPlans === true || contract?.membreAcces === undefined) && (
          <NavItem
            id="navsidebar-bons-plans"
            to={RoutePaths.BON_PLANS_HOME}
            onClose={onClose}
            icon={<IconDiscount />}
            activeIcon={<IconDiscountBlack />}
          >
            Mes bons plans
          </NavItem>
        )}
        {displayRoutes?.[RoutePaths.MON_COMPTE] && (
          <NavItem
            id="menu-mon-garage"
            to={RoutePaths.MON_COMPTE}
            onClose={onClose}
            icon={<IconGarage />}
            activeIcon={<IconGarageBlack />}
          >
            Mon garage
          </NavItem>
        )}
        {displayRoutes?.[RoutePaths.DECLARER_SINISTRE] && (
          <NavItem
            id="navsidebar-suivi-sinistre"
            to={RoutePaths.SINISTRES}
            onClose={onClose}
            icon={<IconRepairCar />}
            activeIcon={<IconRepairCarBlack />}
          >
            Mes sinistres
          </NavItem>
        )}
        {(contract?.membreAcces?.parrainage === true || contract?.membreAcces === undefined) && (
          <NavItem
            id="menu-parrainage"
            to={RoutePaths.PARRAINAGE}
            onClose={onClose}
            icon={<IconSponsorship />}
            activeIcon={<IconSponsorshipBlack />}
          >
            Parrainage
          </NavItem>
        )}
        <NavItem
          id="menu-user-profil"
          to={RoutePaths.USER_PROFIL}
          onClose={onClose}
          icon={<IconProfil />}
          activeIcon={<IconProfilBlack />}
        >
          Mon profil
        </NavItem>
      </nav>

      <div className={styles.appInfo}>
        <Text textVariant="body-em" className={styles.appInfoTitle}>
          Application <br /> Roole Premium
        </Text>

        <Text textVariant="ui-1"> L'intégralité de nos services sur smartphone. </Text>
        <div className={styles.appInfoStores}>
          <ExternalLink
            href="https://apps.apple.com/fr/app/roole-connect/id1605740783"
            icon={null}
            className={styles.storeLink}
          >
            <img
              id="navsidebar-app-ios"
              onClick={(e) => {
                sendToDataLayer(e.currentTarget.id, true);
              }}
              src={absoluteURL(illustrationDownloadAppleApp, process.env.REACT_APP_ASSETS_ROOT)}
              alt="Télécharger dans l'AppStore"
              className={styles.storeImgLink}
            />
          </ExternalLink>
          <ExternalLink
            href="https://play.google.com/store/apps/details?id=com.roole.rooleconnect&hl=fr"
            icon={null}
            className={styles.storeLink}
          >
            <img
              id="navsidebar-app-android"
              onClick={(e) => {
                sendToDataLayer(e.currentTarget.id, true);
              }}
              src={absoluteURL(illustrationDownloadAndroidApp, process.env.REACT_APP_ASSETS_ROOT)}
              alt="Télécharger sur Google Play"
              className={styles.storeImgLink}
            />
          </ExternalLink>
        </div>
      </div>
      <Button
        id="navsidebar-deconnexion"
        variant="outlined-light"
        onClick={(e) => {
          sendToDataLayer(e.currentTarget.id, true);
          signOut();
        }}
        className={styles.buttonDeconnexion}
        style={{ padding: "2px" }}
      >
        Me déconnecter
      </Button>
    </div>
  );
};
