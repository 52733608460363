import { useQuery } from "react-query";
import { MonContrat } from "domain/api-schemas";
import { contractApi } from "domain/api/Contract/contractApi";

const DossierCompletVenteReseau = 1;
const VenteReseau = 4;

export enum FrequenceCotisation {
  Mensuelle = 12,
  An = 1,
}

export enum ModePaiement {
  Iban = "Iban",
  Cb = "Cb",
  Chq = "Chq",
}

export const useMonContrat = (idAdhesion: string) => {
  const {
    isLoading: monContratLoading,
    error: monContratError,
    data: monContrat,
    refetch,
  } = useQuery<MonContrat, Error>(
    "query-fetchmoncontrat",
    async () => await contractApi.fetchMonContrat(idAdhesion),
    { enabled: !!idAdhesion },
  );

  return { monContrat, monContratLoading, monContratError, refetch };
};

export interface PeriodesType {
  periode: string;
  ligneContrat: number;
}

export const getPeriodesAbonnement = (contrat: MonContrat) => {
  let periodes: PeriodesType[] = [];
  let debut = new Date(contrat.dateSouscription).getFullYear();
  const fin = new Date(contrat.dateFin).getFullYear();

  if (checkIfTacite(contrat)) {
    let ligne = fin - debut;
    for (let year = fin; year > debut; year--) {
      periodes.push({ periode: year - 1 + " - " + year, ligneContrat: ligne });
      ligne--;
    }
  } else {
    let ligne = contrat.abonnements.length;
    contrat.abonnements.forEach((abonnement) => {
      periodes.push({
        periode:
          new Date(abonnement.dateDebut).getFullYear() +
          " - " +
          new Date(abonnement.dateFin).getFullYear(),
        ligneContrat: ligne--,
      });
    });
  }

  if (contrat.reseauVente === DossierCompletVenteReseau || contrat.reseauVente === VenteReseau) {
    periodes.pop();
  }

  return periodes;
};

export const checkIfTacite = (monContrat: MonContrat) => {
  const tacite = 1;
  const taciteVenir1 = 2;
  const taciteVenir12 = 5;

  return (
    monContrat.typeReconduction === tacite ||
    monContrat.typeReconduction === taciteVenir1 ||
    monContrat.typeReconduction === taciteVenir12
  );
};

export const getNomPack = (contract: MonContrat) => {
  return contract?.packServices?.nomPack?.split("-")[1]?.trim() ?? "";
};
