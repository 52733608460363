import { FC } from "react";
import { classNames } from "utils/classNames";

import { ReactComponent as Star } from "images/icons/star.svg";
import { ReactComponent as StarHalf } from "images/icons/star_half.svg";
import { ReactComponent as StarEmpty } from "images/icons/star_border.svg";

import styles from "./MemberReview.module.scss";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import { Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { BonPlanReview } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";

type MemberReviewProps = {
  type?: "review" | "review-cta";
  review?: BonPlanReview;
  className?: string;
};

export const MemberReview: FC<MemberReviewProps> = ({
  type = "review",
  review,
  className,
  ...props
}) => {
  const { sendToDataLayer } = useTracking();

  const { note, text, author, date } = review ?? {};
  const fullStars = Math.floor(note!);
  const halfStars = note! % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  if (type === "review-cta" || !review) {
    return (
      <div className={classNames(styles.card, styles.reviewCta, className)} {...props}>
        <Text textVariant="heading-5">Partagez votre expérience !</Text>
        <Text textVariant="body">Vous souhaitez partager votre expérience avec nous ?</Text>
        <div className={styles.giveAdvice}>
          <Button
            id="bons-plans-avis"
            variant="primary"
            onClick={(e) => {
              sendToDataLayer(e.currentTarget.id, true);
              window.open("https://roole.typeform.com/to/DTzL5ua7", "_blank");
            }}
          >
            Donner mon avis
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.card, styles.review, className)} {...props}>
      <div className={styles.starsAndDescriptionContainer}>
        <div className={styles.starsContainer}>
          {Array.from({ length: fullStars }).map((_, index) => (
            <Star key={index} className={styles.star} />
          ))}
          {Array.from({ length: halfStars }).map((_, index) => (
            <StarHalf key={index + fullStars} className={styles.star} />
          ))}
          {Array.from({ length: emptyStars }).map((_, index) => (
            <StarEmpty key={index + fullStars + halfStars} className={styles.star} />
          ))}
        </div>
        <Text as="p" className={styles.description}>
          “{text}”
        </Text>
      </div>
      <div className={styles.bottomContainer}>
        <p className={styles.name}>{author}</p>
        <div className={styles.dot}></div>
        <p className={styles.date}>
          {formatDistanceToNow(new Date(date!), { addSuffix: true, locale: fr })}
        </p>
      </div>
    </div>
  );
};
