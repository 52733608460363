import { HTMLAttributes, FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/atoms/Button/Button";
import { classNames } from "utils/classNames";
import { RoutePaths } from "routes/routePaths";
import { ReactComponent as HelpIcon } from "images/icons/help.svg";

import styles from "./HelpCard.module.scss";

type HelpCardProps = {
  variant?:
    | "secondary"
    | "primary"
    | "secondary-light"
    | "outlined"
    | "outlined-dark"
    | "outlined-light";
  sendToDataLayer?: () => void;
  onClick?: () => void;
  text?: string;
} & HTMLAttributes<HTMLDivElement>;

export const HelpCard: FC<HelpCardProps> = ({
  className,
  variant = "secondary",
  id,
  sendToDataLayer,
  onClick,
  text,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <div className={classNames(styles.helpCard, className)} {...props}>
      <Button
        icon={
          <span
            style={{
              marginRight: "4px",
            }}
          >
            <HelpIcon />
          </span>
        }
        variant={variant}
        size="small"
        onClick={() => {
          sendToDataLayer?.();
          if (onClick) {
            onClick();
          } else if (text === "Centre d'aide") {
            window.open(RoutePaths.AIDE, "_blank");
          } else {
            navigate(RoutePaths.CONTACT_REASON);
          }
        }}
        id={id}
      >
        {text ?? "Centre d'aide"}
      </Button>
    </div>
  );
};
