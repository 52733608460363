import { FC, useCallback, useEffect, useState } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilRefresher_UNSTABLE,
} from "recoil";
import { dossierPjQuery } from "context/dossierState/atom";
import { DossierGarantie } from "domain/api-schemas";
import useMediaQuery from "hooks/useMediaQuery";
import { LoaderV2, Stepper, StepperDropdown } from "@roole/components-library";
import styles from "./Page.module.scss";
import { getSteps, transformStepType, getDefaultValueStep } from "./stepUtils";
import { TileContent } from "./TileContent";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { Footer } from "components/molecules/Footer/Footer";
import { currentContratQuery } from "context/currentContratState/selector";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { checkDossierOld } from "domain/service/Sinistre/useDossier";
import { globalState } from "context/globalState/atom";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";
import { useAuthenticationContext } from "AuthenticationProvider";

export const Page: FC = () => {
  const isMobileOrTablet = useMediaQuery("mobile_and_tablet");
  const { id, idAdhesion } = useParams();
  const [dossierData, setDossierData] = useState<DossierGarantie | null>(null);
  const globalStateValue = useRecoilValue(globalState);
  const dossierQuery = dossierPjQuery({
    crmId: id ?? "",
    idAdhesion: globalStateValue.idAdhesion! || idAdhesion!,
  });
  const dossierDataLoadable = useRecoilValueLoadable(dossierQuery);
  const contrat = useRecoilValueLoadable(currentContratQuery);
  const isDossierOld = checkDossierOld(dossierData) ?? false;

  const refreshDossier = useRecoilRefresher_UNSTABLE(dossierQuery);

  const { isJwtAuthenticated } = useAuthenticationContext();

  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const setupLayoutState = (setLayoutState: (state: any) => void, handleClose: () => void) => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Détail du dossier",
      closeButtonId: "detail-sinsistre-croix",
      onCloseCallback: handleClose,
    });
  };
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    sendCloseWebViewEvent({ hasError: false });
    if (!isJwtAuthenticated) {
      navigate(RoutePaths.SINISTRES);
    }
  }, [navigate]);

  useEffect(() => {
    refreshDossier();
  }, [refreshDossier]);

  useEffect(() => {
    setupLayoutState(setLayoutState, handleClose);
  }, [setLayoutState, handleClose]);

  useEffect(() => {
    if (dossierDataLoadable.state === "hasValue") {
      const data = dossierDataLoadable.contents;

      setDossierData({
        ...data.dossiersGaranties[0],
        evenementId: data.crmId,
        evenementNom: data.nom,
        dateEvenement: data.dateEvenement,
        typeEvenement: data.typeEvenement,
      });
    }
  }, [dossierDataLoadable]);

  if (dossierDataLoadable.state === "loading" || !dossierData) {
    return <LoaderV2 />;
  }

  if (dossierDataLoadable.state === "hasError") {
    sendCloseWebViewEvent({ hasError: true });
    return <Navigate to={RoutePaths.SINISTRES} />;
  }

  const steps = getSteps(dossierData);
  const transformedSteps = transformStepType(steps.steps);
  const defaultValue = getDefaultValueStep(dossierData);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.stepper}>
          {isMobileOrTablet ? (
            <StepperDropdown steps={transformedSteps.steps} defaultValue={defaultValue} />
          ) : (
            <Stepper steps={steps.steps} />
          )}
        </div>
        <TileContent dossierData={dossierData} isDossierOld={isDossierOld} />
      </div>
      {!isJwtAuthenticated && <Footer contrat={contrat?.contents || null} idAdhesion={null} />}
    </>
  );
};
