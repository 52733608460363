import {
  DossierGarantie,
  EtatReglementDossierGarantie,
  Evenement,
  GarantieV2,
  StatutDossierGarantie,
} from "domain/api-schemas";

const GFA = "PKFRC";
const AIDE_REPARATION = "AIDREP";

export const filterEvenements = (evenements: Evenement[]) => {
  const evenementsEncours: Evenement[] = [];
  let evenementsTermines: Evenement[] = [];

  evenements.forEach((evenement) => {
    const dossierEnCours = evenement.dossiersGaranties?.filter(
      (dossier: DossierGarantie) =>
        [
          StatutDossierGarantie.EnCours,
          StatutDossierGarantie.EnEtude,
          StatutDossierGarantie.Accorde,
          StatutDossierGarantie.Incomplet,
        ].includes(dossier.statut.code) &&
        dossier.etatReglement?.code !== EtatReglementDossierGarantie.Regle,
    );
    const dossierTermines = evenement.dossiersGaranties?.filter(
      (dossier: DossierGarantie) =>
        dossier.statut.code === StatutDossierGarantie.Refuse ||
        (dossier.statut.code === StatutDossierGarantie.Accorde &&
          dossier.etatReglement?.code === EtatReglementDossierGarantie.Regle),
    );
    if (dossierEnCours && dossierEnCours.length > 0) {
      evenementsEncours.push({ ...evenement, dossiersGaranties: dossierEnCours });
    }
    if (dossierTermines && dossierTermines.length > 0) {
      evenementsTermines.push({ ...evenement, dossiersGaranties: dossierTermines });
    }
  });

  return { evenementsEncours, evenementsTermines };
};

interface TagDossier {
  label: string;
  type:
    | "warning"
    | "neutral"
    | "validation"
    | "alert"
    | "other"
    | "brand_light"
    | "brand"
    | "light";
}
export const getTagDossier = (
  statutDossier: number,
  etatReglement: number | null,
): TagDossier | undefined => {
  switch (statutDossier) {
    case StatutDossierGarantie.EnCours:
      return { label: "Dossier incomplet", type: "warning" };
    case StatutDossierGarantie.EnEtude:
      return { label: "En étude", type: "neutral" };
    case StatutDossierGarantie.Accorde:
      const label = etatReglement === EtatReglementDossierGarantie.Regle ? "Réglé" : "Accordé";
      return { label: label, type: "validation" };
    case StatutDossierGarantie.Incomplet:
      return { label: "Action requise", type: "alert" };
    case StatutDossierGarantie.Refuse:
      return { label: "Refusé", type: "neutral" };
    default:
      break;
  }
};

export const displayAssurancePrincipale = (garanties: Array<GarantieV2>) => {
  return (
    garanties.filter((garantie) => garantie.code === GFA).length > 0 &&
    garanties.filter((garantie) => garantie.code === AIDE_REPARATION).length > 0
  );
};
