import { CSSProperties, FC, PropsWithChildren, useState } from "react";
import { Button } from "components/atoms/Button/Button";
import { v4 as uuidv4 } from "uuid";

import { classNames } from "utils/classNames";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";
import { ReactComponent as MessageCircle } from "images/icons/message-circle.svg";
import { ReactComponent as Newsletter } from "images/icons/newsletter.svg";
import { ReactComponent as Phone } from "images/icons/phone.svg";

import styles from "./ErrorBlock.module.scss";
import { useZendeskContext } from "ZendeskProvider";

export enum ErrorBlockVariant {
  PhoneCall = "phoneCall",
  Chat = "chat",
  Email = "email",
}

type ErrorBlockProps = {
  className?: string;
  title?: string;
  phoneNumber?: string;
  variants?: ErrorBlockVariant[];
  location?: string;
  email?: string;
};

type ErrorBlockBaseProps = {
  className?: string;
  title: string;
  location?: string;
};

const NUMERO_TELEPHONE_CONTACT_CULB = "01 46 90 37 00";
const EMAIL_CONTACT = "dpo@roole.fr";

const ErrorBlockBase: FC<PropsWithChildren<ErrorBlockBaseProps>> = ({
  title,
  className,
  location,
  children,
}) => {
  return (
    <section className={classNames(styles.container, className)}>
      <div className={styles.block}>
        <WarningIcon className={styles.warningIcon} />
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>
          Nous rencontrons un problème technique, cette rubrique est temporairement indisponible.{" "}
        </p>
        <p className={styles.text}>
          {`Mais pas de soucis, vous pouvez toujours nous contacter et nous prendrons en charge votre${" "}
          ${location || "demande"} !`}
        </p>
        {children}
      </div>
    </section>
  );
};

export const ErrorBlock: FC<ErrorBlockProps> = ({
  title = "Oups !",
  className,
  variants = [ErrorBlockVariant.PhoneCall],
  phoneNumber,
  location,
  email,
}) => {
  const { openChat } = useZendeskContext();
  const [emailMessage, setEmailMessage] = useState("Écrivez-nous");

  return (
    <ErrorBlockBase
      title={title}
      className={className}
      location={location}
      children={
        <div className={styles.buttonBlock}>
          {variants.map((variant, index) => {
            let newProps = null;
            const iconStyle: CSSProperties = {
              color: "transparent",
              stroke: "#fff",
            };
            if (variant === ErrorBlockVariant.PhoneCall) {
              newProps = {
                onClick: () => {
                  window.open(`tel:${phoneNumber ?? NUMERO_TELEPHONE_CONTACT_CULB}`);
                },
                label: phoneNumber ?? NUMERO_TELEPHONE_CONTACT_CULB,
                icon: <Phone style={iconStyle} />,
              };
            } else if (variant === ErrorBlockVariant.Email) {
              newProps = {
                onClick: () => {
                  setEmailMessage(email ?? EMAIL_CONTACT);
                },
                label: emailMessage,
                icon: <Newsletter style={iconStyle} />,
              };
            } else {
              newProps = {
                onClick: () => {
                  openChat();
                },
                label: "Chattez avec nous",
                icon: <MessageCircle style={iconStyle} />,
              };
            }

            return (
              <Button
                key={uuidv4()}
                variant={index % 2 === 0 ? "secondary" : "outlined-light"}
                onClick={newProps.onClick}
                icon={newProps.icon}
              >
                {newProps.label}
              </Button>
            );
          })}
        </div>
      }
    />
  );
};
