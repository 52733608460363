import { WizardStep, useWizard } from "components/molecules/Wizard";
import { useEffect } from "react";
import { OverlayLoader } from "@roole/components-library";

export const StatusStep: WizardStep = () => {
  const { nextStep } = useWizard();

  useEffect(() => {
    nextStep();
  });

  return <OverlayLoader />;
};
