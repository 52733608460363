import { FC, useState } from "react";

import { classNames } from "utils/classNames";
import { CommonProps } from "utils/CommonProps";
import styles from "./DealershipLogo.module.scss";

enum LogoState {
  PENDING,
  LOADED,
  NOT_FOUND,
}

interface DealershipLogoProps extends CommonProps {
  url?: string | null;
  small?: boolean;
  altText?: string;
}

export const DealershipLogo: FC<DealershipLogoProps> = ({
  className,
  url,
  small = false,
  altText = "Logo concession",
}) => {
  const [state, setState] = useState<LogoState>(LogoState.PENDING);
  const variantExtended = small ? "small" : "big";
  const variant = state === LogoState.PENDING ? "pending" : variantExtended;

  if (!url || state === LogoState.NOT_FOUND) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.dealershipLogo,
        styles[`dealershipLogo--${variant}`],
        styles.ouss,
        className,
      )}
    >
      <img
        src={url}
        alt={altText}
        onLoad={() => setState(LogoState.LOADED)}
        onError={() => setState(LogoState.NOT_FOUND)}
      />
    </div>
  );
};
