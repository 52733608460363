import axiosClient from "clientProvider/axiosConfig";

export type DeclarationSinistreParams = {
  idAdhesion: string;
  typeEvenementId: string;
  evenementId: string;
  garanties: {
    id: string;
    isDossierComplet: boolean;
    piecesJointes: {
      typeId: string;
      nom: string;
      blobNom: string;
    }[];
  }[];
  origine: 7 | 9;
  note: string;
  isReprise: boolean;
  dateEvenement: string;
  isVehiculeRetrouve?: boolean | null;
  isVehiculeReparable?: boolean | null;
  mailMembre: string;
  iban?: string;
};

export const declarationSinistreApi = {
  declarerSinistre: async ({
    idAdhesion,
    typeEvenementId,
    evenementId,
    garanties,
    origine,
    note,
    isReprise,
    dateEvenement,
    isVehiculeRetrouve,
    isVehiculeReparable,
    mailMembre,
    iban,
  }: DeclarationSinistreParams) => {
    // typeRemboursement :
    // Virement: 100000001
    // Cheque: 100000000

    const body: any = {
      // declaration
      idAdhesion,
      isReprise,
      evenementId,

      // sinistre
      dateEvenement,
      typeEvenementId,
      origine,
      note,
      garanties,
      mailMembre,

      // vehicule volé
      isVehiculeRetrouve,
      isVehiculeReparable,

      // coordonnees bancaires
      typeRemboursement: !iban ? 100000000 : 100000001,
      iban,
    };

    const result = await axiosClient.post(`DeclarationSinistre/`, body);

    return result.data;
  },
};
