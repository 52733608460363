import { useCallback } from "react";
import { useRecoilState } from "recoil";

import {
  RedirectionMode,
  UniversignConfig,
  UniversignEvent,
  UniversignEventType,
  UniversignStatus,
  UNIVERSIGN_EVENT,
  UNIVERSIGN_SCRIPT_ID,
} from "./universign-types";
import { defer, Deffered } from "./defer";
import { eSignatureState } from "./eSignatureState";
import { Environment } from "App.types";

const defaultScriptLoading = defer<void>();
const environment = process.env.REACT_APP_ENV;

export const useESignature = (scriptLoading?: Deffered<void>) => {
  const [signatureState, setSignatureState] = useRecoilState(eSignatureState);

  const loadScript = useCallback(
    async (url: string) => {
      const loading = scriptLoading ?? defaultScriptLoading;

      if (document.getElementById(UNIVERSIGN_SCRIPT_ID) === null) {
        setSignatureState((state) => ({ ...state, loading: true, error: null }));
        const script = document.createElement("script");
        script.id = UNIVERSIGN_SCRIPT_ID;
        script.src = url;
        script.onload = () => {
          setSignatureState((state) => ({ ...state, loading: false, loaded: true }));
          loading.resolve();
        };
        script.onerror = () => {
          loading.reject(`RuntimeError: unable to load ${UNIVERSIGN_SCRIPT_ID}`);
        };
        document.head.appendChild(script);
      }

      return loading.promise;
    },
    [scriptLoading, setSignatureState],
  );

  const eventListener = useCallback(
    (evt: UniversignEvent) => {
      switch (evt.detail.eventType) {
        case UniversignEventType.BEGIN:
          if (evt.detail.status === UniversignStatus.READY) {
            setSignatureState((state) => ({ ...state, embeding: false, embeded: true }));
          }
          break;
        case UniversignEventType.END:
          if ([UniversignStatus.SIGNED, UniversignStatus.CANCELED].includes(evt.detail.status)) {
            setSignatureState((state) => ({
              ...state,
              signing: false,
              status: evt.detail.status,
              completed: true,
            }));
          }
          break;
        default:
          setSignatureState((state) => ({
            ...state,
            loading: false,
            embeding: false,
            signing: false,
            error: "RuntimeError: something went wrong durin eSignature",
          }));
          break;
      }
    },
    [setSignatureState],
  );

  const embedIframe = useCallback(
    (
      domId: string,
      url: string,
      signerId: string,
      config: UniversignConfig = { redirectionMode: RedirectionMode.IN },
    ) => {
      if (typeof window.universignSigInit !== "function") {
        throw new Error(`RuntimeError: You should load ${UNIVERSIGN_SCRIPT_ID} script`);
      }
      setSignatureState((state) => ({ ...state, embeding: true, error: null }));

      window.addEventListener(UNIVERSIGN_EVENT, eventListener as EventListener);
      const urlBase = url.split("/sig/");
      if (environment === Environment.PROD) {
        window.universignSigInit(domId, signerId, config);
      } else {
        window.universignSigInit(domId, signerId, config, urlBase[0]);
      }
    },
    [eventListener, setSignatureState],
  );

  const setup = useCallback(
    (domId: string, url: string, signerId: string, config?: UniversignConfig) => {
      loadScript(`${url}embed.js`)
        .then(() => embedIframe(domId, url, signerId, config))
        .catch((err) => {
          setSignatureState((state) => ({
            ...state,
            loading: false,
            embeding: false,
            signing: false,
            error: err,
          }));
        });

      return () => {
        window.removeEventListener(UNIVERSIGN_EVENT, eventListener as EventListener);
      };
    },
    [loadScript, embedIframe, setSignatureState, eventListener],
  );

  const pending = signatureState.loading || signatureState.embeding || signatureState.signing;

  return {
    ...signatureState,
    pending,
    embedIframe,
    eventListener,
    loadScript,
    setup,
  };
};
