import { useMutation } from "react-query";
import {
  CoordonneesBancairesData,
  Member,
  Membre,
  UpdateInfosMembreRequest,
} from "domain/api-schemas";
import { membreApi } from "domain/api/Membre/membreApi";

export const useUpdateMember = (idAdhesion: string) =>
  useMutation<Member, Error, UpdateInfosMembreRequest>(
    async (data) => await membreApi.updateMembre(idAdhesion, data),
  );

export const useUpdateIban = (idAdhesion: string) =>
  useMutation<unknown, Error, CoordonneesBancairesData>(
    async (data) => await membreApi.updateCoordonneesBancaires(idAdhesion, data),
  );

export const ConvertToMember = (memberOrigin?: Membre): Member | null => {
  if (!memberOrigin) {
    return null;
  }

  return {
    providers: memberOrigin.providers,
    id: memberOrigin.id,
    civilite: memberOrigin.civilite,
    prenom: memberOrigin.prenom,
    nom: memberOrigin.nom,
    email: memberOrigin.email,
    telephoneFixe: memberOrigin.telephoneFixe,
    telephoneMobile: memberOrigin.telephoneMobile,
    rue: memberOrigin.rue,
    lieuditBpHameau: memberOrigin.lieuditBpHameau,
    batimentResidence: memberOrigin.batimentResidence,
    infoEscPorteRemise: memberOrigin.infoEscPorteRemise,
    codePostal: memberOrigin.codePostal,
    ville: memberOrigin.ville,
    hasLinkedAccount: memberOrigin.hasLinkedAccount,
    idAdhesions: memberOrigin.idAdhesions,
    statutMembre: memberOrigin.statutMembre,
    dateStatusMember: memberOrigin.dateStatusMember,
  };
};
