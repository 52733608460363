import { DropDown, DropDownItem, Text } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import {
  CategoriesMotifsState,
  contactUsStepContextStateInitValue,
  contactUsStepState,
} from "context/contactUsState/atom";
import { useTracking } from "hooks/useTracking";
import { classNames } from "utils/classNames";
import { text_to_id } from "utils/textToId";
import { RoutePaths } from "routes/routePaths";

import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";

import styles from "./ContactReasonStep.module.scss";
import commonStyles from "pages/ContactUs/Page.module.scss";

type ReasonInputs = {
  subject: DropDownItem;
  reason: DropDownItem;
};

const defaultSubject = { id: 1, name: "Sélectionnez un sujet", disabled: true };
const defaultReason = { id: 1, name: "Sélectionnez un motif", disabled: true };

export const ContactReasonStep: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const categoriesMotifsState = useRecoilValue(CategoriesMotifsState);
  const [error, setError] = useState<boolean>(false);
  const [subject, setSubject] = useState(defaultSubject);
  const [reasons, setReasons] = useState([defaultReason]);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const [cancelClicked, setCancelClicked] = useState(false);

  useEffect(() => {
    setContactUsStepContextState({
      ...contactUsStepContextState,
      activeStep: 1,
    });
  }, []);

  const { control, handleSubmit, setValue } = useForm<ReasonInputs>();

  const subjects: DropDownItem[] = categoriesMotifsState.map((category) => {
    return { id: category.ordre, name: category.libelle, disabled: false };
  });

  useEffect(() => {
    const currentReasons = categoriesMotifsState?.[subject.id - 1].motifs?.map((motif) => {
      return { id: motif.id, name: motif.libelle, disabled: false };
    }) || [defaultReason];
    setReasons(currentReasons);
  }, [categoriesMotifsState, subject]);

  const handleDataLayer = (subject: DropDownItem, reason: DropDownItem) => {
    sendToDataLayer(`contactez-nous-sujet-${text_to_id(subject.name)}`);
    sendToDataLayer(`contactez-nous-motif-${text_to_id(reason.name)}`);
    sendToDataLayer("contactez-nous-motif-bouton-suivant");
  };

  const onSubmit: SubmitHandler<ReasonInputs> = async (data) => {
    if (data.reason.disabled === false) {
      if (cancelClicked) {
        setCancelClicked(false);
        return;
      }
      setContactUsStepContextState({
        ...contactUsStepContextState,
        subject: data.subject,
        reason: data.reason,
      });
      handleDataLayer(data.subject, data.reason);
      navigate(RoutePaths.CONTACT_TYPE);
    }
    setError(true);
  };

  const changeSelectHandle = (item: DropDownItem, onChange: any) => {
    onChange(item);
    setSubject(item);
    setValue("reason", defaultReason);
  };

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Vous avez besoin d’aide ?
        </Text>
        <br />
        <Text>
          La relation client est à votre service pour toutes questions concernant la gestion de
          votre contrat, une déclaration de sinistre ou autres.
        </Text>
      </div>
      <Card noMargin>
        <Text as="h2" textVariant="heading-4">
          Quelques informations sur votre problème
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="subject"
            defaultValue={contactUsStepContextState.subject || defaultSubject}
            render={({ field: { value: valueSub, onChange } }) => (
              <div
                className={classNames(
                  styles.contactReason__Form,
                  error && styles.contactReason__Form__Error,
                )}
              >
                <DropDown
                  possibleValues={subjects}
                  setSelectedValue={(item) => changeSelectHandle(item, onChange)}
                  selectedValue={valueSub || subjects[0]}
                />
                <Controller
                  control={control}
                  name="reason"
                  defaultValue={contactUsStepContextState.reason || defaultReason}
                  render={({ field: { value: valueRea, onChange } }) => {
                    return (
                      <>
                        {valueSub.disabled !== true && (
                          <DropDown
                            possibleValues={reasons}
                            setSelectedValue={(item) => {
                              setError(false);
                              onChange(item);
                            }}
                            selectedValue={valueRea}
                          />
                        )}
                        {error && (
                          <div className={commonStyles.contactUsStep__Error}>
                            <Text>Veuillez sélectionner un sujet et un motif</Text>
                          </div>
                        )}
                        <NavigationButtons
                          prevProps={{
                            onClick: () => {
                              setCancelClicked(true);
                              setContactUsStepContextState(contactUsStepContextStateInitValue);
                              navigate(RoutePaths.CONTRAT_HOME);
                            },
                            text: "Annuler",
                            gtmElementId: "contactez-nous-motif-bouton-annuler",
                          }}
                          nextProps={{
                            onClick: () => undefined,
                            type: "submit",
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div>
            )}
          />
        </form>
      </Card>
    </>
  );
};
