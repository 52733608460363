import { CSSProperties, FC, useState } from "react";

import { Box } from "components/atoms/Box/Box";

import styles from "./SelectList.module.scss";

type SelectItem = {
  id: string;
  label: string;
};

type SelectListProps = {
  list: Array<SelectItem>;
  onChange?: (selection: Array<SelectItem["id"]>) => void;
  maxLines?: number;
  multiple?: boolean;
  selected?: Array<SelectItem["id"]>;
};

export const SelectList: FC<SelectListProps> = ({
  list,
  onChange,
  maxLines,
  multiple = false,
  selected: initialSelectedItems = [],
}) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const updateSelected = (newSelection: Array<SelectItem["id"]>) => {
    setSelectedItems(newSelection);
    onChange?.(newSelection);
  };

  const toggleSelect = (id: SelectItem["id"]) => {
    if (multiple) {
      return updateSelected(
        selectedItems.includes(id)
          ? selectedItems.filter((i) => i !== id)
          : selectedItems.concat(id), // e.q. [...selectedItems, id]
      );
    }
    return updateSelected([id]);
  };

  return (
    <Box className={styles.container}>
      <ul style={maxLines ? ({ "--max-lines": maxLines } as CSSProperties) : undefined}>
        {list?.map(({ id, label }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={id}
            className={selectedItems.includes(id) ? styles.selected : undefined}
            onClick={() => toggleSelect(id)}
          >
            {label}
          </li>
        ))}
      </ul>
    </Box>
  );
};
