import {
  WizardStep,
  WizardStepLayout,
  WizardNav,
  NEXT_BTN,
  useWizard,
} from "components/molecules/Wizard";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { InputText, Loader, Modal, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import styles from "./Page.module.scss";
import { useRecoilState } from "recoil";
import { dateEffetState } from "./Context/DateEffetState";
import { WizardActionType } from "components/molecules/Wizard/WizardAction";
import { format } from "date-fns";
import { useConfirmResiliation, useInfosResiliation } from "domain/service/Contract/useResiliation";
import { PublicRoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";
import { immatValid } from "./useResiliation";

export const ImmatStep: WizardStep = () => {
  const params = useParams();
  const { dispatch, submitStep } = useWizard();
  const [immat, setImmat] = useState("");
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [, setDateEffet] = useRecoilState(dateEffetState);
  const [dossierResiliation, setDossierResiliation] = useState({
    id: "",
    immat: "",
    numContrat: "",
    etatContrat: "",
  });
  const RESILIE = "Resilie";
  const RENOUVELE = "Renouvele";

  const { data: infosResiliation, isLoading: loading } = useInfosResiliation(params.id ?? "");
  const { mutateAsync: confirmResiliation, isLoading: loaderConfirm } = useConfirmResiliation();
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    if (!infosResiliation) {
      return;
    }
    if (infosResiliation.etatContrat === RESILIE) {
      setDateEffet(format(Date.parse(infosResiliation.dateEffetResiliation), "dd/MM/yyyy"));
      submitStep();
    } else if (infosResiliation.etatContrat === RENOUVELE) {
      navigate(PublicRoutePaths.CONFIRMER_RESILIATION_ERROR);
    } else {
      setDossierResiliation({
        id: infosResiliation.id,
        numContrat: infosResiliation.numContrat,
        immat: infosResiliation.immatriculation,
        etatContrat: infosResiliation.etatContrat,
      });
    }
  }, [infosResiliation]);

  const handleChange = (e: any) => {
    setImmat(e.target.value);
  };

  const handleConfirm = async () => {
    setShowModalConfirm(false);
    const result = await confirmResiliation(dossierResiliation.id);
    setDateEffet(format(Date.parse(result.dateEffetResiliation), "dd/MM/yyyy"));
    submitStep();
  };

  const handleAbort = () => {
    setShowModalConfirm(false);
  };

  const validate = useCallback(() => {
    if (immatValid(immat, dossierResiliation.immat)) {
      sendToDataLayer("resil-resilier-mon-contrat", false, dossierResiliation.id);
      setShowModalConfirm(true);
    } else {
      dispatch({
        type: WizardActionType.SUBMIT_STEP_FAILED,
        error: `Immatriculation non valide.<br/>
                Il semblerait que l'immatriculation saisie ne soit pas la bonne.<br/>
                Merci de vérifier votre saisie.`,
        errorNextLabel: "Ressaisir",
      });
    }
  }, [dossierResiliation.immat, immat]);

  return (
    <WizardStepLayout className={styles.w50} title="">
      {loading || loaderConfirm ? (
        <div className={styles.center}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.textCenter}>
            <Text textVariant="body-em" as="p" className={styles.mb10}>
              Contrat {dossierResiliation.numContrat}
            </Text>
            <Text textVariant="body" as="p" className={styles.mb10}>
              Veuillez saisir l'immatriculation du véhicule lié à votre contrat afin de confirmer
              votre demande de résiliation:
            </Text>
            <InputText
              border
              placeholder="AB-123-AB"
              type="text"
              value={immat}
              onChange={handleChange}
            />
          </div>
          <WizardNav
            nextStepLabel="Résilier mon contrat"
            buttons={NEXT_BTN}
            nextStepHandler={validate}
          />
          <Modal
            title="Confirmer la résiliation"
            content={
              <div className={(styles.modalContent, styles.textCenter)}>
                <p>
                  Vous êtes sur le point de résilier votre contrat.
                  <br />
                  <b>Cette action est irréversible.</b>
                </p>

                <div className={styles.buttonCenter}>
                  <Button
                    variant={"outlined-light"}
                    size="small"
                    type="button"
                    className="center-label"
                    onClick={handleAbort}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="primary"
                    size="small"
                    type="button"
                    className="center-label"
                    onClick={handleConfirm}
                  >
                    Confirmer
                  </Button>
                </div>
              </div>
            }
            isOpen={showModalConfirm}
            handleClose={handleAbort}
          />
        </>
      )}
    </WizardStepLayout>
  );
};
