import { useEffect, useState } from "react";

// See https://keycode.info to quickly find event key code

type KeyboardEventHandler = (event: KeyboardEvent) => void;

export const useKeyPress = (targetKey: string, handler?: KeyboardEventHandler): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);
  useEffect(() => {
    const downHandler: KeyboardEventHandler = (event) => {
      if (event.key === targetKey) {
        setKeyPressed(true);
        handler?.(event);
      }
    };
    const upHandler: KeyboardEventHandler = (event) => {
      if (event.key === targetKey) {
        setKeyPressed(false);
      }
    };
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey, handler]);

  return keyPressed;
};
