import { Resiliation } from "domain/api-schemas";

export enum ResiliationSteps {
  Reason = "reason",
  Confirm = "confirm",
  Retention = "retention",
}

export type ResiliationAction = {
  step: ResiliationSteps;
  data?: Resiliation;
};

export type ResiliationState = ResiliationAction & {
  completion: number;
};
