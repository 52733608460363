import { Avatar, ButtonGroup, InputIban, Message } from "@roole/components-library";
import { useEffect, useState } from "react";
import { useStepperContext } from "./StepperContext";
import { validateIBan } from "utils/iban";
import { useRecoilState } from "recoil";
import { monContratState } from "context/currentContratState/atom";
import lodash from "lodash";
import { declarationDataState } from "context/declarationState/atom";
import { ibanFormat } from "domain/service/Sinistre/useDossier";

export const CoordonneesBancaires: React.FC = () => {
  const { setActions, prevStep, nextStep } = useStepperContext();
  const [errors, setErrors] = useState<{
    iban?: string;
  }>({});
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [monContrat] = useRecoilState(monContratState);

  useEffect(() => {
    setActions({});
  }, []);

  return (
    <div className="flex flex-col gap-L">
      <div className="flex flex-col gap-3XS">
        <h2 className="heading-2">Mode de remboursement</h2>

        <p className="body-medium">
          Pour recevoir votre remboursement
          <sup>1</sup> directement sur votre compte bancaire, munissez vous de votre RIB (relevé
          d’identité bancaire) sur lequel figure votre IBAN et renseignez-le ci-dessous :{" "}
        </p>
      </div>

      <div className="flex flex-col gap-XS">
        <div className="flex gap-2XS items-center">
          <Avatar size="M" type="icon" icon="user" variant="brand_light" />

          <div className="flex flex-col">
            <p className="body-small text-secondary">Titulaire du compte</p>
            <p className="body-medium text-secondary">
              {lodash.startCase((monContrat?.membre?.prenom || "").toLowerCase())}{" "}
              {lodash.startCase((monContrat?.membre?.nom || "").toLowerCase())}
            </p>
          </div>
        </div>

        <InputIban
          value={ibanFormat(declarationData?.iban ?? "")}
          onChange={(e) => {
            setErrors({ ...errors, iban: undefined });
            setDeclarationData({
              ...(declarationData as any),
              iban: e.currentTarget.value.replaceAll(" ", "").trim(),
            });
          }}
          error={errors.iban}
        />
      </div>

      <div className="flex flex-col gap-XS">
        <Message
          type="information"
          message={
            <p>
              En continuant sans saisir votre IBAN, votre remboursement vous parviendra par chèque.
              Il sera expédié à l’adresse renseignée sur votre contrat, rubrique “Mon contrat”..
            </p>
          }
          icon="info"
        />
      </div>

      <div className="self-center">
        <ButtonGroup
          buttonPrimary={{
            variant: "primary-brand",
            children: <p className="body-medium">Continuer</p>,
            onClick: () => {
              if (!declarationData?.iban?.length || validateIBan(declarationData?.iban ?? "")) {
                nextStep();
              } else {
                setErrors({
                  ...errors,
                  iban: "L’IBAN que vous avez saisi est incorrect. Veuillez vérifier votre saisie.",
                });
              }
            },
          }}
          buttonSecondary={{
            variant: "secondary-dark",
            children: <p className="body-medium">Retour</p>,
            onClick: prevStep,
          }}
          direction="row"
          size="large"
        />
      </div>

      <p className="body-small text-secondary">
        <sup>1</sup> Remboursement sous réserve d’éligibilité à la garantie, suite à l’étude du
        dossier menée par un conseiller Roole, une fois votre dossier complété.
      </p>
    </div>
  );
};
