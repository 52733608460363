import { ReactEventHandler } from "react";

export const noDelegation = (handler?: ReactEventHandler): ReactEventHandler | undefined =>
  handler &&
  ((event) => {
    // Only if the element the event was triggered is the element where the handler was attached (and not one of its children)
    // See https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Events#event_delegation
    // See https://medium.com/@etherealm/currenttarget-vs-target-in-js-2f3fd3a543e5
    if (event.currentTarget === event.target) {
      handler(event);
    }
  });
