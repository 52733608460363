import { FC, ReactNode } from "react";
import { useRecoilValueLoadable } from "recoil";

import { navigationState } from "context/navigationState/atom";
import { MonContrat } from "domain/api-schemas";
import { NavSidebar } from "./NavSidebar";

import styles from "./NavSidebarLayout.module.scss";

export const NavSidebarLayout: FC<{
  children: ReactNode;
  contract?: MonContrat;
}> = ({ children, contract }) => {
  const navigationStateValue = useRecoilValueLoadable(navigationState);
  const loading = navigationStateValue.state === "loading";

  return (
    <div className={styles.container}>
      <NavSidebar
        className={styles.layoutSideBar}
        displayRoutes={!loading ? navigationStateValue.contents?.displayRoutes : {}}
        contract={contract}
      />
      <div className={styles.layoutBody}>{children}</div>
    </div>
  );
};
