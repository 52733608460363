import { FC, useRef } from "react";
import { useUniqueId } from "hooks/useUniqueId";
import { useKeyPress } from "hooks/useKeyPress";
import {
  ModalWithControl,
  ModalWithControlProps,
  ModalAPI,
} from "components/molecules/Modal/ModalWithControl";

import { Button } from "components/atoms/Button/Button";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";

import styles from "./VoletLateral.module.scss";

type ConfirmModalProps = {
  title?: string;
  onClose?: () => void;
} & ModalWithControlProps;

export const VoletLateral: FC<ConfirmModalProps> = ({ title, onClose, children }) => {
  const titleId = useUniqueId("modal-title");
  const contentId = useUniqueId("modal-content");
  const modalRef = useRef<ModalAPI>(null);
  const _onAbort = () => modalRef.current?.toggle(false, onClose);
  useKeyPress("Escape", _onAbort);
  return (
    <ModalWithControl
      ref={modalRef}
      className={styles.voletLateral}
      onOverlayClick={_onAbort}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      onAbort={_onAbort}
    >
      <header className={styles.voletLateralHeader}>
        <Button onClick={_onAbort}>
          <CloseIcon className={styles.closeIcon} />
        </Button>
      </header>
      <div className={styles.voletLateralContent}>
        <h2>{title}</h2>
        {children}
      </div>
    </ModalWithControl>
  );
};
