import { formatCurrency } from "utils/formatCurrency";

import styles from "./GarantiesLine.module.scss";

interface GarantieLineProps {
  code: string;
  titre: string;
  plafondMaximum: number;
  statutMembre: string;
  packServiceCode: string;
}

const GarantiesLine = ({
  code,
  titre,
  plafondMaximum,
  statutMembre,
  packServiceCode,
}: GarantieLineProps) => {
  const VRValue = code === "VR" ? `${plafondMaximum} jour(s)` : formatCurrency(plafondMaximum);
  const nameClass =
    statutMembre === "Premium" || packServiceCode === "4F"
      ? styles.GarantiesLine__name
      : styles.GarantiesLine__name_disabled;
  const subtitleClass =
    statutMembre === "Premium" || packServiceCode === "4F"
      ? styles.subtitle
      : styles.GarantiesLine__subtitle_disabled;

  return (
    <p className={styles.GarantiesLine}>
      <span className={nameClass} dangerouslySetInnerHTML={{ __html: titre }} />
      {plafondMaximum !== 0 && <span className={subtitleClass}>Jusqu'à {VRValue}</span>}
    </p>
  );
};

export default GarantiesLine;
