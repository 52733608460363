import { useAuthenticationContext } from "AuthenticationProvider";
import { useEffect, createContext, useState, useMemo } from "react";

const CookieBotContext = createContext({});

const CookieBotProvider = ({ children }: { children: JSX.Element }) => {
  const { isJwtAuthenticated, isJwtLoading, isAuth0Loading } = useAuthenticationContext();
  const [isScriptsInjected, setIsScriptsInjected] = useState(false);

  useEffect(() => {
    const scriptId = "Cookiebot";

    const hideConsentOnCookiebotDialogInit = () => {
      window.Cookiebot.submitCustomConsent(false, false, false);
      window.addEventListener("CookiebotOnConsentReady", () => {
        window.Cookiebot.deleteConsentCookie();
      });
    };

    if (!isAuth0Loading && !isJwtLoading) {
      if (isJwtAuthenticated) {
        window.addEventListener("CookiebotOnDialogInit", hideConsentOnCookiebotDialogInit);
      }

      if (!isScriptsInjected) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://consent.cookiebot.com/uc.js";
        script.setAttribute("data-cbid", "ae6d2bd4-3255-451a-ba73-352e999e420a");
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);
        setIsScriptsInjected(true);
      }
    }

    return () => {
      if (isJwtAuthenticated) {
        window.removeEventListener("CookiebotOnDialogInit", hideConsentOnCookiebotDialogInit);
      }
      if (isScriptsInjected) {
        const script = document.getElementById(scriptId);
        if (script) {
          document.body.removeChild(script);
        }
      }
    };
  }, [isAuth0Loading, isJwtAuthenticated, isJwtLoading, isScriptsInjected]);

  const providerValue = useMemo(() => ({}), []);

  return <CookieBotContext.Provider value={providerValue}>{children}</CookieBotContext.Provider>;
};

export default CookieBotProvider;
