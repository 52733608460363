import React, { FC, useState, useEffect } from "react";
import {
  DrawerMobile,
  Message,
  TextV2,
  Tile,
  ButtonUpload,
  ButtonV2,
} from "@roole/components-library";
import styles from "./UploadDrawer.module.scss";
import { addPjDecla } from "domain/api/Sinistre/AddPjDecla";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { validateAndSetFile, handleDelete, UploadedFile, AUTRES_TYPE_ID } from "./utils";
import { useAuthenticationContext } from "AuthenticationProvider";

interface UploadDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedPJ: { nom: string; description?: string; crmId?: string } | null;
  onFilesUpload: (files: UploadedFile[]) => void;
  dossierData?: DeclarationParams;
  idAdhesion: string;
}

const MAX_FILE_SIZE = 5242880;

export const UploadDrawer: FC<UploadDrawerProps> = ({
  isOpen,
  handleClose,
  selectedPJ,
  onFilesUpload,
  dossierData,
  idAdhesion,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { isJwtAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(null);
      setUploadedFiles([]);
      setIsUploading(false);
    }
  }, [isOpen]);

  const handleFileChange = async (file: File | null) => {
    const pjType = selectedPJ ? { typeId: selectedPJ.crmId } : { typeId: AUTRES_TYPE_ID };
    await validateAndSetFile(file, setErrorMessage, setUploadedFiles, pjType);
  };

  const handleValidate = () => {
    if (isUploading) {
      return;
    }
    setIsUploading(true);

    const uploadPromises = uploadedFiles.map((file) =>
      addPjDecla({
        evenementId: dossierData?.evenementId || "",
        idAdhesion: idAdhesion,
        nom: file.name.split(".").slice(0, -1).join("."),
        contenu: file.base64.split(",")[1],
        extension: `.${file.name.split(".").pop()}`,
        categorie: "Sinistre",
        Source: isJwtAuthenticated ? "RoolePremium" : "EspaceMembre",
      }),
    );

    Promise.all(uploadPromises)
      .then((results) => {
        const uploadedFilesWithBlobNom = results.map((result, index) => ({
          ...uploadedFiles[index],
          blobNom: result,
          size: Math.round(uploadedFiles[index].size / 1024),
        }));

        onFilesUpload(uploadedFilesWithBlobNom);
        setUploadedFiles([]);
        setIsUploading(false);
        setErrorMessage(null);
        handleClose();
      })
      .catch((error) => {
        console.error("Error uploading files", error);
        setIsUploading(false);
        setErrorMessage(
          "Une erreur est survenue lors de l'envoi des fichiers. Veuillez réessayer.",
        );
      });
  };

  const renderUploadedPJTiles = () => {
    return uploadedFiles.map((file) => (
      <Tile
        key={file.name}
        title={file.name}
        subtitle={{
          text: `${(file.size / 1024).toFixed(0)} Ko - Ajouté le ${file.date}`,
        }}
        avatar={{
          icon: "file",
        }}
        button={{
          icon: "trash",
          onClick: () => handleDelete(file.name, setUploadedFiles),
        }}
      />
    ));
  };

  const primaryLabel = uploadedFiles.length > 0 ? "Valider" : "";

  return (
    <DrawerMobile
      title="Ajouter un document"
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <div className={styles.modal}>
          <Message
            icon="info"
            type="information"
            title="Consignes d'importation :"
            message={
              <>
                <TextV2 textVariant="body-small">
                  · Les formats acceptés sont : JPG, PNG, PDF.
                </TextV2>
                <TextV2 textVariant="body-small">
                  · La taille maximum est de : 5 Mo par fichier.
                </TextV2>
                <TextV2 textVariant="body-small">
                  Assurez-vous que votre document n’est pas tronqué, ne comporte pas de flou ou de
                  reflet et qu’il est lisible.
                </TextV2>
              </>
            }
          />
          <div className={styles.selectedPJ}>
            <TextV2 textVariant="body-medium-em">{selectedPJ?.nom || ""}</TextV2>
          </div>
          {uploadedFiles.length > 0 && (
            <div className={styles.selectedPJ}>{renderUploadedPJTiles()}</div>
          )}
          <ButtonUpload
            onFileChange={handleFileChange}
            accept="image/jpeg, image/png, application/pdf"
            text="Parcourir ma bibliothèque"
            icon="upload"
            errMessage={errorMessage || "Ce type de format n’est pas accepté."}
            sizeErrMessage="Ce fichier dépasse la taille maximum autorisée."
            maxFileSize={MAX_FILE_SIZE / 1024 / 1024}
          />
          {errorMessage && (
            <div className={styles.errorMessage}>
              <Message
                icon="info"
                type="error"
                message={
                  <TextV2 textVariant="body-small" className={styles.errorText}>
                    {errorMessage}
                  </TextV2>
                }
              />
            </div>
          )}
          <div className={styles.buttons}>
            {primaryLabel && (
              <ButtonV2
                variant="primary-brand"
                onClick={() => {
                  if (!isUploading) {
                    handleValidate();
                  }
                }}
                disabledDark={isUploading}
              >
                {primaryLabel}
              </ButtonV2>
            )}
            <ButtonV2 variant="secondary-dark" onClick={handleClose}>
              Annuler
            </ButtonV2>
          </div>
        </div>
      }
    />
  );
};
