import {
  Contrat,
  Garantie,
  InfosReconduction,
  MonContrat,
  Echeance,
  MotifResiliation,
  PlafondIndemniteFinanciere,
  ReconductionData,
  Resiliation,
  ResiliationContract,
  ReconductionCbData,
  CoordonneesBancairesData,
  InfosResiliation,
  ResiliationResult,
} from "domain/api-schemas";
import axiosClient from "clientProvider/axiosConfig";

export const contractApi = {
  fetchMonContrat: async (idAdhesion: string) => {
    const result = await axiosClient.get<MonContrat>(`MonContrat/InfosContrat/${idAdhesion}`);
    return result.data;
  },

  fetchContrat: async (idAdhesion: string) => {
    const result = await axiosClient.get<Contrat>(`InfosContractuelles/InfosContrat/${idAdhesion}`);
    return result.data;
  },

  fetchContratEcheance: async (codePack: string, contratId: string, dateFin: string) => {
    const result = await axiosClient.get<Echeance>(
      `MonContrat/InfosContrat/Echeance/${contratId}/${codePack}/${dateFin}`,
    );

    return result.data;
  },

  fetchGarantie: async (idAdhesion: string | null) => {
    if (!idAdhesion) {
      return null;
    }
    const result = await axiosClient.get<Garantie[]>(`Garanties/IdAdhesion/${idAdhesion}`);
    return result.data;
  },

  fetchIndemnite: async (codePack: string, stringValue: string) => {
    const result = await axiosClient.get<PlafondIndemniteFinanciere[]>(
      `Garanties/PlafondsIndemnitesFinancieres?CodePack=${codePack}${stringValue}`,
    );
    return result.data;
  },

  fetchContrats: async (listIdAdhesions: string[]) => {
    const idAdhesions = listIdAdhesions.map((idAdhesion) => "idAdhesions=" + idAdhesion).join("&");
    const result = await axiosClient.get<Contrat[]>(
      `InfosContractuelles/ContratsByIdAdhesions?${idAdhesions}`,
      { timeout: 50000 },
    );
    return result.data;
  },

  fetchReconductionContrat: async (idAdhesion: string) => {
    const result = await axiosClient.get<InfosReconduction>(
      `Reconduction/InfosReconduction/${idAdhesion}`,
    );
    return result.data;
  },

  postReconduction: async (data: ReconductionData | ReconductionCbData) => {
    const { data: response } = await axiosClient.post("Reconduction", data, {
      timeout: 60000,
    });
    return response;
  },

  postResiliation: async (data: Resiliation) => {
    const result = await axiosClient.post(`Resiliation`, data, {
      timeout: 50000,
    });
    return result.data;
  },

  queryResiliationContract: async (idAdhesion: string) => {
    const result = await axiosClient.get<ResiliationContract>(
      `Resiliation/InfosContrat/${idAdhesion}`,
    );
    return result.data;
  },

  resiliationModifs: async () => {
    const result = await axiosClient.get<MotifResiliation[]>("Resiliation/Motifs");
    return result.data;
  },

  getInfosResiliation: async (dossierResiliationId: string) => {
    const result = await axiosClient.get<InfosResiliation>(`Resiliation/${dossierResiliationId}`);
    return result.data;
  },

  confirmResiliation: async (dossierResiliationId: string) => {
    const result = await axiosClient.get<ResiliationResult>(
      `Resiliation/Terminer/${dossierResiliationId}`,
    );
    return result.data;
  },

  updateCoordonneesBancaires: async (data: CoordonneesBancairesData) => {
    await axiosClient.post<string>(`CoordonneesBancaires/Update`, data);
  },
};
