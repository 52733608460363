import { InputAreaV2, Message, RadioButtonGroup, TextV2, Tile } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import styles from "./DetailStep.module.scss";
import { useRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { formatToFrenchLongDate } from "utils/formatDate";
import { displayAssurancePrincipale } from "domain/service/Sinistre/useEvenement";
import { Couverture } from "domain/api-schemas";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { useStepperContext } from "../StepperContext";
import { useTracking } from "hooks/useTracking";
import { formatDateDayMonthYear } from "../../../../utils/formatDate";

const yesNo = [
  {
    label: "Oui",
    value: 1,
  },
  {
    label: "Non",
    value: 0,
  },
];

const couvertures = [
  {
    label: "Tous risques",
    value: Couverture.TousRisques,
  },
  {
    label: "Au tiers",
    value: Couverture.AuTiers,
  },
];

const aideAuxReparation = "1d0a1ca0-396a-e811-9acb-0050568cd8df";
const rachatDeFranchise = "bf3a8739-aab4-e711-9ac5-005056a41d51";

export const DetailStep: FC = () => {
  const { prevStep, nextStep, setActions } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [isFieldMissing, setIsFieldMissing] = useState(false);

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const { sendToDataLayer } = useTracking();

  const isAssurancePrincipale = declarationData?.typeEvenement?.garanties
    ? displayAssurancePrincipale(declarationData.typeEvenement.garanties)
    : false;

  const handleChange = (e: any) => {
    setDeclarationData({
      ...declarationData,
      isDeclareAssuranceAccident: yesNo[e].value === 1,
    } as DeclarationParams);
  };

  const handleChangeCouverture = (e: any) => {
    setDeclarationData({
      ...declarationData,
      couvertureAssurance: couvertures[e].value,
    } as DeclarationParams);
  };

  useEffect(() => {
    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Continuer",
        id: "sinisterDeclare_guarantees",
        onClick: (e) => {
          if (
            !declarationData?.description?.trim()?.length ||
            (declarationData?.couvertureAssurance === undefined && isAssurancePrincipale) ||
            declarationData?.isDeclareAssuranceAccident === undefined
          ) {
            setIsFieldMissing(true);
            return;
          } else {
            setIsFieldMissing(false);
          }
          sendToDataLayer(e.currentTarget.id, true);
          setDeclarationData({
            ...declarationData,
            garantiesNonEligibles: [
              ...(declarationData?.garantiesNonEligibles ?? []).filter(
                (item) => item !== aideAuxReparation && item !== rachatDeFranchise,
              ),
              declarationData.couvertureAssurance === Couverture.AuTiers
                ? rachatDeFranchise
                : aideAuxReparation,
            ],
          } as DeclarationParams);
          nextStep();
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => prevStep(),
      },
    });
  }, [setActions, prevStep, nextStep, declarationData]);

  useEffect(() => {
    setDeclarationData({
      ...declarationData,
      garantiesEligibles: [],
      garantiesNonEligibles: [],
    } as DeclarationParams);
  }, []);

  return (
    <>
      <Tile
        title={
          declarationData?.dateEvenement
            ? `Sinistre du ${
                isMobile
                  ? formatDateDayMonthYear(new Date(declarationData?.dateEvenement))
                  : formatToFrenchLongDate(new Date(declarationData?.dateEvenement))
              }`
            : ""
        }
        subtitle={{
          text: declarationData?.typeEvenement?.nom ?? "",
          color: "primary",
          textVariant: "heading-4",
        }}
        avatar={{
          icon: declarationData?.typeEvenement?.icone,
          size: "XL",
        }}
        button={{
          icon: "edit",
          label: isMobile ? "" : "Modifier",
          onClick: () => prevStep(),
        }}
      />
      <div className={styles.content}>
        <TextV2 textVariant="body-small" color="secondary">
          Tous les champs sont obligatoires
        </TextV2>

        <div>
          <TextV2 textVariant="heading-2" as="h2" className={styles.title}>
            Que s'est-il passé ?
          </TextV2>
          <TextV2 textVariant="body-medium" as="p" className={styles.consignes}>
            Décrivez-nous précisément : quels sont les dégâts, l'origine, si vous êtes responsable,
            si un tiers est impliqué...
          </TextV2>
        </div>

        <Message
          icon="info"
          message={
            <TextV2 textVariant="body-small">
              <TextV2 textVariant="body-small-em">Par exemple · </TextV2>
              En rentrant chez moi hier, j'ai percuté le véhicule devant moi. Le conducteur n'est
              pas blessé. Mon pare-choc est abîmé.
            </TextV2>
          }
          type={"information"}
        />
        <div>
          <InputAreaV2
            label={"Saisissez les détails de votre sinistre"}
            value={declarationData?.description ?? ""}
            onChange={(e) =>
              setDeclarationData({
                ...declarationData,
                description: e.target.value,
              } as DeclarationParams)
            }
            maxLength={1000}
            error={!declarationData?.description?.trim()?.length && isFieldMissing}
          />

          {!declarationData?.description?.trim()?.length && isFieldMissing && (
            <div className={styles.errorMessage}>
              <Message
                icon="alert-circle"
                message={
                  <TextV2 className={styles.errorMessageColor} textVariant="body-small">
                    Veuillez saisir les détails de votre sinistre pour continuer.
                  </TextV2>
                }
                type={"error"}
              />
            </div>
          )}
        </div>

        <div className={styles.questions}>
          {declarationData?.typeEvenement?.garanties && isAssurancePrincipale && (
            <div className={styles.radioDiv}>
              <TextV2 textVariant="heading-2" as="h2" extraCss={{ marginBottom: 16 }}>
                Assurance principale
              </TextV2>
              <TextV2 textVariant="body-medium" as="p" className={styles.subtitle}>
                Quelle est la couverture de votre assurance principale ?
              </TextV2>
              <RadioButtonGroup
                direction="row"
                elements={couvertures}
                name="couverture"
                onChange={handleChangeCouverture}
                variant="tile"
                checked={couvertures.map((c) => c.value === declarationData.couvertureAssurance)}
                error={declarationData?.couvertureAssurance === undefined && isFieldMissing}
                errorMessage={"Veuillez choisir une option pour continuer."}
              />
            </div>
          )}

          <div>
            <TextV2 textVariant="body-medium" as="p" className={styles.subtitle}>
              Avez-vous déclaré votre accident à votre assurance principale ?
            </TextV2>

            <RadioButtonGroup
              direction="row"
              elements={yesNo}
              name="aDeclare"
              onChange={handleChange}
              variant="tile"
              checked={[
                !!declarationData?.isDeclareAssuranceAccident,
                declarationData?.isDeclareAssuranceAccident === false,
              ]}
              error={declarationData?.isDeclareAssuranceAccident === undefined && isFieldMissing}
              errorMessage={"Veuillez choisir une option pour continuer."}
            />
          </div>
        </div>

        {declarationData?.isDeclareAssuranceAccident === false && (
          <Message
            icon="info"
            message={
              <TextV2 textVariant="body-small">
                Pour être remboursé(e), vous aurez besoin de certaines pièces justificatives
                obtenues auprès de votre assurance principale.
              </TextV2>
            }
            type={"information"}
          />
        )}
      </div>
    </>
  );
};
