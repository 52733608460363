import { FC } from "react";
import styles from "./Event.module.scss";
import { IconV2, TextV2 } from "@roole/components-library";
import { Evenement } from "domain/api-schemas";
import { Dossier } from "./Dossier";
import { formatToFrenchLongDate } from "utils/formatDate";

export const Event: FC<{ evenement: Evenement }> = ({ evenement }) => {
  const eventDate = new Date(evenement.dateEvenement);
  return (
    <div className={styles.containerEvent}>
      <div className={styles.eventTitle}>
        <IconV2 name={evenement.typeEvenement?.icone ?? ""} size={32} />
        <div className={styles.eventSubTitle}>
          <TextV2 textVariant="heading-3" as="h3">
            {evenement.typeEvenement?.nom}
          </TextV2>
          <TextV2 className={styles.dot}>·</TextV2>
          <TextV2 textVariant="body-large">{`du ${formatToFrenchLongDate(eventDate)}`}</TextV2>
        </div>
      </div>
      {evenement.dossiersGaranties?.map((dossier) => {
        return <Dossier key={dossier.crmId} dossier={dossier} />;
      })}
    </div>
  );
};
