import axiosClient from "clientProvider/axiosConfig";

interface addPjDeclaProps {
  evenementId: string;
  idAdhesion: string;
  nom: string;
  contenu: string;
  extension: string;
  categorie: string;
  Source: "RoolePremium" | "EspaceMembre";
}

export const addPjDecla = async (props: addPjDeclaProps) => {
  const result = await axiosClient.post("/Document", {
    ...props,
  });

  return result.data;
};
