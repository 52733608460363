import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalState } from "context/globalState/atom";
import { isListEmpty } from "../utils/array";
import { USER_ID_IN_LOCALSTORAGE, USER_ID_TOTAL_WASH } from "context/global.types";
import { useAuthenticationContext } from "AuthenticationProvider";

export const useNavigationBack = () => {
  const navigate = useNavigate();
  const { logout } = useAuthenticationContext();
  const { listContratAhesion } = useRecoilValue(globalState);

  const handleBack = () => {
    if (!isListEmpty(listContratAhesion)) {
      navigate(-1);
    } else {
      window.localStorage.removeItem(USER_ID_IN_LOCALSTORAGE);
      window.localStorage.removeItem(USER_ID_TOTAL_WASH);
      logout();
    }
  };

  return { handleBack };
};
