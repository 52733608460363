import { FC, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { OverlayLoader } from "@roole/components-library";
import UnAuthorizedError from "./UnauthorizedError";
import { useSearchParamsValues } from "hooks/useSearchParamsValues";
import { useAuthenticationContext } from "AuthenticationProvider";

const SignIn: FC = () => {
  const { isAuth0Loading, isAuth0Authenticated, error, loginWithRedirect } =
    useAuthenticationContext();
  const search = useSearchParamsValues();

  useEffect(() => {
    const tryRedirect = async () => {
      if (search?.displayChangePwd) {
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname ? window.location.pathname : "/",
          },
          authorizationParams: {
            prompt: "login",
            screen_hint: "login",
          },
        });
      } else {
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname ? window.location.pathname : "/",
          },
        });
      }
    };

    if (!isAuth0Loading && !error && !isAuth0Authenticated) {
      tryRedirect().catch((err) => console.error(err));
    }
  }, [error, isAuth0Authenticated, isAuth0Loading, loginWithRedirect, search?.displayChangePwd]);

  if (isAuth0Loading) {
    return (
      <div className="page-layout">
        <OverlayLoader />
      </div>
    );
  }

  if (!isAuth0Authenticated && error && !isAuth0Loading) {
    return <UnAuthorizedError />;
  }

  return <>{isAuth0Authenticated && <Navigate to={RoutePaths.HOME} />}</>;
};

export default SignIn;
