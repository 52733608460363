import React, { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { InputText, Text, Modal, OverlayLoader } from "@roole/components-library";
import { Card } from "components/atoms/Card/Card";
import { Button } from "components/atoms/Button/Button";
import { useRecoilValue } from "recoil";
import { membreApi } from "domain/api/Membre/membreApi";
import { EMAIL_FORMAT } from "utils/regex";
import { globalState } from "context/globalState/atom";

import styles from "./UserProfil.module.scss";
import { useAuthenticationContext } from "AuthenticationProvider";

type UserProfilInfos = {
  email: string;
};

const emailValidation = {
  required: {
    value: true,
    message: "Ce champ est obligatoire",
  },

  pattern: {
    value: EMAIL_FORMAT,
    message:
      "Le format d'email saisi est incorrect. Veuillez respecter le format suivant: exemple@email.com",
  },
};

const UserProfil: FC = () => {
  const { control, handleSubmit, getValues } = useForm<UserProfilInfos>();
  const [message, setMessage] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userEmail } = useRecoilValue(globalState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout } = useAuthenticationContext();

  const onSubmit: SubmitHandler<UserProfilInfos> = async (data) => {
    try {
      setIsLoading(true);
      await membreApi.updateUserEmail({ newEmail: data.email });
      setMessage(
        "Modification enregistrée, vous allez être déconnecté dans un instant. Merci de vérifier votre boîte mail et cliquer sur le lien.",
      );
      setIsSuccess(true);
    } catch (err) {
      setMessage("Votre modification n'a pas pu être sauvegardée, veuillez réessayer plus tard.");
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    if (email === userEmail) {
      return "Vous n'avez apporté aucune modification à l'adresse email existante.";
    }
    if (!email) {
      return emailValidation.required.message;
    }
    if (!EMAIL_FORMAT.test(email)) {
      return emailValidation.pattern.message;
    }
    return null;
  };

  const handleValidate = async () => {
    setIsModalOpen(false);
    await handleSubmit(onSubmit)();
  };

  const handleOpenModal = () => {
    const email = getValues("email");
    const emailError = validateEmail(email);

    if (!emailError) {
      setIsModalOpen(true);
    } else {
      setMessage(emailError);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        logout();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, logout]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <OverlayLoader />
      </div>
    );
  }

  return (
    <div className={styles.userprofil__wrapper}>
      <Text as="h3" textVariant="heading-3" className={styles.userprofil__title}>
        Mon profil
      </Text>
      <Card className={styles.userprofil__card}>
        <Text as="h4" textVariant="heading-4" className={styles.userprofil__subtitle}>
          Mes informations de connexion
        </Text>
        <Controller
          control={control}
          name="email"
          defaultValue={userEmail}
          rules={{ ...emailValidation }}
          render={({ field, fieldState: { error } }) => (
            <InputText
              border
              label={"Email de connexion"}
              placeholder={"exemple@email.com"}
              {...field}
              required
              error={error?.message}
              type={"email"}
            />
          )}
        />
        <small className={isSuccess ? styles.successmessage : styles.errormessage}>{message}</small>
        <div className={styles.userprofil__button}>
          <Button variant="primary" onClick={handleOpenModal}>
            Enregistrer
          </Button>
        </div>
      </Card>
      {isModalOpen && (
        <Modal
          title="Attention !"
          content={
            <>
              <p>
                Vous êtes sur le point de modifier votre adresse email de connexion :{" "}
                <strong>{getValues("email")}</strong>. Êtes-vous sûr de vouloir la modifier ?
              </p>
            </>
          }
          button={{ label: "Valider", handleButton: handleValidate }}
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default UserProfil;
