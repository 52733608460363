import React, { useState } from "react";
import { RedirectLoginOptions } from "@auth0/auth0-react";
import { RoutePaths } from "routes/routePaths";
import { membreApi } from "domain/api/Membre/membreApi";
import { Logo, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { Box } from "components/atoms/Box/Box";
import { ReactComponent as EmailIcon } from "images/EmailImage.svg";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";
import { ReactComponent as ValidateIcon } from "images/icons/validate.svg";
import { classNames } from "utils/classNames";
import { useMatchMedia } from "hooks/useMatchMedia";

import cssVariables from "styles/variables.module.scss";
import styles from "./UnauthorizedError.module.scss";

interface EmailNoVerifiedProps {
  email?: string;
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>;
}

const EmailNoVerifiedError: React.FC<EmailNoVerifiedProps> = ({ email, loginWithRedirect }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSucccess, setIsSuccess] = useState<boolean>(false);
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  const sendEmailVerification = async (email: string) => {
    setHasError(false);
    setIsSuccess(false);
    try {
      await membreApi.sendEmailVerification(email);
      setIsSuccess(true);
    } catch (err) {
      setHasError(true);
      setIsSuccess(false);
    }
  };

  return (
    <Box className={classNames(styles.boxcontainer)}>
      <header>
        <div className={styles.unauthorized__logo}>
          <Logo temp={false} dark={false} url={RoutePaths.HOME} />
        </div>
        <div className={styles.unauthorized__image}>
          <EmailIcon />
        </div>
        <div className={styles.unauthorized__title}>
          <Text textVariant="heading-5" as="h2" className={styles.unauthorized__title_text}>
            Vérifiez votre adresse email
          </Text>
        </div>
      </header>
      <Text textVariant="ui-1" as="p" className={styles.unauthorized__subtitle}>
        {" "}
        Afin de finaliser votre création de compte, nous avons besoin de vérifier la validité de
        votre adresse email.
      </Text>
      <Text textVariant="ui-1" as="p" className={styles.unauthorized__subtitle}>
        {" "}
        Veuillez cliquer sur le lien envoyé à l'adresse suivante:
      </Text>
      <p className={styles.unauthorized__email}>{email}</p>
      <Button
        variant="outlined-light"
        size="small"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              prompt: "login",
              screen_hint: "signup",
            },
          })
        }
      >
        Modifier l'email
      </Button>

      <hr />
      <Text textVariant="subheading-em" as="p" className={styles.unauthorized__subtitle}>
        Impossible de retrouver l'email ?
      </Text>
      <Text textVariant="ui-1" as="p" className={styles.unauthorized__subtitleWithmargin}>
        {" "}
        N'oubliez pas de vérifier vos courriers indésirables (spams) ou renvoyez l'email de
        vérification.
      </Text>
      <Button
        variant="outlined-light"
        size="small"
        onClick={() => (email ? sendEmailVerification(email) : () => {})}
      >
        Renvoyer un email de vérification
      </Button>
      <div className={styles.messageContainer}>
        {hasError && (
          <>
            {isDesktop ? (
              <Text textVariant="ui-1" as="p" className={styles.messageContainer__errorMessage}>
                <WarningIcon className={styles.warningIcon} />
                Une erreur est survenue. Veuillez réessayer plus tard
              </Text>
            ) : (
              <Text textVariant="ui-1" as="p" className={styles.messageContainer__errorMessage}>
                <WarningIcon className={styles.warningIcon} />
                Une erreur est survenue. <br />
                Veuillez réessayer plus tard
              </Text>
            )}
          </>
        )}
        {isSucccess && (
          <>
            <Text textVariant="ui-1" as="p" className={styles.messageContainer__successMessage}>
              <ValidateIcon />
              Merci de vérifier votre boîte mail
            </Text>
          </>
        )}
      </div>
    </Box>
  );
};

export default EmailNoVerifiedError;
