import { atom } from "recoil";
import { InfosContratReconduction, InfosReconduction, OffrePromoResult } from "domain/api-schemas";
import { ReconductionDispatcher } from "./dispatcher";

export interface ReconductionState {
  isExpired?: boolean;
  idAdhesion?: string;
  infosContratReconduction: InfosContratReconduction | null;
  promo: OffrePromoResult | null;
  promoError: boolean;
  codePromo: string | null;
  phone: string | null;
  errors?: Record<string, boolean>;
  iban: string | null;
  titulaire: string | null;
  coordonneesBancaireId?: string;
  loading: boolean;
  infosReconduction: InfosReconduction | null;
  isDesktop: boolean;
  isNewBankInfos: boolean;
  defaultPhoneNumber: string | null;
  currentStep: number;
}

export const initState: ReconductionState = {
  isExpired: false,
  idAdhesion: undefined,
  errors: {},
  infosContratReconduction: null,
  loading: false,
  infosReconduction: null,
  isDesktop: false,
  isNewBankInfos: false,
  promo: null,
  titulaire: null,
  phone: null,
  codePromo: null,
  promoError: false,
  iban: null,
  coordonneesBancaireId: undefined,
  defaultPhoneNumber: null,
  currentStep: 1,
};

export const initStateWithDefaultValues = (
  infosReconduction: InfosReconduction | null,
  isDesktop: boolean,
  phoneNumber: string | null,
) => {
  const { infosContratReconduction } = infosReconduction || {};
  const { iban, titulaire, id: cbId } = infosContratReconduction?.coordonneesBancaire || {};
  const { membre } = infosContratReconduction || {};
  return {
    infosReconduction,
    isDesktop,
    infosContratReconduction: infosContratReconduction || null,
    iban: iban || null,
    titulaire: titulaire || null,
    phone: membre?.telephoneMobile || null,
    coordonneesBancaireId: cbId,
    defaultPhoneNumber: phoneNumber,
  };
};

export const reconductionState = atom<ReconductionState>({
  key: "reconductionState",
  default: initState,
});

export const dispatcherState = atom<ReconductionDispatcher | undefined>({
  key: "reconductionDispatcherState",
  default: undefined,
});
