import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import ReconductionCotisationBlock from "./ReconductionCotisationBlock";
import IbanDescription from "pages/Reconduction/description/IbanDescription";
import IbanForm from "components/molecules/IBan/IbanForm";
import { RoutePaths } from "routes/routePaths";
import { dispatcherState, reconductionState } from "context/reconductionState/Atoms";
import { useTracking } from "hooks/useTracking";
import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";
import { ContactBlockTunnel } from "components/molecules/ContactBlockTunnel/ContactBlockTunnel";
import styles from "pages/Reconduction/Reconduction.module.scss";
import { formatCurrency } from "utils/formatCurrency";
import { Modal, Text, OverlayLoader } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { Box } from "components/atoms/Box/Box";
import { ReactComponent as IconCheck } from "images/icons/check-circle-outline.svg";
import { contractApi } from "domain/api/Contract/contractApi";
import { globalState } from "context/globalState/atom";
import { currentContratQuery } from "context/currentContratState/selector";

const MoyenPaiment: React.FC = () => {
  const {
    infosReconduction,
    isDesktop,
    promo,
    promoError,
    iban,
    titulaire,
    isNewBankInfos,
    currentStep,
  } = useRecoilValue(reconductionState);
  const dispatcher = useRecoilValue(dispatcherState);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userEmail } = useRecoilValue(globalState);
  const monContrat = useRecoilValue(currentContratQuery);

  useEffect(() => {
    if (infosReconduction === null) {
      navigate(RoutePaths.RECONDUCTION_HOME);
    }
    document?.documentElement?.scrollTo(0, 0);
  }, []);

  const handlePreviousStep = useCallback(() => {
    dispatcher?.updateStep(currentStep - 1);
    navigate(RoutePaths.RECONDUCTION_HOME);
    sendToDataLayer("reconduction-paiement-btn-precedent");
  }, [currentStep, dispatcher, navigate]);

  const handleNexStep = useCallback(
    (newIban: string, newTitle: string) => {
      dispatcher?.handlePaimentStep(newIban, newTitle, currentStep + 1);
      navigate(RoutePaths.RECONDUCTION_INFOPERSO);
      sendToDataLayer("reconduction-paiement-btn-suivant");
    },
    [currentStep, dispatcher, navigate],
  );

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  const confirmHandler = () => {
    setIsOpen(false);
    setLoading(true);
    contractApi
      .postReconduction({
        email: userEmail,
        IdAdhesion: monContrat?.idAdhesion,
        iban: "",
        coordonneesBancaireId: null,
        titulaire: titulaire || "",
        telephone: monContrat?.membre.telephoneMobile,
        offrePromotionnelleId: promo?.offrePromotionnelleId,
        successUrl: "",
        cancelUrl: "",
        failUrl: "",
        IsReconductionCb: true,
      })
      .then((result) => {
        if (result) {
          if (result.urlContrat === "Success") {
            setLoading(false);
            setEmailSent(true);
          }
        }
      });
  };

  const finishHandler = () => {
    navigate(RoutePaths.RECONDUCTION_CONFIRMATION_CB);
  };

  return (
    <>
      {loading && <OverlayLoader />}
      {!loading && (
        <>
          <IbanDescription />
          <div className={styles.Reconduction__titleAndContent}>
            <div className={styles.Reconduction__content}>
              <div>
                <IbanForm
                  iban={iban}
                  titulaire={titulaire}
                  isNewIban={!iban || !titulaire || isNewBankInfos}
                  handleIsNewIban={() => dispatcher?.handleIsNewIban()}
                  previousAction={handlePreviousStep}
                  nextAction={handleNexStep}
                />
                {monContrat?.membre.telephoneMobile && !promo && (
                  <Box className={styles.ReconductionCB__Box}>
                    <Text textVariant="heading-6" as="h6">
                      Vous souhaitez régler par Carte Bancaire ?
                    </Text>
                    <p className={(styles.iban__boxIntro, styles.ReconductionCB__Content)}>
                      {`Vous paierez l'intégralité de votre cotisation : 
                    ${
                      infosReconduction?.tarifMensuel &&
                      formatCurrency(Math.round(infosReconduction?.tarifMensuel * 12 * 100) / 100)
                    } `}
                    </p>
                    <Button variant="outlined-light" size="large" onClick={handleClick}>
                      <span id="reconduction-cb">Régler par carte bancaire</span>
                    </Button>
                  </Box>
                )}
              </div>

              <div className={styles.Reconduction__contentRight}>
                <ReconductionCotisationBlock
                  price={infosReconduction?.tarifMensuel}
                  handleChangePromo={() => undefined}
                  monthFree={promo?.echeancesOffertes}
                  promoError={promoError}
                  isDisplayPromoCode={false}
                />
                {isDesktop && (
                  <aside>
                    <SocialBlock className={styles.Callblock}>
                      {[<ContactBlockTunnel idTracking="reconduction-telephone" />]}
                    </SocialBlock>
                  </aside>
                )}
              </div>
            </div>
          </div>

          <Modal
            title="Information"
            content={
              <div>
                <Text
                  textVariant="body"
                  as="p"
                  className={(styles.textCenter, styles.Modal__Content)}
                >
                  Après votre confirmation, vous recevrez un email afin de compléter votre
                  reconduction et payer votre cotisation.
                </Text>
                <div className={styles.Modal__Buttons}>
                  <Button
                    variant={"outlined-light"}
                    size="small"
                    type="button"
                    className="center-label"
                    onClick={closeHandler}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="primary"
                    size="small"
                    type="button"
                    className="center-label"
                    onClick={confirmHandler}
                  >
                    Confirmer
                  </Button>
                </div>
              </div>
            }
            isOpen={isOpen}
            handleClose={closeHandler}
          />
          <Modal
            content={
              <div>
                <div className={styles.textCenter}>
                  <div className={styles.iconCenter}>
                    <IconCheck className={styles.iconCheck} />
                  </div>
                  <Text textVariant="heading-6" as="h6" className={styles.Modal__Content}>
                    Email envoyé
                  </Text>
                  <Text textVariant="body-em" as="p" className={styles.Modal__Content}>
                    Vous allez être redirigé vers la page d'accueil.
                  </Text>
                </div>
                <div className={styles.Modal__Buttons}>
                  <Button
                    variant="primary"
                    size="small"
                    type="button"
                    className="center-label"
                    onClick={finishHandler}
                  >
                    Terminer
                  </Button>
                </div>
              </div>
            }
            isOpen={emailSent}
            handleClose={finishHandler}
          />
        </>
      )}
    </>
  );
};

export default MoyenPaiment;
