import { FC, useState } from "react";
import { Controller, SubmitHandler, UseFormReturn, useForm, useFieldArray } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { ErrorMessage } from "@hookform/error-message";
import { globalState } from "context/globalState/atom";
import { monContratState } from "context/currentContratState/atom";
import { Box } from "components/atoms/Box/Box";
import { ExternalLink } from "components/atoms/ExternalLink/ExternalLink";
import { Title } from "components/atoms/Title/Title";
import { Button } from "components/atoms/Button/Button";
import { TextInputContainer } from "components/molecules/TextInput/TextInputContainer";
import fnac from "images/fnac.png";
import { ReactComponent as CadeauIcon } from "images/logo-parrainage-cadeau.svg";
import { useParrainage } from "domain/service/Member/useParainnage";
import { absoluteURL } from "utils/absoluteURL";
import { preventDefault } from "utils/preventDefault";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { EMAIL_FORMAT } from "utils/regex";

import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./Page.module.scss";
import { useTracking } from "hooks/useTracking";

const parrainageFile = `${process.env.REACT_APP_PUBLIC_DOCUMENT}parrainage/Reglement_Parrainage_Roole.pdf`;

const emailValidation = {
  pattern: {
    value: EMAIL_FORMAT,
    message:
      "Le format d'email saisi est incorrect. Veuillez respecter le format suivant: exemple@gmail.com",
  },
};

const makeEmail = (email = "") => ({ id: Date.now().toString(), email });

type FormData = { mailsFilleuls: Array<Record<"id" | "email", string>> };

type ParrainageInputProps = {
  form: UseFormReturn<FormData>;
  required?: boolean;
};

const ParrainagesInput: FC<ParrainageInputProps> = ({ form, required }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const { fields, append } = useFieldArray({
    name: "mailsFilleuls",
    control,
  });
  watch("mailsFilleuls"); // force silent revalidation... to reactivate submit button.

  return (
    <Box className={styles.inputMultiple} data-inputs-length={fields.length}>
      <div>
        {fields.map(({ id }, index) => (
          <div key={id} className={styles.field}>
            <TextInputContainer>
              <Controller
                render={({ field }) => <input {...field} />}
                name={`mailsFilleuls.${index}.email`}
                control={control}
                rules={{ ...emailValidation, required }}
              />
              <label>Email du filleul</label>
            </TextInputContainer>
            <small className={styles.error}>
              <ErrorMessage errors={errors} name={`mailsFilleuls.${index}.email`} />
            </small>
          </div>
        ))}
      </div>
      <Button
        disabled={fields.length >= 10}
        type="button"
        variant="secondary"
        data-selector="track-btn-envoi-invitation"
        size="small"
        onClick={preventDefault(() => append(makeEmail()))}
      >
        + Ajouter une invitation
      </Button>
    </Box>
  );
};

export const Page: FC = () => {
  const { sendToDataLayer } = useTracking();
  const globalStateValue = useRecoilValue(globalState);
  const contract = useRecoilValue(monContratState);
  const { mutateAsync: sendParrainage, error } = useParrainage(globalStateValue.idAdhesion!);
  const form = useForm<FormData>({
    defaultValues: {
      mailsFilleuls: [makeEmail()],
    },
  });
  const {
    getValues,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const [numberMessageSent, setNumberMessageSent] = useState(0);
  const hasOneEmail = getValues("mailsFilleuls").some(({ email }) => !!email);

  useSmartPushFilters(["parrainage-4658"]);

  const onSubmit: SubmitHandler<FormData> = async ({ mailsFilleuls }) => {
    sendToDataLayer("parrainage-btn-envoyer", true);
    const valideMailsFilleuls = mailsFilleuls.map(({ email }) => email).filter(Boolean);
    setNumberMessageSent(valideMailsFilleuls.length);
    await sendParrainage({
      mailsFilleuls: valideMailsFilleuls,
    });
    const defaultMailsFilleuls = [makeEmail()];
    form.setValue("mailsFilleuls", defaultMailsFilleuls); // force reset fields, including length
    form.reset({ mailsFilleuls: defaultMailsFilleuls }, { keepDefaultValues: true }); // reset form status
  };

  return (
    <>
      <Title className={styles.headTitle}>Parrainage</Title>
      {error && (
        <ErrorBlock
          phoneNumber={contract?.numeroTelephoneContact}
          variants={[ErrorBlockVariant.PhoneCall]}
        />
      )}
      {!error && (
        <div className={styles.content}>
          <article>
            <section>
              <div className={styles.info}>
                <Title>Pour vous</Title>
                <Box>
                  <img
                    alt="Fnac"
                    width={74}
                    src={absoluteURL(fnac, process.env.REACT_APP_ASSETS_ROOT)}
                  />
                  <p>
                    <big>40 €</big>
                    <strong>en e-carte cadeau Fnac</strong>
                    <small>
                      envoyé lorsque votre filleul atteint <div>3 mois d'ancienneté</div>
                    </small>
                  </p>
                </Box>
              </div>
              <div className={styles.info}>
                <Title>Pour votre filleul</Title>
                <Box className={styles.BoxParainage}>
                  <CadeauIcon width={74} />
                  <p>
                    <big>
                      2<span>&nbsp;mois</span>
                    </big>
                    <strong>offerts</strong>
                    <small>pour sa 1ère adhésion chez&nbsp;Roole</small>
                  </p>
                </Box>
              </div>
            </section>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Title as="h3">
                Faites découvrir Roole à vos proches sans tarder&nbsp;! Offre&nbsp;limitée à 10
                filleuls
              </Title>
              <ParrainagesInput form={form} required={!hasOneEmail} />
              {isSubmitSuccessful && (
                <strong className={styles.success}>
                  <i className={styles.icon}>✓</i>&nbsp;
                  {numberMessageSent > 1 ? "Emails envoyés" : "Email envoyé"}
                </strong>
              )}
              <Button
                disabled={isSubmitting || !hasOneEmail}
                variant="primary"
                data-selector="track-btn-envoi-mail"
              >
                Envoyer
              </Button>
            </form>
            <div className={styles.legalMention}>
              <Title as="h4">Mentions légales</Title>
              <p className={styles.legal}>
                Vos données sont collectées par Roole, marque commerciale d’IDENTICAR SAS, 7 place
                René Clair 92100 Boulogne-Billancourt, SIREN 507 621 043, et sont nécessaires pour
                gérer votre compte et vos accès à cette plateforme. Conformément à la loi «
                Informatique et Libertés » du 6 janvier 1978 modifiée et du Règlement (UE) Général
                sur la Protection des Données « RGPD », vous bénéficiez d’un droit d’accès,
                d’opposition, de rectification, de mise à jour, d’effacement, ainsi qu’un droit à la
                portabilité et à l’oubli des informations qui vous concernent, que vous pouvez
                exercer en vous adressant au Délégué à la Protection des Données de Roole, soit par
                courrier à Roole, DPO, 7 place René Clair 92100 Boulogne-Billancourt, soit par
                e-mail à <a href="mailto:dpo@roole.fr">dpo@roole.fr</a> en justifiant de votre
                identité par tout moyen.
                <ExternalLink href={parrainageFile} icon={false} className={styles.link}>
                  Règlement de l'opération parrainage
                </ExternalLink>
              </p>
            </div>
          </article>
        </div>
      )}
    </>
  );
};
