import { Navigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { Page } from "./Page";
import { monContratState } from "context/currentContratState/atom";
import { currentContratQuery } from "context/currentContratState/selector";
import { Footer } from "components/molecules/Footer/Footer";

export const PageGuard = () => {
  const { contents: contract } = useRecoilValueLoadable(monContratState);
  const monContrat = useRecoilValueLoadable(currentContratQuery);

  return contract?.contratId ? (
    <>
      <Page />
      <Footer
        contrat={monContrat?.contents || null}
        idAdhesion={monContrat?.contents?.idAdhesion || null}
      />
    </>
  ) : (
    <Navigate to={RoutePaths.CONTRAT_HOME} />
  );
};
