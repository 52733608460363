import { ValiditeContrat } from "domain/service/Contract/useContract";

export const getInactifContractsCount = (contracts: any[]): number => {
  let counter = 0;

  if (!Array.isArray(contracts)) {
    return 0;
  }

  for (const contract of contracts) {
    if (contract.validiteContrat === ValiditeContrat.Inactif) {
      counter++;
    }
  }

  return counter;
};

export const getFirstValidContractIdAdhesion = (contracts: any[]): string => {
  const ret = contracts[0]?.idAdhesion.toString();

  for (const contract of contracts) {
    if (
      contract.validiteContrat === ValiditeContrat.Actif ||
      contract.validiteContrat === ValiditeContrat.ExpireBientot
    ) {
      return contract.idAdhesion.toString();
    }
  }

  return ret;
};
