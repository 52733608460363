import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalState } from "context/globalState/atom";
import { PublicRoutePaths } from "routes/routePaths";
import { useSearchParamsValues } from "hooks/useSearchParamsValues";
import { OverlayLoader } from "@roole/components-library";
import {
  bonsPlansMobilitesApi,
  TransactionStatus,
} from "domain/api/BonPlansMobilites/bonsPlansMobilitesApi";

const PaiementResult = () => {
  const { idAdhesion } = useRecoilValue(globalState);
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>();
  const search = useSearchParamsValues();

  const fetchTransactionStatus = async (hostedCheckoutId: string | null) => {
    if ((idAdhesion || search.idAdhesion) && hostedCheckoutId) {
      const data = await bonsPlansMobilitesApi.getTransactionStatus(
        idAdhesion || search.idAdhesion,
        hostedCheckoutId,
      );
      return data;
    }
    return null;
  };

  fetchTransactionStatus(search.hostedCheckoutId)
    .then((data) => {
      if (data !== null) {
        setTransactionStatus(data);
      }
      setError(false);
    })
    .catch(() => {
      setError(true);
    })
    .finally(() => setLoading(false));

  if (loading) {
    return <OverlayLoader />;
  }

  if (!loading && transactionStatus === TransactionStatus.annulee) {
    return <Navigate to={PublicRoutePaths.PAIEMENT_ABANDON} />;
  }

  if (!loading && (transactionStatus === TransactionStatus.incorrecte || error)) {
    return <Navigate to={PublicRoutePaths.PAIEMENT_ERROR} />;
  }
  if (!loading && transactionStatus === TransactionStatus.valide) {
    return (
      <Navigate
        to={PublicRoutePaths.PAIEMENT_SUCCESS}
        state={{ idAdhesion: idAdhesion || search.idAdhesion }}
      />
    );
  }
  return <></>;
};

export default PaiementResult;
