import { FC } from "react";
import { ButtonV2, RadioButtonGroup, TextV2, Message } from "@roole/components-library";
import styles from "./GarantiesStep.module.scss";

type ModalContentProps = {
  garantieExclusions: Array<{
    question: string;
    reponseAttendue: string;
    noteIneligibilite?: string;
    noteInformation?: string;
  }>;
  checkEligible: (isEligible: boolean, responses?: any) => void;
  handleClose: () => void;
  responses: Record<string, string>;
  setResponses: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  errors: Record<string, boolean>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  resetError: boolean;
  setResetError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEligibleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNotEligibleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalContent: FC<ModalContentProps> = ({
  garantieExclusions,
  checkEligible,
  handleClose,
  responses,
  setResponses,
  errors,
  setErrors,
  resetError,
  setResetError,
  setIsEligibleModalOpen,
  setIsNotEligibleModalOpen,
}) => {
  const allAnswered = garantieExclusions.every(({ question }) => !!responses[question]);

  const handleRadioChange = (question: string, value: string) => {
    setResponses((prev) => ({ ...prev, [question]: value }));
    setErrors((prev) => ({ ...prev, [question]: false }));
  };

  const verifyResponses = () => {
    const newErrors: Record<string, boolean> = {};
    let allValid = true;

    garantieExclusions.forEach(({ question, reponseAttendue }) => {
      const response = responses[question];

      if (!response) {
        newErrors[question] = true;
        allValid = false;
      } else if (response !== reponseAttendue) {
        allValid = false;
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      console.log("Une ou plusieurs questions n'ont pas été répondues ou sont incorrectes.");
      return;
    }

    if (allValid) {
      setIsEligibleModalOpen(true);
      checkEligible(true, responses);
      setResponses({});
      handleClose();
      setResetError(false);
    } else {
      setIsNotEligibleModalOpen(true);
      checkEligible(false, responses);
      setResponses({});
      handleClose();
      setResetError(false);
    }
  };

  return (
    <div>
      <TextV2 textVariant="heading-3" as="h3" style={{ marginTop: "8px", marginBottom: "16px" }}>
        Complétez les informations
      </TextV2>

      {garantieExclusions.map(({ question }) => (
        <div
          key={question}
          style={{ marginBottom: "16px", gap: "16px", display: "flex", flexDirection: "column" }}
        >
          <TextV2>{question}</TextV2>
          <RadioButtonGroup
            direction="row"
            elements={[
              { label: "Oui", value: "Oui" },
              { label: "Non", value: "Non" },
            ]}
            name={question}
            onChange={(e) => handleRadioChange(question, e === 0 ? "Oui" : "Non")}
            error={errors[question]}
            variant="tile"
          />
        </div>
      ))}
      {/* {garantieExclusions && (
          <Message
            type="information"
            message={
              <div>
                {garantieExclusions.map((exclusion) => (
                  <div key={exclusion.question} style={{ marginBottom: "8px" }}>
                    {exclusion.noteInformation && exclusion.noteInformation !== "<p></p>" && (
                      <div style={{ display: "flex", gap: "8px" }}>
                        <IconV2 name="info" color="neutral" />
                        <div>{exclusion.noteInformation}</div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }
          />
        )} */}

      {!allAnswered && resetError && (
        <div style={{ marginTop: "32px" }}>
          <Message
            icon="info"
            type="error"
            message={
              <TextV2 textVariant="body-small" color="error">
                Vous devez compléter les informations pour vérifier votre éligibilité.
              </TextV2>
            }
          />
        </div>
      )}

      <div className={styles.modalButtons}>
        <ButtonV2
          variant="secondary-dark"
          onClick={() => {
            setResponses({});
            handleClose();
          }}
        >
          Annuler
        </ButtonV2>
        <ButtonV2
          variant="primary-brand"
          onClick={() => {
            setResetError(true);
            verifyResponses();
          }}
        >
          Vérifier mon éligibilité
        </ButtonV2>
      </div>
    </div>
  );
};

export default ModalContent;
