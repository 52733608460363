import { DropDownItem } from "@roole/components-library";
import { atom } from "recoil";
import { ContactCategoriesMotifs } from "domain/api-schemas";
import { APPLICATIONSTATES } from "context/global.types";
import { categoriesMotifsQuery } from "./selector";

export enum CallBackPeriod {
  Morning = "09h/13h",
  Afternoon = "13h/18h",
}

export enum ContactType {
  undefined = 0,
  mail = 1,
  telephone = 2,
}
export interface ContactUsStepState {
  activeStep: number;
  subject: DropDownItem | null;
  reason: DropDownItem | null;
  callBackDate: string;
  callBackPeriod: CallBackPeriod | null;
  telephoneNumber: string;
  messageValue: string;
  contactType: ContactType;
  errors?: {
    telephoneNumber?: string | null;
    callBackDate?: string | null;
    callBackPeriod?: string | null;
  };
}

export const contactUsStepContextStateInitValue: ContactUsStepState = {
  activeStep: 1,
  subject: null,
  reason: null,
  callBackDate: "",
  callBackPeriod: null,
  telephoneNumber: "",
  messageValue: "",
  contactType: ContactType.telephone,
  errors: {
    telephoneNumber: null,
    callBackDate: null,
    callBackPeriod: null,
  },
};

export const contactUsStepState = atom<ContactUsStepState>({
  key: APPLICATIONSTATES.StepState,
  default: contactUsStepContextStateInitValue,
});

export const CategoriesMotifsState = atom<ContactCategoriesMotifs[]>({
  key: APPLICATIONSTATES.CategoriesMotifsState,
  default: categoriesMotifsQuery(null),
});
