import { selector, selectorFamily } from "recoil";
import { globalState } from "context/globalState/atom";
import { contractApi } from "domain/api/Contract/contractApi";

export const listContratsInfoQuery = selectorFamily({
  key: "ListContratsInfoQuery",
  get: (listContratAhesion: string[]) => async () => {
    if (!listContratAhesion) {
      return [];
    }
    const response = await contractApi.fetchContrats(listContratAhesion);
    return response;
  },
});

export const listContratsQuery = selector({
  key: "ListContratsQuery",
  get: ({ get }) => get(listContratsInfoQuery(get(globalState).listContratAhesion || [])),
});
