import { useQuery } from "react-query";
import { PlafondIndemniteFinanciere } from "domain/api-schemas";
import { contractApi } from "domain/api/Contract/contractApi";

export const useIndemnite = (
  codePack: string,
  optionsPack: {
    codePack?: string | null;
    tarifAnnuelTac?: number;
  }[] = [],
) => {
  const stringValue =
    optionsPack.length > 0
      ? `&${optionsPack
          .map(({ codePack: codePackOptionsPack }) => `optionPack=${codePackOptionsPack}`)
          .join("&")}`
      : "";

  const skip = codePack === "OV";

  const result = useQuery<PlafondIndemniteFinanciere[], Error>(
    "query-indemnite",
    async () => await contractApi.fetchIndemnite(codePack, stringValue),
    { enabled: !skip, retry: false },
  );

  return skip ? { data: [] } : result;
};
