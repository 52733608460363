import { FC, useEffect } from "react";
import { Header } from "components/molecules/Header/Header";
import { Text } from "@roole/components-library";
import Logo from "components/atoms/Logo/Logo";
import errorImage from "../../images/error.png";
import styles from "../Error/ErrorPage.module.scss";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";

const ConfirmerResiliationError: FC = () => {
  useEffect(() => {
    sendCloseWebViewEvent({
      hasError: true,
      errorDetails: { errorMessage: "Resiliation Error, Etat contrat === RENOUVELE" },
    });
  });

  return (
    <div className={styles.PageLayout}>
      <Header className={styles.PageLayout__header}>
        <Logo id="header-logo" url="" />
      </Header>
      <div className={styles.PageLayout__content}>
        <img className={styles.image} src={errorImage} alt="" />
        <Text as="h3" textVariant="heading-3" className={styles.title}>
          Nous sommes désolés une erreur est survenue
        </Text>
        <Text as="span" className={styles.subTitle}>
          Votre lien de résiliation a expiré, merci de nous contacter pour en obtenir un nouveau.
        </Text>
      </div>
    </div>
  );
};

export default ConfirmerResiliationError;
