import { createElement, FC } from "react";
import { Icon, Text } from "@roole/components-library";

import { useMobileOsDetection } from "hooks/useMobileOsDetection";
import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";
import { maskByBlockOf } from "utils/maskByBlockOf";
import styles from "./PhoneNumber.module.scss";

interface PhoneNumberProps extends CommonProps {
  value?: string | null;
  useIcon?: boolean;
}

const formatPhoneNumber = maskByBlockOf(2, "\u00a0");

export const PhoneNumber: FC<PhoneNumberProps> = ({
  className,
  value,
  useIcon = true,
  ...props
}) => {
  const cleanValue = value?.replace(/\\s+/g, "");
  const isValid = !!cleanValue && cleanValue.length > 0;
  const { isMobile: isActive } = useMobileOsDetection();

  if (!isValid) {
    return null;
  }

  const elType = isActive ? "a" : "span";
  const elProps = {
    className: classNames(styles.phoneNumber, className),
    ...(isActive ? { href: `tel:${cleanValue}` } : {}),
    ...props,
  };

  return createElement(
    elType,
    elProps,
    <>
      {useIcon && <Icon variant="phone" />}
      <Text textVariant="body">{formatPhoneNumber(cleanValue)}</Text>
    </>,
  );
};
