import axiosClient from "clientProvider/axiosConfig";
import { PjDescription } from "domain/api-schemas";

export const descriptionPjApi = {
  fetchDescriptionPj: async (crmIds: string[]) => {
    const result = await axiosClient.get<PjDescription>(
      `PiecesJustificatives/crmIds?${crmIds.map((crmId) => "crmIds=" + crmId).join("&")}`,
    );

    return result.data;
  },
};
