import { FC } from "react";
import { Button } from "components/atoms/Button/Button";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { TunnelHeader } from "components/molecules/Header/TunnelHeader";

import styles from "pages/Reconduction/Reconduction.module.scss";

export const PageAbandonCRM: FC = () => {
  return (
    <section>
      <TunnelHeader
        id={"crm-reconduction-success"}
        title={"Payer ma cotisation"}
        onCloseCallback={undefined}
        closeButtonId={"CRM-reconduction-failed"}
        displayClose={undefined}
        displayMobileCallBlock={false}
        disableLogoLink={true}
        phoneNumber={""}
      />
      <EndPage title="Votre reconduction a été abandonnée.">
        <p>
          Vous avez refusé de signer votre contrat de reconduction, votre contrat n'a donc pas été
          renouvelé et vous ne serez pas prélevé.
        </p>
        <p>
          Vous bénéficierez de vos garanties et avantages jusqu'à la fin de votre contrat actuel.
        </p>
        <p>
          Si vous souhaitez nous contacter, nous serons ravis de répondre à toutes vos questions au{" "}
          <strong>01&nbsp;46&nbsp;90&nbsp;37&nbsp;00</strong> (du lundi au vendredi, de 9h à 18h)
        </p>
        <div className={styles.CRMReconduction_RetourButton}>
          <Button
            variant="primary"
            size="large"
            onClick={() => window.location.replace(window.location.origin)}
          >
            Retour sur site mon compte
          </Button>
        </div>
      </EndPage>
    </section>
  );
};
