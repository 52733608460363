import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "components/molecules/Header/Header";
import { Text } from "@roole/components-library";
import Logo from "components/atoms/Logo/Logo";
import { PublicRoutePaths, RoutePaths } from "routes/routePaths";
import { Button } from "components/atoms/Button/Button";
import errorImage from "images/error.png";

import styles from "./ErrorPage.module.scss";
import { useAuthenticationContext } from "AuthenticationProvider";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";

const ErrorPage: FC<{ errorNumber?: number; errorMsg?: string }> = ({ errorNumber, errorMsg }) => {
  const navigate = useNavigate();
  const isRoutePath = Object.values(RoutePaths).includes(
    window.location.pathname as unknown as RoutePaths,
  );
  const ispublicRoutePath = Object.values(PublicRoutePaths).includes(
    window.location.pathname as unknown as PublicRoutePaths,
  );
  const { isAuth0Authenticated } = useAuthenticationContext();
  if (isRoutePath && !ispublicRoutePath && !isAuth0Authenticated) {
    navigate(PublicRoutePaths.SIGNIN);
  }

  const errorAndUrl = () => {
    switch (errorNumber) {
      case 404:
        return {
          error: "La page à laquelle vous tentez d'accéder n'existe pas",
          url: PublicRoutePaths.SIGNIN,
          button: "Retour à l'accueil",
        };
      case 401:
        return {
          error: "Un email de vérification est envoyé , veuillez le valider",
          url: PublicRoutePaths.SIGNIN,
          button: "Se Connecter",
        };
      default:
        return {
          error:
            "Suite à un incident, nous ne sommes pas en mesure d'afficher la page que vous souhaitez. Veuillez réessayer.",
        };
    }
  };

  const { error, url, button } = errorAndUrl();

  useEffect(() => {
    sendCloseWebViewEvent({ hasError: true, errorDetails: { error, errorMessage: errorMsg } });
  });

  return (
    <div className={styles.PageLayout}>
      <Header className={styles.PageLayout__header}>
        <Logo id="header-logo" url={RoutePaths.CONTRAT_HOME} />
      </Header>
      <div className={styles.PageLayout__content}>
        <img className={styles.image} src={errorImage} />
        <Text as="h3" textVariant="heading-3" className={styles.title}>
          Nous sommes désolés une erreur est survenue
        </Text>
        <Text as="span" className={styles.subTitle}>
          {errorMsg ?? error}
        </Text>
        {url && button && (
          <Button type={"button"} variant="outlined-light" onClick={() => navigate(url)}>
            {button}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
