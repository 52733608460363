import { WizardNav, WizardStep, WizardStepLayout } from "components/molecules/Wizard";
import { Text } from "@roole/components-library";
import { Box } from "components/atoms/Box/Box";
import { useRecoilValue } from "recoil";
import { echeancierState } from "./Context/EcheancierState/atom";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import EcheancierBox from "./EcheancierBox";
import { useTracking } from "hooks/useTracking";

export const InfoStep: WizardStep = () => {
  const echeancier = useRecoilValue(echeancierState);
  const navigate = useNavigate();

  const { sendToDataLayer } = useTracking();

  return (
    <WizardStepLayout title={""}>
      <div>
        <Text textVariant="heading-4" as="h3">
          Régulariser ma facture impayée
        </Text>
        <EcheancierBox echeancier={echeancier}></EcheancierBox>
        <Box>
          <Text textVariant="body-em" as="p">
            Vous avez actuellement un impayé à régulariser pour continuer à profiter des services
            Roole.
          </Text>
          <Text textVariant="body-em" as="p">
            Le règlement se fait uniquement par carte bancaire et vous pourrez modifier votre RIB si
            jamais il a changé.
          </Text>
        </Box>
        <WizardNav
          prevStepHandler={() => navigate(RoutePaths.CONTRAT)}
          prevStepLabel="Annuler"
          nextSendToDataLayer={() => {
            sendToDataLayer("regularisation-commencer", false);
          }}
        />
      </div>
    </WizardStepLayout>
  );
};
