import { FC, ReactNode } from "react";
import styles from "./TilePJ.module.scss";
import { TextV2, ProgressIndicator } from "@roole/components-library";

export const TilePJ: FC<{
  title: string;
  percentage: number;
  nbTotal: number;
  nbSent: number;
  action: ReactNode;
}> = ({ title, percentage, nbTotal, nbSent, action }) => {
  return (
    <div className={styles.containerPJ}>
      <div className={styles.containerPJLeft}>
        <div className={styles.containerPJTitle}>
          <TextV2 textVariant="body-medium" as="div">
            {title}
          </TextV2>
          <TextV2 textVariant="body-large-em" as="div">
            {percentage}%
          </TextV2>
        </div>
        <div className={styles.progressDiv}>
          <ProgressIndicator percentage={percentage} />
        </div>
        <TextV2 textVariant="body-small">Justificatifs ajoutés : </TextV2>
        <TextV2 textVariant="body-small-em">{`${nbSent}/${nbTotal}`}</TextV2>
      </div>
      <div className={styles.containerPJRight}>{action}</div>
    </div>
  );
};
