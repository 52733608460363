import axios, { AxiosError } from "axios";

export enum ErrorType {
  axiosError = "axiosError",
  stockError = "'stockError'",
  authentError = "authentError",
}

interface IErrorBase {
  error: Error | AxiosError;
  type: ErrorType.axiosError | ErrorType.stockError | ErrorType.authentError;
}

interface IAxiosError extends IErrorBase {
  error: AxiosError;
  type: ErrorType.axiosError;
}

interface IStockError extends IErrorBase {
  error: Error;
  type: ErrorType.stockError;
}

export interface IAuthentError extends IErrorBase {
  error: Error;
  type: ErrorType.authentError;
}

export function axiosErrorHandler(defaultError: unknown) {
  if (axios.isAxiosError(defaultError)) {
    return {
      error: defaultError,
      type: ErrorType.axiosError,
    } as IAxiosError;
  }
  return {
    error: defaultError,
    type: ErrorType.stockError,
  } as IStockError;
}
