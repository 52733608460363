import { ButtonHTMLAttributes, FC } from "react";
import styles from "./Badge.module.scss";
import { classNames } from "utils/classNames";

export type BadgeProps = {
  variant?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Badge: FC<BadgeProps> = ({ variant, children }) => {
  return (
    <span
      className={classNames(
        styles.badge,
        variant === "success" && styles.success,
        variant === "danger" && styles.danger,
      )}
    >
      {children}
    </span>
  );
};

export default Badge;
