import { DropDownItem } from "@roole/components-library";

import { Concession } from "domain/api-schemas";

export interface TimeSlot {
  id: number;
  name: string;
  description?: string;
}

export interface Appointment {
  reason: DropDownItem;
  reminder: {
    date: string;
    timeSlot: TimeSlot;
  };
  days: DropDownItem[];
}

export interface AppointmentContext {
  opened: boolean;
  scheduled?: boolean;
  dealership?: Concession;
}

export const POSSIBLE_REASONS: DropDownItem[] = [
  {
    id: 0,
    name: "Sélectionner un motif de rendez-vous *",
    disabled: true,
  },
  {
    id: 1,
    name: "Révision",
    disabled: false,
  },
  {
    id: 2,
    name: "Pneus",
    disabled: false,
  },
  {
    id: 3,
    name: "Carrosserie",
    disabled: false,
  },
  {
    id: 4,
    name: "Climatisation",
    disabled: false,
  },
  {
    id: 5,
    name: "Pare-brise",
    disabled: false,
  },
  {
    id: 6,
    name: "Autre",
    disabled: false,
  },
];

export const POSSIBLE_DAYS: DropDownItem[] = [
  {
    id: 0,
    name: "Sélectionner une préférence de jour de rendez-vous *",
    disabled: true,
  },
  {
    id: 1,
    name: "Lundi",
    disabled: false,
  },
  {
    id: 2,
    name: "Mardi",
    disabled: false,
  },
  {
    id: 3,
    name: "Mercredi",
    disabled: false,
  },
  {
    id: 4,
    name: "Jeudi",
    disabled: false,
  },
  {
    id: 5,
    name: "Vendredi",
    disabled: false,
  },
  {
    id: 6,
    name: "Samedi",
    disabled: false,
  },
];

export const POSSIBLE_TIME_SLOTS: TimeSlot[] = [
  {
    id: 0,
    name: "Matin",
    description: "(entre 9h00 et 12h00)",
  },
  {
    id: 1,
    name: "Après-midi",
    description: "(entre 14h00 et 18h00)",
  },
  {
    id: 2,
    name: "Toute la journée",
    description: "(entre 9h00 et 18h00)",
  },
];

export const DEFAULT_RULES = {
  required: { value: true, message: "Ce champ est obligatoire" },
};
