import { atom, selectorFamily } from "recoil";
import { DossierGarantie } from "../../domain/api-schemas";
import { dossierApi } from "../../domain/api/Sinistre/DossierApi";

export const dossierPjQuery = selectorFamily({
  key: "dossierPjQuery",
  get: (params: { crmId: string; idAdhesion: string }) => async () => {
    return await dossierApi.fetchEvenements(params.crmId, params.idAdhesion);
  },
});

export const dossierDataState = atom<DossierGarantie | null>({
  key: "dossierDataState",
  default: null,
});
