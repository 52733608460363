import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { classNames } from "utils/classNames";
import { Button } from "components/atoms/Button/Button";
import ErrorLine from "components/atoms/Error/ErrorRendering/ErrorRendering";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import { RoutePaths } from "routes/routePaths";
import { FORM_DATA, FORM_UNMODIFIABLE_DATA, STE_CIVILITE } from "./FormCard.config";
import { UpdateInfosMembreRequest } from "domain/api-schemas";

import styles from "./FormCard.module.scss";

export type FormCardProps = {
  defaultValues?: any;
  handleOnSubmit: (e: UpdateInfosMembreRequest) => void;
  abort: () => void;
  sendToDataLayer: (id: string) => void;
};

const TooltipFormCard: FC<{
  key: string;
  gtmTooltipId: string | undefined;
  sendToDataLayer: (id: string) => void;
}> = ({ key, gtmTooltipId, sendToDataLayer }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.FormCard__tooltipContainer} key={`tooltip-${key}`}>
      Pour modifier ces champs
      <button
        type="button"
        key={`link-${key}`}
        className={styles.FormCard__tooltipContact}
        onClick={() => {
          sendToDataLayer(gtmTooltipId || "");
          navigate(RoutePaths.CONTACT_REASON);
        }}
      >
        contactez-nous
      </button>
    </div>
  );
};

const FormUnEditable: FC<{ initValues: any; sendToDataLayer: (id: string) => void }> = ({
  initValues,
  sendToDataLayer,
}) => {
  return (
    <>
      {FORM_UNMODIFIABLE_DATA.map(
        ({ label, key, hideForSte, hideForPerso, gtmId, gtmTooltipId }, index) => {
          const isHideForPro = hideForSte === false && initValues?.civilite === STE_CIVILITE;
          const isHideForPerso = hideForPerso && initValues?.civilite !== STE_CIVILITE;
          const displayLabel =
            initValues?.civilite === "Societe" && key === "completeName"
              ? "Raison sociale:"
              : label;
          return (
            <div key={`readForm-${key}`}>
              {!isHideForPro && !isHideForPerso && (
                <div className={styles.FormCard__tooltipWrapper} key={`container-${key}`}>
                  <div
                    key={`form-button-${key}`}
                    className={styles.FormCard__staticField}
                    onClick={() => sendToDataLayer(gtmId)}
                    role="button"
                  >
                    {displayLabel} {initValues[key]}
                  </div>
                  <Tooltip
                    place="bottom"
                    id={`not-modifiable-tooltip-${key}`}
                    anchorId={`not-modifiable-tooltip-${key}`}
                    events={["click", "hover"]}
                    content={
                      <TooltipFormCard
                        key={key}
                        gtmTooltipId={gtmTooltipId}
                        sendToDataLayer={sendToDataLayer}
                      />
                    }
                  />
                </div>
              )}
            </div>
          );
        },
      )}
    </>
  );
};

const FormCard: FC<FormCardProps> = ({ defaultValues, handleOnSubmit, abort, sendToDataLayer }) => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { errors } = formState || {};
  const handleAbort = () => {
    sendToDataLayer("info-perso-btn-annuler");
    abort?.();
  };

  return (
    <form className={styles.FormCard} onSubmit={handleSubmit(handleOnSubmit)}>
      <FormUnEditable initValues={defaultValues} sendToDataLayer={sendToDataLayer} />
      {FORM_DATA.map(
        ({
          key,
          mapping,
          label,
          required = false,
          maxLength,
          type,
          pattern,
          size,
          errorMessage,
          errorPatternMessage,
          gtmId,
        }) => (
          <div key={`editCard-${key}`} className={styles.FormCard__textfield}>
            <label
              className={classNames(
                styles.FormCard__textfieldLabel,
                required && styles.FormCard__textfieldLabel_required,
              )}
              htmlFor={key}
            >
              {label}
            </label>

            <input
              {...register(key, {
                required,
                maxLength,
                value: defaultValues?.[mapping],
                pattern,
              })}
              maxLength={maxLength}
              type={type || "text"}
              className={classNames(
                styles.FormCard__textfieldInput,
                errors?.[key] && styles.FormCard__textfieldInput_error,
              )}
              id={key}
              size={size}
              onClick={() => sendToDataLayer(gtmId)}
            />
            <ErrorLine
              errors={errors}
              nameProperty={key}
              errorMessage={errorPatternMessage || errorMessage}
            />
          </div>
        ),
      )}
      * Champs obligatoire
      <div className={styles.FormCard__button_container}>
        <Button
          className={styles.FormCard__button}
          variant="outlined-light"
          size="small"
          onClick={handleAbort}
        >
          Annuler
        </Button>
        <Button
          className={styles.FormCard__button}
          variant="primary"
          type="submit"
          size="small"
          onClick={() => sendToDataLayer("info-perso-btn-valider")}
        >
          Valider
        </Button>
      </div>
    </form>
  );
};

export default FormCard;
