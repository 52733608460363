import { TextV2, Tag, ButtonV2, Avatar, IconV2, Message } from "@roole/components-library";
import {
  DossierGarantie,
  EtatReglementDossierGarantie,
  StatutDossierGarantie,
  StatutPiece,
} from "domain/api-schemas";
import { getTagDossier } from "domain/service/Sinistre/useEvenement";
import { FC } from "react";
import { formatDate } from "utils/formatDate";
import { TilePJ } from "./TilePJ";
import styles from "./Dossier.module.scss";
import { classNames } from "utils/classNames";
import { formatCurrency } from "utils/formatCurrency";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { calculNbPjSentOld, checkDossierOld } from "domain/service/Sinistre/useDossier";
import { globalState } from "context/globalState/atom";

export const Dossier: FC<{ dossier: DossierGarantie }> = ({ dossier }) => {
  const globalStateValue = useRecoilValue(globalState);
  const idAdhesion = globalStateValue.idAdhesion;
  const isDossierOld = checkDossierOld(dossier);
  const navigate = useNavigate();
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const PJBloquants =
    dossier.piecesAttendues?.filter(
      (piece) => piece.isBloquant && piece.statut !== StatutPiece.Conforme,
    ) ?? [];
  const nbPJTotal = PJBloquants.length;

  let nbPJSent = PJBloquants.reduce((acc, pj) => {
    const nbPieceDossier =
      dossier.piecesDossiers?.filter(
        (piece) => piece.typeId?.toLocaleLowerCase() === pj.typeId?.toLocaleLowerCase(),
      ).length || 0;

    return acc + Math.min(nbPieceDossier, 1);
  }, 0);

  const nbPJTraitees =
    PJBloquants?.filter((piece) => piece.statut !== StatutPiece.NonRecu)?.length ?? 0;

  const nbSent = isDossierOld ? calculNbPjSentOld(dossier, nbPJTotal) : nbPJSent;
  const percentage = (nbSent * 100) / nbPJTotal;
  const nbPJRestant = nbPJTotal - nbPJSent;
  const nbPJRestantTraitees = nbPJTotal - nbPJTraitees;
  const nbPJNonConforme = PJBloquants.filter(
    (piece) => piece.statut === StatutPiece.NonConforme,
  ).length;

  const tagStatutDossier = getTagDossier(dossier.statut.code, dossier.etatReglement?.code);

  const handleNavigate = () =>
    navigate(`${RoutePaths.DETAIL_SINISTRE}/${dossier.crmId}/${idAdhesion}`);

  const messageToSendDossier = (
    <div style={{ marginTop: 16 }}>
      <Message
        message={
          <TextV2 textVariant={isMobile ? "body-small" : "body-medium"}>
            Une fois les justificatifs obligatoires ajoutés, pensez à nous envoyer votre dossier.
          </TextV2>
        }
        type="warning"
        icon="info"
      />
    </div>
  );

  const modeReglement = (code: number) =>
    ({
      100000000: "Chèque",
      100000001: "Virement bancaire",
    }[code] ?? null);

  return (
    <div className={styles.containerDossier} key={dossier.crmId}>
      <div className={styles.dossierHeader}>
        <div className={styles.dossierTitle}>
          <TextV2 textVariant="heading-4" extraCss={{ marginRight: 12 }}>
            {dossier.garantie?.nom}
          </TextV2>
          <div className={styles.tag}>
            <Tag label={tagStatutDossier?.label ?? ""} type={tagStatutDossier?.type} />
          </div>
        </div>
        <div className={styles.dossierSubTitle}>
          <TextV2
            color="secondary"
            textVariant="body-small-em"
            as="div"
          >{`Dossier ${dossier.nom}`}</TextV2>
          <TextV2 color="secondary" className={styles.dot}>
            ·
          </TextV2>
          <TextV2 color="secondary" textVariant="body-small" as="div">{`Crée le ${formatDate(
            dossier.dateOuverture,
          )}`}</TextV2>
        </div>
      </div>

      {dossier.statut.code === StatutDossierGarantie.EnCours && (
        <>
          <TilePJ
            percentage={!isNaN(percentage) ? Math.floor(percentage) : 0}
            nbSent={nbSent}
            nbTotal={nbPJTotal}
            title="Complétez votre dossier"
            action={
              <ButtonV2 variant="primary-brand" size="small" onClick={handleNavigate}>
                Compléter
              </ButtonV2>
            }
          />
          {nbPJRestant === 0 && messageToSendDossier}
        </>
      )}

      {dossier.statut.code === StatutDossierGarantie.EnEtude && (
        <div>
          <div>
            <div className={styles.feedbackTitle}>
              <IconV2 name={"file"} color="neutral" size={24} />
              <TextV2 color="secondary" textVariant="body-small">
                Justificatifs ajoutés : {`${nbSent}/${nbPJTotal}`}
              </TextV2>
            </div>
            <div className={styles.feedbackTitle}>
              <IconV2 name={"clock"} color="neutral" size={24} />
              <TextV2 color="secondary" textVariant="body-small">
                Vérification en cours
              </TextV2>
            </div>
          </div>
          <div className={styles.tileFeedback}>
            <div className={styles.tileFeedbackLeft}>
              <Avatar type="logo" size="S" variant="brand" />
              <TextV2 textVariant="body-small-em">
                Roole
                <TextV2 textVariant="body-small" as="p">
                  Votre dossier est actuellement entre les mains de nos conseillers.
                </TextV2>
              </TextV2>
            </div>
            <div className={styles.tileFeedbackRight}>
              <ButtonV2 variant="secondary-dark" size="small" onClick={handleNavigate}>
                Voir le dossier
              </ButtonV2>
            </div>
          </div>
        </div>
      )}

      {dossier.statut.code === StatutDossierGarantie.Incomplet && (
        <div>
          <div
            className={
              nbPJRestantTraitees !== 0 || nbPJNonConforme !== 0 ? styles.feedbackTitleDiv : ""
            }
          >
            <div className={styles.feedbackAlert}>
              {(nbPJRestantTraitees !== 0 || nbPJNonConforme !== 0) && (
                <IconV2 name={"alert-triangle"} color="negative" size={24} />
              )}
              <div>
                <ul
                  className={classNames(
                    styles.list,
                    (nbPJRestantTraitees === 0 || nbPJNonConforme === 0) && styles.noBullet,
                  )}
                >
                  {nbPJNonConforme > 0 && (
                    <li className={nbPJRestantTraitees > 0 ? "" : styles.noBullet}>
                      <TextV2 textVariant="body-small">{`Justificatif${
                        nbPJNonConforme > 1 ? "s" : ""
                      } non conforme${nbPJNonConforme > 1 ? "s" : ""} : `}</TextV2>
                      <TextV2 textVariant="body-small-em">{nbPJNonConforme}</TextV2>
                    </li>
                  )}
                  {nbPJRestantTraitees > 0 && (
                    <li className={nbPJNonConforme > 0 ? "" : styles.noBullet}>
                      <TextV2 textVariant="body-small">{`Justificatif${
                        nbPJRestantTraitees > 1 ? "s" : ""
                      } à ajouter : `}</TextV2>
                      <TextV2 textVariant="body-small-em">{nbPJRestantTraitees}</TextV2>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.tileFeedback}>
            <div className={styles.tileFeedbackLeft}>
              <div>
                <TextV2 textVariant="body-medium">Complétez votre dossier</TextV2>
                <TextV2 textVariant="body-small" as="p">
                  Vous y êtes presque, vous devez nous renvoyer des pièces justificatives.
                </TextV2>
              </div>
            </div>
            <div className={styles.tileFeedbackRight}>
              <ButtonV2 variant="primary-brand" size="small" onClick={handleNavigate}>
                Compléter
              </ButtonV2>
            </div>
          </div>
          {nbPJRestantTraitees === 0 && nbPJNonConforme === 0 && messageToSendDossier}
        </div>
      )}

      {dossier.statut.code === StatutDossierGarantie.Accorde && (
        <div>
          <div className={styles.feedbackTitleDiv}>
            <div>
              <TextV2 textVariant="body-small" color="secondary">
                Montant
              </TextV2>
              <div className={styles.feedbackTitle}>
                <IconV2 name={"payment"} color="positive" size={24} />
                <TextV2 textVariant="body-medium-em">
                  {formatCurrency(dossier.montantARegler)}
                </TextV2>
              </div>
            </div>
            <div>
              <TextV2 textVariant="body-small" color="secondary">
                Mode de remboursement
              </TextV2>
              <TextV2 textVariant="body-medium-em" as="div">
                {modeReglement(dossier.modeReglement.code)}
              </TextV2>
            </div>
          </div>
          <div className={styles.tileFeedback}>
            <div className={styles.tileFeedbackLeft}>
              <Avatar type="logo" size="S" variant="brand" />
              <TextV2 textVariant="body-small-em">
                Roole
                <TextV2 textVariant="body-small" as="p">
                  {dossier.etatReglement?.code === EtatReglementDossierGarantie.Regle
                    ? "C'est tout bon, nous avons procédé au remboursement !"
                    : "Nous allons procéder au remboursement dans les prochains jours."}
                </TextV2>
              </TextV2>
            </div>
            <div className={styles.tileFeedbackRight}>
              <ButtonV2 variant="secondary-dark" size="small" onClick={handleNavigate}>
                Voir le dossier
              </ButtonV2>
            </div>
          </div>
        </div>
      )}

      {dossier.statut.code === StatutDossierGarantie.Refuse && (
        <div className={styles.tileFeedback}>
          <div className={styles.tileFeedbackLeft}>
            <IconV2 name={"shield-off"} size={24} />
            <div>
              <TextV2 textVariant="body-small-em">Motif du refus</TextV2>
              <TextV2 textVariant="body-small" as="p">
                {dossier.motifRefus.nom}
              </TextV2>
            </div>
          </div>
          <div className={styles.tileFeedbackRight}>
            <ButtonV2 variant="secondary-dark" size="small" onClick={handleNavigate}>
              Voir le dossier
            </ButtonV2>
          </div>
        </div>
      )}
    </div>
  );
};
