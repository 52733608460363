import { FC, useEffect, useState } from "react";
import { matchPath, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Text } from "@roole/components-library";
import {
  contactUsStepContextStateInitValue,
  contactUsStepState,
} from "context/contactUsState/atom";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { monContratState } from "context/currentContratState/atom";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { CallCard } from "components/molecules/CallCard/CallCard";
import { useMatchMedia } from "hooks/useMatchMedia";
import { Stepper } from "components/atoms/Stepper/Stepper";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";
import { Button } from "components/atoms/Button/Button";
import { ReactComponent as IconMessenger } from "images/icons/messenger.svg";
import { RoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";
import { useBlocker } from "hooks/useBlocker";

import cssVariables from "styles/variables.module.scss";
import styles from "./Page.module.scss";
import { useHealthCheckContext } from "HealthCheckProvider";
import { HealthCheckGroups } from "SignalRModels";

export const Page: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const monContrat = useRecoilValue(monContratState);
  const { sendToDataLayer } = useTracking();
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const navigate = useNavigate();
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const [disableGroup, setDisableGroup] = useState(false);
  const { getHealthCheckByGroupName } = useHealthCheckContext();

  useEffect(() => {
    setDisableGroup(getHealthCheckByGroupName(HealthCheckGroups.Contact));
  }, [getHealthCheckByGroupName]);

  const headerTitle = "Contactez-nous";

  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: headerTitle,
      onCloseCallback: handleClick,
      disableLogoLink: true,
      closeButtonId: "contactez-nous-croix-quitter",
    });
  }, []);

  const handleClick = () => {
    navigate(RoutePaths.CONTRAT_HOME);
  };

  const redirectToMessenger = () => {
    window.location.replace(
      "https://www.messenger.com/t/283489405024532/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792",
    );
  };

  const SocialChildren = [
    <div key="Question">
      <span className={styles.SocialBlock__content}>
        <Text as="h3" textVariant="subheading-em" className={styles.contactUs__SocialsTitle}>
          Une question ?
        </Text>
        <Text as="h4" className={styles.ContactUs__SmallerHeader}>
          Téléphone
        </Text>
        <p>
          <b>24h/24, 7j/7</b> pour vous porter assistance <b>en cas d’urgence.</b> Pour toute autre
          demande, nous sommes joignables du <b>lundi au vendredi de 9h à 18h.</b>
        </p>
        <p className={styles.ContactUs__Tips}>Privilégiez les horaires 12h-14h</p>
      </span>
      <div className={styles.SocialBlock__callCardContainer}>
        <CallCard
          className={styles.SocialBlock__callCardButton}
          variant={isDesktop ? "secondary" : "outlined-dark"}
          sendToDataLayer={() => sendToDataLayer("contactez-nous-bouton-appel")}
          phoneIcon
          text={formatPhoneNumber(monContrat?.numeroTelephoneContact)}
          onClick={() =>
            !isDesktop &&
            window.location.replace(`tel:${formatPhoneNumber(monContrat?.numeroTelephoneContact)}`)
          }
        />
        {isDesktop && <>Appel gratuit</>}
      </div>
    </div>,
    <div key="Facebook">
      <Text as="h4" className={styles.ContactUs__SmallerHeader}>
        Facebook
      </Text>
      <p>
        Précisez votre n° de membre ou votre immatriculation dans vos messages privés, nous
        répondons rapidement !
      </p>
      <div className={styles.SocialBlock__callCardContainer}>
        <Button
          className={styles.SocialBlock__FacebookButton}
          icon={<IconMessenger className={styles.SocialBlock__FacebookIcon} />}
          size="small"
          variant="secondary"
          onClick={() => {
            sendToDataLayer("contactez-nous-bouton-messenger");
            redirectToMessenger();
          }}
        >
          Envoyer un message
        </Button>
      </div>
    </div>,
    <div key="Mail">
      <Text as="h4" className={styles.ContactUs__SmallerHeader}>
        Courrier
      </Text>
      <p>
        Écrivez-nous à l’adresse suivante : Roole, 7 place René Clair, 92100 Boulogne-Billancourt.
      </p>
    </div>,
  ];

  const steps = ["Choix du motif", "Moyen de contact", "Détails de votre demande", "Confirmation"];

  useBlocker((tx) => {
    if (!matchPath(`${RoutePaths.CONTACT}/:state`, tx.location.pathname)) {
      if (
        window.confirm(
          "Vous allez quitter le processus et aucune information déjà saisie ne sera enregistrée. " +
            "\n Etes-vous sûr de vouloir quitter cette page ?",
        )
      ) {
        setContactUsStepContextState(contactUsStepContextStateInitValue);
        tx.retry();
      }
    } else {
      tx.retry();
    }
  }, contactUsStepContextState.activeStep < steps.length);

  return disableGroup ? (
    <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
  ) : (
    <>
      <Stepper steps={steps} activeStep={contactUsStepContextState.activeStep} />
      <div className={styles.contactUsFormWrapper}>
        <div className={styles.ContactUs__Layout}>
          {!!monContrat && !!contactUsStepContextState ? (
            <Outlet />
          ) : (
            <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
          )}
        </div>
        {isDesktop && (
          <aside>
            <SocialBlock className={styles.SocialBlock}>{SocialChildren}</SocialBlock>
          </aside>
        )}
      </div>
    </>
  );
};
