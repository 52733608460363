import { ButtonV2Props } from "@roole/components-library";

export type ButtonGroupActions = {
  buttonPrimary?: ButtonV2Props;
  buttonSecondary?: ButtonV2Props;
};

export const DATE_STEP = 0.33333;
export const EVENT_STEP = 0.66666;
export const DETAIL_STEP = 0.99999;
export const GARANTIES_STEP = 1.99999;
export const PJS_STEP = 2.99999;
export const COORDONNEES_BANCAIRES_STEP = 3.99999;
export const RECAP_STEP = 4.99999;
export const CONFIRMATION_STEP = 5.99999;
