import { Box } from "components/atoms/Box/Box";
import { FC, useState } from "react";
import { Text } from "@roole/components-library";
import { MonContrat } from "domain/api-schemas";

import styles from "./AttestationCard.module.scss";

import { PeriodesType, getPeriodesAbonnement } from "domain/service/Contract/useMoncontrat";
import DownloadDocs from "./DownloadDocs";
import { Button } from "components/atoms/Button/Button";
import { VoletLateral } from "components/atoms/VoletLateral/VoletLateral";
import { ReactComponent as InfoIcon } from "images/icons/information.svg";

const ARGOS = "FI.7";
const FACTURE = "ZI.6";

const AttestationCard: FC<{
  contract: MonContrat | null;
  isArgosDiplayed: boolean | undefined;
}> = ({ contract, isArgosDiplayed }) => {
  const periodes = contract ? getPeriodesAbonnement(contract) : [];
  const [isOpen, setIsOpen] = useState(false);
  const changeStateDrawer = () => {
    setIsOpen(!isOpen);
  };
  const nbTPeriodesToDisplay = isArgosDiplayed ? 4 : 5;

  return (
    <Box>
      {isArgosDiplayed && (
        <>
          <Text textVariant="heading-6" as="h2" className={styles.cardTitle}>
            Attestation
          </Text>
          <DownloadDocs
            contract={contract}
            title="Attestation ARGOS"
            codeCourier={ARGOS}
            filename="attestation-argos"
            trackingId="mon-contrat-argos"
          />
        </>
      )}

      <Text textVariant="heading-6" as="h2" className={styles.cardTitle}>
        Avis d'échéance
      </Text>

      {periodes.length > 0 ? (
        <>
          {periodes.slice(0, nbTPeriodesToDisplay).map((p: PeriodesType) => (
            <DownloadDocs
              key={p.ligneContrat}
              contract={contract}
              title={`Période ${p.periode}`}
              codeCourier={FACTURE}
              numeroLigneContrat={p.ligneContrat}
              filename={`periode-${p.periode}`}
              trackingId="mon-contrat-echeance"
            />
          ))}
          {periodes.length > nbTPeriodesToDisplay && (
            <div className={styles.buttonCenter}>
              <Button size="small" variant="outlined-light" onClick={changeStateDrawer}>
                Voir plus
              </Button>
            </div>
          )}
          {isOpen && (
            <VoletLateral title="Avis d'échéance" onClose={changeStateDrawer}>
              {periodes.map((p: PeriodesType) => (
                <DownloadDocs
                  key={p.ligneContrat}
                  contract={contract}
                  title={`Période ${p.periode}`}
                  codeCourier={FACTURE}
                  numeroLigneContrat={p.ligneContrat}
                  filename={`periode-${p.periode}`}
                  trackingId="mon-contrat-echeance"
                />
              ))}
            </VoletLateral>
          )}
        </>
      ) : (
        <div className={styles.infoMessage}>
          <InfoIcon className={styles.infoMessageIcon} />
          <Text textVariant="body" as="p" className={styles.infoMessageText}>
            Aucun avis d’échéance de disponible lors de la première année de votre abonnement.
          </Text>
        </div>
      )}
    </Box>
  );
};

export default AttestationCard;
