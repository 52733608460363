import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  contactUsStepContextStateInitValue,
  contactUsStepState,
} from "context/contactUsState/atom";
import { userListContratsState } from "context/contratsState/atom";
import { monContratState } from "context/currentContratState/atom";
import { ContactCreateActivityData } from "domain/api-schemas";
import { Loader } from "@roole/components-library";
import { RoutePaths } from "routes/routePaths";
import { communicationApi } from "domain/api/Communication/communicationApi";

import styles from "./ContactConfirmationStep.module.scss";

export const ContactConfirmationStep: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const userListContratsContextState = useRecoilValue(userListContratsState);
  const monContratContextState = useRecoilValue(monContratState);
  const navigate = useNavigate();

  useEffect(() => {
    if (contactUsStepContextState.subject === null) {
      navigate(RoutePaths.CONTACT_REASON);
    }
    async function postContactUs() {
      const data: ContactCreateActivityData = {
        membreId: monContratContextState?.membre.id,
        hasManyContrat: userListContratsContextState?.length > 1,
        immatriculation: monContratContextState?.vehicule?.immatriculation || "",
        message: contactUsStepContextState.messageValue,
        numeroContrat: monContratContextState?.identifiantContrat,
        motifId: contactUsStepContextState.reason?.id || 0,
        canalId: contactUsStepContextState.contactType,
        telephone: contactUsStepContextState.telephoneNumber || null,
        creneauHoraire:
          contactUsStepContextState.callBackDate && contactUsStepContextState.callBackPeriod
            ? `${contactUsStepContextState.callBackDate}-${contactUsStepContextState.callBackPeriod}`
            : null,
      };

      setContactUsStepContextState({ ...contactUsStepContextStateInitValue, activeStep: 4 });
      try {
        const status = await communicationApi.postContactUs(data);
        status === 204
          ? navigate(RoutePaths.CONTACT_SUCCESS)
          : navigate(RoutePaths.CONTACT_FAILURE);
      } catch (err: any) {
        console.error(err);
        navigate(RoutePaths.CONTACT_FAILURE);
      }
    }
    postContactUs();
  }, []);

  return <Loader className={styles.contactConfirmationStep__Loader} />;
};
