import { Box } from "components/atoms/Box/Box";
import { FC } from "react";
import { Text } from "@roole/components-library";
import { EtatContrat, MonContrat } from "domain/api-schemas";
import { Button } from "components/atoms/Button/Button";
import styles from "./InfoCard.module.scss";
import Badge from "components/atoms/Badge/Badge";
import { formatDate } from "utils/formatDate";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { formatCurrency } from "utils/formatCurrency";
import { capitalizeFirstLetter } from "utils/CapitalizeFirstLetter";
import { useTracking } from "hooks/useTracking";
import { getJourRestantPeriodeReconduction } from "domain/service/Contract/useReconduction";
import { ModePaiement, getNomPack } from "domain/service/Contract/useMoncontrat";
import { ReactComponent as InfoIcon } from "images/icons/information.svg";

const InfoCard: FC<{
  contract: MonContrat | null;
  isTacite: boolean;
  isAnneeSup: boolean;
}> = ({ contract, isTacite, isAnneeSup }) => {
  const navigate = useNavigate();
  const nomPack = getNomPack(contract as MonContrat);
  const { sendToDataLayer } = useTracking();
  const LabelPaiment = {
    [ModePaiement.Iban]: "prélèvement mensuel",
    [ModePaiement.Chq]: "chèque",
    [ModePaiement.Cb]: "carte bancaire",
  };

  if (contract === null) {
    return <></>;
  }

  const joursRestant = getJourRestantPeriodeReconduction(contract);

  return (
    <Box>
      <div className={styles.ContractInfo__cardBox}>
        <div>
          <div className={styles.ContractInfo__cardTitleWrapper}>
            <Text textVariant="heading-6" as="h2" className={styles.ContractInfo__cardTitle}>
              Pack {capitalizeFirstLetter(nomPack)}
            </Text>
            <Badge variant={isTacite || joursRestant > 0 ? "success" : "danger"}>
              {isTacite || joursRestant > 0 ? "Actif" : "Expiré"}
            </Badge>
          </div>
          {!isTacite ||
          !isAnneeSup ||
          (isTacite && contract?.etatContrat === EtatContrat.Resilie) ? (
            <>
              <Text textVariant="body" as="p">
                Période de couverture
              </Text>
              <Text textVariant="body-em" as="p">
                du {formatDate(contract?.dateDebut)} au {formatDate(contract?.dateFin)}
              </Text>
            </>
          ) : (
            <>
              <Text textVariant="body" as="p">
                Début de couverture
              </Text>
              <Text textVariant="body-em" as="p">
                {formatDate(contract?.dateSouscription)}
              </Text>
            </>
          )}
          {isAnneeSup && contract?.montantCotisation && (
            <>
              <Text textVariant="body" as="p">
                Cotisation
              </Text>
              <Text textVariant="body-em" as="p">
                {formatCurrency(contract?.montantCotisation)} / {isTacite ? "mois" : "an"}
              </Text>
            </>
          )}
          {!isTacite && isAnneeSup && (
            <Text textVariant="body-em" as="p">
              {contract?.dateReglement != null
                ? `réglée le ${formatDate(contract?.dateReglement, {
                    dateStyle: "long",
                  })} par ${LabelPaiment[contract?.modePaiement as ModePaiement]}`
                : ""}
            </Text>
          )}
        </div>
        {contract?.etatContrat !== EtatContrat.Resilie &&
          contract?.etatContrat !== EtatContrat.Expire &&
          contract?.etatContrat !== EtatContrat.Abandonne && (
            <div style={{ marginLeft: "8px" }}>
              <Button
                className={styles.ContractInfo__cardButton}
                id="mon-contrat-resilier-contrat"
                onClick={(e) => {
                  sendToDataLayer(e.currentTarget.id, true);
                  navigate(RoutePaths.RESILIATION);
                }}
                size="small"
                variant="outlined-light"
              >
                Résilier mon contrat
              </Button>
            </div>
          )}
      </div>
      {contract?.etatContrat === EtatContrat.Resilie && (
        <div className={styles.infoMessage}>
          <InfoIcon className={styles.infoMessageIcon} />
          <div>
            <Text textVariant="body-em" as="p">
              Votre demande de résiliation est prise en compte
            </Text>
            <Text textVariant="body" as="p" className={styles.infoMessageText}>
              Vous restez membre Roole et pouvez bénéficier de vos garanties et Bons Plans
              <b>{` jusqu'à la fin de votre contrat le ${formatDate(contract?.dateFin)}.`}</b>
            </Text>
          </div>
        </div>
      )}
    </Box>
  );
};

export default InfoCard;
