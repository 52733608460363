import { FC } from "react";

interface LabelProps {
  title: string;
  value: string;
  style: string;
  trackingValue?: string;
}

export const Label: FC<LabelProps> = ({ title, value, style, trackingValue }) => (
  <p>
    {title} :{" "}
    {trackingValue ? (
      <strong className={style} data-selector={trackingValue}>
        {value}
      </strong>
    ) : (
      <strong className={style}>{value}</strong>
    )}
  </p>
);
