import { WizardActionType } from "components/molecules/Wizard/WizardAction";
import { useWizard } from "components/molecules/Wizard/WizardContext";
import { hostedTokenizationIdState } from "context/hostedTokenizationIdState/atom";
import { hostedTokenizationUrlState } from "context/hostedTokenizationUrlState/atom";
import { paiementCbApi } from "domain/api/PaiementCb/paiementCbApi";
import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

export const usePaiement = () => {
  const [hostedTokenizationUrl, setHostedTokenizationUrl] = useRecoilState(
    hostedTokenizationUrlState,
  );
  const [, setHostedTokenizationId] = useRecoilState(hostedTokenizationIdState);
  const wizard = useWizard();

  const showIframe = (dossierId: string) => {
    paiementCbApi.getHostedTokenizationUrl(dossierId).then((result: string) => {
      setHostedTokenizationUrl(result);
    });
  };

  const tokenizer = useMemo(() => {
    if (hostedTokenizationUrl !== "") {
      return new window.Tokenizer(hostedTokenizationUrl, "div-hosted-tokenization", {
        hideCardholderName: false,
      });
    }
  }, [hostedTokenizationUrl]);

  const submitFormTokenization = useCallback(
    (dossierId: string | undefined) => {
      tokenizer.submitTokenization().then((resultTokenization: any) => {
        tokenizer.destroy();
        if (resultTokenization.success) {
          setHostedTokenizationId(resultTokenization.hostedTokenizationId);
        } else {
          wizard.dispatch({
            type: WizardActionType.SUBMIT_STEP_FAILED,
            error: "Erreur, veuillez réessayer",
            errorNextLabel: "Relancer le paiement",
          });
          if (dossierId) {
            showIframe(dossierId);
          }
        }
      });
    },
    [tokenizer],
  );

  return {
    tokenizer,
    showIframe,
    submitFormTokenization,
  };
};
