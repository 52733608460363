import { addNewAccessTokenInterceptor } from "clientProvider/axiosConfig";
import { membreApi } from "domain/api/Membre/membreApi";

export const useSelfcareRequest = () => {
  const setToken = (id: string, scope: string) => {
    if (id) {
      membreApi.checkDossierIdSelfcareRequest({ id: id, type: scope }).then(async (result: any) => {
        await addNewAccessTokenInterceptor(result.token.accessToken);
      });
    }
  };

  return {
    setToken,
  };
};
