import React, { ChangeEventHandler } from "react";
import InputMask from "react-input-mask";
import styles from "./Page.module.scss";

interface InputFieldProps {
  value: string;
  error: any;
  mask: string | (string | RegExp)[];
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const InputField: React.FC<InputFieldProps> = ({ value, mask, onChange, error, placeholder }) => (
  <InputMask
    mask={mask}
    value={value.toUpperCase()}
    onChange={onChange}
    className={error && styles.inputfieldError}
    maskPlaceholder={""}
    placeholder={placeholder || ""}
    required
  />
);

export default InputField;
