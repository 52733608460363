import { formatCurrency } from "utils/formatCurrency";
import { Garantie, PlafondIndemniteFinanciere } from "domain/api-schemas";

import styleVoletLateral from "components/atoms/VoletLateral/VoletLateral.module.scss";

interface EvenementGarantie {
  plafond?: number;
  id?: string;
  nom?: string | null;
}

const TAG_PPERegex = /%plafond_par_evt%/gi;
const TAG_EPGRegex = /%liste_evt%/gi;
const TAG_IFRegex = /%tableau_indem%/gi;
const TAG_PFRegex = /%plafond%/gi;
const CODE_VR = "VR";

const descriptionTransforms = {
  TAG_PPE: (evenements: EvenementGarantie[], description: string, code: string) =>
    description.replace(
      TAG_PPERegex,
      `<ul>
    ${evenements
      ?.map(
        ({ plafond, nom }: EvenementGarantie) => `<li>${
          code === CODE_VR ? `${plafond} jour(s)` : formatCurrency(plafond || 0)
        }
      en cas de ${nom?.toLowerCase()}</li>`,
      )
      .join("")}</ul>`,
    ),
  TAG_EPG: (evenements: EvenementGarantie[], description: string) =>
    description.replace(
      TAG_EPGRegex,
      `<ul>
    ${evenements
      ?.map(({ nom }: EvenementGarantie) => `<li>${nom?.toLowerCase()}</li>`)
      .join("")}</ul>`,
    ),
  TAG_IF: (listeIndemnite: PlafondIndemniteFinanciere[], description: string) =>
    description.replace(
      TAG_IFRegex,
      listeIndemnite && listeIndemnite.length > 0
        ? `<div class="${styleVoletLateral.tableIF}">
    <span class="${styleVoletLateral.bold}">Ancienneté de votre contrat</span>
    <span class="${styleVoletLateral.bold}">Montant de l'indemnité</span>
    ${(listeIndemnite || [])
      .map(
        ({ ageContrat, plafond }: PlafondIndemniteFinanciere) => `<span>Année ${
          (ageContrat || 0) >= 5 ? `${ageContrat} et +` : ageContrat
        } </span>
    <span class="${styleVoletLateral.bold}">${formatCurrency(plafond ?? 0)}</span>`,
      )
      .join("")}
  </div>`
        : "",
    ),
  TAG_PF: (plafondMaximum: number, description: string) =>
    description.replace(TAG_PFRegex, plafondMaximum?.toString()),
};

const formatSelectedGarantie = (
  description: string,
  garantie: Garantie,
  listeIndemnite?: Array<PlafondIndemniteFinanciere>,
): string => {
  let newDescription = description;
  const { evenements = [], code, plafondMaximum }: Garantie = garantie || {};
  newDescription = descriptionTransforms.TAG_PPE(evenements || [], newDescription, code);
  newDescription = descriptionTransforms.TAG_EPG(evenements || [], newDescription);
  newDescription = descriptionTransforms.TAG_PF(plafondMaximum, newDescription);
  newDescription = descriptionTransforms.TAG_IF(listeIndemnite || [], newDescription);

  return newDescription;
};

export { formatSelectedGarantie };
