import { FC, CSSProperties, HTMLAttributes } from "react";
import { v4 as uuidv4 } from "uuid";

import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";

import cssVariables from "styles/variables.module.scss";
import styles from "./Breadcrumb.module.scss";

type InternalBreadcrumbProps = {
  steps: Array<string>;
  className?: string;
  percent: number;
  index: number;
};

const BreadcrumbDesktop: FC<InternalBreadcrumbProps> = ({ steps, percent, index, className }) => (
  <ul
    className={classNames(styles.breadcrumb, className)}
    style={{ "--num-steps": steps.length, "--percent": percent } as CSSProperties}
  >
    {steps.map((step, i) => (
      <li
        key={uuidv4()}
        className={i <= index ? styles.completed : undefined}
        style={{ gridColumnStart: i + 1 }}
      >
        {step}
      </li>
    ))}
  </ul>
);

const BreadcrumbMobile: FC<InternalBreadcrumbProps> = ({ steps, index, className }) => (
  <div className={classNames(styles.mobileStep, className)}>
    <span>
      Étape {index + 1}/ {steps.length}
    </span>
    <h2>{`- ${steps[index]}`}</h2>
  </div>
);

type BreadcrumbProps = {
  steps: Array<string>;
  className?: string;
  percent?: number;
} & HTMLAttributes<HTMLDivElement>;

export const Breadcrumb: FC<BreadcrumbProps> = ({ steps, className, percent = 0 }) => {
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isTablet = useMatchMedia(cssVariables.breakpointTablet);
  const index = Math.floor((steps.length - 1) * (percent / 100));
  return isMobile || isTablet
    ? BreadcrumbMobile({ className, steps, percent, index })
    : BreadcrumbDesktop({ className, steps, percent, index });
};
