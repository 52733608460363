import type { ReactNode, FC } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LinkIcon } from "images/icons/link.svg";
import { classNames } from "utils/classNames";

import styles from "./Link.module.scss";

type LinkPropTypes = {
  children: ReactNode;
  className?: string;
  isExternalUrl?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent) => void;
  id?: string;
};

const Link: FC<LinkPropTypes> = ({
  children,
  className,
  isExternalUrl,
  onClick = () => undefined,
  to,
  id,
  ...props
}: LinkPropTypes) => {
  const getLinkItem = (subChildren: ReactNode) =>
    !isExternalUrl ? (
      <NavLink
        to={to}
        className={classNames(styles.Link, className)}
        onClick={onClick}
        id={id}
        {...props}
      >
        {subChildren}
      </NavLink>
    ) : (
      <a
        href={to}
        className={classNames(styles.Link, className)}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        id={id}
        {...props}
      >
        {subChildren}
      </a>
    );

  return getLinkItem(
    <>
      <LinkIcon className={styles.Link__icon} />
      {children}
    </>,
  );
};

export default Link;
