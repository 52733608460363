import { Modal, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { useEffect, useState } from "react";
import { useWizard } from "./WizardContext";
import styles from "./WizardErrorModal.module.scss";
import { WizardActionType } from "./WizardAction";

export const WizardErrorModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const { error, dispatch, errorNextLabel } = useWizard();

  const closeHandler = () => {
    setIsOpen(false);
    dispatch({ type: WizardActionType.RESET_ERROR });
  };

  const contactRoole = () => {
    window.location.href = `${process.env.REACT_APP_SITE_PUBLIC}/contact`;
  };

  useEffect(() => {
    if (error) {
      setIsOpen(true);
    }
  }, [error]);

  return (
    <Modal
      title="Oups, une erreur s’est produite"
      content={
        <div className={styles.wizardErrorModal__content}>
          <Text textVariant="body" as="p" dangerouslySetInnerHTML={{ __html: error }}></Text>
          <div className={styles.wizardErrorModal__buttons}>
            <Button
              variant={"outlined-light"}
              size="small"
              type="button"
              className="center-label"
              onClick={contactRoole}
            >
              Contacter Roole
            </Button>
            <Button
              variant="primary"
              size="small"
              type="button"
              className="center-label"
              onClick={closeHandler}
            >
              {errorNextLabel}
            </Button>
          </div>
        </div>
      }
      isOpen={isOpen}
      handleClose={closeHandler}
    />
  );
};
