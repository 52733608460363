import { FC, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Text, OverlayLoader } from "@roole/components-library";
import { Controller, useForm } from "react-hook-form";

import { HelpBlock } from "components/molecules/HelpBlock/HelpBlock";
import { TextInputContainer } from "components/molecules/TextInput/TextInputContainer";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { classNames } from "utils/classNames";
import { monContratState } from "context/currentContratState/atom";
import { userListContratsState } from "context/contratsState/atom";

import { getInactifContractsCount } from "utils/nbExpiredContract";

import { useNavigationBack } from "hooks/useNavigationBack";

import { useSignOut } from "hooks/useSignOut";

import InputField from "./InputField";
import NumberContractHelp from "./NumberContractHelp";
import { useAddContract } from "./useAddContract";

import styles from "./Page.module.scss";
import { useTracking } from "hooks/useTracking";

export const Page: FC = () => {
  const { errorMessage, loading, submit, isFatalError } = useAddContract();
  const { handleSubmit, formState, control } = useForm();
  const { isValid, isSubmitted } = formState || {};
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const monContrat = useRecoilValue(monContratState);
  const contracts = useRecoilValue(userListContratsState);
  const expiredContracts = getInactifContractsCount(contracts);
  const { signOut } = useSignOut();

  const errorRendering = () =>
    isSubmitted &&
    (!isValid || errorMessage) && <p className={styles.textfieldError}>{errorMessage}</p>;

  const { handleBack } = useNavigationBack();
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "ajout-contrat-logo",
      title: "Ajouter un contrat",
      displayFooter: false,
      onCloseCallback: () => {
        if (monContrat?.code === 14 || expiredContracts === contracts.length) {
          signOut();
        } else {
          handleBack();
        }
      },
      closeButtonId: "ajout-contrat-croix",
    });
  }, [contracts, expiredContracts, handleBack, monContrat, setLayoutState, signOut]);

  const HelpChildren = [
    <div key="NumberContract">
      <Text as="h2" textVariant="heading-4" className={styles.contactUs__SocialsTitle}>
        Aide
      </Text>
      <NumberContractHelp />
    </div>,
  ];

  const renderSocialBlock = () => (
    <aside className={styles.aside__margin}>
      <HelpBlock children={HelpChildren} />
    </aside>
  );

  return (
    <>
      {loading && <OverlayLoader />}
      <div className={styles.contactUsFormWrapper}>
        <div className={styles.ContactUs__Layout}>
          {!isFatalError && (
            <Card className={classNames(styles.content)}>
              <Text as="h2" textVariant="heading-4">
                Vos informations de contrat
              </Text>
              <form onSubmit={handleSubmit(submit)}>
                <TextInputContainer
                  className={classNames(
                    styles.inputsContainer,
                    styles["inputsContainer--marginTop"],
                  )}
                >
                  <Controller
                    control={control}
                    name="identifiantContrat"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        value={value}
                        onChange={onChange}
                        error={errorMessage.includes("contrat") ? errorMessage : null}
                        mask="**-99999999"
                      />
                    )}
                  />
                  <label>Numéro de contrat ou de commande*</label>
                </TextInputContainer>
                <TextInputContainer
                  className={classNames(
                    styles.inputsContainer,
                    styles["inputsContainer--marginBottom"],
                  )}
                >
                  <Controller
                    control={control}
                    name="immatriculation"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        value={value}
                        onChange={onChange}
                        error={errorMessage.includes("immatriculation") ? errorMessage : null}
                        placeholder={"Ex: AA123BB (sans espace)"}
                        mask={"***********"}
                      />
                    )}
                  />
                  <label>Immatriculation du véhicule*</label>
                </TextInputContainer>
                {errorRendering()}
                <div
                  className={classNames(
                    styles.navigationButtons,
                    errorMessage !== "" && styles["navigationButtons--error"],
                  )}
                >
                  <NavigationButtons
                    prevProps={{
                      onClick: () => {
                        if (monContrat?.code === 14 || expiredContracts === contracts.length) {
                          signOut();
                        } else {
                          sendToDataLayer("ajout-contrat-btn-annuler");
                          handleBack();
                        }
                      },
                      type: "button",
                      text: "Annuler",
                    }}
                    nextProps={{
                      onClick: () => {},
                      type: "submit",
                      text: "Valider",
                    }}
                  />
                </div>
              </form>
            </Card>
          )}
          {renderSocialBlock()}
        </div>
      </div>
    </>
  );
};
