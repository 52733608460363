import { FC } from "react";
import { IconV2, TextV2, Message, ButtonV2 } from "@roole/components-library";
import styles from "./EtudeContent.module.scss";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";
import { useAuthenticationContext } from "AuthenticationProvider";
import { ReactComponent as IconDossierEtude } from "images/icons/dossier-etude.svg";

export const EtudeContent: FC = () => {
  const { isJwtAuthenticated } = useAuthenticationContext();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <IconDossierEtude />
      <TextV2 textVariant="heading-2" extraCss={{ marginTop: 8, marginBottom: 8 }}>
        Merci de patienter
      </TextV2>
      <TextV2 textVariant="body-medium" extraCss={{ textAlign: "center" }}>
        Votre dossier est entre les mains de nos conseillers.
      </TextV2>
      <TextV2 textVariant="body-medium" extraCss={{ textAlign: "center" }}>
        Si des modifications sont nécessaires pour le bon traitement de votre dossier, nous
        reviendrons vers vous dans les plus brefs délais.
      </TextV2>
      <div className={styles.message}>
        <Message
          icon="info"
          type="information"
          message={
            <TextV2 textVariant="body-small">
              Cette opération peut prendre quelques jours, nous faisons au plus vite.
            </TextV2>
          }
        />
      </div>
      <ButtonV2
        variant="secondary-dark"
        size="large"
        onClick={() => {
          sendCloseWebViewEvent({ hasError: false });
          if (!isJwtAuthenticated) {
            navigate(RoutePaths.SINISTRES);
          }
        }}
      >
        Retour à mes sinistres
      </ButtonV2>
    </div>
  );
};
