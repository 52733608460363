import axiosClient from "clientProvider/axiosConfig";
import {
  BonPlanCategory,
  BonPlanCustomLink,
  BonPlanHomepage,
  BonPlanMobilite,
} from "domain/api-schemas";

export enum TransactionStatus {
  valide = 0,
  annulee = 1,
  incorrecte = 2,
}

export const bonsPlansMobilitesApi = {
  fetchBonPlans: async (idAdhesion: string) => {
    const result = await axiosClient.get<BonPlanMobilite[]>(`BonplanMobilite/${idAdhesion}`);
    return result.data;
  },

  fetchBonPlanById: async (idAdhesion: string | null, idBonplan: string | null) => {
    if (!idAdhesion || !idBonplan) {
      return null;
    }
    const result = await axiosClient.get<BonPlanMobilite>(
      `BonplanMobilite/${idBonplan}/${idAdhesion}`,
    );
    return result.data;
  },

  fetchBonPlanByCategoryId: async (idAdhesion: string | null, idCategory: string | null) => {
    if (!idAdhesion || !idCategory) {
      return null;
    }
    const result = await axiosClient.get<BonPlanMobilite[]>(
      `BonplanMobilite/byCategory/${idCategory}/${idAdhesion}`,
    );
    return result.data;
  },

  fetchCategories: async (idAdhesion: string | null): Promise<BonPlanCategory[] | null> => {
    if (!idAdhesion) {
      return null;
    }
    const result = await axiosClient.get<BonPlanCategory[]>(
      `BonplanMobilite/categories/${idAdhesion}`,
    );
    return result.data;
  },

  fetchHomepage: async (idAdhesion: string | null) => {
    if (!idAdhesion) {
      return null;
    }
    const result = await axiosClient.get<BonPlanHomepage>(`BonplanMobilite/homepage/${idAdhesion}`);
    return result.data;
  },

  generateCustomLink: async (id: string, idAdhesion: string | null) => {
    if (!idAdhesion || !id) {
      return null;
    }
    const result = await axiosClient.get<BonPlanCustomLink>(
      `BonplanMobilite/GenerateCustomLink/${id}/${idAdhesion}`,
    );
    return result.data;
  },
  getTransactionStatus: async (idAdhesion: string, hostedCheckoutId: string) => {
    const result = await axiosClient.get<TransactionStatus>(
      `BonplanMobilite/VerifyTransactionStatus/${idAdhesion}/${hostedCheckoutId}`,
    );
    return result.data;
  },
};
