import {
  StatutDossierGarantie,
  EtatReglementDossierGarantie,
  DossierGarantie,
} from "domain/api-schemas";
import { StepperDropdownProps, StepperProps } from "@roole/components-library";
import { formatDate } from "utils/formatDate";

export const getSteps = (dossierData: DossierGarantie): StepperProps => {
  const descStep2 = dossierData.dateDemandeEtude
    ? `Vous l'avez envoyé le ${formatDate(dossierData.dateDemandeEtude)}`
    : "Vous ajoutez toutes les pièces justificatives obligatoires";
  const descStep3 = dossierData.dateDerniereAction
    ? `Nous avons vérifié le ${formatDate(dossierData.dateDerniereAction)}`
    : "Nous vérifions les pièces justificatives dans les meilleurs délais";
  const descStep4 = dossierData.dateReglement
    ? `Le ${formatDate(dossierData.dateReglement)}`
    : `Le ${formatDate(dossierData.dateDerniereAction)}`;

  switch (dossierData.statut.code) {
    case StatutDossierGarantie.EnCours:
    case StatutDossierGarantie.Incomplet:
      return {
        steps: [
          {
            badge: "check",
            label: "Déclaration du sinistre",
            description: `Vous l'avez créé le ${formatDate(dossierData.dateOuverture)}`,
            type: "filled",
          },
          {
            badge: 2,
            label: "Complétion du dossier",
            description: "Vous ajoutez toutes les pièces justificatives obligatoires",
            type: "active",
          },
          {
            badge: 3,
            label: "Étude du dossier",
            description: "Nous vérifions les pièces justificatives dans les meilleurs délais",
            type: "default",
          },
          {
            badge: 4,
            label: "Conclusion du dossier",
            description: "Nous apportons la réponse à votre dossier",
            type: "default",
          },
        ],
      };
    case StatutDossierGarantie.Accorde:
      return {
        steps: [
          {
            badge: "check",
            label: "Déclaration du sinistre",
            description: `Vous l'avez créé le ${formatDate(dossierData.dateOuverture)}`,
            type: "filled",
          },
          {
            badge: "check",
            label: "Complétion du dossier",
            description: descStep2,
            type: "filled",
          },
          {
            badge: "check",
            label: "Étude du dossier",
            description: descStep3,
            type: "filled",
          },
          {
            badge: 4,
            label:
              dossierData.etatReglement.code === EtatReglementDossierGarantie.Regle
                ? "Remboursement validé"
                : "Conclusion du dossier",
            description: descStep4,
            type: "active",
          },
        ],
      };
    case StatutDossierGarantie.Refuse:
      return {
        steps: [
          {
            badge: "check",
            label: "Déclaration du sinistre",
            description: `Vous l'avez créé le ${formatDate(dossierData.dateOuverture)}`,
            type: "filled",
          },
          {
            badge: "check",
            label: "Complétion du dossier",
            description: descStep2,
            type: "filled",
          },
          {
            badge: "check",
            label: "Étude du dossier",
            description: descStep3,
            type: "filled",
          },
          {
            badge: "shield-off",
            label: "Prise en charge",
            description: `Refusé le ${formatDate(
              dossierData.dateDerniereAction || dossierData.dateModification,
            )}`,
            type: "active-sub",
          },
        ],
      };
    case StatutDossierGarantie.EnEtude:
      return {
        steps: [
          {
            badge: "check",
            label: "Déclaration du sinistre",
            description: `Vous l'avez créé le ${formatDate(dossierData.dateOuverture)}`,
            type: "filled",
          },
          {
            badge: "check",
            label: "Complétion du dossier",
            description: descStep2,
            type: "filled",
          },
          {
            badge: 3,
            label: "Étude du dossier",
            description: "Nous vérifions les pièces justificatives dans les meilleurs délais",
            type: "active",
          },
          {
            badge: 4,
            label: "Conclusion du dossier",
            description: "Nous apportons la réponse à votre dossier",
            type: "default",
          },
        ],
      };
    default:
      return {
        steps: [],
      };
  }
};

export const transformStepType = (steps: StepperProps["steps"]): StepperDropdownProps => {
  return {
    steps: steps.map((step) => ({
      ...step,
      type: step.type === "filled" || step.type === "active" ? "active" : "default",
    })),
  };
};

export const getDefaultValueStep = (dossierData: DossierGarantie): number => {
  switch (dossierData.statut.code) {
    case StatutDossierGarantie.EnCours:
      return 1;
    case StatutDossierGarantie.Incomplet:
      return 1;
    case StatutDossierGarantie.EnEtude:
      return 2;
    case StatutDossierGarantie.Refuse:
      return 3;
    case StatutDossierGarantie.Accorde:
      return 3;
    default:
      return 0;
  }
};
