import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dispatcherState } from "context/reconductionState/Atoms";
import { globalState } from "context/globalState/atom";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { Button } from "components/atoms/Button/Button";
import { RoutePaths } from "routes/routePaths";
import {
  eSignatureState,
  initialESignatureState,
} from "pages/Reconduction/Components/ESignature/eSignatureState";
import styles from "pages/Reconduction/Reconduction.module.scss";
import { useTracking } from "hooks/useTracking";

const ReconductionConfirmationCancel: FC = () => {
  const dispatcher = useRecoilValue(dispatcherState);
  const navigate = useNavigate();
  const setGlobalState = useSetRecoilState(globalState);
  const setSignatureState = useSetRecoilState(eSignatureState);

  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    setSignatureState((state) => ({ ...state, ...initialESignatureState }));
  }, [setSignatureState]);

  useEffect(() => {
    dispatcher?.cleanState();
    setGlobalState((state) => ({ ...state, redirectUrlExt: null }));
  }, [dispatcher, setGlobalState]);

  return (
    <EndPage title="Votre reconduction a été abandonnée">
      <p>
        Vous avez refusé de signer votre contrat de reconduction, votre contrat n'a donc pas été
        renouvelé et vous ne serez pas prélevé.
      </p>
      <p>Vous bénéficierez de vos garanties et avantages jusqu'à la fin de votre contrat actuel.</p>
      <p>
        Si vous souhaitez nous contacter, nous serons ravis de répondre à toutes vos questions au{" "}
        <strong>01&nbsp;46&nbsp;90&nbsp;37&nbsp;00</strong> (du lundi au vendredi, de 9h à 18h)
      </p>
      <Button
        variant="outlined-light"
        size="small"
        className={styles.Reconduction_RetourButton}
        onClick={() => {
          navigate(RoutePaths.CONTRAT, { replace: true });
          sendToDataLayer("reconduction_echec", true);
        }}
      >
        Retour à l'accueil
      </Button>
    </EndPage>
  );
};

export default ReconductionConfirmationCancel;
