import React, { FC } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Outlet } from "react-router-dom";

import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { TunnelHeader } from "components/molecules/Header/TunnelHeader";
import { Footer } from "components/molecules/Footer/Footer";
import { OverlayLoader } from "@roole/components-library";

import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { currentContratQuery } from "context/currentContratState/selector";
import { globalState } from "context/globalState/atom";
import { LoadableState } from "context/state.types";
import { tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { useSignOut } from "hooks/useSignOut";

import styles from "./TunnelLayout.module.scss";
import { useAuthenticationContext } from "AuthenticationProvider";

export const TunnelLayout: FC = () => {
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const { idAdhesion } = useRecoilValue(globalState);
  const { isJwtAuthenticated } = useAuthenticationContext();
  const layoutState = useRecoilValue(tunnelLayoutState);
  const { signOut } = useSignOut();
  const {
    isLoading,
    title,
    layoutId,
    closeButtonId,
    displayCloseButton = true,
    displayFooter,
    onCloseCallback,
    displayMobileCallBlock,
    disableLogoLink,
  } = layoutState;
  const loading = isLoading || monContrat.state === LoadableState.Loading;
  const handleCloseButtonClick = () =>
    displayCloseButton && typeof onCloseCallback !== "function" ? signOut() : onCloseCallback?.();
  return (
    <section className={styles.page}>
      {loading && <OverlayLoader />}

      <>
        {!isJwtAuthenticated && (
          <TunnelHeader
            id={layoutId}
            title={title}
            onCloseCallback={handleCloseButtonClick}
            closeButtonId={closeButtonId}
            displayClose={displayCloseButton && monContrat.state !== LoadableState.Has_Error}
            displayMobileCallBlock={displayMobileCallBlock}
            disableLogoLink={!idAdhesion || !!disableLogoLink}
            phoneNumber={formatPhoneNumber(monContrat.contents?.numeroTelephoneContact)}
            text={"Appelez-nous"}
          />
        )}
        {!loading && monContrat.state === LoadableState.Has_Error && (
          <ErrorBlock
            phoneNumber={monContrat?.contents.numeroTelephoneContact}
            variants={[ErrorBlockVariant.PhoneCall]}
          />
        )}
        {!loading && monContrat.state === LoadableState.Has_Value && <Outlet />}
        {displayFooter && !isJwtAuthenticated && (
          <Footer idAdhesion={idAdhesion || null} contrat={monContrat.contents} />
        )}
      </>
    </section>
  );
};
