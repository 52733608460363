import { Wizard } from "components/molecules/Wizard";
import { RenewalConfirmationStep } from "./RenewalConfirmationStep";
import { RenewalEsignStep } from "./RenewalEsignStep";
import { RenewalPaymentStep } from "./RenewalPaymentStep";
import { RenewalRoutePath } from "./RenewalRoutePath";
import { RenewalStatusStep } from "./RenewalStatusStep";
import { RenewalStepGuard } from "./RenewalStepGuard";
import { initRenewalWizard } from "./useRenewal";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TunnelHeader } from "components/molecules/Header/TunnelHeader";
import ErrorPage from "pages/Error/ErrorPage";
import { OverlayLoader } from "@roole/components-library";
import { useSelfcareRequest } from "hooks/useSelfcareRequest";
import {
  DossierStateType,
  checkDossierIdSelfcareRequest,
  useGetSelfcareRequest,
} from "domain/service/Member/useSelfcareRequest";

export const RenewalWizard = () => {
  const params = useParams();
  const [expiresIn, setExpiresIn] = useState<number | null | undefined>();
  const [dossierState, setDossierState] = useState<DossierStateType>();

  let interval: any = null;
  const { setToken } = useSelfcareRequest();
  const SCOPE = "reconduction";

  const { data: selfcareRequest, isLoading: loading } = useGetSelfcareRequest({
    id: params.id ?? "",
    type: SCOPE,
  });

  useEffect(() => {
    if (!params.id || loading) {
      return;
    }
    selfcareRequest &&
      checkDossierIdSelfcareRequest(selfcareRequest).then((result) => {
        setDossierState({
          code: result.code,
          message: result.displayMessage
            ? "Votre lien de reconduction a expiré, merci de nous contacter pour en obtenir un nouveau."
            : undefined,
          tokenExpiration: result.token,
        });
      });
  }, [params.id, loading]);

  useEffect(() => {
    dossierState?.tokenExpiration && setExpiresIn(dossierState?.tokenExpiration);
  }, [dossierState]);

  const callSetToken = () => {
    if (params.id) {
      setToken(params.id, SCOPE);
    }
  };

  useEffect(() => {
    if (interval == null && expiresIn) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        callSetToken();
      }, expiresIn * 1000);
    }

    return () => clearInterval(interval);
  }, [expiresIn]);

  return (
    <>
      {loading ? (
        <OverlayLoader />
      ) : (
        <>
          {dossierState?.tokenExpiration ? (
            <>
              <TunnelHeader
                title={""}
                disableLogoLink={true}
                phoneNumber={""}
                displayClose={false}
              />
              <Wizard initHandler={initRenewalWizard}>
                <RenewalStatusStep path={RenewalRoutePath.STATUS_STEP} />
                <RenewalEsignStep
                  path={RenewalRoutePath.ESIGN_STEP}
                  guard={RenewalStepGuard}
                  label="Signature de votre contrat"
                />
                <RenewalPaymentStep
                  path={RenewalRoutePath.PAYMENT_STEP}
                  guard={RenewalStepGuard}
                  label="Paiement de votre cotisation"
                />
                <RenewalConfirmationStep
                  path={RenewalRoutePath.CONFIRMATION_STEP}
                  label="Confirmation de souscription"
                />
              </Wizard>
            </>
          ) : (
            <ErrorPage errorMsg={dossierState?.message} errorNumber={dossierState?.code} />
          )}
        </>
      )}
    </>
  );
};
