export const useLocalStorage = () => {
  const setValue = (key: string, value: string) => {
    if (typeof window !== "undefined" && !window.localStorage.getItem(key)) {
      window.localStorage.setItem(key, value);
    }
  };

  const getValue = (key: string) => {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem(key);
    }
    return null;
  };

  const deleteValue = (key: string) => {
    if (typeof window !== "undefined") {
      return window.localStorage.removeItem(key);
    }
  };

  return { setValue, getValue, deleteValue };
};
