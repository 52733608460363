import { selector, selectorFamily } from "recoil";
import { globalState } from "./globalState/atom";
import { fetchToggle } from "domain/api/useFeatureToggle";
import { contractApi } from "domain/api/Contract/contractApi";

export const toggleFeatureQuery = selectorFamily({
  key: "toggleFeature",
  get: (toggleFeature) => async () => {
    if (!toggleFeature) {
      return false;
    }
    try {
      return await fetchToggle(toggleFeature.toString());
    } catch (error) {
      return false;
    }
  },
});

export const resiliationModifsQuery = selector({
  key: "ResilitationModif",
  get: async () => {
    try {
      return await contractApi.resiliationModifs();
    } catch (error) {
      return [];
    }
  },
});

const reconductionInfosQuery = selectorFamily({
  key: "reconductionContratInfo",
  get: (idAdhesion: string | null) => async () => {
    try {
      return await contractApi.fetchReconductionContrat(idAdhesion!);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
});

export const reconductionQuery = selector({
  key: "reconductionContrat",
  get: ({ get }) => get(reconductionInfosQuery(get(globalState).idAdhesion || null)),
});
