import { FC } from "react";
import { Routes as Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { privateRoutes, publicRoutes, throughAppRoutes } from "routes/AppRoute";
import { GlobalStyles, OverlayLoader } from "@roole/components-library";
import TokenProvider from "./clientProvider/TokenProvider";
import { ScrollToTop } from "utils/ScrollToTop";
import HealthCheckProvider from "HealthCheckProvider";
import SmartTribuneProvider from "SmartTribuneProvider";
import "./styles/index.scss";
import ZendeskProvider from "ZendeskProvider";
import { useAuthenticationContext } from "AuthenticationProvider";
import CookieBotProvider from "components/hoc/CookieBotProvider";
import GTMProvider from "GTMProvider";
import { RoutePaths } from "routes/routePaths";

const isPathInPrivateRoutes = (path: string): path is RoutePaths => {
  return Object.values(RoutePaths).includes(path as RoutePaths);
};

export const App: FC = () => {
  const { isJwtAuthenticated, isJwtLoading, isAuth0Loading, isAuth0Authenticated, user } =
    useAuthenticationContext();
  const location = useLocation();

  if (isAuth0Loading || isJwtLoading) {
    return <OverlayLoader className="mobile-loader" />;
  }

  let routes;
  if (isJwtAuthenticated) {
    routes = (
      <SmartTribuneProvider isEnabled={false}>
        <ZendeskProvider isEnabled={false}>
          <>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
              />
              <style>{`
          * {
            user-select: none !important;
            -ms-user-select: none !important;
            -moz-user-select: none !important;
            -khtml-user-select: none !important;
            -webkit-user-select: none !important;
            -webkit-touch-callout: none !important;
          }
        `}</style>
            </Helmet>
            <Switch>{throughAppRoutes()}</Switch>
          </>
        </ZendeskProvider>
      </SmartTribuneProvider>
    );
  } else if (
    isPathInPrivateRoutes(location.pathname) ||
    (isAuth0Authenticated && user?.email_verified)
  ) {
    routes = (
      <SmartTribuneProvider>
        <ZendeskProvider>
          <>
            <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Switch>
              {privateRoutes()}
              {publicRoutes()}
            </Switch>
          </>
        </ZendeskProvider>
      </SmartTribuneProvider>
    );
  } else {
    return (
      <>
        <GlobalStyles />
        <ScrollToTop>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Helmet>
          <Switch>{publicRoutes()}</Switch>
        </ScrollToTop>
      </>
    );
  }

  return (
    <>
      <GlobalStyles />
      <ScrollToTop>
        <TokenProvider>
          <HealthCheckProvider>
            <CookieBotProvider>
              <GTMProvider>{routes}</GTMProvider>
            </CookieBotProvider>
          </HealthCheckProvider>
        </TokenProvider>
      </ScrollToTop>
    </>
  );
};
