import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RoutePaths } from "routes/routePaths";
import SignatureTelephone from "pages/Reconduction/Components/SignatureTelephone";
import { dispatcherState, reconductionState } from "context/reconductionState/Atoms";
import { useTracking } from "hooks/useTracking";
import ReconductionCotisationBlock from "./ReconductionCotisationBlock";
import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";
import { ContactBlockTunnel } from "components/molecules/ContactBlockTunnel/ContactBlockTunnel";

import styles from "pages/Reconduction/Reconduction.module.scss";

const InfoPersonnelles: React.FC = () => {
  const { infosReconduction, promoError, phone, promo, isDesktop, currentStep } =
    useRecoilValue(reconductionState);
  const dispatcher = useRecoilValue(dispatcherState);
  const { sendToDataLayer } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    if (infosReconduction === null) {
      navigate(RoutePaths.RECONDUCTION_HOME);
    }
  }, []);

  const handlePreviousStep = useCallback(() => {
    dispatcher?.updateStep(currentStep - 1);
    navigate(RoutePaths.RECONDUCTION_IBAN);
    sendToDataLayer("reconduction-info-btn-precedent");
  }, [currentStep, dispatcher, navigate]);

  const handleNextStep = useCallback(
    (newPhone: string) => {
      dispatcher?.handleTelephoneStep(newPhone, currentStep + 1);
      navigate(RoutePaths.RECONDUCTION_SIGNATURE);
      sendToDataLayer("reconduction-info-btn-suivant");
    },
    [currentStep, dispatcher, navigate],
  );

  return (
    <div>
      <div className={styles.Reconduction__titleAndContent}>
        <div className={styles.InfoPersonnelles__title}>Indiquez vos infos personnelles</div>
        <div className={styles.Reconduction__content}>
          <div>
            <SignatureTelephone
              phoneValue={phone || null}
              handlePhoneChanged={handleNextStep}
              previousAction={handlePreviousStep}
            />
          </div>
          <div className={styles.Reconduction__contentRight}>
            <ReconductionCotisationBlock
              price={infosReconduction?.tarifMensuel}
              handleChangePromo={() => {}}
              monthFree={promo?.echeancesOffertes}
              promoError={promoError}
              isDisplayPromoCode={false}
              nextButtonLabel="valider"
            />
            {isDesktop && (
              <aside>
                <SocialBlock className={styles.Callblock}>
                  {[<ContactBlockTunnel idTracking="reconduction-telephone" />]}
                </SocialBlock>
              </aside>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPersonnelles;
