import { GarantieCategory } from "domain/api-schemas";
import axiosClient from "clientProvider/axiosConfig";

export const garantiesApi = {
  fetchGarantieCategories: async (idAdhesion: string | null) => {
    if (!idAdhesion) {
      return null;
    }
    const result = await axiosClient.get<GarantieCategory[]>(
      `v2/Garanties/IdAdhesion/${idAdhesion}`,
    );
    return result.data;
  },
};
