import { FC, ReactElement } from "react";

export type WizardStepIcon = "check" | "creditCard" | "eSign" | "info";

export enum WizardStepStatus {
  UNSTARTED = "UNSTARTED",
  STARTED = "STARTED",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  LOCKED = "LOCKED",
}

export interface WizardStepObject {
  path: string;
  label?: string;
  description?: string;
  icon?: WizardStepIcon;
  status: WizardStepStatus;
  error: string | null;
  index: number;
  isFirst: boolean;
  isLast: boolean;
}

type WizardStepProps = Pick<WizardStepObject, "path" | "label" | "icon"> & { guard?: FC };

export type WizardStep<P = {}> = FC<WizardStepProps & P>;

export type WizardStepElement<P = {}> = ReactElement<WizardStepProps & P, WizardStep<P>>;

export type WizardStepsByPath = { [key: string]: Partial<WizardStepObject> };

export type WizardInitHandler = () => Promise<WizardStepsByPath>;

export type WizardStepHandler<T> = () => Promise<T>;

export type WizardNavHandler<T> = (result?: T) => void;
