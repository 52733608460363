type PushParams = {
  event: string;
  data?: Object;
};

/**
 * Push to dataLayer dynamically to avoid missing id or svg component problem
 * @returns {void}
 */
const useDataLayer = () => {
  const pushToDataLayer = ({ event, data }: PushParams) => {
    // Avoid ssr error (no window defined)
    // @ts-ignore
    if (typeof window !== undefined && window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event,
        ...data,
      });
    }
  };

  return { pushToDataLayer };
};

export default useDataLayer;
