import { Box } from "components/atoms/Box/Box";
import { MonContrat } from "domain/api-schemas";
import { FC } from "react";
import { ReactComponent as AlertIcon } from "images/alert.svg";
import styles from "./RegularisationCard.module.scss";
import { Button, Text } from "@roole/components-library";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import {
  getJourRestantDerniereChance,
  getJourRestantPeriodeReconduction,
} from "domain/service/Contract//useReconduction";
import { formatDate } from "utils/formatDate";
import addDays from "date-fns/addDays";

const ReconductionCard: FC<{
  contract: MonContrat;
  className?: string;
}> = ({ contract, className }) => {
  const navigate = useNavigate();
  const joursRestant = getJourRestantPeriodeReconduction(contract);
  const dateFinPeriode = addDays(new Date(contract.dateFin), 30);

  return (
    <Box className={className}>
      <span className={styles.iconWrap}>
        <span className={styles.icon}>
          <AlertIcon />
        </span>
        <span className={styles.text}>
          <Text textVariant="heading-6" as="span">
            Votre contrat {joursRestant && joursRestant > 0 ? "arrive à échéance" : "a expiré"}
          </Text>
        </span>
      </span>
      {joursRestant > 0 ? (
        <>
          <Text textVariant="body-em" as="p" className={styles.cardSubTitle}>
            Vous avez encore {joursRestant} jours pour prolonger votre adhésion
          </Text>
          <Text textVariant="body" as="p">
            A compter du {formatDate(dateFinPeriode)} votre véhicule ne sera plus couvert en cas de
            sinistre !
          </Text>
        </>
      ) : (
        <>
          <Text textVariant="body-em" as="p" className={styles.cardSubTitle}>
            Vous n’êtes plus couvert en cas de sinistre depuis le {formatDate(dateFinPeriode)}
          </Text>
          <Text textVariant="body" as="p">
            Dernière chance ! Vous avez encore {getJourRestantDerniereChance(contract)} jours pour
            prolonger votre adhésion. Passé ce délai votre contrat sera définitivement expiré.
          </Text>
        </>
      )}
      <div className={styles.buttonCenter}>
        <Button
          variant="primary"
          onClick={(e) => {
            navigate(RoutePaths.RECONDUCTION_HOME);
          }}
        >
          Reconduire
        </Button>
      </div>
    </Box>
  );
};

export default ReconductionCard;
