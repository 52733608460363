import { FC, PropsWithChildren } from "react";
import { SkeletonTheme } from "react-loading-skeleton";

const baseColors = {
  light: "var(--gray-15)",
  dark: "var(--gray-25)",
};

interface SkeletonThemeProps {
  dark?: boolean;
}

export const CustomSkeletonTheme: FC<PropsWithChildren<SkeletonThemeProps>> = ({
  children,
  dark = false,
}) => {
  const baseColor = dark ? baseColors.dark : baseColors.light;

  return <SkeletonTheme baseColor={baseColor}>{children}</SkeletonTheme>;
};
