import { ButtonV2Props } from "@roole/components-library";
import { createContext, useContext } from "react";

type ButtonGroupActions = {
  buttonPrimary?: ButtonV2Props;
  buttonSecondary?: ButtonV2Props;
};

interface StepperContextType {
  setActions: (value: ButtonGroupActions) => void;
  goToStep: (newStep: number) => void;
  prevStep: () => void;
  nextStep: () => void;
  cancelDeclaration: () => void;
}

export const StepperContext = createContext<StepperContextType | undefined>(undefined);

export const useStepperContext = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("useStepperContext must be used within a StepperProvider");
  }

  return context;
};
