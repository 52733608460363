import { FC } from "react";

import { MonContrat } from "domain/api-schemas";
import { useMatchMedia } from "hooks/useMatchMedia";
import { RoutePaths } from "routes/routePaths";
import { classNames } from "utils/classNames";

import { Header } from "./Header";
import { CarInfo } from "./CarInfo/CarInfo";
import { ContactBlock } from "components/molecules/ContactBlock/ContactBlock";
import BurgerMenu from "components/molecules/BurgerMenu/BurgerMenu";
import Logo from "components/atoms/Logo/Logo";

import styles from "./MainHeader.module.scss";
import cssVariables from "styles/variables.module.scss";
import { useTracking } from "hooks/useTracking";
import { HelpCard } from "../HelpCard/HelpCard";

export type MainHeaderProps = {
  toggleMenu: (value: boolean) => void;
  isOpen: boolean;
  phoneNumber: string;
  contract: MonContrat | null;
  error?: boolean;
};

const MainHeader: FC<MainHeaderProps> = ({
  contract,
  toggleMenu,
  isOpen,
  phoneNumber,
  error = false,
}: MainHeaderProps) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const { sendToDataLayer } = useTracking();
  return (
    <Header className={classNames(styles.MainHeader, styles.MainHeader__Error)}>
      <Logo id="header-logo" url={RoutePaths.CONTRAT_HOME} />
      {!error &&
        (isDesktop ? (
          <>
            <CarInfo contract={contract} className={styles.MainHeader__CarInfo} />
            <HelpCard
              id="header-contactez-nous"
              className={styles.MainHeader__HelpCard}
              sendToDataLayer={() => sendToDataLayer("header-contactez-nous", true)}
              text="Centre d'aide"
            />
          </>
        ) : (
          <ContactBlock
            id="header-contactez-nous"
            phoneNumber={phoneNumber}
            className={styles.MainHeader__ContactBlock}
            contract={contract}
            sendToDataLayer={() => sendToDataLayer("header-contactez-nous", true)}
          />
        ))}

      {!isDesktop && !error && <BurgerMenu isOpen={isOpen} toggleOpen={toggleMenu} />}
    </Header>
  );
};

export default MainHeader;
