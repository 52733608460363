import { selector, selectorFamily } from "recoil";
import { globalState } from "context/globalState/atom";
import { contractApi } from "domain/api/Contract/contractApi";
import { refreshMonContratTriggerState } from "./atom";

const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
    date.getDate(),
  ).padStart(2, "0")}`;
};

export const monContratWithEcheanceQuery = selectorFamily({
  key: "ContratInfoQuery",
  get: (params: { idAdhesion: string | null; refreshKey: number }) => async () => {
    const { idAdhesion } = params;
    if (!idAdhesion) {
      return null;
    }

    const response = await contractApi.fetchMonContrat(idAdhesion);

    const { contratId, dateFin, packServices } = response;
    const codePack = packServices.codePack;
    const formattedDateFin = formatDate(dateFin);

    const responseEcheance = await contractApi.fetchContratEcheance(
      codePack,
      contratId,
      formattedDateFin,
    );

    return { ...response, ...responseEcheance };
  },
});

export const currentWithEcheanceQuery = selector({
  key: "CurrentWithEcheanceQuery",
  get: ({ get }) =>
    get(
      monContratWithEcheanceQuery({
        idAdhesion: get(globalState).idAdhesion ?? null,
        refreshKey: get(refreshMonContratTriggerState),
      }),
    ),
});
