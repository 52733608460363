import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "components/atoms/Button/Button";
import { RoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";

interface ContratButtonProps {
  hasMultiContrats: boolean;
}

const ContratButton: FC<ContratButtonProps> = ({ hasMultiContrats }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { sendToDataLayer } = useTracking();

  return (
    <Button
      id={hasMultiContrats ? "mon-contrat-selection-contrat" : "ajout-contrat"}
      variant="outlined-light"
      size="small"
      onClick={(e) => {
        sendToDataLayer(e.currentTarget.id, true);
        navigate(hasMultiContrats ? RoutePaths.CONTRAT_CHOISIR : RoutePaths.CONTRAT_AJOUTER, {
          replace: true,
          state: { redirect: pathname },
        });
      }}
    >
      {hasMultiContrats ? "Tous mes contrats" : "Ajouter un contrat"}
    </Button>
  );
};

export default ContratButton;
