import { ComponentProps, FC, isValidElement } from "react";
import { Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import Skeleton from "react-loading-skeleton";

import { ActivateHotDealFn, DisclosureHotDealFn } from "./HotDealBox.types";
import { Box } from "components/atoms/Box/Box";
import { Tag } from "components/atoms/Tag/Tag";
import { SeeMore } from "components/molecules/SeeMore/SeeMore";
import { BonPlan } from "domain/api-schemas";
import { FallbackProps, isEmpty } from "utils/FallbackProps";
import { classNames } from "utils/classNames";
import styles from "./HotDealBox.module.scss";

interface HotDealBoxProps extends ComponentProps<typeof Box> {
  hotDeal: BonPlan;
  onActivate?: ActivateHotDealFn;
  onDisclosure?: DisclosureHotDealFn;
  primary?: boolean;
  disabled?: boolean;
}

const hotDealFallback: FallbackProps<BonPlan, "titre" | "sousTitre" | "description"> = {
  titre: <Skeleton />,
  sousTitre: <Skeleton width="80%" />,
  description: (
    <>
      <Skeleton count={2} />
      <Skeleton width="50%" />
    </>
  ),
};

export const HotDealBox: FC<HotDealBoxProps> = ({
  className,
  hotDeal,
  onActivate,
  onDisclosure,
  variant = "secondary",
  primary = true,
  disabled = false,
}) => {
  const { active, titre, sousTitre, description } = isEmpty(hotDeal)
    ? hotDealFallback
    : hotDeal || {};

  return (
    <Box className={classNames(styles.hotDealBox, className)} variant={variant}>
      <div className={styles.hotDealBox__heading}>
        <div className={styles.hotDealBox__title}>
          <Text textVariant="heading-6" as={primary ? "h3" : "h4"}>
            {titre}
          </Text>
          {sousTitre && (
            <Text textVariant="body-em" as={primary ? "h4" : "h5"}>
              {sousTitre}
            </Text>
          )}
        </div>
        {active && <Tag className={styles.hotDealBox__tag}>Activé</Tag>}
      </div>
      {isValidElement(description) ? (
        description
      ) : (
        <SeeMore onDisclosure={onDisclosure} id="mon-garage-voir-plus">
          <Text dangerouslySetInnerHTML={{ __html: description }} textVariant="ui-1" />
        </SeeMore>
      )}
      {!!onActivate && (
        <Button
          onClick={() => !disabled && onActivate?.()}
          className={classNames(styles.hotDealBox__activateBtn, "center-label")}
          type="button"
          disabled={disabled}
          id="mon-garage-recevoir-coupon"
          size="small"
          variant={active ? "outlined-light" : "primary"}
        >
          {active ? "Recevoir à nouveau" : "Recevoir ce coupon"}
        </Button>
      )}
    </Box>
  );
};
