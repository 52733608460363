import { useEffect, useState } from "react";
import { RoutePaths } from "routes/routePaths";
import { Header } from "components/molecules/Header/Header";
import Logo from "components/atoms/Logo/Logo";
import { EndPage } from "components/molecules/EndPage/EndPage";
import { OverlayLoader } from "@roole/components-library";

import styles from "./BonplanVinci.module.scss";
import { useSearchParams } from "react-router-dom";

const BonplanVinci: React.FC<{}> = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params] = useSearchParams();
  const redirectionUri = params.get("redirectionUri");

  useEffect(() => {
    setLoading(true);
    if (!redirectionUri) {
      setError(true);
      setLoading(false);
    } else {
      window.location.replace(redirectionUri);
    }
  }, [redirectionUri]);

  return (
    <div className={styles.PageLayout}>
      <Header className={styles.PageLayout__header}>
        <Logo id="header-logo" url={RoutePaths.CONTRAT_HOME} />
      </Header>
      {loading && <OverlayLoader />}
      {!loading && error && (
        <EndPage title="Votre code promotionnel a expiré">
          <p>
            La durée de validité de votre code promotionnel a expiré.
            <br />
            Veuillez-vous reconnecter sur votre compte afin de pouvoir de nouveau accéder à la page
            de ce bon plan. <br />
          </p>
          <p>L'équipe Roole</p>
        </EndPage>
      )}
    </div>
  );
};

export default BonplanVinci;
