import { FC, Children, PropsWithChildren } from "react";
import { Box } from "components/atoms/Box/Box";
import styles from "./ListBox.module.scss";
import { ReactComponent as BulleInfo } from "images/icons/BulleInfo.svg";
import { classNames } from "utils/classNames";

interface ListBoxProps {
  onInfo?: (index: number) => void;
  className?: string;
  headLine?: string;
  statutMembre: string;
}

const DesactivatedButton = () => (
  <div className={styles.buttonContainer}>
    <div className={styles.button}>
      <p>Désactivée</p>
    </div>
  </div>
);

export const ListBox: FC<PropsWithChildren<ListBoxProps>> = ({
  children,
  onInfo,
  className,
  headLine,
  statutMembre,
}) => {
  const list = Children.toArray(children);

  return list.length ? (
    <Box className={classNames(styles.container, className)}>
      <ul>
        <div className={styles.headerContainer}>
          {headLine && (
            <li className={statutMembre === "Premium" ? styles.headLine : styles.headLineDisable}>
              {headLine}
            </li>
          )}
          {statutMembre !== "Premium" && <DesactivatedButton />}
        </div>
        {list.map((content, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={`${content}-${index}`}
            onClick={onInfo && (() => onInfo(index))}
            className={onInfo && styles.withInfo}
          >
            {content}
            {onInfo && <BulleInfo className={styles.info} />}
          </li>
        ))}
      </ul>
    </Box>
  ) : null;
};
