import axiosClient from "clientProvider/axiosConfig";

interface DemandeEtudeProps {
  idAdhesion: number;
  dossierGarantie: {
    crmId: string;
    nom: string;
  };
  garantie: {
    crmId: string;
    nom: string;
  };
  evenement: {
    crmId: string;
    nom: string;
  };
  typeEvenement: {
    crmId: string;
    nom: string;
  };
  identifiantContrat: string;
  nomMembre: string;
  Source: "RoolePremium" | "EspaceMembre";
}

export const DemandeEtude = async (props: DemandeEtudeProps) => {
  const result = await axiosClient.post("/DeclarationSinistre/DemandeEtude", {
    ...props,
  });

  return result.data;
};
