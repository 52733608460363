import { FC } from "react";
import { Button } from "components/atoms/Button/Button";

import { useWizard } from "./WizardContext";
import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";
import styles from "./WizardNav.module.scss";

export interface WizardNavProps extends CommonProps {
  nextStepLabel?: string;
  nextStepHandler?(): void;
  nextStepDisabled?: boolean;
  prevStepLabel?: string;
  prevStepHandler?(): void;
  prevStepDisabled?: boolean;
  buttons?: number;
  disabled?: boolean;
  nextSendToDataLayer?: () => void;
}

export const NO_BTN = 0x1;
export const NEXT_BTN = 0x2;
export const PREV_BTN = 0x4;

export const WizardNav: FC<WizardNavProps> = ({
  className,
  nextStepLabel,
  nextStepHandler,
  nextStepDisabled = false,
  prevStepLabel,
  prevStepHandler,
  prevStepDisabled = false,
  buttons = NEXT_BTN | PREV_BTN,
  disabled = false,
  nextSendToDataLayer,
}) => {
  const { activeStep, loading, submitting, initialized, submitStep, prevStep } = useWizard();

  const prevBtnHandler = () => (prevStepHandler ?? prevStep)();
  const prevDisabled = disabled || prevStepDisabled || loading || submitting;

  const nextBtnHandler = () => (nextStepHandler ?? submitStep)();
  const nextDisabled = !initialized || disabled || nextStepDisabled || loading || submitting;

  return (
    <nav className={classNames(styles.wizardNav, className)}>
      {!!(buttons & PREV_BTN) && (
        <Button
          variant="outlined-light"
          className={classNames(styles.wizardNav__prevBtn, "center-label")}
          type="button"
          disabled={prevDisabled}
          size="small"
          onClick={prevBtnHandler}
        >
          {prevStepLabel ?? (activeStep?.isFirst ? "Annuler" : "Précédent")}
        </Button>
      )}
      {!!(buttons & NEXT_BTN) && (
        <Button
          variant="primary"
          className={classNames(styles.wizardNav__nextBtn, "center-label")}
          type="button"
          disabled={nextDisabled}
          size="small"
          onClick={() => {
            nextSendToDataLayer?.();
            nextBtnHandler?.();
          }}
        >
          {nextStepLabel ?? (activeStep?.isLast ? "Se connecter" : "Suivant")}
        </Button>
      )}
    </nav>
  );
};
