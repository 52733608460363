import axios from "axios";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { accessTokenOptions } from "./auth0.util";

export const API_ROOT = process.env.REACT_APP_API_ROOT;

const axiosClient = axios.create({
  baseURL: `${API_ROOT}/api`,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
  timeout: 50000,
});

// adds access tokens in all api requests
export const addAccessTokenInterceptor = (
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>,
) => {
  axiosClient.interceptors.request.use(async (config) => {
    if (config.headers) {
      const token = await getAccessTokenSilently({ authorizationParams: accessTokenOptions });
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

export const addNewAccessTokenInterceptor = async (token: string) => {
  axiosClient.interceptors.request.use(async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  });
};

// Axios request interceptor to handle idAdhesion
axiosClient.interceptors.request.use(
  async (config) => {
    if (config.method === "post") {
      if (
        config.data &&
        config.data.idAdhesion !== undefined &&
        Number.isInteger(config.data.idAdhesion)
      ) {
        config.data.idAdhesion = config.data.idAdhesion.toString();
      }
    } else if (config.method === "get" && config.params && config.params.idAdhesion !== undefined) {
      const idAdhesion = config.params.idAdhesion;
      if (Number.isInteger(idAdhesion)) {
        config.params.idAdhesion = idAdhesion.toString();
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosClient;
