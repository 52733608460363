export enum SignalRTarget {
  HealthCheckResult = "HealthCheckResult",
}

export enum HealthCheckGroups {
  BonPLanNationaux = "BonPLanNationaux",
  DeclarationSinistre = "DeclarationSinistre",
  BonPlanAuto = "BonPlanAuto",
  RenouvellementContrat = "RenouvellementContrat",
  Contact = "Contact",
}

export type HealthCheck = {
  groupName: HealthCheckGroups;
  isHealthy: boolean;
};
