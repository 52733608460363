import { useMutation } from "react-query";
import { OffrePromoResult, ValidationOffrePromoData } from "domain/api-schemas";
import { membreApi } from "domain/api/Membre/membreApi";

export const useCodePromo = (idAdhesion: string) =>
  useMutation<OffrePromoResult, Error, Omit<ValidationOffrePromoData, "idAdhesion">>(
    membreApi.validateCodePromo,
    {
      onMutate: (data) => {
        Object.assign(data, { idAdhesion: idAdhesion?.toString() });
      },
    },
  );
