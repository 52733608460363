import { Box } from "components/atoms/Box/Box";
import { FC, useState } from "react";
import { InputText, Loader, Modal, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { CoordonneesBancairesData, Member, MonContrat } from "domain/api-schemas";
import styles from "./PaymentCard.module.scss";
import { formatDate } from "utils/formatDate";
import { formatCurrency } from "utils/formatCurrency";
import { reconductionQuery } from "context/selectors";
import { useRecoilValue } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { validateIBan } from "utils/iban";
import { IbanFormInputs } from "components/molecules/IBan/IbanForm";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { getMaskedIban } from "utils/MaskedIban";
import { useUpdateIban } from "domain/service/Member/useUpdateMember";
import { ReactComponent as InfoIcon } from "images/icons/information.svg";

const PaymentCard: FC<{
  contract: MonContrat | null;
  member: Member | null;
  setMonContrat: any;
}> = ({ contract, member, setMonContrat }) => {
  const iban = contract?.coordonneesBancaires.iban;
  const infosReconduction = useRecoilValue(reconductionQuery);
  const { control, handleSubmit } = useForm<IbanFormInputs>();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: updateCoordonneesBancaires, isLoading: updateLoading } = useUpdateIban(
    contract?.idAdhesion ?? "",
  );

  const handleConfirm = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit: SubmitHandler<IbanFormInputs> = (input) => {
    const data: CoordonneesBancairesData = {
      dossierRegulId: null,
      iban: input.iban.replace(/\s/g, "") ?? "",
    };
    updateCoordonneesBancaires(data).then(() => {
      setMonContrat({
        ...contract,
        coordonneesBancaires: { iban: input.iban.replace(/\s/g, "") },
      } as MonContrat);
      navigate(RoutePaths.CONTRAT);
    });
  };

  const handleAbort = () => {
    setShowModalConfirm(false);
  };

  const handleClick = () => {
    setShowModalConfirm(true);
  };

  const validations = {
    iban: {
      required: {
        value: true,
        message: "Ce champ est obligatoire",
      },
      validate: {
        iban: (value: string) =>
          validateIBan(value.replace(/\s/g, "")) || "Ce champ doit être saisi au bon format",
      },
    },
  };

  return (
    <Box>
      <Text textVariant="heading-5" as="h2" className={styles.PaymentCard__cardTitle}>
        Prochain paiement
      </Text>
      <Text textVariant="body" as="p">
        {`Prélèvement de ${
          contract?.montantProchaineEcheance
            ? formatCurrency(contract?.montantProchaineEcheance)
            : formatCurrency(infosReconduction.tarifMensuel)
        } le
        ${formatDate(contract?.dateProchaineEcheance ?? null, {
          dateStyle: "long",
        })}`}
      </Text>
      <div className={styles.PaymentCard__ibanBox}>
        <div>
          <Text textVariant="heading-6" as="h3" className={styles.PaymentCard__cardTitle}>
            Coordonnées bancaires
          </Text>
          <p>
            <b>Nom : </b>
            {member?.prenom} {member?.nom}
          </p>
          <p>
            <b>IBAN : </b> {getMaskedIban(iban ?? "")}
          </p>
        </div>
        <div>
          <Button variant="outlined-light" size="small" onClick={handleClick}>
            Modifier
          </Button>
        </div>
        <Modal
          title="Modifier mes coordonnées bancaires"
          content={
            <div>
              {updateLoading && (
                <div className={styles.center}>
                  <div className={styles.loaderContainer}>
                    <Loader />
                  </div>
                </div>
              )}
              {!updateLoading && (
                <>
                  <Text textVariant="body" as="h5" className={styles.title}>
                    <b>Titulaire</b> : {contract?.membre.prenom} {contract?.membre.nom}
                  </Text>
                  <Controller
                    control={control}
                    name="iban"
                    rules={{ ...validations.iban }}
                    render={({ field, fieldState: { error } }) => (
                      <InputText
                        border
                        type="text"
                        placeholder="Ex. FR7630001007941234567890185"
                        {...field}
                        minLength={error?.type === "iban" ? (field.value || "").length + 1 : -1}
                        error={error?.message}
                      />
                    )}
                  />
                  <span className={styles.info}>
                    <InfoIcon className={styles.infoIcon} />
                    <Text textVariant="body" className={styles.infoText}>
                      Le titulaire du RIB doit être identique au titulaire du contrat
                    </Text>
                  </span>
                  <div className={styles.buttonCenter}>
                    <Button size="large" variant="outlined-light" onClick={handleAbort}>
                      Annuler
                    </Button>
                    <Button size="large" variant="primary" onClick={handleConfirm}>
                      Valider
                    </Button>
                  </div>
                </>
              )}
            </div>
          }
          isOpen={showModalConfirm}
          handleClose={handleAbort}
        />
      </div>
    </Box>
  );
};

export default PaymentCard;
