import { FC, HTMLAttributes } from "react";
import { classNames } from "utils/classNames";
import styles from "./ButtonGroup.module.scss";

export const ButtonGroup: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames(styles.btnContainer, className)} {...props}>
    {children}
  </div>
);
