import { FC } from "react";

import { useTracking } from "hooks/useTracking";

import { Button } from "components/atoms/Button/Button";

import styles from "./NavigationButtons.module.scss";

interface NavigationButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  text?: string;
  type?: "button" | "submit" | "reset";
  isHidden?: boolean;
  gtmElementId?: string;
}

interface NavigationButtonsProps {
  prevProps: NavigationButtonProps;
  nextProps: NavigationButtonProps;
}

export const NavigationButtons: FC<NavigationButtonsProps> = ({ prevProps, nextProps }) => {
  const { sendToDataLayer } = useTracking();

  return (
    <div className={styles.navigationButtons}>
      {!prevProps.isHidden && (
        <Button
          type={prevProps.type}
          onClick={() => {
            if (!!prevProps.gtmElementId) {
              sendToDataLayer(prevProps.gtmElementId || "prevButton");
            }
            prevProps.onClick();
          }}
          variant="outlined-light"
          disabled={prevProps.isDisabled}
        >
          {!!prevProps.text ? prevProps.text : "Précédent"}
        </Button>
      )}
      {!nextProps.isHidden && (
        <Button
          type={nextProps.type}
          variant="primary"
          onClick={() => {
            if (!!nextProps.gtmElementId) {
              sendToDataLayer(nextProps.gtmElementId || "nextButton");
            }
            nextProps.onClick();
          }}
          disabled={nextProps.isDisabled}
        >
          {!!nextProps.text ? nextProps.text : "Suivant"}
        </Button>
      )}
    </div>
  );
};
