import { OverlayLoader, Text } from "@roole/components-library";

import { ReminderBox } from "./ReminderBox";
import {
  useWizard,
  WizardAside,
  WizardNav,
  WizardStatus,
  WizardStep,
  WizardStepLayout,
} from "components/molecules/Wizard";
import { getNavProps, setStepsStatus } from "./useRenewal";
import { renewalState } from "context/renewalState/atom";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { WizardNavProps } from "components/molecules/Wizard/WizardNav";
import { eSignatureState } from "../Components/ESignature/eSignatureState";
import { useTracking } from "hooks/useTracking";
import { useParams } from "react-router";
import { useDossierReconduction } from "domain/service/Renewal/useRenewal";

export const RenewalStatusStep: WizardStep = () => {
  const { steps, dispatch } = useWizard();
  const [renewal, setRenewal] = useRecoilState(renewalState);
  const [navProps, setNavProps] = useState<WizardNavProps>();
  const [signatureState] = useRecoilState(eSignatureState);

  const { sendToDataLayer } = useTracking();

  const params = useParams();

  const { data: dossierReconduction, isLoading: loading } = useDossierReconduction(params.id ?? "");

  useEffect(() => {
    dossierReconduction && setRenewal(dossierReconduction);
  }, [dossierReconduction]);

  useEffect(() => {
    if (renewal.dateCreation !== null) {
      setStepsStatus(steps, renewal, signatureState.status).then((result) => {
        setNavProps(getNavProps(steps, result));
      });
    }

    return () => {
      setNavProps(undefined);
    };
  }, [renewal, dispatch, steps, signatureState.status]);

  return (
    <>
      {loading && <OverlayLoader />}
      <WizardStepLayout title="Merci de vouloir adhérer à notre club pour une année de plus !">
        <>
          <Text textVariant="heading-4" as="h2">
            Les 3 étapes de votre parcours de souscription&nbsp;:
          </Text>
          <WizardStatus />
          <WizardNav
            {...navProps}
            nextSendToDataLayer={() => {
              sendToDataLayer("reconduction-commencer", false, renewal.id);
            }}
          />
        </>
        <WizardAside>
          <ReminderBox />
        </WizardAside>
      </WizardStepLayout>
    </>
  );
};
