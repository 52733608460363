import { Action, ActionWithProps } from "utils/Action";
import { WizardStepsByPath } from "./Wizard.types";

export enum WizardActionType {
  INIT_WIZARD = "INIT_WIZARD",
  INIT_WIZARD_SUCCEEDED = "INIT_WIZARD_SUCCEEDED",
  INIT_WIZARD_FAILED = "INIT_WIZARD_FAILED",
  SUBMIT_STEP = "SUBMIT_STEP",
  SUBMIT_STEP_SUCCEEDED = "SUBMIT_STEP_SUCCEEDED",
  SUBMIT_STEP_FAILED = "SUBMIT_STEP_FAILED",
  RESET_ERROR = "RESET_ERROR",
}

export type WizardAction =
  | Action<WizardActionType.INIT_WIZARD>
  | ActionWithProps<WizardActionType.INIT_WIZARD_SUCCEEDED, { stepsByPath: WizardStepsByPath }>
  | ActionWithProps<WizardActionType.INIT_WIZARD_FAILED, { error: string }>
  | ActionWithProps<WizardActionType.SUBMIT_STEP, { stepIndex: number }>
  | ActionWithProps<
      WizardActionType.SUBMIT_STEP_SUCCEEDED,
      { stepIndex: number; description: string | undefined }
    >
  | ActionWithProps<
      WizardActionType.SUBMIT_STEP_FAILED,
      { stepIndex: number; error: string; errorNextLabel: string }
    >
  | Action<WizardActionType.RESET_ERROR>;
