import { InputText, Loader, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import styles from "./AppointmentModal.module.scss";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";
import { WarningIcon } from "components/atoms/Icons/WarningIcon";
import { UseMutateFunction } from "react-query";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from "recoil";
import { currentContratQuery, monContratQuery } from "context/currentContratState/selector";
import { useForm, Controller } from "react-hook-form";
import { globalState } from "context/globalState/atom";

type AppointmentModalProps = {
  isScheduling: boolean;
  schedulePartnerAppointment: UseMutateFunction<string, unknown, number, unknown>;
  onClose?(): void;
};
export const AppointmentModal: React.FC<AppointmentModalProps> = ({
  isScheduling,
  schedulePartnerAppointment,
  onClose: _onClose,
}) => {
  const [globalStateValue] = useRecoilState(globalState);
  const refreshMonContrat = useRecoilRefresher_UNSTABLE(
    monContratQuery(globalStateValue.idAdhesion),
  );
  const { contents } = useRecoilValueLoadable(currentContratQuery);
  const [hasError, setHasError] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: { kilometrage: String(contents?.vehicule?.kilometrage ?? "") },
  });

  const onClose = (refresh: boolean = false) => {
    if (isScheduling) {
      return;
    }

    if (refresh) {
      refreshMonContrat();
      window.location.reload();
    }
    setHasError(false);

    _onClose?.();
  };

  return (
    <form
      className={styles.AppointmentModal}
      onSubmit={handleSubmit(({ kilometrage }) => {
        if (isScheduling) {
          return;
        }

        schedulePartnerAppointment(Number(kilometrage), {
          onSuccess: (urlRedirection) => {
            window.open(urlRedirection, "_blank");
            onClose(true);
          },
        });
      })}
    >
      <div className={styles.header}>
        <Text textVariant="heading-3">Prendre rendez-vous</Text>
        <CloseIcon
          title="Fermer et annuler"
          color="white"
          className={styles.close}
          onClick={() => onClose()}
        />
      </div>

      <Text className={styles.text}>
        Renseignez le kilométrage de votre véhicule pour que votre garage prépare au mieux votre
        <br />
        rendez-vous.
      </Text>

      <Text className={styles.label}>Kilométrage de votre véhicule :</Text>

      <Controller
        name="kilometrage"
        control={control}
        rules={{ required: true, validate: (kilometrage) => (Number(kilometrage) || 0) > 0 }}
        render={({ field, fieldState }) => (
          <InputText
            mask="9999999"
            maskChar=""
            className={styles.input}
            suffix="KM"
            {...field}
            error={
              (fieldState.invalid && "Veuillez renseigner un kilométrage valide.") || undefined
            }
          />
        )}
      />

      <div className={styles.controls}>
        <Button variant="outlined-light" size="small" onClick={() => onClose()}>
          Annuler
        </Button>

        <Button variant="primary" size="small">
          Continuer
        </Button>
      </div>

      {(isScheduling || hasError) && (
        <div className={styles.cover}>
          {isScheduling && <Loader className={styles.loader} />}
          {!isScheduling && hasError && (
            <div className={styles.error}>
              <div className={styles.error__message}>
                <WarningIcon className={styles.warning} width="44px" />
                <Text className={styles.error__message__title} textVariant="heading-featured-grid">
                  Oups ...
                </Text>
                <Text className={styles.error__message__content}>
                  Une erreur est survenue, nous travaillons dessus, veuillez réessayer
                  ultérieurement.
                </Text>
              </div>

              <Button
                variant="outlined-light"
                size="small"
                onClick={() => onClose()}
                className={styles.error__close}
              >
                Ok
              </Button>
            </div>
          )}
        </div>
      )}
    </form>
  );
};
