import {
  AddContractRequest,
  CoordonneesBancairesData,
  UpdateInfosMembreRequest,
  UpdateUserEmailRequest,
} from "domain/api-schemas";
import axiosClient from "clientProvider/axiosConfig";

export const membreApi = {
  updateMembre: async (idAdhesion: string, membreRequest: UpdateInfosMembreRequest) => {
    const result = await axiosClient.patch(`/Membre?idAdhesion=${idAdhesion}`, membreRequest, {
      timeout: 30000,
    });
    return result.data?.member;
  },

  updateCoordonneesBancaires: async (idAdhesion: string, data: CoordonneesBancairesData) => {
    await axiosClient.patch(`/Membre/UpdateCoordonneesBancaires?idAdhesion=${idAdhesion}`, data, {
      timeout: 30000,
    });
  },

  postAddContract: async (data: AddContractRequest) => {
    const result = await axiosClient.post("Membre/AddContract", data, {
      timeout: 50000,
    });
    return result.data;
  },

  sendParrainage: (emails: { mailsFilleuls: string[] }) =>
    axiosClient.post("Parrainage/CreationFilleuls", emails),

  validateCodePromo: async (data: Object) => {
    const result = await axiosClient.post("TarifProduit/ValidationOffrePromo", data);
    return result.data;
  },

  updateUserEmail: async (data: UpdateUserEmailRequest) => {
    const result = await axiosClient.put("Membre/UpdateEmail", data, { timeout: 100000 });
    return result.data;
  },

  sendEmailVerification: async (email: string) => {
    await axiosClient.post(
      "Membre/SendEmailVerification",
      { email: email.trim() },
      {
        headers: {
          XApiKey: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp",
        },
        timeout: 100000,
      },
    );
  },

  checkDossierIdSelfcareRequest: async ({ id, type }: { id: string; type: string }) => {
    const result = await axiosClient.get(`SelfcareRequest/CheckDossierId/${id}/${type}`);
    return result.data;
  },
};
