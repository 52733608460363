import { FC } from "react";
import { useRecoilValueLoadable } from "recoil";

import { VoletLateral } from "components/atoms/VoletLateral/VoletLateral";
import { Title } from "components/atoms/Title/Title";
import { formatSelectedGarantie } from "../utils";
import { useIndemnite } from "domain/service/Contract/useIndemnite";
import { Garantie } from "domain/api-schemas";
import { classNames } from "utils/classNames";
import { useGarantiesList } from "./useGarantiesList";
import GroupedItem from "./GroupedItem";
import { v4 as uuidv4 } from "uuid";

import { RoutePaths } from "routes/routePaths";

import { currentContratQuery } from "context/currentContratState/selector";

import { GarantiesDesactivatedCard } from "../GarantiesDesactivedCard/GarantiesDesactivatedCard";

import styles from "./GarantiesList.module.scss";

interface OptionPackInterface {
  codePack?: string | null;
  tarifAnnuelTac?: number;
}

interface GarantiesListProps {
  list: Garantie[] | null;
  codepack: string;
  optionspack?: OptionPackInterface[] | null;
  forceMobileDisplay?: boolean;
  hideTitle?: boolean;
  className?: string;
}

export const GarantiesList: FC<GarantiesListProps> = ({
  list,
  codepack,
  optionspack,
  forceMobileDisplay = false,
  hideTitle = false,
  className,
}) => {
  const monContrat = useRecoilValueLoadable(currentContratQuery);
  const { data: listeIndemnite } = useIndemnite(codepack, optionspack || []);
  const { orderAndGroupedGaranties, selectedItem, onAbort, setItemAndAnalytics } =
    useGarantiesList(list);
  const { contents } = monContrat;
  const statutMembre = contents.membre.statutMembre;
  const packServiceCode = contents.packServices.codePack;
  const isReconduction = window.location.pathname === RoutePaths.RECONDUCTION_HOME;

  return (
    <>
      {!!orderAndGroupedGaranties?.length && (
        <div className={classNames(styles.garanties, className)}>
          {!hideTitle && <Title className={styles.garantiesTitle}>Mes garanties</Title>}
          {statutMembre !== "Premium" && packServiceCode !== "4F" && !isReconduction && (
            <GarantiesDesactivatedCard contract={contents} />
          )}
          <div
            className={classNames(
              styles.garantiesListContainer,
              forceMobileDisplay && styles.garantiesListContainer_mobileView,
            )}
          >
            {statutMembre === "Premium" || packServiceCode === "4F" || isReconduction ? (
              orderAndGroupedGaranties.map((categorie: Array<Garantie>, index) => (
                <GroupedItem
                  categorie={categorie}
                  index={index}
                  key={uuidv4()}
                  setItemAndAnalytics={setItemAndAnalytics}
                  statutMembre={"Premium"}
                  packServiceCode={packServiceCode}
                />
              ))
            ) : (
              <>
                {orderAndGroupedGaranties[7] && (
                  <GroupedItem
                    categorie={orderAndGroupedGaranties[7]}
                    index={7}
                    key={uuidv4()}
                    setItemAndAnalytics={setItemAndAnalytics}
                    statutMembre={"Premium"}
                    packServiceCode={packServiceCode}
                  />
                )}
                {orderAndGroupedGaranties.map(
                  (categorie: Array<Garantie>, index) =>
                    index !== 7 && (
                      <GroupedItem
                        categorie={categorie}
                        index={index}
                        key={uuidv4()}
                        setItemAndAnalytics={setItemAndAnalytics}
                        statutMembre={statutMembre}
                        packServiceCode={packServiceCode}
                      />
                    ),
                )}
              </>
            )}
          </div>
        </div>
      )}
      {selectedItem !== null && (
        <VoletLateral title={selectedItem?.titre} onClose={onAbort}>
          <div
            dangerouslySetInnerHTML={{
              __html: formatSelectedGarantie(
                selectedItem?.description || "",
                selectedItem,
                listeIndemnite,
              ),
            }}
          />
        </VoletLateral>
      )}
    </>
  );
};
