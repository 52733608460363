import { WizardStepObject, WizardStepStatus } from "./Wizard.types";
import { WizardAction, WizardActionType } from "./WizardAction";

export interface WizardState {
  steps: WizardStepObject[];
  loading: boolean;
  submitting: boolean;
  initialized: boolean;
  error: string | null;
  errorNextLabel: string;
}

export const initialState: WizardState = {
  steps: [],
  loading: false,
  submitting: false,
  initialized: false,
  error: null,
  errorNextLabel: "Ok",
};

export const initWizardReducer = (state: WizardState): WizardState => {
  const statuses = [WizardStepStatus.LOCKED, WizardStepStatus.STARTED];

  return {
    ...state,
    steps: (state.steps || []).map((step, index, steps) => ({
      ...step,
      status: statuses[index] || WizardStepStatus.UNSTARTED,
      error: null,
      index,
      isFirst: index === 0,
      isLast: index === steps.length - 1,
    })),
  };
};

export const wizardReducer = (state: WizardState, action: WizardAction): WizardState => {
  switch (action.type) {
    case WizardActionType.RESET_ERROR:
      return { ...state, error: null };
    case WizardActionType.INIT_WIZARD:
      return { ...state, loading: true, error: null };
    case WizardActionType.INIT_WIZARD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        steps: state.steps.map((step) => ({ ...step, ...action.stepsByPath[step.path] })),
        initialized: true,
      };
    case WizardActionType.INIT_WIZARD_FAILED:
      return { ...state, loading: false, error: action.error };
    case WizardActionType.SUBMIT_STEP:
      return { ...state, submitting: true, error: null };
    case WizardActionType.SUBMIT_STEP_SUCCEEDED:
      return {
        ...state,
        steps: state.steps.map((step, index) => {
          if (index === action.stepIndex) {
            step.description = action.description;
          }
          if (index === action.stepIndex && step.status !== WizardStepStatus.LOCKED) {
            return { ...step, status: WizardStepStatus.SUCCEEDED };
          }
          if (index === action.stepIndex + 1 && step.status === WizardStepStatus.UNSTARTED) {
            return { ...step, status: WizardStepStatus.STARTED };
          }
          return step;
        }),
        submitting: false,
        error: null,
      };
    case WizardActionType.SUBMIT_STEP_FAILED:
      return {
        ...state,
        steps: state.steps.map((step, index) =>
          index === action.stepIndex
            ? { ...step, status: WizardStepStatus.FAILED, error: action.error }
            : step,
        ),
        submitting: false,
        error: action.error,
        errorNextLabel: action.errorNextLabel ?? "Ok",
      };
    default:
      return { ...initialState };
  }
};
