import { ReactNode, ReactElement } from "react";
import { Text } from "@roole/components-library";

import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";
import styles from "./BoxList.module.scss";

type BoxListItem = { id?: string | null };

interface BoxListProps<T extends BoxListItem> extends CommonProps {
  items: T[];
  itemRenderer(item: T): ReactElement;
  heading?: ReactNode;
  noItemsText?: string;
}

export const BoxList = <T extends BoxListItem>({
  className,
  heading,
  items,
  noItemsText,
  itemRenderer,
}: BoxListProps<T>) => {
  const numItems = items ? items.length : 0;
  const hasItemRender = typeof itemRenderer === "function";

  return numItems > 0 && hasItemRender ? (
    <div className={classNames(styles.boxList, className)}>
      {heading && <div className={styles.boxList__heading}>{heading}</div>}
      <ul>
        {items.map((item, index) => (
          <li key={`boxItem-${item?.id ?? index}`}>{itemRenderer(item)}</li>
        ))}
      </ul>
    </div>
  ) : (
    <Text className="text--secondary" textVariant="body">
      {noItemsText || "Aucunes données"}
    </Text>
  );
};
