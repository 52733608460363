import { FC } from "react";
import { Label } from "components/atoms/Label/Label";

import styles from "./InfoContractBlock.module.scss";

type InfoContractBlockProps = {
  contrat: {
    membre: {
      prenom: string;
      nom: string;
    };
    numeroContrat: string;
    packServices: {
      codePack: string;
    };
    vehicule: {
      immatriculation: string;
      modele: string;
      marque: string;
    };
  };
};

export const InfoContractBlock: FC<InfoContractBlockProps> = ({ contrat }) => (
  <div className={styles.container}>
    <h3 className={styles.title}>Rappel de votre contrat</h3>
    <div className={styles.content}>
      <div className={styles.contractInfo}>
        <p className={styles.brandTitle}>
          <strong className={styles.nowrap}>{contrat.vehicule.marque}</strong>
          &nbsp;
          {contrat.vehicule.modele}
        </p>
        <Label
          title="Immatriculation"
          style={styles.nowrap}
          value={contrat.vehicule.immatriculation}
        />
      </div>
      <div className={styles.contractInfo}>
        <p>
          <strong>
            {contrat.membre.prenom}&nbsp;{contrat.membre.nom}
          </strong>
        </p>
        <Label
          title="N° contrat"
          style={styles.nowrap}
          value={`${contrat.numeroContrat}`}
          trackingValue="contractNumber"
        />
      </div>
    </div>
  </div>
);
