import React from "react";
import { InputText } from "@roole/components-library";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MOBILE_PHONE_NUMBER } from "utils/regex";
import { Card } from "components/atoms/Card/Card";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";

import styles from "./Signature.module.scss";

export type IPhoneFormInputs = {
  phoneNumber: string;
};

const phoneValidation = {
  required: {
    value: true,
    message: "Ce champ est obligatoire",
  },
  validate: {
    iban: (value: string) =>
      new RegExp(MOBILE_PHONE_NUMBER).test(value.trim()) ||
      "Le format de numéro téléphone saisi est incorrect.",
  },
};

interface SignatureProps {
  phoneValue: string | null;
  previousAction: () => void;
  handlePhoneChanged: (newPhoneValue: string) => void;
}

const SignatureTelephone: React.FC<SignatureProps> = ({
  phoneValue,
  handlePhoneChanged,
  previousAction,
}) => {
  const { control, handleSubmit } = useForm<IPhoneFormInputs>();
  const onSubmit: SubmitHandler<IPhoneFormInputs> = (data) => handlePhoneChanged(data.phoneNumber);
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        <p className={styles.Signature__boxIntro}>
          Vous allez être redirigé sur notre plateforme de signature électronique. Pour signer votre
          contrat nous allons vous envoyer un code par SMS.
        </p>
        <p className={styles.Signature__boxLabel}>Confirmez votre numéro de portable :</p>
        <div className={styles.Signature__phoneNumber}>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{ ...phoneValidation }}
            defaultValue={phoneValue || undefined}
            render={({ field, fieldState: { error } }) => (
              <InputText
                border
                label={"Votre numéro de téléphone *"}
                {...field}
                minLength={(field.value || "").length + 1}
                required
                error={error?.message}
              />
            )}
          />
        </div>

        <NavigationButtons
          prevProps={{
            onClick: () => previousAction(),
          }}
          nextProps={{
            onClick: () => {},
            type: "submit",
            text: "Payer ma cotisation",
          }}
        />
      </Card>
    </form>
  );
};
export default SignatureTelephone;
