import React, { FC, useCallback, useEffect, useState } from "react";
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from "recoil";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { globalState } from "context/globalState/atom";
import { dispatcherState } from "context/reconductionState/Atoms";
import { useCreateDispatcher, ReconductionDispatcher } from "context/reconductionState/dispatcher";
import { listContratsInfoQuery } from "context/contratsState/selector";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { useTracking } from "hooks/useTracking";
import { reconductionQuery } from "context/selectors";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useBlocker } from "hooks/useBlocker";
import { STEPS_ROUTES, StepsReconduction } from "./Reconduction.types";
import { Stepper } from "components/atoms/Stepper/Stepper";
import { InfosReconduction } from "domain/api-schemas";

import useSmartPushFilters from "hooks/useSmartPush";

import cssVariables from "styles/variables.module.scss";
import styles from "./Reconduction.module.scss";

export type OutletContextType = { infosReconduction: InfosReconduction | null; isDesktop: boolean };

export const Page: FC = () => {
  const globalStateValue = useRecoilValue(globalState);
  const setDispatcher = useSetRecoilState(dispatcherState);
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const dispatcherRef = React.useRef<ReconductionDispatcher>(useCreateDispatcher());
  const infosReconduction = useRecoilValue(reconductionQuery);
  const refreshlistContrats = useRecoilRefresher_UNSTABLE(
    listContratsInfoQuery(globalStateValue.listContratAhesion),
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useSmartPushFilters(["renouveler-4644"]);

  const { sendToDataLayer } = useTracking();
  const handleOnClose = useCallback(async () => {
    if (pathname === RoutePaths.RECONDUCTION_RESULT_SUCCESS) {
      refreshlistContrats();
      sendToDataLayer("reconduction-confirmation");
    } else if (pathname === RoutePaths.RECONDUCTION_RESULT_CANCELED) {
      sendToDataLayer("reconduction-echec");
    }
    navigate(RoutePaths.CONTRAT, { replace: true });
  }, [navigate, pathname, refreshlistContrats, sendToDataLayer]);

  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Régler ma cotisation",
      displayMobileCallBlock: true,
      onCloseCallback: handleOnClose,
      displayCloseButton: true,
    });
  }, []);

  React.useEffect(() => {
    setDispatcher(dispatcherRef.current);
  }, [setDispatcher]);

  useEffect(() => {
    const stepIndex = STEPS_ROUTES.indexOf(pathname);
    setActiveStep(stepIndex + 1);
  }, [pathname]);

  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  useEffect(() => {
    if (!isDesktop) {
      document.body.classList.add("withCotisationBlockSticky");
    } else {
      document.body.classList.remove("withCotisationBlockSticky");
    }
    return () => document.body.classList.remove("withCotisationBlockSticky");
  }, [isDesktop]);

  useBlocker((tx) => {
    if (!matchPath(`${RoutePaths.RECONDUCTION_ROUTE}/:state`, tx.location.pathname)) {
      if (
        window.confirm(
          "Vous allez quitter le processus et aucune information déjà saisie ne sera enregistrée. " +
            "\n Etes-vous sûr de vouloir quitter cette page ?",
        )
      ) {
        tx.retry();
      }
    } else {
      tx.retry();
    }
  }, activeStep < StepsReconduction.length - 1);

  return (
    <>
      <Stepper steps={StepsReconduction} activeStep={activeStep} />
      <div className={styles.Reconduction}>
        <Outlet context={{ infosReconduction, isDesktop }} />
      </div>
    </>
  );
};
