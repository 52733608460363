import { selector, selectorFamily } from "recoil";
import { globalState } from "context/globalState/atom";
import { contractApi } from "domain/api/Contract/contractApi";

export const monContratQuery = selectorFamily({
  key: "ContratInfoQuery",
  get: (idAdhesion: string | null) => async () => {
    if (!idAdhesion) {
      return null;
    }

    const response = await contractApi.fetchMonContrat(idAdhesion);
    return response;
  },
});

export const currentContratQuery = selector({
  key: "CurrentContratQuery",
  get: ({ get }) => get(monContratQuery(get(globalState).idAdhesion || null)),
});
