import { useEffect, createContext, useState, useMemo, useCallback, useContext } from "react";
import { useRecoilValueLoadable } from "recoil";
import { monContratState } from "context/currentContratState/atom";
import { useSmartTribuneContext } from "SmartTribuneProvider";
import { useLocation } from "react-router-dom";
import { PublicRoutePaths, RoutePaths } from "routes/routePaths";
import { useBodyEventDelegation } from "hooks/useBodyEventDelegation";
import { useAuthenticationContext } from "AuthenticationProvider";

interface ZendeskContextType {
  openChat: () => void;
  showZendeskChat: () => void;
}

export const ZendeskAlwaysOnPaths = [
  RoutePaths.USER_PROFIL,
  RoutePaths.GARANTIES,
  RoutePaths.CONTRAT_AJOUTER,
  RoutePaths.CONTRAT_CHOISIR,
];

export const ZendeskDisabledPaths = [
  RoutePaths.AIDE,
  PublicRoutePaths.SIGNIN,
  PublicRoutePaths.Error,
];

const ZendeskContext = createContext<ZendeskContextType | undefined>(undefined);

export function useZendeskContext() {
  const context = useContext(ZendeskContext);
  if (context === undefined) {
    throw new Error("useZendeskContextt must be used within an AppContextProvider");
  }
  return context;
}

const ZendeskProvider = ({
  children,
  isEnabled = true,
}: {
  children: JSX.Element;
  isEnabled?: boolean;
}) => {
  const { showSmartPush, hideSmartPush } = useSmartTribuneContext();
  const { pathname } = useLocation();
  const { isAuth0Authenticated, user } = useAuthenticationContext();

  const monContratLoadable = useRecoilValueLoadable(monContratState); // Modification ici
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isZendeskEnabled, setIsZendeskEnabled] = useState(false);

  const showZendeskChat = useCallback(() => {
    if (!isZendeskEnabled) {
      window.zE.activate({ hideOnClose: true });
      hideSmartPush();
    }
  }, [isZendeskEnabled, hideSmartPush]);

  window.showZendeskChat = showZendeskChat;

  const openChat = useCallback(() => {
    if (!isZendeskEnabled) {
      showZendeskChat();
    } else if (window.zE) {
      window.zE.activate();
    }
  }, [isZendeskEnabled, showZendeskChat]);

  const showSmartPushOnZendeskClose = () => {
    if (!window.isZendeskEnabled) {
      showSmartPush();
    }
  };

  useEffect(() => {
    window.isZendeskEnabled = isEnabled && ZendeskAlwaysOnPaths.includes(pathname as RoutePaths);
    setIsZendeskEnabled(window.isZendeskEnabled);
  }, [pathname]);

  useEffect(() => {
    if (isAuth0Authenticated && user?.email_verified && !scriptLoaded && isEnabled) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        setScriptLoaded(true);
      });
    }

    let interval: NodeJS.Timeout;

    if (scriptLoaded) {
      const zendeskStyleTag = document.getElementById("zendeskStyle");
      if (!isZendeskEnabled) {
        if (zendeskStyleTag) {
          zendeskStyleTag.textContent = `
          /* Hide the Zendesk Chat widget container */
          #launcher {
            display: none !important;
          }
        `;
        }
        interval = setInterval(() => {
          if (window.zE) {
            window.zE("webWidget:on", "close", showSmartPushOnZendeskClose);
            clearInterval(Number(interval));
          }
        }, 30);
      } else {
        if (zendeskStyleTag) {
          zendeskStyleTag.textContent = `
          /* Hide the Zendesk Chat widget container */
          #launcher {
            display: block !important;
          }
        `;
        }
        interval = setInterval(() => {
          if (window.zE) {
            window.zE(function () {
              window.$zopim.livechat.badge.hide();
              window.zE.show();
            });
            clearInterval(Number(interval));
          }
        }, 30);
      }
    }

    return () => {
      clearInterval(Number(interval));
    };
  }, [isAuth0Authenticated, user, scriptLoaded, isZendeskEnabled, showSmartPush]);

  useEffect(() => {
    if (
      isAuth0Authenticated &&
      user?.email_verified &&
      scriptLoaded &&
      monContratLoadable.state === "hasValue"
    ) {
      const monContrat = monContratLoadable.contents;
      const { membre, identifiantContrat, vehicule } = monContrat ?? {};
      const membreSurmame = membre?.nom ? (membre?.prenom || "") + " " : "";
      const membreName = membreSurmame + (membre?.nom ?? "");

      const details = [identifiantContrat, vehicule?.immatriculation].filter(Boolean);
      let name = "";
      name = [membreName || "", details.length ? `(${details.join(" / ")})` : ""]
        .filter(Boolean)
        .join(" ");

      window.zE("webWidget", "prefill", {
        name: { value: name, readOnly: false },
        email: { value: user?.email, readOnly: false },
      });
    }
  }, [isAuth0Authenticated, user, scriptLoaded, monContratLoadable]); // Ajout de monContratLoadable ici

  useBodyEventDelegation("#st-push-root", (event, targetElement) => {
    const openChatBtn = (event.target as Element).closest(
      ".contact-button-name-contact-livechat-1079",
    );
    if (openChatBtn) {
      showZendeskChat();
    }
  });

  const value = useMemo(
    () => ({
      openChat,
      showZendeskChat,
    }),
    [openChat, showZendeskChat],
  );

  return <ZendeskContext.Provider value={value}>{children}</ZendeskContext.Provider>;
};

export default ZendeskProvider;
