import axiosClient from "clientProvider/axiosConfig";
import { DossierGarantie } from "domain/api-schemas";

export const dossierApi = {
  fetchEvenements: async (crmId: string, idAdhesion: string) => {
    const result = await axiosClient.get<DossierGarantie>(
      `Evenement/DossierGarantieId/${crmId}/${idAdhesion}`,
    );
    return result.data;
  },
};
