export enum URLS {
  BLOG = "https://www.media.roole.fr",
  MENTIONS_LEGALES = "https://www.roole.fr/mentions-legales/",
  CHARTE_COOKIES = "https://www.roole.fr/cookies/",
  POLITIQUE_CONFIDENTIALITE = "https://www.roole.fr/charte-de-confidentialite/",
  ORIAS = "https://www.orias.fr",
  FACEBOOK = "https://www.facebook.com/RooleFr",
  TWITTER = "https://twitter.com/roole_fr",
  YOUTUBE = "https://www.youtube.com/channel/UCXOLivOE4PLRheo2GbLmAYA",
  INSTAGRAM = "https://www.instagram.com/roole_fr/",
  LINKEDIN = "https://www.linkedin.com/company/roole/",
  TIKTOK = "https://www.tiktok.com/@roole_fr",
  CENTRE_AIDE = "https://www.roole.fr/contact",
}
