import { FC, HTMLAttributes } from "react";
import Link from "components/atoms/Link/Link";
import { Box } from "components/atoms/Box/Box";
import { Title } from "components/atoms/Title/Title";
import { classNames } from "utils/classNames";

import styles from "./Tuile.module.scss";
import { useTracking } from "hooks/useTracking";

type TuileProps = {
  titre: string;
  url: string;
  isExternalUrl?: boolean;
  titreLink: string;
  isDisabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Tuile: FC<TuileProps> = ({
  titre,
  url,
  isExternalUrl = false,
  className,
  titreLink,
  children,
  isDisabled = false,
  id,
}) => {
  const { sendToDataLayer } = useTracking();
  const content = (
    <>
      <Title className={styles.titre} as="h3">
        {titre}
      </Title>
      <div className={styles.tuileContent}>{children}</div>
      <Link
        className={styles.titreLink}
        to={url}
        isExternalUrl={isExternalUrl}
        id={id}
        onClick={(e) => sendToDataLayer(e.currentTarget.id, true)}
      >
        {titreLink}
      </Link>
    </>
  );

  return (
    <Box
      className={classNames(className, styles.container, isDisabled && styles.containerDisabled)}
    >
      {content}
    </Box>
  );
};
