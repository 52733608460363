import { FC } from "react";
import { Title } from "components/atoms/Title/Title";
import { Button } from "components/atoms/Button/Button";
import FormCard from "./CardItems/FormCard";
import UserInformationCard from "./CardItems/UserInformationCard";
import { classNames } from "utils/classNames";
import { Member, UpdateInfosMembreRequest } from "domain/api-schemas";

import styles from "./Page.module.scss";

interface MembreInfosProps {
  isModify: boolean;
  isLoading: boolean;
  member: Member | null;
  hasError: boolean;
  setIsModify: (value: boolean) => void;
  onSubmit: (infosMembreRequest: UpdateInfosMembreRequest) => void;
  sendToDataLayer: (id: string) => void;
}

const MembreInfos: FC<MembreInfosProps> = ({
  isModify,
  isLoading,
  member,
  setIsModify,
  hasError,
  onSubmit,
  sendToDataLayer,
}) => {
  const membreSurmame = member?.prenom ? member.nom + " " : "";
  const membreName = `${membreSurmame}${member?.prenom}`;
  return (
    <>
      <div className={styles.ContractInfo__BoxTitleContainer}>
        <Title className={styles.ContractInfo__Boxtitle}>Mes informations personnelles</Title>
        {!isModify && !isLoading && (
          <Button
            className={styles.ContractInfo__UpdateButton}
            variant="outlined-light"
            size="small"
            onClick={() => setIsModify(true)}
          >
            Modifier
          </Button>
        )}
      </div>
      {isModify ? (
        <FormCard
          defaultValues={{
            ...member,
            completeName: membreName,
            mobilePhone: member?.telephoneMobile,
          }}
          handleOnSubmit={onSubmit}
          abort={() => setIsModify(false)}
          sendToDataLayer={sendToDataLayer}
        />
      ) : (
        <>
          <UserInformationCard
            lineClassName={styles.ContractInfo__line}
            lineClassNameAlt={styles.ContractInfo__line_alt}
            informations={member}
          />
          {isLoading && (
            <span className={styles.UpdateInfo_status}>En cours de sauvegarde ...</span>
          )}
          {hasError && !isLoading && (
            <span className={classNames(styles.UpdateInfo_status, styles.UpdateInfo_status_error)}>
              Votre modification n'a pas pu être sauvegardée, veuillez réessayer plus tard.
            </span>
          )}
        </>
      )}
    </>
  );
};

export default MembreInfos;
