import { FC } from "react";
import { TextV2, Tag, Message, ButtonV2 } from "@roole/components-library";
import styles from "./RefuseContent.module.scss";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";
import { useAuthenticationContext } from "AuthenticationProvider";

export const RefuseContent: FC<{ motifRefus: string }> = ({ motifRefus }) => {
  const navigate = useNavigate();
  const { isJwtAuthenticated } = useAuthenticationContext();

  return (
    <div>
      <div className={styles.header}>
        <TextV2 textVariant="heading-3">Oups...</TextV2>
        <Tag type="neutral" label="Refusé" />
      </div>
      <TextV2 textVariant="body-medium">
        Nous regrettons de vous informer que votre sinistre ne peut être pris en charge car les
        critères d'éligibilité de la garantie proposée ne sont pas respectés.
      </TextV2>
      <div className={styles.message}>
        <Message
          icon="info"
          type="information"
          message={
            <span>
              <TextV2 textVariant="body-small-em">Motif du refus · </TextV2>
              <TextV2 textVariant="body-small">{motifRefus}</TextV2>
            </span>
          }
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV2
          variant="secondary-dark"
          size="large"
          onClick={() => {
            sendCloseWebViewEvent({ hasError: false });
            if (!isJwtAuthenticated) {
              navigate(RoutePaths.SINISTRES);
            }
          }}
        >
          Retour à mes sinistres
        </ButtonV2>
      </div>
    </div>
  );
};
