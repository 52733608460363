import { FC } from "react";
import { formatDate } from "utils/formatDate";
import { ReactComponent as Telechargement } from "images/icons/telechargement.svg";
import { ExternalLink } from "components/atoms/ExternalLink/ExternalLink";
import { useTracking } from "hooks/useTracking";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "pages/Reconduction/Page";

import styles from "./GarantiesDescription.module.scss";

const GarantiesDescription: FC<{ isExpired: boolean }> = ({ isExpired }) => {
  const { infosReconduction } = useOutletContext<OutletContextType>();
  const { sendToDataLayer } = useTracking();

  const { urlIpid, urlFic, urlCgv } = infosReconduction?.infosContratReconduction || {};
  return (
    <div className={styles.GarantiesDescription}>
      <div className={styles.GarantiesDescription__title}>
        {isExpired
          ? "Votre cotisation est arrivée à son terme. "
          : "Votre cotisation arrive à son terme"}
      </div>

      <div className={styles.GarantiesDescription__subTitle}>
        {isExpired
          ? `Pour être à nouveau couvert par vos garanties et retrouver tous vos Bons Plans, réglez votre cotisation. Votre contrat s'est terminé le ${formatDate(
              infosReconduction?.infosContratReconduction?.dateFin || "",
            )}.`
          : `Pour prolonger la couverture de vos garanties et profiter de vos Bons Plans, réglez votre cotisation. Votre contrat se termine le ${formatDate(
              infosReconduction?.infosContratReconduction?.dateFin || "",
            )}.`}
      </div>
      <div className={styles.GarantiesDescription__links}>
        {!!urlIpid && (
          <ExternalLink
            onClick={() => sendToDataLayer("reconduction-ipid")}
            icon={<Telechargement />}
            href={urlIpid}
          >
            Document d'information sur le produit d'assurance
          </ExternalLink>
        )}
        {!!urlFic && (
          <ExternalLink
            onClick={() => sendToDataLayer("reconduction-fic")}
            icon={<Telechargement />}
            href={urlFic}
          >
            Fiche d'information Client
          </ExternalLink>
        )}
        <ExternalLink
          onClick={() => sendToDataLayer("reconduction-cg")}
          icon={<Telechargement />}
          href={urlCgv}
        >
          Conditions générales
        </ExternalLink>
      </div>
    </div>
  );
};

export default GarantiesDescription;
