import { useMutation, useQuery } from "react-query";
import {
  InfosReconduction,
  MonContrat,
  ReconductionData,
  ReconductionResult,
} from "domain/api-schemas";
import { contractApi } from "domain/api/Contract/contractApi";
import { addDays, differenceInDays, subDays } from "date-fns";

export type {
  InfosReconduction,
  ReconductionData,
  ReconductionResult,
  InfosContratReconduction,
} from "domain/api-schemas";

export const useReconductionContract = (idAdhesion?: string | null) =>
  useQuery<InfosReconduction, Error>(
    "query-reconduction",
    async () => contractApi.fetchReconductionContrat(idAdhesion!),
    { enabled: !!idAdhesion },
  );

export const useReconduction = () =>
  useMutation<ReconductionResult, Error, ReconductionData>(
    async (data) => await contractApi.postReconduction(data),
  );

export const canRenewContract = (contrat: MonContrat) => {
  const dateDebutRenou = subDays(new Date(contrat.dateFin), 60);
  const dateFinRenouDerniereChance = addDays(new Date(contrat.dateFin), 90);
  const today = new Date();
  return dateDebutRenou < today && today < dateFinRenouDerniereChance;
};

export const getJourRestantPeriodeReconduction = (contrat: MonContrat) => {
  const dateFinRenou = addDays(new Date(contrat.dateFin), 30);
  return differenceInDays(dateFinRenou, new Date());
};

export const getJourRestantDerniereChance = (contrat: MonContrat) => {
  const dateFinRenou = addDays(new Date(contrat.dateFin), 90);
  return differenceInDays(dateFinRenou, new Date());
};
