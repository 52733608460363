import { useCallback, useRef, useState } from "react";
import { Modal, Text, OverlayLoader } from "@roole/components-library";

import { Title } from "components/atoms/Title/Title";
import { Button } from "components/atoms/Button/Button";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { DealershipBox } from "components/molecules/DealershipBox/DealershipBox";
import { DealershipList } from "components/molecules/DealershipList/DealershipList";
import { AppointmentStatus } from "components/molecules/AppointmentSchedule/AppointmentStatus";
import { AppointmentSchedule } from "components/molecules/AppointmentSchedule/AppointmentSchedule";
import {
  Appointment,
  AppointmentContext,
} from "components/molecules/AppointmentSchedule/Appointment";
import { BonPlan, Concession } from "domain/api-schemas";
import { CustomSkeletonTheme } from "styles/themes/CustomSkeletonTheme";
import { useMyGarage } from "domain/service/MyGarage/useMyGarage";
import { useTracking } from "hooks/useTracking";

import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./MyGarage.module.scss";
import { ModalAPI, ModalWithControl } from "components/molecules/Modal/ModalWithControl";
import { AppointmentModal } from "components/molecules/AppointmentModal/AppointmentModal";

export const MyGarage = () => {
  const articleRef = useRef<HTMLElement | null>(null);
  const rdvModalRef = useRef<ModalAPI>(null);
  const [appointmentCtx, setAppointmentCtx] = useState<AppointmentContext | null>(null);
  const { sendToDataLayer } = useTracking();
  const {
    isLoading,
    isMyGarageLoading,
    isDealershipsLoading,
    isActivating,
    isConnecting,
    isScheduling,
    isSchedulingPartner,
    myDealership,
    otherDealerships,
    filters,
    selectedFilters,
    hasMoreDealerships,
    setPartnerDealership,
    activateHotDeal,
    getNextDealerships,
    changeFilters,
    connectDealership,
    scheduleAppointment,
    schedulePartnerAppointment,
  } = useMyGarage();

  useSmartPushFilters(["bons-plans-auto-4650"]);

  const makeAppointmentHandler = useCallback(
    (dealership: Concession): void => {
      sendToDataLayer("mon-garage-prise-rdv");

      if (dealership.optionRDV === AppointmentStatus.RICH_DIGITALIZED) {
        setPartnerDealership(dealership);
        rdvModalRef.current?.toggle();
      } else if (
        dealership.optionRDV &&
        [AppointmentStatus.DIGITALIZED].includes(dealership.optionRDV)
      ) {
        scheduleAppointment(
          { dealership },
          {
            onSuccess: () => {
              if (dealership.urlRDV) {
                window.open(dealership.urlRDV, "_blank");
              }
            },
          },
        );
      } else if (dealership.optionRDV === AppointmentStatus.SEMI_DIGITALIZED) {
        setAppointmentCtx({ opened: true, dealership });
      }
    },
    [setAppointmentCtx, scheduleAppointment],
  );

  const scheduleAppointmentHandler = useCallback(
    (appointment: Appointment) => {
      if (appointmentCtx?.dealership) {
        scheduleAppointment(
          { appointment, dealership: appointmentCtx.dealership },
          {
            onSuccess: () => setAppointmentCtx((value) => ({ ...value!, scheduled: true })),
          },
        );
      }
    },
    [scheduleAppointment, appointmentCtx],
  );

  const activateHotDealHandler = useCallback(
    (hotDeal: BonPlan, dealership: Concession) => () => {
      if (!hotDeal.active) {
        sendToDataLayer("mon-garage-recevoir-coupon");
      }
      if (hotDeal.id && dealership.id) {
        activateHotDeal({ idBonPlan: hotDeal.id, idConcession: dealership.id });
      }
    },
    [sendToDataLayer, activateHotDeal],
  );

  const disclosureHotDealHandler = useCallback(
    () => (expanded: boolean) => {
      if (expanded) {
        sendToDataLayer("mon-garage-voir-plus");
      }
    },
    [sendToDataLayer],
  );

  const connectDealershipHandler = useCallback(
    (dealership: Concession) => {
      sendToDataLayer("mongarage-associerdistrib");
      connectDealership(
        { dealership },
        {
          onSuccess: () => articleRef.current?.scrollIntoView({ behavior: "smooth" }),
        },
      );
    },
    [sendToDataLayer, connectDealership],
  );

  const cancelAppointmentHandler = () => {
    setAppointmentCtx({ opened: false });
  };

  const nextDealerships = isLoading
    ? [...(otherDealerships || []), {}, {}, {}, {}]
    : otherDealerships;

  return (
    <article className={styles.myGarage} ref={articleRef}>
      {isLoading || myDealership ? (
        <CustomSkeletonTheme>
          {(isActivating || isConnecting || isScheduling) && <OverlayLoader />}
          <Modal
            title="Être rappelé pour un rendez-vous"
            content={
              <AppointmentSchedule
                scheduled={appointmentCtx?.scheduled}
                onSubmit={scheduleAppointmentHandler}
                onCancel={cancelAppointmentHandler}
              />
            }
            isOpen={appointmentCtx?.opened}
            handleClose={cancelAppointmentHandler}
          />

          <ModalWithControl
            ref={rdvModalRef}
            open={false}
            onOverlayClick={() => rdvModalRef.current?.toggle(false)}
          >
            <AppointmentModal
              isScheduling={isSchedulingPartner}
              schedulePartnerAppointment={schedulePartnerAppointment}
              onClose={() => rdvModalRef.current?.toggle(false)}
            />
          </ModalWithControl>

          <Title className={styles.myGarage__title} as="h1">
            Mon garage
          </Title>
          <DealershipBox
            dealership={myDealership}
            disabled={isMyGarageLoading}
            onActivateHotDeal={activateHotDealHandler}
            onDisclosureHotDeal={disclosureHotDealHandler}
            onMakeAppointment={makeAppointmentHandler}
          />
          <DealershipList
            className={styles.myGarage__dealershipList}
            favoriteDealership={myDealership}
            dealerships={nextDealerships}
            filters={filters}
            selectedFilters={selectedFilters}
            disabled={isDealershipsLoading}
            onActivateHotDeal={activateHotDealHandler}
            onDisclosureHotDeal={disclosureHotDealHandler}
            onMakeAppointment={makeAppointmentHandler}
            onFiltersChange={changeFilters}
            onConnectDealership={connectDealershipHandler}
          />
          <Text className={styles.myGarage__legalInformation} textVariant="body" as="p">
            Les bons plans auto sont soumis aux conditions d'éligibilité et de durée du garage. Ces
            conditions sont disponibles directement auprès de celui-ci et relèvent de sa
            responsabilité.
          </Text>
          {!isLoading && hasMoreDealerships && (
            <Button
              className={styles.myGarage__moreDealershipsBtn}
              type="button"
              onClick={() => getNextDealerships()}
              variant="outlined-light"
              size="large"
            >
              Voir les 5 garages suivants
            </Button>
          )}
        </CustomSkeletonTheme>
      ) : (
        <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
      )}
    </article>
  );
};
