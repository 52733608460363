import { FC } from "react";
import { CallCard } from "components/molecules/CallCard/CallCard";
import { classNames } from "utils/classNames";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";

import styles from "./CallBlock.module.scss";

interface CallBlockProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  text?: string;
  sendToDataLayer?: () => void;
}

const CallBlock: FC<CallBlockProps> = ({
  children,
  title,
  text,
  className,
  sendToDataLayer,
}: CallBlockProps) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const defaultText = "Nous sommes à votre écoute du lundi au vendredi, de 9h à 18h";
  return (
    <div className={classNames(styles.CallBlock, className)}>
      {title && <p className={styles.CallBlock__title}>{title}</p>}

      <span className={styles.CallBlock__content}>{children || defaultText}</span>

      <div>
        <CallCard
          className={styles.CallBlock__callCardButton}
          variant={isDesktop ? "secondary" : "outlined-dark"}
          sendToDataLayer={sendToDataLayer}
          text={text}
        />
        {isDesktop && <>Appel gratuit</>}
      </div>
    </div>
  );
};

export default CallBlock;
