import { FC, PropsWithChildren } from "react";
import { Text } from "@roole/components-library";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import styles from "./CustomCalendarHeader.module.scss";

const HeaderLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <div className={styles.customCalendarHeader}>{children}</div>;
};

export const CustomCalendarHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <HeaderLayout>
      <Text textVariant="body" className={styles.customCalendarHeader__date}>
        {monthDate.toLocaleString("fr-FR", {
          month: "long",
          year: "numeric",
        })}
      </Text>
      <button
        type="button"
        className="react-datepicker__navigation"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        aria-label="Mois précédent"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
          Mois précédent
        </span>
      </button>
      <button
        type="button"
        className="react-datepicker__navigation"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        aria-label="Mois suivant"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
          Mois suivant
        </span>
      </button>
    </HeaderLayout>
  );
};
