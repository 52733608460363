import { PropsWithChildren, useEffect, FC } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalState } from "context/globalState/atom";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { authentificationApi } from "domain/api/Authentification/Authentification";
import { userListContratsState } from "context/contratsState/atom";
import { getInactifContractsCount, getFirstValidContractIdAdhesion } from "utils/nbExpiredContract";

const HomePage: FC<PropsWithChildren<{}>> = ({ children }) => {
  const contracts = useRecoilValue(userListContratsState);
  const expiredContracts = getInactifContractsCount(contracts);
  const setGlobalState = useSetRecoilState(globalState);

  const navigate = useNavigate();

  useEffect(() => {
    authentificationApi.linkUser();
  }, []);

  useEffect(() => {
    if (
      contracts.length === 0 ||
      (expiredContracts === contracts.length && contracts.length <= 1)
    ) {
      setGlobalState((state) => ({
        ...state,
        idAdhesion: getFirstValidContractIdAdhesion(contracts),
      }));
      navigate(RoutePaths.CONTRAT_AJOUTER, { replace: true });
    } else if (contracts.length - expiredContracts === 1) {
      setGlobalState((state) => ({
        ...state,
        idAdhesion: getFirstValidContractIdAdhesion(contracts),
      }));
      navigate(RoutePaths.CONTRAT_HOME, { replace: true });
    } else {
      navigate(RoutePaths.CONTRAT_CHOISIR, { replace: true });
    }
  }, [contracts, navigate, setGlobalState, expiredContracts]);

  return <>{children}</>;
};

export default HomePage;
