import { evenementApi, TypesEvenementsParams } from "domain/api/Sinistre/EvenementApi";
import { selectorFamily } from "recoil";

export const listTypesEvenementsQuery = selectorFamily({
  key: "listTypesEvenementsQuery",
  get: (params: TypesEvenementsParams | null) => async () => {
    if (params == null) {
      return [];
    }
    return await evenementApi.fetchTypesEvenements(params);
  },
});
