import { FC, PropsWithChildren } from "react";

import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";

import styles from "./Box.module.scss";

interface BoxProps extends CommonProps {
  variant?: "primary" | "secondary" | "accent";
}

export const Box: FC<PropsWithChildren<BoxProps>> = ({
  className,
  variant = "primary",
  ...props
}) => <div className={classNames(styles.box, styles[`box--${variant}`], className)} {...props} />;
