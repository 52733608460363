import { evenementApi } from "domain/api/Sinistre/EvenementApi";
import { globalState } from "context/globalState/atom";
import { selector, selectorFamily, atom } from "recoil";

export const refreshEvenementsState = atom({
  key: "refreshEvenementsState",
  default: 0,
});

export const evenementsQuery = selectorFamily({
  key: "evenementsQuery",
  get: (idAdhesion: string | null) => async () => {
    if (!idAdhesion) {
      return null;
    }

    return await evenementApi.fetchEvenements(idAdhesion);
  },
});

export const listEvenementsQuery = selector({
  key: "listEvenementsQuery",
  get: ({ get }) => {
    get(refreshEvenementsState);
    const idAdhesion = get(globalState).idAdhesion || null;
    return get(evenementsQuery(idAdhesion));
  },
});

export const evenementsState = atom({
  key: "evenementsState",
  default: {
    evenementsEncours: [],
    evenementsTermines: [],
  },
});
