import axiosClient from "clientProvider/axiosConfig";
import { EligibiliteGarantie } from "domain/api-schemas";

export type ExclusionGarantie = {
  garantieCode: string;
  evenementCode: string;
};

export const exclusionGarantieApi = {
  fetchExclusionsGarantie: async (params: ExclusionGarantie) => {
    const result = await axiosClient.get<EligibiliteGarantie[]>(
      `Garanties/ExclusionsByGarantieAndEvenement`,
      {
        params,
      },
    );
    return result.data;
  },
};
