import { useMutation, useQueryClient } from "react-query";
import { membreApi } from "domain/api/Membre/membreApi";

export const useParrainage = (idAdhesion: string) => {
  const queryClient = useQueryClient(); /// TODO: a supprimer
  return useMutation(membreApi.sendParrainage, {
    onMutate: (data) => {
      // append idAdhesion to data
      queryClient.setQueryData("parrainageEmails", () =>
        Object.assign(data, { idAdhesion: idAdhesion?.toString() }),
      );
    },
  });
};
