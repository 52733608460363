import { DropDown, DropDownItem, Text } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { contactUsStepState, CallBackPeriod } from "context/contactUsState/atom";
import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";
import { InputPhone } from "components/atoms/InputPhone/InputPhone";
import { monContratState } from "context/currentContratState/atom";
import { AbstractContactUs } from "components/atoms/AbstractContactUs/AbstractContactUs";
import { InputArea } from "components/atoms/InputArea/InputArea";
import { DatePickerFr } from "components/atoms/DatePickerFr/DatePickerFr";
import { useTracking } from "hooks/useTracking";
import { ALL_PHONE_NUMBER } from "utils/regex";
import { classNames } from "utils/classNames";
import { formatDateDayMonth } from "utils/formatDate";
import { RoutePaths } from "routes/routePaths";

import commonStyles from "pages/ContactUs/Page.module.scss";
import styles from "./ContactTelephoneStep.module.scss";

type TelephoneInputs = {
  telephoneNumber: string;
  callBackDate: string;
  callBackPeriod: DropDownItem;
  message: string;
};

const periods: DropDownItem[] = [
  { id: 1, name: "Créneau de rappel souhaité ​*", disabled: true },
  { id: 2, name: "Matin (9h - 13h)", disabled: false },
  { id: 3, name: "Après-midi (13h - 18h)", disabled: false },
];

const validateTelephoneNumber = (value: string) => {
  const newValue = value.trim().replace(/\s/g, "");
  const result = ALL_PHONE_NUMBER.test(newValue);
  return result;
};
const validations = {
  telephoneNumber: {
    required: {
      value: true,
      message: "Ce champ est obligatoire",
    },
    validate: {
      telephoneNumber: (value: string) =>
        validateTelephoneNumber(value) ||
        "Veuillez saisir un numéro de téléphone valide (10 chiffres)",
    },
  },
};

export const ContactTelephoneStep: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const monContratContextState = useRecoilValue(monContratState);
  const navigate = useNavigate();
  const [errorScript, setErrorScript] = useState<boolean>(false);
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    if (contactUsStepContextState.subject === null) {
      navigate(RoutePaths.CONTACT_REASON);
    }
    setContactUsStepContextState({
      ...contactUsStepContextState,
      activeStep: 3,
    });
  }, []);

  const { control, handleSubmit, getValues } = useForm<TelephoneInputs>({
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const phoneValueChangedHandle = (newValue: string, onChange: (...event: any[]) => void) => {
    if (!!contactUsStepContextState.errors?.telephoneNumber) {
      setContactUsStepContextState({
        ...contactUsStepContextState,
        errors: {
          ...contactUsStepContextState.errors,
          telephoneNumber: null,
        },
      });
    }
    onChange(newValue);
  };

  const onChangeDateHandler = (onChange: (...event: any[]) => void, item: Date | null) => {
    if (!!contactUsStepContextState.errors?.callBackDate) {
      setContactUsStepContextState({
        ...contactUsStepContextState,
        errors: {
          ...contactUsStepContextState.errors,
          callBackDate: undefined,
        },
      });
    }
    onChange(formatDateDayMonth(item));
  };

  const onChangePeriodHandler = (
    onChange: (...event: any[]) => void,
    item: DropDownItem | null,
  ) => {
    if (!!contactUsStepContextState.errors?.callBackPeriod) {
      setContactUsStepContextState({
        ...contactUsStepContextState,
        errors: {
          ...contactUsStepContextState.errors,
          callBackPeriod: undefined,
        },
      });
    }
    onChange(item);
  };

  const onSubmit: SubmitHandler<TelephoneInputs> = async (data) => {
    if (
      ALL_PHONE_NUMBER.test(data.telephoneNumber) &&
      !!data.callBackDate &&
      data.callBackPeriod.disabled !== true &&
      !errorScript
    ) {
      setContactUsStepContextState({
        ...contactUsStepContextState,
        telephoneNumber: data.telephoneNumber,
        callBackDate: data.callBackDate,
        callBackPeriod:
          data.callBackPeriod.name === "Matin (9h - 13h)"
            ? CallBackPeriod.Morning
            : CallBackPeriod.Afternoon,
        messageValue: data.message,
        activeStep: 4,
      });
      sendToDataLayer("contactez-nous-canal-telephone");
      navigate(RoutePaths.CONTACT_CONFIRMATION);
    } else {
      const telephoneError = !ALL_PHONE_NUMBER.test(data.telephoneNumber)
        ? "Veuillez saisir un numéro de téléphone valide (10 chiffres)"
        : null;
      const periodError = data.callBackPeriod.disabled
        ? "Veuillez saisir un créneau de rappel"
        : null;
      const dateError = !data.callBackDate ? "Veuillez sélectionner une date de rappel" : null;

      setContactUsStepContextState({
        ...contactUsStepContextState,
        errors: {
          ...contactUsStepContextState.errors,
          telephoneNumber: telephoneError,
          callBackPeriod: periodError,
          callBackDate: dateError,
        },
      });
    }
  };

  const PreviousClickHandler = () => {
    setContactUsStepContextState({
      ...contactUsStepContextState,
      telephoneNumber: getValues("telephoneNumber"),
      callBackDate: getValues("callBackDate"),
      messageValue: getValues("message"),
    });
    navigate(RoutePaths.CONTACT_TYPE);
  };
  const today = new Date();
  const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Détails de votre demande
        </Text>
        <br />
        <Text>
          La relation client est à votre service pour toutes questions concernant la gestion de
          votre contrat, une déclaration de sinistre ou autres.
        </Text>
      </div>
      <Card noMargin className={styles.contactUsTelephoneStep__Card}>
        <Text as="h2" textVariant="heading-4">
          Vous souhaitez programmer votre rappel ?
        </Text>
        <br />
        <AbstractContactUs
          subject={contactUsStepContextState?.subject}
          reason={contactUsStepContextState?.reason}
        />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            control={control}
            name="telephoneNumber"
            defaultValue={
              monContratContextState?.membre.telephoneMobile ||
              monContratContextState?.membre.telephoneFixe ||
              ""
            }
            rules={{ ...validations.telephoneNumber }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <InputPhone
                  phoneValue={value || contactUsStepContextState.telephoneNumber || null}
                  errors={
                    contactUsStepContextState.errors?.telephoneNumber || error?.message || null
                  }
                  handlePhoneChanged={(newValue: string) => {
                    phoneValueChangedHandle(newValue, onChange);
                  }}
                  label="Votre numéro de téléphone *"
                  required
                />
              );
            }}
          />
          <Controller
            control={control}
            name="callBackDate"
            defaultValue=""
            render={({ field: { onChange } }) => (
              <>
                <DatePickerFr
                  onChange={(item: Date | null) => onChangeDateHandler(onChange, item)}
                  wrapperClassName={classNames(
                    styles.contactUsTelephoneStep__DateInput,
                    contactUsStepContextState.errors?.callBackDate &&
                      styles.contactUsTelephoneStep__DateInput__Error,
                  )}
                  placeholderText={"Date de rappel souhaitée *"}
                  minDate={minDate}
                  weekendOff
                />
                <>
                  {contactUsStepContextState.errors?.callBackDate && (
                    <Text as="p" className={styles.contactUsTelephoneStep__Error}>
                      {contactUsStepContextState.errors?.callBackDate}
                    </Text>
                  )}
                </>
              </>
            )}
          />
          <Controller
            control={control}
            name="callBackPeriod"
            defaultValue={periods[0]}
            render={({ field: { value: valueSub, onChange } }) => (
              <div>
                <DropDown
                  possibleValues={periods}
                  setSelectedValue={(item: DropDownItem | null) => {
                    onChangePeriodHandler(onChange, item);
                  }}
                  selectedValue={valueSub || periods[0]}
                  error={contactUsStepContextState.errors?.callBackPeriod || undefined}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="message"
            defaultValue=""
            render={({ field: { onChange } }) => (
              <InputArea
                placeholder="Veuillez nous expliquer votre problème (optionnel). 1 000 caractères maximum."
                maxLength={1000}
                onChange={onChange}
                className={styles.contactUsTelephoneStep__InputArea}
                setError={setErrorScript}
              />
            )}
          />

          <NavigationButtons
            prevProps={{
              onClick: () => PreviousClickHandler(),
              gtmElementId: "contactez-nous-telephone-bouton-precedent",
            }}
            nextProps={{
              onClick: () => undefined,
              type: "submit",
              text: "Envoyer",
            }}
          />
        </form>
      </Card>
    </>
  );
};
