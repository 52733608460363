import { WizardNav, WizardStep, WizardStepLayout, NEXT_BTN } from "components/molecules/Wizard";
import { InputText, Text, Loader } from "@roole/components-library";
import styles from "./ConfirmationStep.module.scss";
import { classNames } from "utils/classNames";
import { useCallback, useEffect, useState } from "react";
import { monContratState } from "context/currentContratState/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { validateIBan } from "utils/iban";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { CoordonneesBancairesData, MonContrat } from "domain/api-schemas";
import { regularisationState } from "context/regularisationState/atom";
import { getMaskedIban } from "utils/MaskedIban";
import { useTracking } from "hooks/useTracking";
import { useUpdateIban } from "domain/service/Member/useUpdateMember";

export type IbanFormInputs = {
  iban: string;
};

export const ConfirmationStep: WizardStep = () => {
  const [checked, setChecked] = useState(false);
  const [monContrat, setMonContrat] = useRecoilState(monContratState);
  const { control, handleSubmit, setValue } = useForm<IbanFormInputs>();
  const navigate = useNavigate();
  const dossierId = useRecoilValue(regularisationState);
  const iban = monContrat?.coordonneesBancaires.iban ?? "";
  const maskedIban = getMaskedIban(iban);
  const { sendToDataLayer } = useTracking();
  const { mutateAsync: updateCoordonneesBancaires, isLoading: loader } = useUpdateIban(
    monContrat?.idAdhesion ?? "",
  );

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (checked) {
      setValue("iban", iban);
    } else {
      setValue("iban", "");
    }
  }, [checked]);

  const validations = {
    iban: {
      required: {
        value: true,
        message: "Ce champ est obligatoire",
      },
      validate: {
        iban: (value: string) =>
          validateIBan(value.trim()) || "Ce champ doit être saisi au bon format",
      },
    },
  };
  const submitForm = useCallback(() => {
    if (checked) {
      handleSubmit(onSubmit)();
    } else {
      navigate(RoutePaths.CONTRAT);
    }
  }, [checked, handleSubmit]);

  const onSubmit: SubmitHandler<IbanFormInputs> = async (input) => {
    const data: CoordonneesBancairesData = {
      dossierRegulId: dossierId,
      iban: input.iban,
    };
    sendToDataLayer("regularisation-changement-iban", false);
    await updateCoordonneesBancaires(data);
    setMonContrat({
      ...monContrat,
      coordonneesBancaires: {
        iban: input.iban,
        Id: "",
        titulaire: "",
      },
    } as MonContrat);
    navigate(RoutePaths.CONTRAT);
  };

  return (
    <WizardStepLayout title={""}>
      {loader && (
        <div className={classNames(styles.center)}>
          <div className={classNames(styles.loaderContainer)}>
            <Loader />
          </div>
        </div>
      )}
      {!loader && (
        <>
          <Text textVariant="heading-4" as="h3">
            Merci! Tout est bon pour nous!
          </Text>
          <Text textVariant="p" as="p">
            Votre situation est bien régularisée, votre paiement s'est bien passé!
          </Text>
          <div className={classNames(styles.flex)}>
            <label className={classNames(styles.switch)}>
              <input type="checkbox" checked={checked} onChange={handleChange} />
              <span className={classNames(styles.slider, styles.round)}></span>
            </label>
            <Text textVariant="p" as="p">
              Modifier mes coordonnées bancaires
            </Text>
          </div>
          <div>
            <Text
              textVariant="body"
              as="h5"
              className={checked ? "" : classNames(styles.textDisabled)}
            >
              IBAN
            </Text>
            <Text
              textVariant="body"
              as="h5"
              disabled={true}
              className={checked ? "" : classNames(styles.textDisabled)}
            >
              <b>Titulaire</b> : {monContrat?.membre.prenom} {monContrat?.membre.nom}
            </Text>
            <Controller
              control={control}
              name="iban"
              rules={{ ...validations.iban }}
              render={({ field, fieldState: { error } }) => (
                <InputText
                  border
                  type="text"
                  {...field}
                  minLength={error?.type === "iban" ? (field.value || "").length + 1 : -1}
                  placeholder={maskedIban}
                  disabled={!checked}
                  error={error?.message}
                />
              )}
            />
          </div>
        </>
      )}
      <WizardNav
        nextStepLabel="Terminer"
        buttons={NEXT_BTN}
        nextStepHandler={submitForm}
        disabled={loader}
      />
    </WizardStepLayout>
  );
};
