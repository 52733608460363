import styles from "./BonPlanCard.module.scss";
import { useNavigate } from "react-router-dom";
import { DrawerMobile, ModalV2, Text } from "@roole/components-library";
import { classNames } from "utils/classNames";
import { BonPlanMobilite } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";
import { RoutePaths } from "routes/routePaths";
import { useState } from "react";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { useRecoilValue } from "recoil";
import { monContratState } from "context/currentContratState/atom";
import { numRoole } from "pages/Garanties/Page";
import { formatPhoneNumber } from "utils/formatPhoneNumber";

export interface BonPlanCardProps {
  size?: "small" | "large";
  bonPlan: BonPlanMobilite;
  className?: string;
}

const BonPlanCard: React.FC<BonPlanCardProps> = ({ size = "small", bonPlan, className }) => {
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const monContrat = useRecoilValue(monContratState);
  const [openModal, setOpenModal] = useState(false);
  const [labelButton, setLabelButton] = useState<string | undefined>("Appeler Roole");
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const modalProps = function (isMobile: boolean = false) {
    return {
      title: "Passez membre Roole Premium",
      content: (
        <>
          <div
            style={{
              textAlign: "center",
              marginBottom: isMobile ? "16px" : "-16px",
              marginTop: "16px",
            }}
          >
            <Text textVariant="body">
              Ce bon plan est réservé aux membres Roole Premium. Envie d'en bénéficier&nbsp;?
            </Text>
            <br />
            <br />
            <Text textVariant="body">
              Contactez-nous dès maintenant pour changer de statut et accéder à tous nos bons
              plans&nbsp;!
            </Text>
          </div>
        </>
      ),
      isOpen: openModal,
      labelPrimary: labelButton,
      onClickPrimary: () => {
        const contactNumber = monContrat?.numeroTelephoneContact ?? numRoole;
        setLabelButton(contactNumber);
        if (isMobile) {
          window.location.href = `tel:${formatPhoneNumber(contactNumber)}`;
        }
      },
      handleClose: () => {
        setOpenModal(false);
      },
    };
  };

  return (
    bonPlan && (
      <>
        <div
          id={`bons-plans-${bonPlan.partner?.name
            ?.trim()
            .toLocaleLowerCase()
            .replace(/\s/g, "-")
            .replace(/-/g, "_")}`}
          className={classNames(styles.BonPlanCard, className)}
          onClick={(e) => {
            sendToDataLayer(e.currentTarget.id, false);
            if (bonPlan.isActive) {
              navigate(`${RoutePaths.BON_PLANS}?id=${bonPlan.id}`);
            } else {
              setOpenModal(true);
            }
          }}
        >
          <div
            className={classNames(
              styles.BonPlanIllustration,
              size === "large" && styles.BonPlanIllustration_large,
            )}
            style={{
              background: `linear-gradient(3deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%), url("${bonPlan.banner?.picture?.url}"), lightgray 50% / cover no-repeat`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              ...(!bonPlan.isActive && { filter: "grayscale(100%)" }),
            }}
          >
            <div className={styles.companyLogoWrapper}>
              <img
                src={bonPlan.partner?.logo?.url}
                alt="companyLogo"
                className={styles.companyLogo}
              />
            </div>

            {bonPlan.customLabels && bonPlan.customLabels.length > 0 && (
              <Text
                textVariant="body-em"
                as="span"
                className={styles.offerType}
                style={{
                  backgroundColor: bonPlan.customLabels[0].backgroundColor,
                  color: bonPlan.customLabels[0].color,
                }}
              >
                {bonPlan.customLabels[0].text}
              </Text>
            )}
          </div>

          <div className={styles.footer}>
            <Text
              as="h3"
              textVariant="body-em"
              className={styles.bonPlanName}
              dangerouslySetInnerHTML={{ __html: bonPlan.banner?.text }}
            />

            <Text as="p" textVariant="ui-1" className={styles.ctaMapText}>
              {bonPlan.banner?.map}
            </Text>
          </div>
        </div>
        {isMobile ? <DrawerMobile {...modalProps(true)} /> : <ModalV2 {...modalProps()} />}
      </>
    )
  );
};

export default BonPlanCard;
