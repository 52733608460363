import { FC } from "react";
import { DossierGarantie } from "domain/api-schemas";
import { TextV2, Avatar, IconV2 } from "@roole/components-library";
import { formatDate } from "utils/formatDate";
import styles from "./Tile.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";

interface TileProps {
  dossierData: DossierGarantie;
}

export const Tile: FC<TileProps> = ({ dossierData }) => {
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  return (
    <div className={styles.tile}>
      <div className={styles.tileInfo}>
        <div className={styles.avatar}>
          <Avatar
            icon={dossierData.garantie.icone ?? "image"}
            type="icon"
            size="3XL"
            variant="brand_light"
          />
        </div>
        <div className={styles.dossierInfo}>
          <TextV2 textVariant="heading-3">{dossierData.garantie.nom}</TextV2>
          <div className={styles.textInfo}>
            <TextV2 textVariant={isMobile ? "body-small" : "body-medium"}>
              {`${dossierData?.typeEvenement.nom} du ${formatDate(dossierData?.dateEvenement)}`}
            </TextV2>
            <TextV2
              textVariant={isMobile ? "body-small" : "body-medium"}
            >{`Dossier ${dossierData.nom}`}</TextV2>
          </div>
        </div>
      </div>
      <div className={styles.dossierDate}>
        <div className={styles.calendarDate}>
          <IconV2 name="calendar" size={16} />
          <TextV2 textVariant="body-small">{`Crée le ${formatDate(
            dossierData.dateOuverture,
          )}`}</TextV2>
        </div>
        {dossierData.dateDerniereAction && (
          <TextV2 textVariant="body-small" color="secondary">
            {`Mis à jour le ${formatDate(dossierData.dateDerniereAction)}`}
          </TextV2>
        )}
      </div>
    </div>
  );
};
