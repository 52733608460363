import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { globalState } from "context/globalState/atom";
import { Button } from "components/atoms/Button/Button";
import { RoutePaths } from "routes/routePaths";
import { classNames } from "utils/classNames";
import { useTracking } from "hooks/useTracking";

import styles from "./CotisationButton.module.scss";

const PayerCotisationButton: React.FC<{
  isRenouvellementContrat: boolean;
  contratIdAdhesion?: string;
  className?: string;
}> = ({ isRenouvellementContrat, contratIdAdhesion, className }) => {
  const [globalContextState, setGlobalState] = useRecoilState(globalState);
  const { idAdhesion } = globalContextState;

  const setItemSelected = useCallback(() => {
    if (contratIdAdhesion && contratIdAdhesion !== idAdhesion) {
      setGlobalState({ ...globalContextState, idAdhesion: contratIdAdhesion });
    }
  }, [contratIdAdhesion, globalContextState, idAdhesion, setGlobalState]);

  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const handleOnClick = () => {
    sendToDataLayer("reconduction-btn-payer");
    setItemSelected();
    navigate(RoutePaths.RECONDUCTION_HOME);
  };

  return isRenouvellementContrat ? (
    <div className={classNames(styles.buttonRenouv, className)}>
      <Button variant="secondary" size="small" onClick={() => handleOnClick()}>
        Payer ma cotisation
      </Button>
    </div>
  ) : null;
};

export default PayerCotisationButton;
