import { FC, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userListContratsState } from "context/contratsState/atom";
import {
  monContratWithEcheanceState,
  refreshMonContratTriggerState,
} from "context/currentContratWithEcheanceState/atom";
import { Box } from "components/atoms/Box/Box";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { ConvertToMember, useUpdateMember } from "domain/service/Member/useUpdateMember";
import { Member, UpdateInfosMembreRequest } from "domain/api-schemas";
import ContratButton from "./ContratButton";
import MembreInfos from "./MemberInfos";
import { useTracking } from "hooks/useTracking";
import { getInactifContractsCount } from "utils/nbExpiredContract";

import useSmartPushFilters from "hooks/useSmartPush";
import styles from "./Page.module.scss";
import AttestationCard from "./CardItems/AttestationCard";
import LeftCards from "./CardItems/LeftCards";
import { ReactComponent as ContractIcon } from "images/icons/contrat-v2.svg";
import { Text } from "@roole/components-library";

const ageMaxContrat = 8;

const ContratInfo: FC = () => {
  const contrats = useRecoilValue(userListContratsState);
  const monContrat = useRecoilValue(monContratWithEcheanceState);
  const setRefreshMonContratTrigger = useSetRecoilState(refreshMonContratTriggerState);
  const expiredContracts = getInactifContractsCount(contrats);
  const { sendToDataLayer } = useTracking();
  const { membre, idAdhesion } = monContrat ?? {};
  const [isModify, setIsModify] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<Member | null>(
    ConvertToMember(membre) ?? null,
  );
  const { mutateAsync: postUpdateMember, isLoading, error } = useUpdateMember(idAdhesion ?? "");

  useSmartPushFilters(["contrat-4653", "compte-4654"], true);

  useEffect(() => {
    if (membre) {
      setCurrentMember(membre);
    }
  }, [membre]);

  const onSubmit = (infosMembreRequest: UpdateInfosMembreRequest) => {
    postUpdateMember(infosMembreRequest).then(() =>
      setRefreshMonContratTrigger((prev) => prev + 1),
    );
    setIsModify(false);
  };

  const isArgosDiplayed =
    monContrat?.isGravage === null ||
    (monContrat?.isGravage &&
      !!monContrat?.packServices?.isArgos &&
      (monContrat?.anciennete || 1) <= ageMaxContrat);

  const isAnneeSup = monContrat?.anciennete !== undefined && monContrat?.anciennete > 1;

  return (
    <>
      {monContrat ? (
        <>
          <div className={styles.ContractInfo__header}>
            <div>
              <Text textVariant="heading-4" as="h1">
                Mon contrat
              </Text>
              <span className={styles.textIcon}>
                <ContractIcon />
                <Text textVariant="body">{monContrat.identifiantContrat}</Text>
              </span>
            </div>
            <ContratButton hasMultiContrats={contrats.length - expiredContracts > 1} />
          </div>

          <div className={styles.ContractInfo__cards}>
            <LeftCards currentMember={currentMember} isAnneeSup={isAnneeSup} />
            <AttestationCard contract={monContrat} isArgosDiplayed={isArgosDiplayed} />
          </div>

          <Box>
            <MembreInfos
              member={currentMember}
              isLoading={isLoading}
              hasError={!!error}
              onSubmit={onSubmit}
              setIsModify={setIsModify}
              isModify={isModify}
              sendToDataLayer={sendToDataLayer}
            />
          </Box>
        </>
      ) : (
        <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
      )}
    </>
  );
};

export default ContratInfo;
