import { EtatContrat, Member } from "domain/api-schemas";
import { FC } from "react";
import InfoCard from "./InfoCard";
import styles from "../Page.module.scss";
import PaymentCard from "./PaymentCard";
import RegularisationCard from "./RegularisationCard";
import { monContratWithEcheanceState } from "context/currentContratWithEcheanceState/atom";
import { useRecoilState } from "recoil";
import ReconductionCard from "./ReconductionCard";
import { canRenewContract } from "domain/service/Contract/useReconduction";
import { checkIfTacite } from "domain/service/Contract/useMoncontrat";
import FreeMemberInfoCard from "./FreeMemberInfoCard";
import { Box } from "components/atoms/Box/Box";

const impaye = 7;
const free = "Free";

const LeftCards: FC<{
  currentMember: Member | null;
  isAnneeSup: boolean;
}> = ({ currentMember, isAnneeSup }) => {
  const [monContrat, setMonContrat] = useRecoilState(monContratWithEcheanceState);
  if (monContrat === null) {
    return <></>;
  }

  const isTacite = checkIfTacite(monContrat);

  return (
    <>
      {monContrat.membre.statutMembre === free ? (
        <Box>
          <FreeMemberInfoCard contract={monContrat} />
        </Box>
      ) : (
        <div>
          <InfoCard isAnneeSup={isAnneeSup} contract={monContrat} isTacite={isTacite} />
          {isTacite &&
            monContrat.etatContrat !== EtatContrat.Resilie &&
            (monContrat.statutContrat === impaye ? (
              <RegularisationCard contract={monContrat} className={styles.redBorderCard} />
            ) : (
              <PaymentCard
                contract={monContrat}
                member={currentMember}
                setMonContrat={setMonContrat}
              />
            ))}
          {!isTacite && canRenewContract(monContrat) && (
            <ReconductionCard contract={monContrat} className={styles.redBorderCard} />
          )}
        </div>
      )}
    </>
  );
};

export default LeftCards;
