import { FC, useEffect, useRef } from "react";

import { preventDefault } from "utils/preventDefault";
import { useKeyPress } from "hooks/useKeyPress";
import { useUniqueId } from "hooks/useUniqueId";
import { ModalWithControl, ModalWithControlProps, ModalAPI } from "./ModalWithControl";
import { ButtonGroup } from "components/atoms/Button/ButtonGroup";
import { Button } from "components/atoms/Button/Button";
import { Title } from "components/atoms/Title/Title";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";

import styles from "./Modal.module.scss";

type ConfirmModalProps = {
  title: string;
  onConfirm: () => void;
  confirmText?: string;
  confirmTag?: string;
  onAbort?: () => void;
  abortText?: string;
  abortTag?: string;
  btnGroupTag?: string;
  displayClose?: boolean;
  showConfirm?: boolean;
} & ModalWithControlProps;

export const ModalConfirmMessage: FC<ConfirmModalProps> = ({
  title,
  onConfirm,
  confirmText = "Valider",
  confirmTag,
  onAbort,
  abortText = "Annuler",
  abortTag,
  btnGroupTag,
  children,
  open,
  control,
  className,
  displayClose = true,
  showConfirm = true,
}) => {
  const titleId = useUniqueId("modal-title");
  const contentId = useUniqueId("modal-content");
  const modalRef = useRef<ModalAPI>(null);
  const _onAbort = () => modalRef.current?.toggle(false, onAbort);
  const _onConfirm = () => modalRef.current?.toggle(false, onConfirm);
  useKeyPress("Escape", _onAbort);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    modalRef?.current && control?.(modalRef.current);
  }, [control]);

  return (
    <ModalWithControl
      ref={modalRef}
      open={open}
      className={className}
      onOverlayClick={_onAbort}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      role="alertdialog"
    >
      <div className={styles.confirmationMessage}>
        <header className={styles.header}>
          <Title as="h3" className={styles.title} id={titleId}>
            {title}
          </Title>
          {displayClose && (
            <a href="#close" onClick={preventDefault(_onAbort)}>
              <CloseIcon title="Fermer et annuler" />
            </a>
          )}
        </header>
        <div id={contentId} className={styles.content}>
          {children}
        </div>
        <ButtonGroup data-selector={btnGroupTag} className={styles.buttonGroup}>
          <Button data-selector={abortTag} autoFocus onClick={_onAbort}>
            {abortText}
          </Button>
          {showConfirm && (
            <Button data-selector={confirmTag} variant="primary" onClick={_onConfirm}>
              {confirmText}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </ModalWithControl>
  );
};
