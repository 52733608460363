import { FC } from "react";
import { Outlet } from "react-router-dom";

import { useWizard } from "./WizardContext";
import { Stepper } from "components/atoms/Stepper/Stepper";
import { OverlayLoader } from "@roole/components-library";
import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";
import styles from "./WizardLayout.module.scss";

export const WizardLayout: FC<CommonProps> = ({ className }) => {
  const { steps: stepObjects, activeStep, submitting } = useWizard();
  const steps = stepObjects.slice(1).map((step) => step.label ?? step.path);

  return (
    <main className={classNames(styles.wizardLayout, className)}>
      {submitting && <OverlayLoader />}
      {!!activeStep?.index && <Stepper steps={steps} activeStep={activeStep.index} />}
      <div className={styles.wizardLayout__step}>
        <Outlet />
      </div>
    </main>
  );
};
