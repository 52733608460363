import { useEffect, useRef } from "react";
import { InputText } from "@roole/components-library";

import { classNames } from "utils/classNames";

import styles from "./InputPhone.module.scss";

interface InputPhoneProps {
  phoneValue: string | null;
  showExample?: boolean;
  errors: string | null;
  handlePhoneChanged: (...event: any[]) => void;
  className?: string;
  label?: string;
  required?: boolean;
}

export const InputPhone: React.FC<InputPhoneProps> = ({
  phoneValue,
  errors,
  handlePhoneChanged,
  showExample = false,
  className = "",
  label = "Numéro de téléphone portable",
  required = false,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.setCustomValidity(!!errors ? errors : "");
    }
  }, [errors]);

  return (
    <div className={classNames(styles.InputPhone, className)}>
      <div className={styles.InputPhone__Box}>
        <div className={styles.InputPhone__BoxContent}>
          <div
            className={classNames(
              styles.InputPhone__BoxContainer,
              !!errors && styles.InputPhone__BoxContainer__Error,
            )}
          >
            <InputText
              ref={ref}
              defaultValue={phoneValue || ""}
              required={required}
              label={label}
              placeholder="Ex : 0601020304"
              error={!!errors ? errors : ""}
              extraCss={styles.InputPhone__BoxErrorText}
              onChange={(newValue) => handlePhoneChanged(newValue.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
