import { FC, HTMLAttributes, useEffect, useRef } from "react";

const resize = (target: HTMLElement, observer: ResizeObserver) => {
  const width = target.clientWidth;
  const height = width ? Math.round((width / 3) * 4) : 0;
  target.style.height = `${height}px`;
  observer.unobserve(target);
};

export const PortraitLayout: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    try {
      if (ref.current) {
        const ro = new ResizeObserver(([{ target }], observer) =>
          resize(target as HTMLElement, observer),
        );
        ro.observe(ref.current);
        return () => {
          if (ref.current) {
            ro.unobserve(ref.current);
          }
        };
      }
    } catch (err) {
      (err as Error).toString();
    }
  }, []);

  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
};
