import { atom } from "recoil";
import { APPLICATIONSTATES } from "context/global.types";

export interface Atom {
  title: string;
  displayFooter?: boolean;
  isLoading?: boolean;
  onCloseCallback?: () => void;
  displayCloseButton?: boolean;
  layoutId?: string;
  closeButtonId?: string;
  displayMobileCallBlock?: boolean;
  disableLogoLink?: boolean;
  displayTunnelHeader?: boolean;
}

export const defaultStateValues = {
  title: "",
  displayFooter: undefined,
  isLoading: false,
  onCloseCallback: () => {},
  displayCloseButton: undefined,
  layoutId: undefined,
  closeButtonId: undefined,
  displayMobileCallBlock: undefined,
  disableLogoLink: false,
  displayTunnelHeader: false,
};
export const tunnelLayoutState = atom<Atom>({
  key: APPLICATIONSTATES.tunnelLayoutState,
  default: defaultStateValues,
});
