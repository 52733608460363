import { useCallback, useEffect, useReducer } from "react";
import { useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { globalState } from "context/globalState/atom";
import { AddContractRequest } from "domain/api-schemas";
import { listContratsInfoQuery } from "context/contratsState/selector";
import { axiosErrorHandler, ErrorType } from "clientProvider/errorHandle";
import { membreApi } from "domain/api/Membre/membreApi";
import { getMaskedEmail } from "utils/MaskedEmail";
import { addAccessTokenInterceptor } from "clientProvider/axiosConfig";
import { accessTokenOptions, getClaimsFromToken } from "clientProvider/auth0.util";
import { useTracking } from "hooks/useTracking";
import { useAuthenticationContext } from "AuthenticationProvider";

interface AddContractstate {
  errorMessage: string;
  isFatalError: boolean;
  needFetchToken: boolean;
  loading: boolean;
}

export const useAddContract = () => {
  const [globalContextValues, setGlobalState] = useRecoilState(globalState);
  const { listContratAhesion, auth0UserId, userEmail } = globalContextValues;
  const refreshListContracts = useRecoilRefresher_UNSTABLE(
    listContratsInfoQuery(listContratAhesion),
  );
  const [addContratState, setState] = useReducer(
    (state: AddContractstate, newState: Partial<AddContractstate>) => ({
      ...state,
      ...newState,
    }),
    {
      errorMessage: "",
      isFatalError: false,
      needFetchToken: false,
      loading: false,
    },
  );
  const { getAccessTokenSilently } = useAuthenticationContext();
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    const updateState = async () => {
      if (!addContratState.needFetchToken) {
        return;
      }

      getAccessTokenSilently({
        authorizationParams: {
          ...accessTokenOptions,
        },
        cacheMode: "off",
      }).then(async (value) => {
        setGlobalState({
          ...globalContextValues,
          listContratAhesion:
            getClaimsFromToken(value)["id_adhesions"] || globalContextValues.listContratAhesion,
        });
        refreshListContracts();
        navigate(RoutePaths.CONTRAT_CHOISIR, { replace: true });
      });
      addAccessTokenInterceptor(getAccessTokenSilently);
    };
    updateState();
  }, [addContratState.needFetchToken]);

  const submit = useCallback(
    async (values: FieldValues) => {
      try {
        setState({ loading: true });
        const request: AddContractRequest = {
          ...values,
          email: userEmail,
        };

        await membreApi.postAddContract(request);
        setState({ needFetchToken: true, loading: false });
        sendToDataLayer("ajout-contrat-btn-valider");
      } catch (err) {
        const error = axiosErrorHandler(err);
        if (error.type === ErrorType.axiosError) {
          const { data } = error?.error.response || {};
          const originEmailMessage = data?.originalEmail
            ? `Ce contrat est déjà rattaché à un email : ${getMaskedEmail(
                data?.originalEmail,
              )} Merci de vous connecter à l'aide de cet email pour accéder au contrat`
            : "";
          const errorMessage =
            originEmailMessage ||
            data?.error?.join(".") ||
            "Votre contrat n'a pas pu être ajouté. Merci de vérifier les informations saisies.";
          setState({ errorMessage, loading: false });
        } else {
          setState({ isFatalError: true, loading: false });
        }
      }
    },
    [auth0UserId, userEmail],
  );

  return { ...addContratState, submit };
};
