import { Avatar, ButtonGroup, IconV2 } from "@roole/components-library";
import { monContratState } from "context/currentContratState/atom";
import { useRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { useStepperContext } from "./StepperContext";
import useMediaQuery from "hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { useEffect } from "react";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useAuthenticationContext } from "AuthenticationProvider";

export const ConfirmationStep: React.FC = () => {
  const { setActions } = useStepperContext();
  const [declarationData] = useRecoilState(declarationDataState);
  const [monContrat] = useRecoilState(monContratState);
  const isMobile = useMediaQuery("mobile");
  const navigate = useNavigate();
  const { isJwtAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    setActions({});
  }, []);

  const formatDateEvenement = (dateEvenement: string): string => {
    try {
      const parsedDate = new Date(dateEvenement);
      return format(parsedDate, "dd MMMM yyyy", { locale: fr });
    } catch (error) {
      console.error("Erreur lors de la conversion de la date :", error);
      return dateEvenement;
    }
  };

  const dateEvenement = declarationData?.dateEvenement;
  const formattedDate = dateEvenement ? formatDateEvenement(dateEvenement) : "";

  return (
    <div className="flex flex-col gap-L ">
      <div className="flex flex-col gap-S items-center">
        <div className="flex flex-col gap-XS items-center">
          <IconV2 name="check-circle" size={80} />

          <div className="flex flex-col gap-3XS items-center">
            <p className="heading-2 text-center ">Votre déclaration a bien été enregistrée</p>
            <p className="body-medium text-center">
              D’ici quelques instants, vous allez recevoir un email de confirmation.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-XS w-full max-w-fit p-XS rounded-XS bg-sub-brand-light">
          <div className="flex flex-col gap-4XS md:flex-row md:gap-4XS">
            <h5 className="heading-5 ">{declarationData?.typeEvenement?.nom}</h5>
            <p>
              <span className="hidden md:inline">· </span>du {formattedDate}
            </p>
          </div>

          {declarationData?.recap?.dossiersGarantie.map((dossierGarantie) => (
            <a
              className="flex gap-XS justify-between cursor-pointer"
              onClick={() => {
                sendCloseWebViewEvent({ hasError: false });
                if (!isJwtAuthenticated) {
                  navigate(
                    `${RoutePaths.DETAIL_SINISTRE}/${dossierGarantie.id}/${monContrat?.idAdhesion}`,
                  );
                }
              }}
            >
              <div className="flex gap-3XS">
                <IconV2 name="folder" size={24} />

                <div className="flex flex-col">
                  <p className="body-medium ">{dossierGarantie.garantieNom}</p>
                  <p className="body-small text-secondary ">Dossier {dossierGarantie.nom}</p>
                </div>
              </div>

              <IconV2 name="chevron-right" size={24} />
            </a>
          ))}
        </div>

        <div className="flex flex-col gap-XS">
          <h3 className="heading-3 text-center ">Et ensuite ?</h3>

          {!!declarationData?.recap &&
            (!!declarationData?.recap?.dossiersGarantie.find((v) => v.statut === "En cours") ? (
              <div className="flex flex-col md:flex-row gap-S">
                <div className="flex flex gap-2XS flex-1">
                  <Avatar icon="file-plus" size="M" type="icon" variant="brand_light" />

                  <div className="flex flex-col">
                    <p className="body-medium-em">Vous complétez votre dossier</p>
                    <p className="body-medium">
                      Retrouvez votre dossier et ajoutez toutes les pièces justificatives, depuis le
                      menu “Mes sinistres”, de votre compte personnel.
                    </p>
                  </div>
                </div>

                <div className="flex flex gap-2XS flex-1">
                  <Avatar icon="clock" size="M" type="icon" variant="brand_light" />

                  <div className="flex flex-col">
                    <p className="body-medium-em">Nous étudions votre dossier</p>
                    <p className="body-medium">
                      Une fois complété, nous pouvons vérifier si vous êtes éligible à l'activation
                      de la ou les garanties.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row gap-S">
                <div className="flex flex gap-2XS flex-1">
                  <Avatar icon="clock" size="M" type="icon" variant="brand_light" />

                  <div className="flex flex-col">
                    <p className="body-medium-em">Nous étudions votre dossier</p>
                    <p className="body-medium">
                      Nous analysons les pièces justificatives que vous nous avez fournies, sous
                      72h.
                    </p>
                  </div>
                </div>

                <div className="flex flex gap-2XS flex-1">
                  <Avatar icon="clock" size="M" type="icon" variant="brand_light" />

                  <div className="flex flex-col">
                    <p className="body-medium-em">Nous apportons la réponse à votre dossier</p>
                    <p className="body-medium">
                      Nous vérifions si vous êtes éligible à l'activation de la ou les garanties.
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="self-center">
        <ButtonGroup
          buttonPrimary={{
            variant: "primary-brand",
            children: <p className="body-medium">Suivre mes dossiers</p>,
            onClick: () => {
              sendCloseWebViewEvent({ hasError: false });
              if (!isJwtAuthenticated) {
                navigate(RoutePaths.SINISTRES);
              }
            },
          }}
          buttonSecondary={{
            variant: "secondary-dark",
            children: <p className="body-medium">Retour à l'accueil</p>,
            onClick: () => {
              sendCloseWebViewEvent({ hasError: false });
              if (!isJwtAuthenticated) {
                navigate(RoutePaths.CONTRAT_HOME);
              }
            },
          }}
          direction={isMobile ? "column" : "row"}
          size="large"
        />
      </div>
    </div>
  );
};
