import { IconV2, TextV2 } from "@roole/components-library";
import { ReactComponent as IlluIneligibleFull } from "../../../images/illu-ineligible-full.svg";
import styled from "styled-components";
import { useEffect } from "react";
import { useStepperContext } from "./StepperContext";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "AuthenticationProvider";
import { sendCloseWebViewEvent } from "utils/sendEventToApp";

const IconSpan = styled.span`
  &,
  & * {
    display: inline;
  }
`;
export const NoGaranties: React.FC = () => {
  const { setActions, prevStep } = useStepperContext();
  const { isJwtAuthenticated } = useAuthenticationContext();
  const navigate = useNavigate();

  useEffect(() => {
    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Retour à l’accueil",
        onClick: () => {
          sendCloseWebViewEvent({ hasError: false });
          if (!isJwtAuthenticated) {
            navigate(RoutePaths.SINISTRES);
          }
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour à ma déclaration",
        onClick: () => prevStep(),
      },
    });
  }, [navigate, prevStep, setActions]);

  return (
    <div className="flex justify-center w-full w-full">
      <div className="flex flex-col items-center text-center gap-M" style={{ maxWidth: 436 }}>
        <IlluIneligibleFull />

        <div className="flex flex-col gap-XS items-center">
          <TextV2 textVariant="heading-2" extraCss={{ paddingTop: 8 }}>
            Aucune garantie ne semble couvrir votre sinistre
          </TextV2>

          <TextV2>
            Nous regrettons de vous informer que votre sinistre ne peut être pris en charge car les
            critères d'éligibilité de la garantie proposée ne sont pas respectés.
          </TextV2>

          <TextV2 textVariant="body-large-em">Une question, besoin d'aide ?</TextV2>

          <div className="max-w-fit bg-secondary100 px-S py-XS rounded-XS">
            <TextV2>
              <IconSpan>
                <IconV2 name="phone-call" />
              </IconSpan>{" "}
              Contactez-nous · <span className="whitespace-nowrap font-bold">01 48 90 37 00</span>
            </TextV2>
          </div>
        </div>
      </div>
    </div>
  );
};
