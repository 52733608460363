import { RoutePaths } from "routes/routePaths";
import { Alert } from "./Alert";
import styles from "./Alert.module.scss";
import { Button } from "@roole/components-library";
import { useNavigate } from "react-router-dom";
import { echeancierState } from "pages/Regularisation/Context/EcheancierState/atom";
import { useRecoilValue } from "recoil";
import { formatDate } from "utils/formatDate";

export const AlertImpaye = () => {
  const navigate = useNavigate();
  const echeancier = useRecoilValue(echeancierState);

  const dateFirstEcheance = new Date(
    (echeancier?.echeances && echeancier?.echeances[0]?.datePrevue) ?? "",
  );
  const resiliationDate = new Date(dateFirstEcheance.setMonth(dateFirstEcheance.getMonth() + 3));
  return (
    <Alert>
      <div>
        Votre contrat est en situation d'impayé. Sans action de votre part, votre contrat sera
        résilié le <b>{formatDate(resiliationDate)}</b>
      </div>
      <div className={styles.buttonRenouv}>
        <Button
          variant="secondary"
          size="small"
          onClick={(e) => {
            navigate(RoutePaths.REGULARISATION);
          }}
        >
          Régulariser ma situation
        </Button>
      </div>
    </Alert>
  );
};
