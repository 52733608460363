import { FC, useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { TextInput } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { formatCurrency } from "utils/formatCurrency";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import { useCodePromo } from "domain/service/Member/useCodePromo";
import { ReactComponent as IconArrowRight } from "images/icons/arrowRight.svg";
import { ICodePromo } from "pages/Reconduction/Reconduction.types";
import { globalState } from "context/globalState/atom";
import { useTracking } from "hooks/useTracking";

import cssVariables from "styles/variables.module.scss";
import styles from "./ReconductionCotisationBlock.module.scss";

interface IReconductionCotisationBlock {
  handleChangePromo?: ({ promo, codePromo, hasError }: ICodePromo, code: string | null) => void;
  handleNextStep?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  changePromo?: (codePromo: string) => void;
  monthFree?: number;
  promoCode?: string;
  isLoading?: boolean;
  price?: number;
  promoError?: boolean;
  isDisplayPromoCode?: boolean;
  nextButtonLabel?: string;
}

const ReconductionCotisationBlockMobile: FC<IReconductionCotisationBlock> = ({
  price,
  handleNextStep = undefined,
  changePromo,
  promoError,
  monthFree,
  isDisplayPromoCode,
  nextButtonLabel,
  promoCode = "",
  isLoading = false,
}: IReconductionCotisationBlock) => {
  const [codePromo, setCodePromo] = useState<string>(promoCode);

  return (
    <div className={styles.ReconductionCotisationBlockMobile}>
      {isDisplayPromoCode && (
        <div
          className={classNames(
            styles.ReconductionCotisationBlockMobile__promoCode,
            promoError && styles.ReconductionCotisationBlockMobile__promoCode_error,
          )}
        >
          <TextInput border label="Code Promo" value={codePromo} onChange={setCodePromo} />
          <Button
            variant="outlined-light"
            disabled={isLoading}
            onClick={codePromo && changePromo ? () => changePromo(codePromo) : () => {}}
          >
            Ok
          </Button>

          {promoError && (
            <div className={styles.ReconductionCotisationBlocMobile__promoInputsLabelError}>
              Code promo invalide
            </div>
          )}
        </div>
      )}
      <div className={styles.ReconductionCotisationBlockMobile__footer}>
        {price && (
          <p className={styles.ReconductionCotisationBlockMobile__price}>
            {monthFree && price ? (
              <>
                {formatCurrency(0)}
                <span className={styles.ReconductionCotisationBlockMobile__priceInfo}>
                  / mois
                  <p>pendant {monthFree} mois</p>
                  <p>{`puis ${formatCurrency(price)} / mois`}</p>
                </span>
              </>
            ) : null}
            {price && !monthFree ? (
              <>
                {formatCurrency(price)}
                <span className={styles.ReconductionCotisationBlockMobile__priceInfo}>/ mois</span>
              </>
            ) : null}
          </p>
        )}
        {handleNextStep && (
          <Button
            variant="primary"
            icon={<IconArrowRight />}
            disabled={isLoading}
            onClick={handleNextStep}
          >
            {nextButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

const ReconductionCotisationBlockDesktop: FC<IReconductionCotisationBlock> = ({
  changePromo,
  monthFree,
  price,
  handleNextStep,
  promoError,
  isDisplayPromoCode,
  promoCode = "",
  isLoading = false,
}: IReconductionCotisationBlock) => {
  const [codePromo, setCodePromo] = useState<string>(promoCode);

  return (
    <div className={styles.ReconductionCotisationBlockDesktop}>
      <p className={styles.ReconductionCotisationBlockDesktop__title}>
        Montant de votre cotisation
      </p>
      <p className={styles.ReconductionCotisationBlockDesktop__subTitle}>Sans engagement</p>

      <div className={styles.ReconductionCotisationBlockDesktop__price}>
        {monthFree && price ? (
          <>
            {formatCurrency(0)}
            <span className={styles.ReconductionCotisationBlockDesktop__priceInfo}>/ mois</span>
            <div className={styles.ReconductionCotisationBlockDesktop__priceInfo}>
              <p>pendant {monthFree} mois</p>
              <p>{`puis ${formatCurrency(price)} / mois`}</p>
            </div>
          </>
        ) : null}
        {price && !monthFree ? (
          <>
            {formatCurrency(price)}
            <span className={styles.ReconductionCotisationBlockDesktop__priceInfo}>
              /&nbsp;mois
            </span>
          </>
        ) : null}
      </div>

      {isDisplayPromoCode && (
        <div className={styles.ReconductionCotisationBlockDesktop__inputs}>
          <div
            className={classNames(
              styles.ReconductionCotisationBlockDesktop__promoInputs,
              promoError && styles.ReconductionCotisationBlockDesktop__promoInputs_error,
            )}
          >
            <TextInput border label="Code Promo" value={codePromo} onChange={setCodePromo} />
            <Button
              variant="outlined-light"
              disabled={isLoading}
              onClick={codePromo && changePromo ? () => changePromo(codePromo) : () => {}}
            >
              Ok
            </Button>

            {promoError && (
              <div className={styles.ReconductionCotisationBlockDesktop__promoInputsLabelError}>
                Code promo invalide
              </div>
            )}
          </div>
          {handleNextStep && (
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={handleNextStep ? () => handleNextStep() : () => {}}
            >
              Suivant
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const ReconductionCotisationBlock: FC<IReconductionCotisationBlock> = (
  props: IReconductionCotisationBlock,
) => {
  const { handleChangePromo, promoCode, handleNextStep } = props || {};
  const { sendToDataLayer } = useTracking();
  const { idAdhesion } = useRecoilValue(globalState);
  const { mutateAsync: validateCodePromo, isLoading } = useCodePromo(idAdhesion!);

  const handleNextStepAndAnalytics = () => {
    if (typeof handleNextStep === "function") {
      handleNextStep();
      sendToDataLayer("reconduction-btn-payer-code-promo");
    }
    return undefined;
  };

  const changeCodePromo = useCallback(
    async (codePromo: string) => {
      sendToDataLayer("reconduction-btn-ok");

      try {
        const validatedPromo = await validateCodePromo({
          codePromo,
        });
        handleChangePromo?.(
          {
            promo: validatedPromo,
            codePromo,
            hasError: false,
          },
          codePromo,
        );
      } catch {
        handleChangePromo?.({ promo: null, codePromo: undefined, hasError: true }, null);
      }
    },
    [handleChangePromo, sendToDataLayer, validateCodePromo],
  );
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return isDesktop ? (
    <ReconductionCotisationBlockDesktop
      changePromo={changeCodePromo}
      isLoading={isLoading}
      promoCode={promoCode}
      {...props}
      handleNextStep={handleNextStep ? handleNextStepAndAnalytics : undefined}
    />
  ) : (
    <ReconductionCotisationBlockMobile
      changePromo={changeCodePromo}
      isLoading={isLoading}
      promoCode={promoCode}
      {...props}
      handleNextStep={handleNextStep ? handleNextStepAndAnalytics : undefined}
    />
  );
};

export default ReconductionCotisationBlock;
