import { Children, FC, ReactElement } from "react";
import { Text } from "@roole/components-library";

import { WizardAside } from "./WizardAside";
import { Box } from "components/atoms/Box/Box";
import { classNames } from "utils/classNames";
import { CommonProps } from "utils/CommonProps";
import styles from "./WizardStepLayout.module.scss";

interface WizardStepLayoutProps extends CommonProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const WizardStepLayout: FC<WizardStepLayoutProps> = ({
  className,
  title,
  subtitle,
  children,
}) => {
  const elements: ReactElement[] = Children.toArray(children) as ReactElement[];
  const content = elements.filter((el) => el.type !== WizardAside);
  const aside = elements.find((el) => el.type === WizardAside);

  return (
    <article
      className={classNames(
        styles.wizardStepLayout,
        aside && styles["wizardStepLayout--withAside"],
        className,
      )}
    >
      <header className={styles.wizardStepLayout__header}>
        <Text textVariant="heading-1" as="h1">
          {title}
        </Text>
        {subtitle && (
          <Text
            dangerouslySetInnerHTML={{ __html: subtitle }}
            className={styles.wizardStepLayout__subtitle}
            textVariant="body"
            as="p"
          />
        )}
      </header>
      <Box className="custom-box">{content}</Box>
      {aside}
    </article>
  );
};
