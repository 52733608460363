import { DropDown, DropDownItem, Text, OverlayLoader } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { RoutePaths } from "routes/routePaths";
import { reportClaimStepState } from "context/reportClaimState/atom";
import { monContratState } from "context/currentContratState/atom";
import { GuaranteeListResponse, reportClaimApi } from "domain/api/ReportClaim/reportClaimApi";
import { useTracking } from "hooks/useTracking";
import { text_to_id } from "utils/textToId";

import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { InputArea } from "components/atoms/InputArea/InputArea";
import { Card } from "components/atoms/Card/Card";

import commonStyles from "pages/ReportClaim/Page.module.scss";
import styles from "./ReportClaimTypeStep.module.scss";
import { TypeEvenement } from "domain/api-schemas";

type TypeInputs = {
  claimType: DropDownItem;
  claimDescription: string;
};

export const ReportClaimTypeStep: FC = () => {
  const [reportClaimStepContextState, setReportClaimStepContextState] =
    useRecoilState(reportClaimStepState);
  const navigate = useNavigate();
  const [errorScript, setErrorScript] = useState<boolean>(false);
  const { sendToDataLayer } = useTracking();
  const [loading, setLoading] = useState(false);
  const monContratContextState = useRecoilValue(monContratState);

  const types = [
    { id: 0, name: "Choisissez un type d'événement *", disabled: true },
    ...reportClaimStepContextState.typeEvents.map((type, index) => {
      return {
        id: index + 1,
        name: type.libelleTypeEvenement || "",
        disabled: false,
      };
    }),
  ];

  useEffect(() => {
    if (reportClaimStepContextState.claimDate === null) {
      navigate(RoutePaths.DECLARER_SINISTRE_DATE);
    }
  }, []);

  const { control, handleSubmit, getValues } = useForm<TypeInputs>({
    reValidateMode: "onSubmit",
  });

  const onChangeTypeHandler = (onChange: (...event: any[]) => void, item: DropDownItem | null) => {
    if (!!reportClaimStepContextState.errors?.claimType) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        errors: {
          ...reportClaimStepContextState.errors,
          claimType: undefined,
        },
      });
    }
    onChange(item);
  };

  const onChangeDescriptionHandler = (
    onChange: (...event: any[]) => void,
    item: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!!reportClaimStepContextState.errors?.claimDescription) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        errors: {
          ...reportClaimStepContextState.errors,
          claimDescription: undefined,
        },
      });
    }
    onChange(item);
  };

  const handleResponse = async (
    response: GuaranteeListResponse | null,
    data: TypeInputs,
    selectedType: TypeEvenement | null,
  ) => {
    if (response) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        claimType: data.claimType,
        claimDescription: data.claimDescription,
        claimTypeEventId: selectedType?.typeEvenementId || null,
        activeStep: 3,
        guaranteesAvailable: response.garanties,
      });
    } else {
      handleError("Aucune garantie disponible à cette date");
    }
  };

  const handleError = async (typeError: string, descriptionError?: string) => {
    setLoading(false);
    setReportClaimStepContextState({
      ...reportClaimStepContextState,
      errors: {
        claimType: typeError,
        claimDescription: descriptionError,
      },
    });
  };

  const onSubmit: SubmitHandler<TypeInputs> = async (data) => {
    if (data.claimDescription && data.claimType.disabled !== true && !errorScript) {
      setLoading(true);
      const types = reportClaimStepContextState.typeEvents.filter((type) => {
        return data.claimType.name === type.libelleTypeEvenement;
      });
      const selectedType = types.length > 0 ? types[0] : null;
      if (selectedType?.garantiesUtilisees) {
        handleError("Une garantie a déjà été utilisée pour ce type d'événement");
        return;
      }
      await reportClaimApi
        .fetchGuaranteesList(
          monContratContextState?.idAdhesion || "",
          selectedType,
          selectedType?.productId || "",
          reportClaimStepContextState.claimDate?.toDateString() || "",
        )
        .then((response) => {
          handleResponse(response, data, selectedType);
        });
      sendToDataLayer(`declarer-sinistre-evenement-${text_to_id(data.claimType.name)}`);
      sendToDataLayer("declarer-sinistre-type-contexte-textarea");
      sendToDataLayer("declarer-sinistre-type-bouton-suivant");
      navigate(RoutePaths.DECLARER_SINISTRE_GUARANTEES);
    } else {
      const typeError = data.claimType.disabled ? "Veuillez saisir un type d'événement" : "";
      const descriptionError =
        data.claimDescription === "" ? "Veuillez saisir une description de votre événement" : "";
      handleError(typeError, descriptionError);
    }
  };

  const PreviousClickHandler = () => {
    setReportClaimStepContextState({
      ...reportClaimStepContextState,
      activeStep: 1,
      claimType: getValues("claimType"),
      claimDescription: getValues("claimDescription"),
    });
    navigate(RoutePaths.DECLARER_SINISTRE_DATE);
  };

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Description de votre sinistre
        </Text>
        <br />
      </div>
      {loading ? (
        <OverlayLoader />
      ) : (
        <Card noMargin className={styles.contactUsTelephoneStep__Card}>
          <Text
            as="h2"
            textVariant="heading-4"
            className={styles.contactUsTelephoneStep__Card__Title}
          >
            Comment s'est produit votre sinistre&nbsp;?
          </Text>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.contactUsTelephoneStep__Card__Form}
          >
            <Controller
              control={control}
              name="claimType"
              defaultValue={reportClaimStepContextState.claimType || types[0]}
              render={({ field: { value, onChange } }) => (
                <div>
                  <DropDown
                    key={`dd-report-claim-type-${value.id}`}
                    possibleValues={types}
                    setSelectedValue={(item) => {
                      onChangeTypeHandler(onChange, item);
                    }}
                    selectedValue={value || types[0]}
                    error={reportClaimStepContextState.errors?.claimType || undefined}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="claimDescription"
              defaultValue={reportClaimStepContextState.claimDescription || ""}
              render={({ field: { value, onChange } }) => (
                <InputArea
                  placeholder="Précisez nous les circonstances de votre sinistre afin de savoir si votre dossier est éligible à une indemnisation. Par exemple, il peut être important de nous préciser si votre responsabilité est engagée ou non lors d’un accident. 1000 caractères maximum."
                  maxLength={1000}
                  onChange={(item) => {
                    onChangeDescriptionHandler(onChange, item);
                  }}
                  setError={setErrorScript}
                  defaultValue={value || ""}
                  required
                  error={!!reportClaimStepContextState.errors?.claimDescription}
                  errorMessage={reportClaimStepContextState.errors?.claimDescription || undefined}
                />
              )}
            />
            <NavigationButtons
              prevProps={{
                onClick: () => PreviousClickHandler(),
                gtmElementId: "declarer-sinistre-type-bouton-precedent",
              }}
              nextProps={{
                onClick: () => undefined,
                type: "submit",
              }}
            />
          </form>
        </Card>
      )}
    </>
  );
};
