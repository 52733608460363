import {
  DrawerMobile,
  ModalV2,
  ButtonV2,
  TextV2,
  Message,
  IconV2,
} from "@roole/components-library";
import { FC } from "react";

interface ModalProps {
  isMobile: boolean;
  isEligibleModalOpen: boolean;
  isNotEligibleModalOpen: boolean;
  handleEligibleModalClose: () => void;
  handleNotEligibleModalClose: () => void;
  selectedGarantieName?: string;
  garantieExclusions: { question: string; noteIneligibilite?: string }[];
  errors: Record<string, boolean>;
}

export const GarantiesModals: FC<ModalProps> = ({
  isMobile,
  isEligibleModalOpen,
  isNotEligibleModalOpen,
  handleEligibleModalClose,
  handleNotEligibleModalClose,
  selectedGarantieName,
  garantieExclusions,
  errors,
}) => {
  return (
    <>
      {isMobile ? (
        <DrawerMobile
          title={"Éligible à l’étude"}
          content={
            <>
              <div style={{ marginBottom: "32px" }}>
                <TextV2>
                  Un conseiller Roole examinera les détails de votre dossier une fois entièrement
                  complété, pour déterminer si vous êtes bien éligible à l’activation de la
                  garantie.
                </TextV2>
              </div>
              <ButtonV2 variant="primary-brand" onClick={handleEligibleModalClose}>
                Continuer
              </ButtonV2>
            </>
          }
          isOpen={isEligibleModalOpen}
          handleClose={handleEligibleModalClose}
        />
      ) : (
        <ModalV2
          title={"Éligible à l’étude"}
          content={
            <>
              <div style={{ marginBottom: "32px" }}>
                <TextV2>
                  Un conseiller Roole examinera les détails de votre dossier une fois entièrement
                  complété, pour déterminer si vous êtes bien éligible à l’activation de la
                  garantie.
                </TextV2>
              </div>
              <ButtonV2 variant="primary-brand" onClick={handleEligibleModalClose}>
                Continuer
              </ButtonV2>
            </>
          }
          isOpen={isEligibleModalOpen}
          handleClose={handleEligibleModalClose}
        />
      )}

      {isMobile ? (
        <DrawerMobile
          title={`Oups... Vous n’êtes pas éligible à la garantie ${selectedGarantieName}`}
          content={
            <>
              <div
                style={{
                  marginBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <TextV2 style={{ marginTop: "8px" }}>
                  Certaines informations renseignées nous indiquent que votre sinistre ne peut pas
                  être couvert par cette garantie.
                </TextV2>
                <Message
                  type="information"
                  message={
                    <div>
                      {garantieExclusions.map(({ question, noteIneligibilite }) => (
                        <div key={question} style={{ marginBottom: "8px" }}>
                          {!errors[question] && (
                            <div style={{ display: "flex", gap: "8px" }}>
                              <IconV2 name="x-circle" color="neutral" />
                              <TextV2 textVariant="body-medium">{noteIneligibilite}</TextV2>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  }
                />
              </div>
              <ButtonV2 variant="primary-brand" onClick={handleNotEligibleModalClose}>
                Continuer
              </ButtonV2>
            </>
          }
          isOpen={isNotEligibleModalOpen}
          handleClose={handleNotEligibleModalClose}
        />
      ) : (
        <ModalV2
          title={`Oups... Vous n’êtes pas éligible à la garantie ${selectedGarantieName}`}
          content={
            <>
              <div
                style={{
                  marginBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <TextV2>
                  Certaines informations renseignées nous indiquent que votre sinistre ne peut pas
                  être couvert par cette garantie.
                </TextV2>
                <Message
                  type="information"
                  message={
                    <div>
                      {garantieExclusions.map(({ question, noteIneligibilite }) => (
                        <div key={question} style={{ marginBottom: "8px" }}>
                          {!errors[question] && (
                            <div style={{ display: "flex", gap: "8px" }}>
                              <IconV2 name="x-circle" color="neutral" />
                              <TextV2 textVariant="body-medium">{noteIneligibilite}</TextV2>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  }
                />
              </div>
              <ButtonV2 variant="primary-brand" onClick={handleNotEligibleModalClose}>
                Continuer
              </ButtonV2>
            </>
          }
          isOpen={isNotEligibleModalOpen}
          handleClose={handleNotEligibleModalClose}
        />
      )}
    </>
  );
};
