import { FC } from "react";
import ReadItem from "./ReadItem";
import { Member } from "domain/api-schemas";

import styles from "./UserInformationCard.module.scss";

type UserInformationCardProps = {
  lineClassName?: string;
  lineClassNameAlt?: string;
  informations: Member | null;
};

const UserInformationCard: FC<UserInformationCardProps> = ({
  lineClassName,
  lineClassNameAlt,
  informations,
}) => {
  const formatNumber = (phoneNumber?: string) => {
    if (!phoneNumber) {
      return "";
    }

    let formattedNumber = phoneNumber;

    // Handle the +33 prefix
    if (formattedNumber.startsWith("+33")) {
      formattedNumber = "+33 " + formattedNumber.slice(3).replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1 ");
    } else {
      // Handle numbers without +33
      formattedNumber = formattedNumber.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, "$1 ");
    }

    return formattedNumber.trim();
  };

  return (
    <div className={styles.UserInformationCard}>
      <div>
        <ReadItem
          label="Civilité"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.civilite}
        </ReadItem>
        <ReadItem
          label={informations?.civilite === "Societe" ? "Raison social" : "Prénom et nom"}
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.prenom} {informations?.nom}
        </ReadItem>
        <ReadItem label="Email" lineClassName={lineClassName} lineClassNameAlt={lineClassNameAlt}>
          {informations?.email}
        </ReadItem>
        <ReadItem
          label="Téléphone fixe"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {formatNumber(informations?.telephoneFixe)}
        </ReadItem>
        <ReadItem
          label="Téléphone portable"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {formatNumber(informations?.telephoneMobile)}
        </ReadItem>
      </div>
      <div>
        <ReadItem
          label="Adresse (n° et rue)"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.rue}
        </ReadItem>
        <ReadItem
          label="Bâtiment, résidence"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.batimentResidence}
        </ReadItem>
        <ReadItem
          label="Infos esc, porte, remise"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.infoEscPorteRemise}
        </ReadItem>
        <ReadItem
          label="Lieu-dit / BP / Hameau"
          lineClassName={lineClassName}
          lineClassNameAlt={lineClassNameAlt}
        >
          {informations?.lieuditBpHameau}
        </ReadItem>
        <ReadItem label="Ville" lineClassName={lineClassName} lineClassNameAlt={lineClassNameAlt}>
          {informations?.ville} {informations?.codePostal}
        </ReadItem>
      </div>
    </div>
  );
};

export default UserInformationCard;
