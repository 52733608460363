import { FC } from "react";
import styles from "./ErrorRendering.module.scss";

type ErrorRenderingProps = {
  errors: any;
  nameProperty: any;
  errorMessage?: string;
};

const ErrorRendering: FC<ErrorRenderingProps> = ({ errors, nameProperty, errorMessage }) =>
  errors?.[nameProperty] && errorMessage ? (
    <p className={styles.ErrorRendering}>{errorMessage}</p>
  ) : null;

export default ErrorRendering;
