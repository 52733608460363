import { useState, FC } from "react";
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";

type DatePickerFrProps = ReactDatePickerProps & {
  onChange: (item: Date | null) => void;
  weekendOff?: boolean;
};

const isWeekday = (date: Date) => {
  const sunday = 0;
  const saturday = 6;
  const day = date.getDay();
  return day !== sunday && day !== saturday;
};

export const DatePickerFr: FC<DatePickerFrProps> = ({ onChange, weekendOff = false, ...props }) => {
  registerLocale("fr", fr);
  const [date, setDate] = useState<Date | null>(null);

  return (
    <DatePicker
      {...props}
      locale="fr"
      selected={date}
      dateFormat="dd/MM/yyyy"
      onChange={(item) => {
        setDate(item);
        onChange(item);
      }}
      filterDate={weekendOff ? isWeekday : undefined}
    />
  );
};
