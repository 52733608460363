import { useRecoilCallback } from "recoil";
import { ICodePromo } from "pages/Reconduction/Reconduction.types";
import { InfosReconduction } from "domain/api-schemas";
import {
  initState,
  initStateWithDefaultValues,
  ReconductionState,
  reconductionState,
} from "./Atoms";

export const useCreateDispatcher = () => {
  const handleCodePromoValidation = useRecoilCallback(
    ({ set }) =>
      ({ promo, hasError }: ICodePromo, codePromo: string | null) => {
        set(reconductionState, (state: ReconductionState) => {
          return {
            ...state,
            promo: promo || null,
            codePromo,
            promoError: hasError || false,
            loading: false,
          };
        });
      },
  );

  const handlePaimentStep = useRecoilCallback(
    ({ set }) =>
      (newIban: string, newTitle: string, newStep: number) => {
        set(reconductionState, (state: ReconductionState) => {
          return {
            ...state,
            ...{ iban: newIban, titulaire: newTitle, currentStep: newStep },
          };
        });
      },
  );

  const handleTelephoneStep = useRecoilCallback(({ set }) => (phone: string, newStep: number) => {
    set(reconductionState, (state: ReconductionState) => {
      return {
        ...state,
        phone,
        currentStep: newStep,
      };
    });
  });

  const cleanState = useRecoilCallback(({ set }) => () => {
    set(reconductionState, (_) => initState);
  });

  const handleIsNewIban = useRecoilCallback(({ set }) => () => {
    set(reconductionState, (state: ReconductionState) => {
      return { ...state, isNewBankInfos: true };
    });
  });

  const initReconductionState = useRecoilCallback(
    ({ set }) =>
      (
        infosReconduction: InfosReconduction | null,
        isDesktop: boolean,
        phoneNumber: string | null,
        currentStep: number,
      ) => {
        set(reconductionState, (state) => {
          return {
            ...state,
            currentStep,
            ...initStateWithDefaultValues(infosReconduction, isDesktop, phoneNumber),
          };
        });
      },
  );

  const updateStep = useRecoilCallback(
    ({ set }) =>
      (newStep: number) =>
        set(reconductionState, (state: ReconductionState) => {
          return { ...state, currentStep: newStep };
        }),
  );

  return {
    handleCodePromoValidation,
    handlePaimentStep,
    handleTelephoneStep,
    cleanState,
    handleIsNewIban,
    initReconductionState,
    updateStep,
  };
};

export type ReconductionDispatcher = ReturnType<typeof useCreateDispatcher>;
