import { PublicRoutePaths, RoutePaths } from "routes/routePaths";

declare global {
  interface Window {
    __STPUSHInit: any;
    _ST_FAQ: any;
  }
}

const DisabledSmartPushPaths = [
  RoutePaths.HOME,
  RoutePaths.AIDE,
  RoutePaths.USER_PROFIL,
  RoutePaths.GARANTIES,
  RoutePaths.BON_PLANS,
  RoutePaths.CONTRAT_AJOUTER,
  RoutePaths.CONTRAT_CHOISIR,
  PublicRoutePaths.Error,
];

const isDisabledSmartPushPath = (pathname: string): boolean =>
  DisabledSmartPushPaths.includes(pathname as RoutePaths);

const isValidRoutePath = (pathname: string): boolean =>
  Object.values(RoutePaths).includes(pathname as RoutePaths);

const isValidPublicRoutePath = (pathname: string): boolean =>
  Object.values(PublicRoutePaths).includes(pathname as PublicRoutePaths);

const showSmartPush = (pathname?: string): boolean =>
  !pathname ||
  (!isDisabledSmartPushPath(pathname) &&
    (isValidRoutePath(pathname) || isValidPublicRoutePath(pathname)));

const loadScript = (src: string): void => {
  const script = document.createElement("script");
  script.async = true;
  script.src = src;
  document.head.appendChild(script);
};

export const initializeSTPUSH = (pathname: string, defaultTags: string[] = []): void => {
  const shouldShowSmartPush = showSmartPush(pathname);
  const smartPushUrl = process.env.REACT_APP_SMART_PUSH_URL;

  const __STPUSHInit_initialization = {
    analyticsUA: "G-FSD9CVQ6M3",
    buildName: "moncompte",
    searchFiltered: false,
    kbId: 404,
    locale: "fr",
    cookieOptin: false,
    filters: {
      thematics: [],
      tags: defaultTags,
    },
  };

  if (window.__STPUSHInit) {
    shouldShowSmartPush ? window.__STPUSHInit.on() : window.__STPUSHInit.off();
  } else if (shouldShowSmartPush) {
    window.addEventListener("STPUSHLoaded", (e: Event) => {
      window.__STPUSHInit = (e as CustomEvent).detail;
      window.__STPUSHInit.init(__STPUSHInit_initialization);
    });

    window.addEventListener("STPUSHUpdate", (e: Event) => {
      if (window.__STPUSHInit) {
        window.__STPUSHInit.init(
          Object.assign(__STPUSHInit_initialization, (e as CustomEvent).detail),
        );
      }
    });

    if (smartPushUrl) {
      loadScript(smartPushUrl);
    }
  }
};

export const initializeSTFAQ = (): void => {
  const smartTribuneUrl = process.env.REACT_APP_SMART_TRIBUNE_URL;

  window.addEventListener("STFAQLoaded", (e: Event) => {
    if ((e as CustomEvent).detail) {
      window._ST_FAQ = (e as CustomEvent).detail;
      window._ST_FAQ.init({
        analyticsUA: "G-FSD9CVQ6M3",
        buildName: "moncompte",
        kbId: 404,
        locale: "fr",
        cookieOptin: false,
        filters: {
          thematics: [],
          tags: [],
        },
      });
    }
  });

  if (!window._ST_FAQ && !window.faqLoaded && smartTribuneUrl) {
    loadScript(smartTribuneUrl);
    window.faqLoaded = true;
  }

  window.addEventListener(
    "CookiebotOnAccept",
    (e: Event) => {
      handleCookiebotAccept();
    },
    false,
  );
};

export const handleCookiebotAccept = (): void => {
  if (window.Cookiebot?.consent?.statistics) {
    if (window.__STPUSHInit) {
      window.dispatchEvent(
        new CustomEvent("STPUSHUpdate", {
          detail: {
            cookieOptin: true,
          },
        }),
      );
    }

    if (window._ST_FAQ && !window.faqLoaded) {
      window.dispatchEvent(
        new CustomEvent("STFAQLoaded", {
          detail: {
            cookieOptin: true,
          },
        }),
      );
    }
  }
};
