import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { Message } from "./Message/Message";
import { Form } from "./Form/Form";
import { Breadcrumb } from "./Breadcrumb/Breadcrumb";
import { Footer } from "components/molecules/Footer/Footer";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";
import { OverlayLoader } from "@roole/components-library";
import { useResiliation, useResilitationContract } from "domain/service/Contract/useResiliation";
import { resiliationModifsQuery } from "context/selectors";
import { globalState } from "context/globalState/atom";
import { EtatContrat } from "domain/service/Contract/useContract";
import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import { RoutePaths } from "routes/routePaths";
import { currentContratQuery } from "context/currentContratState/selector";

import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./Page.module.scss";
import { useAuthenticationContext } from "AuthenticationProvider";
import { SourceModification } from "domain/api-schemas";
import { sendCloseWebViewEvent, sendResiliatedSuccessfullyEvent } from "utils/sendEventToApp";
import { LoadableState } from "context/state.types";

const steps = ["Ma demande de résiliation", "Confirmation"];

const setupLayoutState = (setLayoutState: (state: any) => void, handleClose: () => void) => {
  setLayoutState({
    ...defaultStateValues,
    layoutId: "header-logo",
    title: "Résilier mon contrat",
    closeButtonId: "resil-croix",
    onCloseCallback: handleClose,
  });
};

export const Page: FC = () => {
  const { idAdhesion } = useRecoilValue(globalState);
  const Contrat = useRecoilValueLoadable(currentContratQuery);
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const modifValues = useRecoilValueLoadable(resiliationModifsQuery);
  const [resiliatedDate, setResiliatedDate] = useState<string>();
  const [confirmSentEmail, setConfirmSentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isJwtAuthenticated } = useAuthenticationContext();
  const { contents } = Contrat;
  const statutMembre = contents?.membre?.statutMembre;

  const { error: resiliationError, mutateAsync: postResiliation } = useResiliation();
  const {
    data: resiliationContract,
    isLoading,
    error: resiliationContractError,
  } = useResilitationContract(idAdhesion!);
  const navigate = useNavigate();

  const isResiliedContrat = useCallback(
    (resiliationContract: ReturnType<typeof useResilitationContract>["data"]) =>
      (resiliationContract?.etatContrat === EtatContrat.Resilie &&
        !!resiliationContract?.dateEffetResiliation) ||
      false,
    [],
  );

  const onClose = useCallback(() => {
    if (isJwtAuthenticated) {
      sendCloseWebViewEvent({ hasError: false });
    } else {
      navigate(-1);
    }
  }, [navigate, isJwtAuthenticated]);

  const handleClose = useCallback(() => {
    navigate(RoutePaths.CONTRAT_HOME);
  }, [navigate]);

  useSmartPushFilters(["resilier-4643"]);

  useEffect(() => {
    setupLayoutState(setLayoutState, handleClose);
  }, [setLayoutState, handleClose]);

  useEffect(() => {
    if (!!resiliatedDate) {
      setLoading(false);
    }
  }, [navigate, resiliatedDate]);

  const submit = useCallback(
    async (data: {
      email: string;
      idAdhesion: string;
      motifResiliationId: string;
      source: SourceModification;
    }) => {
      setLoading(true);
      const { dateEffetResiliation } = await postResiliation(data);
      setResiliatedDate(dateEffetResiliation);
      setConfirmSentEmail(true);
      sendResiliatedSuccessfullyEvent();
    },
    [postResiliation],
  );

  if (loading || isLoading) {
    return <OverlayLoader />;
  }

  if (
    resiliationError ||
    resiliationContractError ||
    modifValues.state === LoadableState.Has_Error
  ) {
    sendCloseWebViewEvent({
      hasError: true,
      errorDetails: { error: resiliationError },
    });
    return (
      <ErrorBlock
        location="résiliation"
        email="resiliation@roole.fr"
        variants={[ErrorBlockVariant.Chat, ErrorBlockVariant.Email]}
      />
    );
  }

  return (
    <>
      {(resiliatedDate || isResiliedContrat(resiliationContract)) && resiliationContract ? (
        <div className={styles.content}>
          <Breadcrumb steps={steps} percent={100} />
          <Message
            endDate={new Date(resiliatedDate || resiliationContract?.dateEffetResiliation || "")}
            confirmSent={confirmSentEmail}
            onClose={onClose}
            statutMembre={statutMembre}
          />
        </div>
      ) : modifValues.state === LoadableState.Has_Value &&
        modifValues.contents &&
        resiliationContract ? (
        <Form
          steps={steps}
          reasons={modifValues.contents}
          contract={resiliationContract}
          className={styles.content}
          onSubmit={submit}
          onClose={onClose}
        />
      ) : (
        <OverlayLoader />
      )}
      {!isJwtAuthenticated && (
        <Footer
          contrat={Contrat?.contents || null}
          idAdhesion={Contrat?.contents?.idAdhesion || null}
        />
      )}
    </>
  );
};
