import { Text } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { useTracking } from "hooks/useTracking";
import { ContactType, contactUsStepState } from "context/contactUsState/atom";
import { RoutePaths } from "routes/routePaths";

import { ReactComponent as IconNewsletter } from "images/icons/newsletter.svg";

import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { ButtonTile } from "components/atoms/ButtonTile/ButtonTile";
import { Card } from "components/atoms/Card/Card";
import { AbstractContactUs } from "components/atoms/AbstractContactUs/AbstractContactUs";

import styles from "./ContactTypeStep.module.scss";
import commonStyles from "pages/ContactUs/Page.module.scss";

const ContactTypeStepView: FC<{
  routes: RoutePaths;
  onClickTile: (route: RoutePaths) => void;
  navigate: NavigateFunction;
  error: boolean;
  setError: (isTrue: boolean) => void;
}> = ({ routes, onClickTile, navigate, error, setError }) => {
  const { sendToDataLayer } = useTracking();
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);

  const handleDataLayer = () => {
    sendToDataLayer(
      `contactez-nous-canal-${routes === RoutePaths.CONTACT_MAIL ? "mail" : "telephone"}`,
    );
    sendToDataLayer("contactez-nous-canal-bouton-suivant");
  };

  const variantToUse = (currentRoute: RoutePaths, toCompareTo: RoutePaths) =>
    currentRoute === toCompareTo ? "primary" : "radio-not-selected";

  const handleSubmit = () => {
    setContactUsStepContextState({
      ...contactUsStepContextState,
      contactType:
        routes === RoutePaths.CONTACT_TELEPHONE ? ContactType.telephone : ContactType.mail,
    });
  };

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Moyen de contact
        </Text>
        <br />
        <Text>
          La relation client est à votre service pour toutes questions concernant la gestion de
          votre contrat, une déclaration de sinistre ou autres.
        </Text>
      </div>
      <Card noMargin className={styles.contactUsTypeStep__Container}>
        <Text as="h2" textVariant="heading-4">
          Comment souhaitez-vous nous contacter ?
        </Text>
        <br />
        <AbstractContactUs
          subject={contactUsStepContextState?.subject}
          reason={contactUsStepContextState?.reason}
        />
        <ButtonTile
          icon={<IconNewsletter className={styles.contactUsTypeStep__NewsletterIcon} />}
          variant={variantToUse(routes, RoutePaths.CONTACT_MAIL)}
          onClick={() => onClickTile(RoutePaths.CONTACT_MAIL)}
          className={styles.contactUsTypeStep__ButtonTile}
          style={{ height: "80px" }}
        >
          Par email
        </ButtonTile>
        {error && (
          <div className={commonStyles.contactUsStep__Error}>
            <Text>Veuillez sélectionner un moyen de contact</Text>
          </div>
        )}
        <NavigationButtons
          prevProps={{
            onClick: () => {
              navigate(RoutePaths.CONTACT_REASON);
            },
            gtmElementId: "contactez-nous-canal-bouton-precedent",
          }}
          nextProps={{
            onClick: () => {
              if (routes !== RoutePaths.CONTACT_TYPE) {
                handleDataLayer();
                handleSubmit();
                navigate(routes);
              } else {
                setError(true);
              }
            },
          }}
        />
      </Card>
    </>
  );
};

const ContactTypeStep: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const [error, setError] = useState<boolean>(false);

  const [routes, setRoutes] = useState<RoutePaths>(RoutePaths.CONTACT_TYPE);

  useEffect(() => {
    setRoutes(RoutePaths.CONTACT_MAIL);
    setContactUsStepContextState({
      ...contactUsStepContextState,
      contactType: ContactType.mail,
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (contactUsStepContextState.subject === null) {
      navigate(RoutePaths.CONTACT_REASON);
    }
    setContactUsStepContextState({
      ...contactUsStepContextState,
      activeStep: 2,
    });
  }, []);

  const onClickTile = (route: RoutePaths) => {
    setContactUsStepContextState({
      ...contactUsStepContextState,
      contactType: route === RoutePaths.CONTACT_MAIL ? ContactType.mail : ContactType.telephone,
    });
    setError(false);
    setRoutes(route);
  };

  return (
    <ContactTypeStepView
      routes={routes}
      onClickTile={onClickTile}
      navigate={navigate}
      error={error}
      setError={setError}
    />
  );
};

export default ContactTypeStep;
