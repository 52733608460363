import {
  AccordionTile,
  Button,
  ButtonGroup,
  Divider,
  IconV2,
  Link,
  Message,
} from "@roole/components-library";
import { monContratState } from "context/currentContratState/atom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { Couverture, ReferentielsPiecesJustificatives } from "domain/api-schemas";
import { declarationDataState } from "context/declarationState/atom";
import { format } from "date-fns";
import { listReferentielsPiecesJustificativesQuery } from "context/referentielsPiecesJustificativesState/selector";
import { getShortMaskedIban } from "utils/MaskedIban";
import { useStepperContext } from "./StepperContext";
import { ibanFormat } from "domain/service/Sinistre/useDossier";
import lodash from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { classNames } from "utils/classNames";
import fr from "date-fns/locale/fr";
import { declarationSinistreApi } from "domain/api/Sinistre/DeclarationSinistre";
import { useAuthenticationContext } from "AuthenticationProvider";
import useMediaQuery from "hooks/useMediaQuery";
import { PJS_STEP } from "./types";

const IconWrapper = styled.span`
  & * {
    display: inline;
    width: min-content;
  }
`;
const LinkWrapper = styled.span`
  & button {
    display: inline;
  }
`;

const safeToLowerCase = (str: string | null | undefined) => str?.toLowerCase() ?? "";

export const RecapStep: React.FC = () => {
  const { goToStep, prevStep, nextStep, setActions, cancelDeclaration } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [monContrat] = useRecoilState(monContratState);
  const [fakePjs, setFakePjs] = useState<Array<string[]>>([]);
  const [declarationSinistreLoading, setDeclarationSinistreLoading] = useState(false);
  const { isJwtAuthenticated, user } = useAuthenticationContext();
  const isMobile = useMediaQuery("mobile");

  const garantiesApplicables = declarationData?.typeEvenement?.garanties
    ?.filter((garantie) => {
      if (declarationData?.typeEvenement?.code === "VOL") {
        if (
          (!declarationData.isVolExpire &&
            declarationData.isVehiculeRetrouve &&
            !declarationData.isVehiculeReparable &&
            garantie.code === "AIDREP") ||
          (!declarationData.isVolExpire &&
            declarationData.isVehiculeRetrouve &&
            declarationData.isVehiculeReparable &&
            garantie.code === "IF") ||
          (declarationData.isVolExpire && garantie.code === "AIDREP")
        ) {
          return false;
        }
      }

      return true;
    })
    ?.filter((_) => (declarationData?.garantiesEligibles ?? []).includes(_.crmId));

  const garanties = useRecoilValueLoadable(
    listReferentielsPiecesJustificativesQuery({
      garantiesIds: declarationData!.typeEvenement!.garanties!.map((_) => _.crmId),
      typeEvenementId: declarationData!.typeEvenement!.crmId,
      isVehiculeRetrouve: !!declarationData?.isVehiculeRetrouve,
      isVehiculeReparable: !!declarationData?.isVehiculeReparable,
    }),
  );

  const garantiesEligibles =
    garanties.state === "hasValue" &&
    (garanties.contents as ReferentielsPiecesJustificatives[]).filter((_) =>
      (declarationData?.garantiesEligibles ?? []).includes(_.garantie.crmId),
    );

  const details = [
    { name: "Sinistre", detail: declarationData?.typeEvenement?.nom },
    {
      name: "Date",
      detail: format(new Date(declarationData?.dateEvenement!), "PPP", { locale: fr }),
    },
    { name: "Description", detail: declarationData?.description },
    {
      name: "Couverture assurance principale",
      detail:
        declarationData?.couvertureAssurance === Couverture.AuTiers ? "Au tiers" : "Tous risques",
    },
    {
      name: "Vous avez déclaré le sinistre à votre assurance principale",
      detail: declarationData?.isDeclareAssuranceAccident ? "Oui" : "Non",
    },
  ];

  useEffect(() => {
    setActions({});

    if (garantiesEligibles && fakePjs.length === 0) {
      setFakePjs(garantiesEligibles.map((g) => g.piecesJustificatives.map((pj) => pj.crmId)));
    }
  }, []);

  return (
    <div className="flex flex-col gap-M">
      <p className="heading-2 ">Récapitulatif de votre déclaration</p>

      <div className="flex flex-col gap-XS ">
        <p className="heading-3 ">Détail du sinistre</p>

        <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
          {details.map((detail, index) => (
            <div className="flex flex-col gap-XS" key={`detail-${detail.name}`}>
              <div className="flex flex-col md:flex-row md:justify-between gap-2 ">
                <p className="flex-1 body-medium ">{detail.name}</p>
                <p className="flex-1 body-medium text-secondary md:text-right ">{detail.detail}</p>
              </div>

              {index !== details.length - 1 && <Divider direction="horizontal" variant="light" />}
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-XS ">
        <div className="flex flex-col flex-3XS">
          <p className="heading-3 ">
            {declarationData?.garantiesEligibles?.length
              ? "Garanties applicables"
              : "Garantie applicable"}
          </p>
          <p className="body-small ">
            Sous réserve des vérifications menées par un conseiller Roole, une fois votre dossier
            complété.
          </p>
        </div>

        {garantiesApplicables?.map((garantie) => (
          <AccordionTile
            title={garantie.nom}
            caption="Informations complémentaires"
            textZone={garantie.plafondMessage}
            key={garantie.nom}
          >
            {Object.entries(declarationData?.responses || {}).map(([question, answer], index) => (
              <div className="flex flex-col gap-XS" key={`response-${index}`}>
                <div className="flex justify-between">
                  <p className="flex-1 body-medium">{question}</p>
                  <p className="flex-1 body-medium text-secondary text-right">{answer}</p>
                </div>
                {index !== Object.entries(declarationData?.responses || {}).length - 1 && (
                  <Divider direction="horizontal" variant="light" />
                )}
              </div>
            ))}
          </AccordionTile>
        ))}
      </div>

      <div className="flex flex-col gap-XS ">
        <div className="flex flex-col flex-3XS">
          <p className="heading-3 ">Pièces justificatives</p>
          <p className="body-small ">
            Vous pourrez ajouter de nouvelles pièces justificatives plus tard, dans le menu “Mes
            sinistres”.
          </p>
        </div>

        {declarationData?.garanties?.map((garantie) => {
          const pjs = garantie.piecesJointes || [];
          const pjsLeft = Array.isArray(garantiesEligibles)
            ? (garantiesEligibles.find(
                (g: { garantie: { crmId: string }; piecesJustificatives?: any[] }) =>
                  g.garantie.crmId === garantie.id,
              )?.piecesJustificatives?.length || 0) - pjs.length
            : 0;
          const nomGarantie = garantiesApplicables?.find((g) => g.crmId === garantie.id)?.nom;

          return (
            <div
              className="flex flex-col gap-XS border border-default rounded-XS p-S "
              key={garantie.id}
            >
              <div className="flex flex-col md:flex-row md:items-center gap-4 justify-between  ">
                <p className="flex-1 heading-4 ">{nomGarantie}</p>
                <p
                  className={classNames(
                    "body-medium md:text-right ",
                    pjsLeft === 0 ? "text-positive" : "text-secondary",
                  )}
                  style={{ textWrap: "nowrap" }}
                >
                  Justificatifs ajoutés :{" "}
                  <b>
                    {
                      pjs
                        .map((pj) => pj.typeId)
                        .filter((typeId, index, self) => self.indexOf(typeId) === index).length
                    }
                    /
                    {(Array.isArray(garantiesEligibles) &&
                      garantiesEligibles.find(
                        (g: { garantie: { crmId: string }; piecesJustificatives: any[] }) =>
                          g.garantie.crmId === garantie.id,
                      )?.piecesJustificatives.length) ||
                      0}
                  </b>{" "}
                  {pjsLeft === 0 && (
                    <IconWrapper>
                      <IconV2 name="checked" color="positive" />
                    </IconWrapper>
                  )}
                </p>
              </div>

              {pjs.map((pj, index) => {
                const pieceReferentielle =
                  garanties.state === "hasValue"
                    ? garanties.contents
                        .flatMap((g) => g.piecesJustificatives)
                        .find((piece) => piece.crmId === pj.typeId)
                    : null;

                return (
                  <div key={index} className="flex flex-col md:flex-row justify-between gap-2 ">
                    <p className="flex-1 body-medium ">{pieceReferentielle?.nom || ""}</p>
                    <p className="body-medium text-secondary md:text-right ">
                      <IconWrapper>
                        <IconV2 name="file" color="neutral" />
                      </IconWrapper>{" "}
                      {pj.nom} ({pj.size} Ko)
                    </p>
                  </div>
                );
              })}

              {pjsLeft > 0 && (
                <Message
                  type="information"
                  icon="info"
                  message={
                    <p className="body-small ">
                      {pjsLeft === 1 && "1 pièce justificative est nécessaire pour votre dossier."}
                      {pjsLeft > 1 &&
                        `${pjsLeft} pièces justificatives sont nécessaires pour votre dossier.`}{" "}
                      <LinkWrapper>
                        <Link
                          label="Ajouter des pièces justificatives"
                          variant="dark"
                          size="small"
                          onClick={() => goToStep(PJS_STEP)}
                        />
                      </LinkWrapper>
                    </p>
                  }
                />
              )}
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-XS ">
        <p className="heading-3 ">Coordonnées bancaires</p>

        {!declarationData?.iban && (
          <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Mode de remboursement</p>
                <p className="body-medium text-secondary md:text-right ">Chèque</p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Titulaire</p>
                <p className="body-medium text-secondary md:text-right ">
                  {lodash.startCase(safeToLowerCase(monContrat?.membre?.prenom))}{" "}
                  {lodash.startCase(safeToLowerCase(monContrat?.membre?.nom))}
                </p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Adresse</p>
                <p className="body-medium text-secondary md:text-right capitalize ">
                  {lodash.startCase(safeToLowerCase(monContrat?.membre?.rue))},{" "}
                  {lodash.startCase(safeToLowerCase(monContrat?.membre?.ville))}{" "}
                  {monContrat?.membre.codePostal}
                </p>
              </div>
            </div>
          </div>
        )}
        {declarationData?.iban && (
          <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Mode de remboursement</p>
                <p className="body-medium text-secondary md:text-right ">Virement bancaire</p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Titulaire</p>
                <p className="body-medium text-secondary md:text-right ">
                  {lodash.startCase(safeToLowerCase(monContrat?.membre.prenom))}{" "}
                  {lodash.startCase(safeToLowerCase(monContrat?.membre.nom))}
                </p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">IBAN</p>
                <p className="body-medium text-secondary md:text-right ">
                  {ibanFormat(getShortMaskedIban(declarationData?.iban))}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-S justify-center items-center">
        <ButtonGroup
          buttonPrimary={{
            variant: "primary-brand",
            disabledLight: declarationSinistreLoading,
            children: <p className="body-medium">Valider ma déclaration</p>,
            onClick: () => {
              if (declarationSinistreLoading) {
                return;
              }

              setDeclarationSinistreLoading(true);

              const garantiesMapped =
                declarationData?.garantiesEligibles?.map((id) => {
                  const garantie = declarationData?.garanties?.find((g) => g.id === id);
                  const pjsLength =
                    garantie?.piecesJointes
                      ?.map((pj) => pj.typeId)
                      .filter((typeId, index, self) => self.indexOf(typeId) === index).length || 0;

                  const totalRequiredPjs =
                    (Array.isArray(garantiesEligibles) &&
                      garantiesEligibles.find(
                        (g: { garantie: { crmId: string }; piecesJustificatives: any[] }) =>
                          g.garantie.crmId === garantie?.id,
                      )?.piecesJustificatives.length) ||
                    0;

                  const isDossierComplet = pjsLength === totalRequiredPjs && totalRequiredPjs !== 0;

                  return {
                    id: id,
                    isDossierComplet,
                    piecesJointes:
                      garantie?.piecesJointes?.map((pj) => ({
                        typeId: pj.typeId || "",
                        nom: pj.nom || "",
                        blobNom: pj.blobNom || "",
                      })) || [],
                  };
                }) ?? [];

              declarationSinistreApi
                .declarerSinistre({
                  idAdhesion: monContrat?.idAdhesion!,
                  typeEvenementId: declarationData?.typeEvenement?.crmId!,
                  evenementId: declarationData?.evenementId!,
                  garanties: garantiesMapped,
                  origine: isJwtAuthenticated ? 9 : 7,
                  note: declarationData?.description! || "",
                  isReprise: false,
                  dateEvenement: declarationData?.dateEvenement!,
                  isVehiculeRetrouve:
                    declarationData?.typeEvenement?.code === "VOL"
                      ? !!declarationData?.isVehiculeRetrouve
                      : undefined,
                  isVehiculeReparable:
                    declarationData?.typeEvenement?.code === "VOL"
                      ? !!declarationData?.isVehiculeReparable
                      : undefined,
                  mailMembre: user?.email!,
                  iban: declarationData?.iban,
                })
                .then((recap) => {
                  setDeclarationData({
                    ...declarationData,
                    recap,
                  } as any);

                  nextStep();
                })
                .catch((error) => {
                  setDeclarationSinistreLoading(false);
                  console.error("declarerSinistre ERROR", error);
                });
            },
          }}
          buttonSecondary={{
            variant: "secondary-dark",
            disabledLight: declarationSinistreLoading,
            children: <p className="body-medium">Retour</p>,
            onClick: () => {
              if (declarationSinistreLoading) {
                return;
              }

              prevStep();
            },
          }}
          direction={isMobile ? "column" : "row"}
          size="large"
        />

        <Link
          label="Annuler ma déclaration"
          variant="dark"
          size="large"
          onClick={cancelDeclaration}
        />
      </div>
    </div>
  );
};
