import { DropDownItem } from "@roole/components-library";
import { InfiniteData } from "react-query";

import { Appointment } from "components/molecules/AppointmentSchedule/Appointment";
import { Concession, ConcessionsGroupeViewModel, MonGarageViewModel } from "domain/api-schemas";

export type InfiniteDealerships = InfiniteData<ConcessionsGroupeViewModel> & {
  dealerships: Concession[];
};

export const mapHotDealActivation = (idBonPlan: string, dealership: Concession): Concession => {
  return dealership?.mesBonsPlans
    ? {
        ...dealership,
        mesBonsPlans: dealership.mesBonsPlans.map((item) =>
          item.id === idBonPlan ? { ...item, active: true } : item,
        ),
      }
    : dealership;
};

export const mapHotDealActivationFromMyGarage = (
  idBonPlan: string,
  myGarage: MonGarageViewModel,
): MonGarageViewModel => {
  return myGarage.maConcession
    ? {
        ...myGarage,
        maConcession: mapHotDealActivation(idBonPlan, myGarage.maConcession),
      }
    : myGarage;
};

export const mapHotDealActivationFromOtherDealerships = (
  idBonPlan: string,
  idConcession: string,
  otherDealerships: InfiniteData<ConcessionsGroupeViewModel>,
): InfiniteData<ConcessionsGroupeViewModel> | null => {
  return {
    ...otherDealerships,
    pages: otherDealerships?.pages.map((page) => {
      if (!page.concessionsGroupe?.find((item) => item.id === idConcession)) {
        return page;
      }

      return {
        ...page,
        concessionsGroupe: page.concessionsGroupe.map((group) =>
          group.id !== idConcession ? group : mapHotDealActivation(idBonPlan, group),
        ),
      };
    }),
  };
};

export const selectMyDealerships = (
  data: InfiniteData<ConcessionsGroupeViewModel>,
): InfiniteDealerships => ({
  ...data,
  dealerships: data?.pages.reduce(
    (items, item) => (!!item.concessionsGroupe ? items.concat(item.concessionsGroupe) : items),
    [] as Concession[],
  ),
});

export const getNextDealershipsParam = ({
  page,
  nbElements,
  nbConcessions,
}: ConcessionsGroupeViewModel) => {
  if (!page || !nbElements || !nbConcessions) {
    return;
  }

  const totalPages = Math.max(nbConcessions / nbElements, 1);
  return page < totalPages ? page + 1 : undefined;
};

export const selectBrands = (data: string[]): DropDownItem[] => {
  if (!data?.length) {
    return [];
  }

  return [{ id: 1, name: "Voir toutes les marques", disabled: false }].concat(
    (data || []).map((name, index) => ({ id: index + 2, name, disabled: false })),
  );
};

export const mapDealershipToLowercase = (dealership: Concession): Concession => {
  return {
    ...dealership,
    nom: dealership.nom?.toLowerCase(),
    marque: dealership.marque?.toLowerCase(),
    adresse: dealership.adresse?.toLowerCase(),
    cpVille: dealership.cpVille?.toLowerCase(),
  };
};

export const mapMyGarageToLowercase = (myGarage: MonGarageViewModel): MonGarageViewModel => {
  return {
    ...myGarage,
    maConcession: myGarage.maConcession
      ? mapDealershipToLowercase(myGarage.maConcession)
      : myGarage.maConcession,
  };
};

export const mapMyDealershipsToLowercase = (
  myDealerships: ConcessionsGroupeViewModel,
): ConcessionsGroupeViewModel => {
  return {
    ...myDealerships,
    concessionsGroupe: myDealerships.concessionsGroupe
      ? myDealerships.concessionsGroupe.map((dealership) => mapDealershipToLowercase(dealership))
      : myDealerships.concessionsGroupe,
  };
};

export const mapAppointmentReminder = ({
  date,
  timeSlot,
}: Partial<Appointment["reminder"]>): string => {
  const formattedDate = date
    ? new Date(date).toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : null;

  return [formattedDate, timeSlot?.name].filter((item) => !!item).join(" - ");
};

const sortItems = (a: DropDownItem, b: DropDownItem): number => {
  if (!a || !b) {
    return 0;
  }

  return a.id - b.id;
};

export const mapAppointment = (appointment?: Appointment): { [key: string]: string } => {
  const sortedDays = appointment?.days.sort(sortItems) || [];

  return appointment
    ? {
        reason: appointment.reason.name,
        reminder: mapAppointmentReminder(appointment.reminder),
        days: sortedDays.map((day) => day.name).join(", "),
      }
    : {};
};
