import { addNewAccessTokenInterceptor } from "clientProvider/axiosConfig";
import { SelfcareRequest } from "domain/api-schemas";
import { membreApi } from "domain/api/Membre/membreApi";
import { useQuery } from "react-query";

export const useGetSelfcareRequest = (data: { id: string; type: string }) =>
  useQuery<SelfcareRequest, Error>(
    "query-SelfcareRequest",
    async () => await membreApi.checkDossierIdSelfcareRequest(data),
  );

export const checkDossierIdSelfcareRequest = async (data: SelfcareRequest) => {
  if (!data.exist) {
    return { code: 404 };
  } else if (!data.valid) {
    return {
      displayMessage: true,
    };
  } else {
    await addNewAccessTokenInterceptor(data.token.accessToken);
    return { token: data.token.expiresIn };
  }
};

export interface DossierStateType {
  code: number | undefined;
  message: string | undefined;
  tokenExpiration: number | undefined;
}
