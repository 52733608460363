import React, { PropsWithChildren } from "react";

interface ReadItemProps {
  label: React.ReactNode | string;
  lineClassName?: string;
  lineClassNameAlt?: string;
}

const ReadItem: React.FC<PropsWithChildren<ReadItemProps>> = ({
  label,
  lineClassName,
  lineClassNameAlt,
  children,
}) => (
  <div className={lineClassName}>
    {label} :<span className={lineClassNameAlt}>{children}</span>
  </div>
);

export default ReadItem;
