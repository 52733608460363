import React, { CSSProperties, ReactNode } from "react";
import {
  ChildrenType,
  EventsType,
  IPosition,
  PlacesType,
  PositionStrategy,
  Tooltip as ReactTooltip,
  VariantType,
  WrapperType,
} from "react-tooltip";
import { useMatchMedia } from "hooks/useMatchMedia";

import "react-tooltip/dist/react-tooltip.css";
import { classNames } from "utils/classNames";
import cssVariables from "styles/variables.module.scss";

import styles from "./Tooltip.module.scss";

type Props = React.PropsWithChildren<{
  className?: string;
  classNameArrow?: string;
  content?: ReactNode | string;
  html?: string;
  place?: PlacesType;
  offset?: number;
  id?: string;
  variant?: VariantType;
  anchorId?: string;
  wrapper?: WrapperType;
  children?: ChildrenType;
  events?: EventsType[];
  positionStrategy?: PositionStrategy;
  delayShow?: number;
  delayHide?: number;
  float?: boolean;
  noArrow?: boolean;
  clickable?: boolean;
  style?: CSSProperties;
  position?: IPosition;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  showIcon?: boolean;
}>;

const Tooltip: React.FunctionComponent<Props> = ({
  place,
  offset,
  content,
  events,
  children,
  className,
  clickable = true,
  classNameArrow,
  html,
  id,
  variant,
  anchorId,
  wrapper,
  positionStrategy,
  delayShow,
  delayHide,
  float,
  noArrow,
  style,
  position,
  isOpen,
  showIcon = true,
}: Props) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return (
    <>
      {isDesktop ? (
        <div>
          {children}
          <a
            data-class={className}
            data-place={place}
            data-offset={offset}
            data-event={events}
            id={id}
          >
            {showIcon && (
              <div className={classNames(styles.Tooltip, className)}>
                <div className={styles.Tooltip__icon} role="button" />
              </div>
            )}
          </a>
        </div>
      ) : (
        <a
          data-class={className}
          data-place={place}
          data-offset={offset}
          data-event={events}
          id={id}
        >
          {children}
          {showIcon && (
            <div className={classNames(styles.Tooltip, className)}>
              <div className={styles.Tooltip__icon} role="button" />
            </div>
          )}
        </a>
      )}

      <ReactTooltip
        events={events}
        clickable={clickable}
        className={className}
        classNameArrow={classNameArrow}
        html={html}
        variant={variant}
        anchorId={anchorId}
        wrapper={wrapper}
        positionStrategy={positionStrategy}
        delayShow={delayShow}
        delayHide={delayHide}
        float={float}
        noArrow={noArrow}
        style={style}
        position={position}
        isOpen={isOpen}
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
