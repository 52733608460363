import axiosClient from "clientProvider/axiosConfig";
import { Appointment } from "components/molecules/AppointmentSchedule/Appointment";
import { Concession, ConcessionsGroupeViewModel, MonGarageViewModel } from "domain/api-schemas";
import * as MyGarageAdapter from "domain/service/MyGarage/myGarageAdapter";

export const endpoints = {
  getMyGarage: "MonGarage",
  getNextDealerships: "MonGarage/ConcessionsGroupe",
  getBrands: "MonGarage/ConcessionsGroupe/Marques",
  activateHotDeal: "MonGarage/BonPlan/Activate",
  connectDealership: "MonGarage/AssocierDistribRattachement",
  scheduleAppointment: "MonGarage/PrendreRendezVous",
  schedulePartnerAppointment: "MonGarage/PrendreRendezVousPartenaire",
};

export const myGarageService = {
  getMyGarage: (idAdhesion: string | null) =>
    axiosClient
      .get<MonGarageViewModel>(endpoints.getMyGarage, {
        params: { idAdhesion },
      })
      .then((result) => MyGarageAdapter.mapMyGarageToLowercase(result.data))
      .catch(() => {}),

  getNextDealerships: (idAdhesion: string | null, filters?: string, page = 1, nbElements = 5) =>
    axiosClient
      .get<ConcessionsGroupeViewModel>(endpoints.getNextDealerships, {
        params: { idAdhesion, "filters[marque]": filters, page, nbElements },
      })
      .then((result) => MyGarageAdapter.mapMyDealershipsToLowercase(result.data)),

  getBrands: (idAdhesion: string | null) =>
    axiosClient
      .get<string[]>(endpoints.getBrands, { params: { idAdhesion } })
      .then((result) => result.data)
      .catch(() => []),

  activateHotDeal: (idBonPlan: string, idConcession: string, idAdhesion: string | null) =>
    axiosClient
      .post<MonGarageViewModel>(endpoints.activateHotDeal, { idBonPlan, idAdhesion, idConcession })
      .then(() => ({ idBonPlan, idConcession })),

  connectDealership: (dealership: Concession, idAdhesion: string | null) =>
    axiosClient
      .post<Concession>(endpoints.connectDealership, {
        idAdhesion,
        idDistrib: dealership.id,
      })
      .then(() => dealership),

  scheduleAppointment: (
    idAdhesion: string | null,
    { id: idDistrib, optionRDV }: Concession,
    appointment?: Appointment,
  ) =>
    axiosClient
      .post<Appointment>(endpoints.scheduleAppointment, {
        idAdhesion,
        idDistrib,
        optionRDV,
        ...MyGarageAdapter.mapAppointment(appointment),
      })
      .then(() => appointment),

  schedulePartnerAppointment: (
    idAdhesion: string | null,
    idDistrib: string | null | undefined,
    kilometrage: number,
  ) =>
    axiosClient
      .post<{ urlRedirection: string }>(endpoints.schedulePartnerAppointment, {
        idAdhesion,
        idDistrib,
        kilometrage,
      })
      .then((res) => res.data.urlRedirection),
};
