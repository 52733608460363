import { formatDate } from "utils/formatDate";

export const AUTRES_TYPE_ID = "C0459527-8137-EF11-8697-005056B0AF88";

export interface UploadedFile {
  name: string;
  base64: string;
  size: number;
  date: string;
  typeId: string;
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const validateAndSetFile = async (
  file: File | null,
  setErrorMessage: (msg: string | null) => void,
  setUploadedFiles: (files: (prevFiles: UploadedFile[]) => UploadedFile[]) => void,
  selectedPJ: { typeId?: string } = { typeId: AUTRES_TYPE_ID },
) => {
  if (!file) {
    setErrorMessage("Aucun fichier sélectionné.");
    return;
  }

  const validTypes = ["image/jpeg", "image/png", "application/pdf"];
  if (!validTypes.includes(file.type)) {
    setErrorMessage("Le fichier doit être une image ou un PDF.");
    return;
  }

  const base64 = await fileToBase64(file);
  const newFile: UploadedFile = {
    name: file.name,
    base64,
    size: file.size,
    date: formatDate(new Date()),
    typeId: selectedPJ.typeId || AUTRES_TYPE_ID,
  };
  setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
  setErrorMessage(null);
};

export const handleDelete = (
  name: string,
  setUploadedFiles: (files: (prevFiles: UploadedFile[]) => UploadedFile[]) => void,
) => {
  setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== name));
};

export const renderFileNames = (uploadedFiles: UploadedFile[]) => {
  return uploadedFiles.map((file) => file.name).join(", ");
};

export const renderConfirmationMessage = (uploadedFiles: UploadedFile[]) => {
  const fileCount = uploadedFiles.length;
  const view = renderFileNames(uploadedFiles);
  const text = `En validant, les documents que vous avez importés précédemment pour la ${
    fileCount > 1 ? "pièces justificatives" : "pièce justificative"
  } ${view}, ne pourront plus être supprimés.`;
  return text;
};
