import { Children, FC, useMemo, PropsWithChildren } from "react";
import { Navigate, RouteObject, useResolvedPath, useRoutes } from "react-router-dom";

import { WizardContextProvider } from "./WizardContext";
import { WizardErrorModal } from "./WizardErrorModal";
import { WizardLayout } from "./WizardLayout";
import { WizardInitHandler, WizardStepElement, WizardStepObject } from "./Wizard.types";
import { WizardStepGuard } from "./WizardStepGuard";

interface WizardProps {
  children?: WizardStepElement[];
  initHandler?: WizardInitHandler;
}

interface WizardConfig {
  steps: Partial<WizardStepObject>[];
  routes: RouteObject[];
  indexPath: string | null;
}

const wrapWithGuard = (el: WizardStepElement, Guard?: FC<PropsWithChildren>) => {
  return Guard ? <Guard>{el}</Guard> : el;
};

export const Wizard: FC<WizardProps> = ({ children, initHandler }) => {
  const { pathname: basePath } = useResolvedPath(".");
  const { steps, routes, indexPath } = useMemo(
    () =>
      (Children.toArray(children) as WizardStepElement[]).reduce(
        (config: WizardConfig, element, index) => {
          const { path, guard, label, icon } = element.props;
          return {
            steps: config.steps.concat({ path, label, icon }),
            routes: config.routes.concat({
              path,
              element: index ? (
                <WizardStepGuard>{wrapWithGuard(element, guard)}</WizardStepGuard>
              ) : (
                element
              ),
            }),
            indexPath: index ? config.indexPath : path,
          };
        },
        { steps: [], routes: [], indexPath: null },
      ),
    [children],
  );

  return useRoutes(
    indexPath
      ? [
          {
            element: (
              <WizardContextProvider
                basePath={basePath}
                indexPath={indexPath}
                steps={steps}
                initHandler={initHandler}
              >
                <WizardErrorModal />
                <WizardLayout />
              </WizardContextProvider>
            ),
            children: [
              {
                index: true,
                element: <Navigate to={indexPath} />,
              },
              ...routes,
              {
                path: "*",
                element: <Navigate to={indexPath} />,
              },
            ],
          },
        ]
      : [],
  );
};
