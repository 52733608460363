import { FC, useEffect } from "react";
import { matchPath, Outlet, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { defaultStateValues, tunnelLayoutState } from "context/tunnelLayoutState/atom";
import {
  reportClaimStepContextStateInitValue,
  reportClaimStepState,
} from "context/reportClaimState/atom";
import { getInfoClaimsQuery } from "context/reportClaimState/selectors";
import { LoadableState } from "context/state.types";
import { RoutePaths } from "routes/routePaths";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useTracking } from "hooks/useTracking";
import { useBlocker } from "hooks/useBlocker";

import { Stepper } from "components/atoms/Stepper/Stepper";
import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";
import { ContactBlockTunnel } from "components/molecules/ContactBlockTunnel/ContactBlockTunnel";
import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./Page.module.scss";
import cssVariables from "styles/variables.module.scss";

export const Page: FC = () => {
  const [reportClaimStepContextState, setReportClaimStepContextState] =
    useRecoilState(reportClaimStepState);
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  useSmartPushFilters(["sinistre-4642"]);

  const headerTitle = "Déclarer un sinistre";
  const handleClick = () => {
    if (reportClaimStepContextState?.isEditing) {
      return navigate(RoutePaths.SINISTRES);
    }
    navigate(RoutePaths.CONTRAT_HOME);
  };
  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        claimId: id,
      });
    }
  }, [id]);

  const currentClaim = useRecoilValueLoadable(getInfoClaimsQuery);

  useEffect(() => {
    if (currentClaim.state === LoadableState.Has_Value) {
      setReportClaimStepContextState({
        ...reportClaimStepContextState,
        claimGuarantee:
          currentClaim.contents?.garantieUtilisee ||
          reportClaimStepContextState?.claimGuarantee ||
          null,
      });
    }
  }, [currentClaim.state, currentClaim.contents, setReportClaimStepContextState]);

  const steps = [
    "Date du sinistre",
    "Description du sinistre",
    "Sélection des garanties",
    "Envoi des pièces justificatives",
    "Confirmation",
  ];

  useEffect(() => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "declarer-sinistre-header-logo",
      title: headerTitle,
      onCloseCallback: handleClick,
      disableLogoLink: true,
      closeButtonId: "declarer-sinistre-croix-bouton",
    });
  }, []);

  useBlocker((tx) => {
    if (!matchPath(`${RoutePaths.DECLARER_SINISTRE}/:state`, tx.location.pathname)) {
      if (
        window.confirm(
          "Vous allez quitter le processus et aucune information déjà saisie ne sera enregistrée. " +
            "\n Etes-vous sûr de vouloir quitter cette page ?",
        )
      ) {
        setReportClaimStepContextState(reportClaimStepContextStateInitValue);
        localStorage.removeItem("editReportClaim");
        tx.retry();
      }
    } else {
      tx.retry();
    }
  }, reportClaimStepContextState.activeStep < steps.length);

  const renderSocialBlock = () => (
    <aside>
      <SocialBlock className={styles.SocialBlock}>
        {[<ContactBlockTunnel idTracking="declarer-sinistre-telephone-bouton" />]}
      </SocialBlock>
    </aside>
  );

  return (
    <>
      <div className={styles.contactUsFormWrapper}>
        <Stepper
          steps={steps}
          activeStep={reportClaimStepContextState.activeStep}
          onClick={() => sendToDataLayer("declarer-sinistre-stepper")}
        />
        <div className={styles.contactUsFormWrapper__Content}>
          <div className={styles.ContactUs__Layout}>
            <Outlet />
          </div>
          {isDesktop && renderSocialBlock()}
        </div>
        {!isDesktop && renderSocialBlock()}
      </div>
    </>
  );
};
