import { InputText } from "@roole/components-library";
import { ComponentProps, forwardRef } from "react";

import { TimeSlot } from "./Appointment";
import { mapAppointmentReminder } from "domain/service/MyGarage/myGarageAdapter";

interface CustomCalendarInputProps extends ComponentProps<typeof InputText> {
  date?: string;
  timeSlot?: TimeSlot;
}

export const CustomCalendarInput = forwardRef<HTMLInputElement, CustomCalendarInputProps>(
  ({ date, timeSlot, ...props }, ref) => {
    const formattedValue = mapAppointmentReminder({ date, timeSlot });

    return <InputText {...props} value={formattedValue} ref={ref} />;
  },
);
