import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { App } from "App";
import { Auth0ProviderWithHistory } from "clientProvider/Auth0ProviderWithHistory";
import { GlobalStyles } from "@roole/components-library";
import "@roole/components-library/build/output.tailwind.css";

import "react-loading-skeleton/dist/skeleton.css";
import "./styles/index.scss";
import AuthenticationProvider from "AuthenticationProvider";

const mount = (
  <StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <GlobalStyles />
        <AuthenticationProvider>
          <App />
        </AuthenticationProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(mount);
