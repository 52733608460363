import React, { useEffect, useRef, MutableRefObject, useState } from "react";
import { ReactComponent as ArrowBack } from "images/icons/arrowBack.svg";
import { classNames } from "utils/classNames";
import styles from "./CarousselWithLeftRightArrows.module.scss";
import { v4 as uuid } from "uuid";
import useMediaQuery from "hooks/useMediaQuery";

type Props = {
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

/**
 Pour utiliser ce component il faut me donner la ref du caroussel, là où il y a le overflow auto, et ce même caroussel doit avoir un id unique
 */
const CarousselWithLeftRightArrows: React.FC<Props> = ({ containerRef }) => {
  const [renderCaroussel, setRenderCaroussel] = useState(false);
  const isMobileOrTablet = useMediaQuery("mobile_and_tablet");

  const arrowLeftRef = useRef<HTMLDivElement | null>(null);
  const arrowRightRef = useRef<HTMLDivElement | null>(null);
  const container = containerRef.current;
  const uid = uuid();
  const carousselId = container?.id!;
  const arrowLeftId = `${uid}-arrowLeft`;
  const arrowRightId = `${uid}-arrowRight`;

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const caroussel = document.getElementById(carousselId);
    const arrowLeft = document.getElementById(arrowLeftId);
    const arrowRight = document.getElementById(arrowRightId);

    const showOrHideArrow = (className: string, isHide: boolean) => {
      const classNames = className.split(" ");

      if (isHide) {
        return [...classNames, styles.advices_arrow_hidden].join(" ");
      }

      return classNames
        .filter((_className) => _className !== styles.advices_arrow_hidden)
        .join(" ");
    };

    const handleScroll = () => {
      if (!caroussel || !arrowLeftRef.current || !arrowRightRef.current) {
        return;
      }
      const scrollPosition = caroussel.scrollLeft;

      arrowLeftRef.current.className = showOrHideArrow(
        arrowLeftRef.current.className,
        scrollPosition === 0,
      );

      arrowRightRef.current.className = showOrHideArrow(
        arrowRightRef.current.className,
        scrollPosition >= caroussel.scrollWidth - caroussel.clientWidth,
      );
    };

    const handleLeft = () => {
      if (!caroussel) {
        return;
      }

      caroussel.scrollTo({
        left: caroussel.scrollLeft - caroussel.clientWidth * 0.8,
        behavior: "smooth",
      });
    };

    const handleRight = () => {
      if (!caroussel) {
        return;
      }

      caroussel.scrollTo({
        left: caroussel.scrollLeft + caroussel.clientWidth * 0.8,
        behavior: "smooth",
      });
    };

    handleScroll();

    caroussel?.addEventListener("scroll", handleScroll);
    arrowLeft?.addEventListener("click", handleLeft);
    arrowRight?.addEventListener("click", handleRight);

    window.addEventListener("resize", handleScroll);

    return () => {
      caroussel?.removeEventListener("scroll", handleScroll);
      arrowLeft?.removeEventListener("mousedown", handleLeft);
      arrowRight?.removeEventListener("mousedown", handleRight);
      window.removeEventListener("resize", handleScroll);
    };
  }, [
    containerRef,
    carousselId,
    arrowLeftId,
    arrowRightId,
    arrowLeftRef,
    arrowRightRef,
    renderCaroussel,
  ]);

  useEffect(() => {
    setRenderCaroussel(!!containerRef.current);
  }, [containerRef]);

  return (
    <>
      {renderCaroussel && !isMobileOrTablet && (
        <>
          <div
            id={arrowLeftId}
            ref={arrowLeftRef}
            className={classNames(styles.advices_arrow, styles.advices_arrow_left)}
          >
            <ArrowBack width={20} height={20} />
          </div>
          <div
            id={arrowRightId}
            ref={arrowRightRef}
            className={classNames(styles.advices_arrow, styles.advices_arrow_right)}
          >
            <ArrowBack width={20} height={20} />
          </div>
        </>
      )}
    </>
  );
};

export default CarousselWithLeftRightArrows;
