import { FC } from "react";

import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import { RoutePaths } from "routes/routePaths";

const MainText: FC = () => (
  <>
    <p>
      Aucun prélèvement ne vous sera effectué.
      <br />
      Vous pouvez repasser votre commande à tout moment.
    </p>
    <p>A très bientôt !</p>
    <p>L'équipe Roole</p>
  </>
);

export const PageAbandonTW: FC = () => (
  <EndPathLayout
    headerTitle="Abandon de la commande"
    subTitle="Votre commande a été abandonnée"
    mainText={<MainText />}
    id="page-abandon-tw"
    buttonText="Retour vers mes bons plans"
    redirectionUrl={RoutePaths.BON_PLANS_HOME}
    displayTunnelHeader
  />
);
