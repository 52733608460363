import axiosClient from "clientProvider/axiosConfig";

export const regularisationApi = {
  getInfos: async (contratId: string | undefined) => {
    const result = await axiosClient.get(`Regularisation/Echeanciers/${contratId}`);
    return { echeances: result.data.echeanciers, montantTotal: result.data.montantTotal };
  },
  createDossier: async (idAdhesion: string | undefined, email: string | undefined) => {
    const result = await axiosClient.post<string>(`Regularisation/Generate`, {
      idAdhesion: idAdhesion,
      email: email,
    });
    return result.data;
  },
};
