import { atom } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { APPLICATIONSTATES } from "context/global.types";
import { navigationMenuQuery } from "context/navigationState/selector";

export interface Atom {
  displayRoutes: Partial<Record<RoutePaths, boolean>>;
}

export const navigationState = atom<Atom>({
  key: APPLICATIONSTATES.NavigationState,
  default: navigationMenuQuery,
});
