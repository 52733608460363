import { FC } from "react";

import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import { RoutePaths } from "routes/routePaths";

const MainText: FC = () => (
  <p>
    Nous vous invitons à réessayer de nous contacter en cliquant sur le bouton ci-dessous ou en nous
    appelant directement au 01 46 90 37 00 du lundi au vendredi de 9h à 18h (appel gratuit)
  </p>
);

export const ContactErrorStep: FC = () => {
  return (
    <EndPathLayout
      headerTitle="Contactez nous"
      subTitle="Votre demande n’a pas pu être prise en compte !"
      mainText={<MainText />}
      id="page-erreur-contactez-nous"
      redirectionUrl={RoutePaths.CONTRAT_HOME}
      redirectionUrlButton={RoutePaths.CONTACT_REASON}
      buttonText="Nouvelle demande de contact"
      closeButtonId="contactez-nous-croix-quitter"
    />
  );
};
