import React from "react";
import { Logo, Text } from "@roole/components-library";
import { RoutePaths } from "routes/routePaths";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";
import { Box } from "components/atoms/Box/Box";
import { classNames } from "utils/classNames";
import { useSearchParamsValues } from "hooks/useSearchParamsValues";
import EmailNoVerifiedError from "pages/Authentification/EmailNoVerifiedError";

import styles from "./UnauthorizedError.module.scss";
import { initializeSTPUSH } from "SmartTribune";
import { useAuthenticationContext } from "AuthenticationProvider";

const UnAuthorizedError: React.FC = () => {
  const { loginWithRedirect } = useAuthenticationContext();

  const erroreValues = useSearchParamsValues();
  const errorDescription =
    erroreValues && erroreValues.hasOwnProperty("error_description")
      ? erroreValues["error_description"]
      : "";
  const isEmailUnverifiedError = errorDescription.includes("Not verified email");

  const getEmail = errorDescription ? errorDescription.split(":")[1] : "";

  const otherErrorsMessage = (
    <>
      <span className={styles.unauthorized__subtitle}>Une erreur est survenue.</span>
      <span className={styles.unauthorized__subtitle}>Veuillez ressayer plus tard.</span>
    </>
  );

  if (isEmailUnverifiedError) {
    initializeSTPUSH("", ["connexion-cp-4655"]);
    return <EmailNoVerifiedError loginWithRedirect={loginWithRedirect} email={getEmail} />;
  }

  return (
    <Box className={classNames(styles.boxcontainer)}>
      <header>
        <div className={styles.unauthorized__logo}>
          <Logo temp={false} dark={false} url={RoutePaths.HOME} />
        </div>
        <div className={styles.unauthorized__warning_image}>
          <WarningIcon className={styles.warningIcon} />
        </div>

        <div className={styles.unauthorized__title}>
          <Text textVariant="heading-5" as="h2">
            Oups!
          </Text>
        </div>
      </header>
      {otherErrorsMessage}
    </Box>
  );
};

export default UnAuthorizedError;
