import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";

const ReconductionConfirmationCb: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(RoutePaths.CONTRAT, { replace: true });
  }, [navigate]);

  return <></>;
};

export default ReconductionConfirmationCb;
