import { Children, FC, PropsWithChildren } from "react";
import { Text } from "@roole/components-library";

import { classNames } from "utils/classNames";
import { CommonProps } from "utils/CommonProps";

import styles from "./Tag.module.scss";

export const Tag: FC<PropsWithChildren<CommonProps>> = ({ className, children }) =>
  Children.count(children) ? (
    <Text className={classNames(styles.tag, className)} textVariant="ui-2">
      {children}
    </Text>
  ) : null;
