import { atom } from "recoil";
import { MonContrat } from "domain/api-schemas";
import { APPLICATIONSTATES } from "context/global.types";
import { currentWithEcheanceQuery } from "./selector";

export const monContratWithEcheanceState = atom<MonContrat | null>({
  key: APPLICATIONSTATES.ContratStateWithEcheance,
  default: currentWithEcheanceQuery,
});

export const refreshMonContratTriggerState = atom({
  key: "refreshMonContratTriggerState",
  default: 0,
});
