import { FC, useCallback, useEffect, useState } from "react";
import { Accordion, Text } from "@roole/components-library";

import { BoxList } from "components/molecules/BoxList/BoxList";
import { HotDealBox } from "components/molecules/HotDealBox/HotDealBox";
import {
  ActivateHotDealFn,
  DisclosureHotDealFn,
} from "components/molecules/HotDealBox/HotDealBox.types";
import { CommonProps } from "utils/CommonProps";
import { BonPlan } from "domain/api-schemas";
import { CustomSkeletonTheme } from "styles/themes/CustomSkeletonTheme";
import styles from "./DealershipHotDeals.module.scss";
import { HealthCheckGroups } from "SignalRModels";
import { useHealthCheckContext } from "HealthCheckProvider";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

interface DealershipHotDealsProps extends CommonProps {
  hotDeals?: BonPlan[] | null;
  hotDealsAlwaysVisible?: boolean;
  disabled?: boolean;
  onActivateHotDeal?(hotDeal: BonPlan): ActivateHotDealFn | undefined;
  onDisclosureHotDeal?(): DisclosureHotDealFn;
}

export const DealershipHotDeals: FC<DealershipHotDealsProps> = ({
  hotDeals,
  hotDealsAlwaysVisible = true,
  disabled = false,
  onActivateHotDeal,
  onDisclosureHotDeal,
}) => {
  const [disableGroup, setDisableGroup] = useState(false);
  const { getHealthCheckByGroupName } = useHealthCheckContext();

  const numHotDeals = hotDeals?.length ?? 0;
  const hotDealsLabel = numHotDeals > 1 ? "bons plans disponibles" : "bon plan disponible";

  const hotDealRenderer = useCallback(
    (hotDeal: BonPlan) => (
      <HotDealBox
        hotDeal={hotDeal}
        className={styles.dealershipHotDeals__childBox}
        primary={hotDealsAlwaysVisible}
        disabled={disabled}
        onActivate={onActivateHotDeal?.(hotDeal)}
        onDisclosure={onDisclosureHotDeal?.()}
      />
    ),
    [hotDealsAlwaysVisible, disabled, onActivateHotDeal, onDisclosureHotDeal],
  );

  useEffect(() => {
    setDisableGroup(getHealthCheckByGroupName(HealthCheckGroups.BonPlanAuto));
  }, [getHealthCheckByGroupName]);

  return (
    <CustomSkeletonTheme dark>
      {disableGroup && <ErrorBlock variants={[ErrorBlockVariant.Chat]} />}
      {!disableGroup &&
        (hotDealsAlwaysVisible || !numHotDeals ? (
          <BoxList<BonPlan>
            items={hotDeals || []}
            itemRenderer={hotDealRenderer}
            heading={
              <Text textVariant="heading-5" as="h2">
                Les bons plans du garage
              </Text>
            }
            noItemsText="Aucun bon plan n’est disponible pour le moment"
          />
        ) : (
          <Accordion
            accordionLabel={
              <Text textVariant="heading-6" as="h3">
                {`${numHotDeals} ${hotDealsLabel} dans ce garage`}
              </Text>
            }
            accordionContent={
              <BoxList<BonPlan>
                className={styles.dealershipHotDeals__accordionContent}
                items={hotDeals || []}
                itemRenderer={hotDealRenderer}
              />
            }
            type="expressive"
            useDefinedStyle={false}
          />
        ))}
    </CustomSkeletonTheme>
  );
};
