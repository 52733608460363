export const sendEventToApp = (detail: any, eventName: string) => {
  if (window.Android) {
    window.Android[eventName](JSON.stringify(detail));
  } else if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers[eventName].postMessage(detail);
  }
};

export const sendCloseWebViewEvent = (detail: { hasError: boolean; errorDetails?: any }) => {
  sendEventToApp(detail, "closeWebView");
};

export const sendDossierEditedEvent = () => {
  sendEventToApp({}, "onDossierSinistreEdited");
};

export const sendResiliatedSuccessfullyEvent = () => {
  sendEventToApp({}, "resiliatedSuccessfully");
};

export const sendAppTrackingEvent = (detail: {
  eventName: string;
  reasonId?: string;
  reason?: string;
}) => {
  sendEventToApp(detail, "trackingEvent");
};
