import { Text } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { RoutePaths } from "routes/routePaths";
import { contactUsStepState } from "context/contactUsState/atom";
import { useTracking } from "hooks/useTracking";

import { NavigationButtons } from "components/molecules/NavigationButtons/NavigationButtons";
import { Card } from "components/atoms/Card/Card";
import { AbstractContactUs } from "components/atoms/AbstractContactUs/AbstractContactUs";
import { InputArea } from "components/atoms/InputArea/InputArea";

import commonStyles from "pages/ContactUs/Page.module.scss";
import styles from "./ContactMailStep.module.scss";

type MailInputs = {
  message: string;
};

export const ContactMailStep: FC = () => {
  const [contactUsStepContextState, setContactUsStepContextState] =
    useRecoilState(contactUsStepState);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  useEffect(() => {
    if (contactUsStepContextState.subject === null) {
      navigate(RoutePaths.CONTACT_REASON);
    }
    setContactUsStepContextState({
      ...contactUsStepContextState,
      activeStep: 3,
    });
  }, []);

  const { control, handleSubmit } = useForm<MailInputs>();

  const onSubmit: SubmitHandler<MailInputs> = async (data: { message: string }) => {
    if (data.message.length > 1 && !error) {
      setContactUsStepContextState({
        ...contactUsStepContextState,
        messageValue: data.message,
        activeStep: 4,
      });
      sendToDataLayer("contactez-nous-canal-mail");
      navigate(RoutePaths.CONTACT_CONFIRMATION);
    }
    setError(true);
  };

  return (
    <>
      <div className={commonStyles.ContactUsTextIntro}>
        <Text as="h1" textVariant="heading-2">
          Détails de votre demande
        </Text>
        <br />
        <Text>
          La relation client est à votre service pour toutes questions concernant la gestion de
          votre contrat, une déclaration de sinistre ou autres.
        </Text>
      </div>
      <Card noMargin>
        <Text as="h2" textVariant="heading-4">
          Quel est l’objet de votre demande ?
        </Text>
        <AbstractContactUs
          subject={contactUsStepContextState?.subject}
          reason={contactUsStepContextState?.reason}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="message"
            defaultValue=""
            render={({ field: { onChange } }) => (
              <>
                <InputArea
                  placeholder="Pour assurer le meilleur traitement possible de votre demande, apportez-nous ici des informations complémentaires. 1 000 caractères maximum."
                  onChange={onChange}
                  className={styles.contactUsMailStep__InputArea}
                  maxLength={1000}
                  error={error}
                  setError={setError}
                  required
                  errorMessage="Veuillez décrire le problème que vous rencontrez dans le champ de saisie."
                />
                <NavigationButtons
                  prevProps={{
                    onClick: () => {
                      navigate(RoutePaths.CONTACT_TYPE);
                    },
                    gtmElementId: "contactez-nous-mail-bouton-precedent",
                  }}
                  nextProps={{
                    onClick: () => undefined,
                    type: "submit",
                    text: "Envoyer",
                  }}
                />
              </>
            )}
          />
        </form>
      </Card>
    </>
  );
};
