import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box } from "components/atoms/Box/Box";
import { RoutePaths } from "routes/routePaths";
import { classNames } from "utils/classNames";
import { Contrat, EtatContrat, ValiditeContrat } from "domain/api-schemas";
import PayerCotisationButton from "components/molecules/CotisationButton/PayerCotisationButton";
import { formatDate } from "utils/formatDate";
import { ReactComponent as AlertIcon } from "images/icons/alert-circle.svg";
import styles from "./Page.module.scss";

const styleName = {
  [ValiditeContrat.Actif]: styles.isActif,
  [ValiditeContrat.ExpireBientot]: styles.isExpiring,
  [ValiditeContrat.Expire]: styles.isExpired,
  [ValiditeContrat.Inactif]: styles.isExpired,
};

const renouvellement = {
  [ValiditeContrat.Actif]: false,
  [ValiditeContrat.ExpireBientot]: true,
  [ValiditeContrat.Expire]: true,
  [ValiditeContrat.Inactif]: false,
};

const ContractItem: FC<{ onClick: () => void; contract: Contrat }> = ({ onClick, contract }) => {
  const {
    validiteContrat,
    prenom,
    nom,
    identifiant,
    idAdhesion,
    immatriculation,
    dateFin,
    marque,
    modele,
  } = contract || {};

  const { state } = useLocation() as {
    state: { returnUrl?: string } | null;
  };

  return (
    <Box className={styles.tuile}>
      <Link to={state?.returnUrl ?? RoutePaths.CONTRAT_HOME} onClick={() => onClick()}>
        <p className={classNames(styles.tuileHead, styleName[validiteContrat])} />
        <div className={styles.tuileContent}>
          <div className={styles.vehicule}>
            <p>
              <b>{marque}</b> {modele}
            </p>
            <p>
              <b>{immatriculation}</b>
            </p>
          </div>
          <div className={styles.infosContrats}>
            <p>
              Membre :
              <b className={styles.marginLeft}>
                {prenom} {nom}
              </b>
            </p>
            <p>
              N° contrat :<b className={styles.marginLeft}>{identifiant}</b>
            </p>

            {dateFin && (
              <div className={styles.iconAfterText}>
                <p>
                  Date de fin du contrat :
                  <b className={styles.marginLeft}>{formatDate(dateFin)} </b>
                </p>
                {contract.etatContrat === EtatContrat.Resilie && <AlertIcon />}
              </div>
            )}
          </div>
        </div>
      </Link>
      <PayerCotisationButton
        isRenouvellementContrat={renouvellement[validiteContrat]}
        contratIdAdhesion={idAdhesion}
        className={styles.cotisationButton}
      />
    </Box>
  );
};

export default ContractItem;
