import { FC, useEffect, useReducer } from "react";

import { useRecoilValue } from "recoil";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { InfoContractBlock } from "components/molecules/InfoContractBlock/InfoContractBlock";
import { Title } from "components/atoms/Title/Title";
import { SelectList } from "components/atoms/SelectList/SelectList";
import { ButtonGroup } from "components/atoms/Button/ButtonGroup";
import { Button } from "components/atoms/Button/Button";
import { ModalConfirmMessage } from "components/molecules/Modal/ModalConfirmMessage";
import {
  MotifResiliation,
  Resiliation,
  ResiliationContract,
  SourceModification,
} from "domain/api-schemas";
import useDataLayer from "hooks/useDataLayer";
import { classNames } from "utils/classNames";
import { globalState } from "context/globalState/atom";
import { ResiliationSteps } from "./ResiliationTypes";
import { resiliationReducer } from "./resiliationReducer";
import { RetentionMessage } from "./RetentionMessage";

import { ReactComponent as BackButtonIcon } from "images/icons/arrowBack.svg";
import styles from "./Form.module.scss";
import { useTracking } from "hooks/useTracking";

import { SocialBlock } from "components/molecules/SocialBlock/SocialBlock";

import { ContactBlockTunnel } from "components/molecules/ContactBlockTunnel/ContactBlockTunnel";
import { useAuthenticationContext } from "AuthenticationProvider";
import { sendAppTrackingEvent } from "utils/sendEventToApp";

const initialState = {
  step: ResiliationSteps.Reason,
  completion: 33,
};

const codePackNoRetention = ["WS", "WT", "XL", "NL", "OB", "OV"];
const exceptCodePackNoRetention = (codePack?: string) =>
  !!codePack && !codePackNoRetention.includes(codePack);

const reasonsList = ({ libelle, id }: MotifResiliation) => ({
  id,
  label: libelle,
});

type FormProps = {
  steps: string[];
  reasons: MotifResiliation[];
  contract: ResiliationContract;
  className?: string;
  onSubmit: (data: Resiliation) => void;
  onClose: () => void;
};

export const Form: FC<FormProps> = ({ steps, reasons, contract, className, onSubmit, onClose }) => {
  const { sendToDataLayer } = useTracking();
  const { pushToDataLayer } = useDataLayer();
  const { isJwtAuthenticated } = useAuthenticationContext();
  const { idAdhesion, userEmail } = useRecoilValue(globalState);
  const [state, dispatch] = useReducer(resiliationReducer, initialState);
  const onConfirm = () => {
    if (!state.data) {
      throw new Error("Invalid data");
    }

    onSubmit?.(state.data);
  };

  const onAbort = () => dispatch({ step: ResiliationSteps.Reason });

  const getRetention = (motivationId?: string) =>
    !!reasons.find(({ id }) => id === motivationId)?.retention;

  const handleChangeReason = (motifResiliationId: string) => {
    const { libelle } = reasons?.find(({ id }) => id === motifResiliationId, 10) || {};

    dispatch({
      step: ResiliationSteps.Reason,
      data: {
        email: userEmail,
        motifResiliationId,
        idAdhesion: idAdhesion?.toString()!,
        source: isJwtAuthenticated ? SourceModification.RoolePremium : SourceModification.SiteEm,
      },
    });

    pushToDataLayer({
      event: "gtm.click",
      data: {
        "gtm.elementId": "resil-reason",
        "gtm.elementText": libelle,
      },
    });
    sendToDataLayer(libelle ? `resil-reason-${libelle}` : "", true);
  };

  const handleClose = () => {
    sendToDataLayer("resil-btn-retour");
    onClose();
  };

  const handleConfirm = (eventId: string) => {
    sendToDataLayer(eventId);
    const { libelle } = reasons?.find(({ id }) => id === state.data?.motifResiliationId, 10) || {};
    sendAppTrackingEvent({
      eventName: "contract_terminate_success",
      reasonId: state.data?.motifResiliationId,
      reason: encodeURIComponent(libelle!),
    });
    onConfirm();
  };

  const handleAbort = () => {
    sendToDataLayer("resil-btn-annuler");
    onAbort();
  };

  useEffect(() => {
    if (
      isJwtAuthenticated &&
      (state.step === ResiliationSteps.Confirm || state.step === ResiliationSteps.Retention)
    ) {
      sendAppTrackingEvent({ eventName: "contract_terminate_inProgress" });
    }
  }, [state]);

  return (
    <div className={classNames(className, styles.resiliationFormWrapper)}>
      <header className={styles.header}>
        <Breadcrumb steps={steps} percent={state.completion} />
      </header>
      <div className={styles.form}>
        <InfoContractBlock contrat={contract} />
        {/* <a href="#multiplecontrat"><b>Je souhaite résilier un autre contrat</b></a> */}
        {(state.step === ResiliationSteps.Reason || state.step === ResiliationSteps.Confirm) && (
          <>
            <Title>Sélectionnez le motif de votre résiliation&nbsp;:</Title>
            <SelectList
              selected={state.data?.motifResiliationId ? [state.data.motifResiliationId] : []}
              list={reasons.map(reasonsList)}
              maxLines={reasons.length}
              onChange={([motifResiliationId]) => handleChangeReason(motifResiliationId)}
            />
            <ButtonGroup data-selector="actionDemande">
              {!isJwtAuthenticated && (
                <Button
                  onClick={handleClose}
                  data-selector="retourAccueil"
                  icon={<BackButtonIcon style={{ width: "12px" }} />}
                >
                  Retour
                </Button>
              )}
              <Button
                onClick={() => {
                  sendToDataLayer("resil-btn-suivant");
                  const doRetention =
                    getRetention(state.data?.motifResiliationId) &&
                    exceptCodePackNoRetention(contract.packServices.codePack);
                  dispatch({
                    step: doRetention ? ResiliationSteps.Retention : ResiliationSteps.Confirm,
                  });
                }}
                variant="primary"
                data-selector="confirmation_etape1"
                disabled={!state.data}
              >
                Suivant
              </Button>
            </ButtonGroup>
          </>
        )}
        {state.step === ResiliationSteps.Confirm && (
          <ModalConfirmMessage
            title="Attention !"
            confirmText="Résilier"
            onConfirm={() => handleConfirm("resil-btn-resilier")}
            onAbort={handleAbort}
            abortTag="annuler_etape2"
            confirmTag="confirmation_etape2"
            btnGroupTag="actionDemande"
          >
            <p>
              Vous êtes sur le point de résilier votre contrat.
              <br />
              Cette action est irréversible.
            </p>
            <p>
              <b>Que voulez-vous faire ?</b>
            </p>
          </ModalConfirmMessage>
        )}
        {state.step === ResiliationSteps.Retention && (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <RetentionMessage
            contract={contract}
            onConfirm={() => handleConfirm("resil-btn-resilier-contrat")}
            onAbort={handleAbort}
          />
        )}
      </div>
      {!isJwtAuthenticated && (
        <aside>
          <SocialBlock>{[<ContactBlockTunnel idTracking="resil-telephone" />]}</SocialBlock>
        </aside>
      )}
    </div>
  );
};
