import { FC, useCallback } from "react";
import { DropDown, DropDownItem, Text } from "@roole/components-library";

import { DealershipBox } from "components/molecules/DealershipBox/DealershipBox";
import { DealershipLogo } from "components/molecules/DealershipBox/DealershipLogo";
import {
  ActivateHotDealFn,
  DisclosureHotDealFn,
} from "components/molecules/HotDealBox/HotDealBox.types";
import { BoxList } from "components/molecules/BoxList/BoxList";
import { BonPlan, Concession } from "domain/api-schemas";
import { CommonProps } from "utils/CommonProps";
import { classNames } from "utils/classNames";
import styles from "./DealershipList.module.scss";

interface DealershipListProps extends CommonProps {
  favoriteDealership?: Concession;
  dealerships?: Concession[] | null;
  filters?: DropDownItem[];
  selectedFilters?: DropDownItem | null;
  disabled?: boolean;
  onFiltersChange?(item: DropDownItem): void;
  onActivateHotDeal?(hotDeal: BonPlan, dealership: Concession): ActivateHotDealFn;
  onDisclosureHotDeal?(): DisclosureHotDealFn;
  onMakeAppointment?(dealership: Concession): void;
  onConnectDealership?(dealership: Concession): void;
}

export const DealershipList: FC<DealershipListProps> = ({
  className,
  favoriteDealership,
  dealerships,
  filters,
  selectedFilters,
  disabled = false,
  onActivateHotDeal,
  onDisclosureHotDeal,
  onMakeAppointment,
  onFiltersChange,
  onConnectDealership,
}) => {
  const dealershipRenderer = useCallback(
    (item: Concession) => (
      <DealershipBox
        dealership={item}
        hotDealsAlwaysVisible={false}
        logoAlwaysVisible={false}
        isFavorite={false}
        disabled={disabled}
        onActivateHotDeal={onActivateHotDeal}
        onDisclosureHotDeal={onDisclosureHotDeal}
        onMakeAppointment={onMakeAppointment}
        onConnectDealership={onConnectDealership}
      />
    ),
    [disabled, onActivateHotDeal, onDisclosureHotDeal, onMakeAppointment, onConnectDealership],
  );

  return (
    <div className={classNames(styles.dealershipList, className)}>
      <div className={styles.dealershipList__heading}>
        <DealershipLogo url={favoriteDealership?.urlLogo} small />
        <Text textVariant="heading-5" as="h2">
          Le réseau partenaire
        </Text>
      </div>
      <div className={styles.dealershipList__subheading}>
        <Text as="p">
          Ci-dessous la liste des garages associés à votre garage préféré. Retrouvez la liste de
          tous nos partenaires dans l’application Roole Premium.
        </Text>

        {!!filters?.length && (
          <div className={styles.dealershipList__filters}>
            <DropDown
              possibleValues={filters}
              setSelectedValue={(item) => !disabled && onFiltersChange?.(item)}
              selectedValue={selectedFilters || filters[0]}
            />
          </div>
        )}
      </div>

      <BoxList<Concession>
        className={styles.dealershipList__boxList}
        items={dealerships || []}
        itemRenderer={dealershipRenderer}
        noItemsText="Aucun garage n’est disponible pour le moment"
      />
    </div>
  );
};
