import { FC, createElement, HTMLAttributes } from "react";

import { classNames } from "utils/classNames";

import styles from "./Title.module.scss";

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: FC<Omit<TitleProps, "as">> | string;
}

export const Title: FC<TitleProps> = ({ as = "h2", className, children, ...props }) =>
  createElement(as, { ...props, className: classNames(className, styles.title) }, children);
