import { ComponentProps, FC, isValidElement, useCallback } from "react";
import { Icon, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import Skeleton from "react-loading-skeleton";

import { DealershipLogo } from "./DealershipLogo";
import { DealershipHotDeals } from "./DealershipHotDeals";
import { Box } from "components/atoms/Box/Box";
import { NumericText } from "components/atoms/NumericText/NumericText";
import { PhoneNumber } from "components/atoms/PhoneNumber/PhoneNumber";
import {
  ActivateHotDealFn,
  DisclosureHotDealFn,
} from "components/molecules/HotDealBox/HotDealBox.types";
import { AppointmentStatus } from "components/molecules/AppointmentSchedule/AppointmentStatus";
import { BonPlan, Concession } from "domain/api-schemas";
import { FallbackProps, isEmpty } from "utils/FallbackProps";
import { classNames } from "utils/classNames";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import styles from "./DealershipBox.module.scss";

interface DealershipBoxProps extends ComponentProps<typeof Box> {
  dealership?: Concession;
  hotDealsAlwaysVisible?: boolean;
  logoAlwaysVisible?: boolean;
  isFavorite?: boolean;
  disabled?: boolean;
  onActivateHotDeal?(hotDeal: BonPlan, dealership: Concession): ActivateHotDealFn;
  onDisclosureHotDeal?(): DisclosureHotDealFn;
  onMakeAppointment?(dealership: Concession): void;
  onConnectDealership?(dealership: Concession): void;
}

const dealershipFallback: FallbackProps<Concession, "nom" | "marque" | "adresse"> = {
  nom: <Skeleton />,
  marque: <Skeleton />,
  adresse: (
    <>
      <Skeleton count={3} />
      <Skeleton width="60%" />
    </>
  ),
  mesBonsPlans: [{}, {}, {}],
};

const checkAppointmentBtnVisibility = (
  onMakeAppointment?: DealershipBoxProps["onMakeAppointment"],
  optionRDV?: number,
  urlRDV?: string | null,
) => {
  if (
    !onMakeAppointment ||
    optionRDV === undefined ||
    optionRDV === AppointmentStatus.NONE ||
    !(optionRDV in AppointmentStatus)
  ) {
    return false;
  }

  return (
    optionRDV === AppointmentStatus.SEMI_DIGITALIZED ||
    optionRDV === AppointmentStatus.RICH_DIGITALIZED ||
    !!urlRDV
  );
};

const getAppointmentBtnLabel = (
  optionRDV: AppointmentStatus,
  isDesktop: boolean = false,
): string => {
  if (optionRDV !== AppointmentStatus.SEMI_DIGITALIZED) {
    return "Prendre rendez-vous";
  }

  return isDesktop ? "Être rappelé pour un rendez-vous" : "Être rappelé";
};

export const DealershipBox: FC<DealershipBoxProps> = ({
  className,
  dealership,
  hotDealsAlwaysVisible = true,
  logoAlwaysVisible = true,
  isFavorite = true,
  disabled = false,
  onActivateHotDeal,
  onDisclosureHotDeal,
  onMakeAppointment,
  onConnectDealership,
}) => {
  const isEmptyDealership = isEmpty(dealership);
  const {
    nom,
    marque,
    adresse,
    cpVille,
    telephone,
    optionRDV,
    urlRDV,
    urlLogo,
    distance,
    mesBonsPlans,
  } = isEmptyDealership ? dealershipFallback : dealership || {};
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const isAppointmentBtnVisible = checkAppointmentBtnVisibility(
    onMakeAppointment,
    optionRDV,
    urlRDV,
  );

  const addressText = isValidElement(adresse)
    ? adresse
    : [adresse, cpVille]
        .filter((item) => !!item)
        .join(", ")
        .trim()
        .toLowerCase();

  const connectDealershipHandler = useCallback(
    () => !disabled && !isFavorite && !!dealership && onConnectDealership?.(dealership),
    [disabled, isFavorite, dealership, onConnectDealership],
  );

  return (
    <Box className={classNames(styles.dealershipBox, className)}>
      <div
        className={classNames(
          styles.dealershipBox__content,
          styles["dealershipBox__content--primary"],
        )}
      >
        <div className={styles.dealershipBox__heading}>
          <Text
            className={classNames(styles.dealershipBox__title, !isFavorite && "text--capitalize")}
            textVariant="heading-5"
            as={isFavorite ? "h2" : "h3"}
          >
            {isFavorite ? "Mon garage" : nom}
          </Text>
          <Text
            onClick={connectDealershipHandler}
            className={classNames(
              styles.dealershipBox__favoriteText,
              isFavorite && styles["dealershipBox__favoriteText--toggled"],
            )}
            textVariant="ui-1"
            disabled={disabled}
            as="button"
          >
            {!isMobile && (
              <span className="text--secondary">
                {isFavorite ? "Mon garage préféré" : "Définir comme garage préféré"}
              </span>
            )}
            <Icon variant="like" />
          </Text>
        </div>
        <div className={classNames(logoAlwaysVisible && styles.dealershipBox__body)}>
          {logoAlwaysVisible && (
            <DealershipLogo className={styles.dealershipBox__logo} url={urlLogo} />
          )}
          <div className={styles.dealershipBox__infos}>
            <Text className="text--capitalize" textVariant="body-em" as={isFavorite ? "h3" : "h4"}>
              {isFavorite ? nom : marque}
            </Text>
            {(addressText || telephone) && (
              <div>
                {addressText && (
                  <Text className="text--capitalize" textVariant="body" as="address">
                    {addressText}
                  </Text>
                )}
                <PhoneNumber className={styles.dealershipBox__phoneNumber} value={telephone} />
              </div>
            )}
            <NumericText
              value={distance}
              addonText={"Distance de mon domicile\u202F:"}
              className="text--secondary"
            />
            {isAppointmentBtnVisible && (
              <Button
                onClick={() => !disabled && onMakeAppointment?.(dealership!)}
                className={classNames(styles.dealershipBox__appointmentBtn, "center-label")}
                type="button"
                disabled={disabled}
                id="mon-garage-prise-rdv"
                size="small"
                variant="primary"
              >
                {getAppointmentBtnLabel(optionRDV as AppointmentStatus, isDesktop)}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.dealershipBox__content}>
        {!hotDealsAlwaysVisible && isEmptyDealership ? (
          <Text textVariant="heading-6" as="h3">
            <Skeleton width="60%" />
          </Text>
        ) : (
          <DealershipHotDeals
            hotDeals={mesBonsPlans}
            hotDealsAlwaysVisible={hotDealsAlwaysVisible}
            disabled={disabled}
            onActivateHotDeal={(hotDeal: BonPlan) => onActivateHotDeal?.(hotDeal, dealership!)}
            onDisclosureHotDeal={onDisclosureHotDeal}
          />
        )}
        {isFavorite && mesBonsPlans && mesBonsPlans.length > 0 && (
          <Text className={styles.dealershipBox__legalInformation} textVariant="body" as="p">
            Les bons plans auto sont soumis aux conditions d'éligibilité et de durée du garage. Ces
            conditions sont disponibles directement auprès de celui-ci et relèvent de sa
            responsabilité.
          </Text>
        )}
      </div>
    </Box>
  );
};
