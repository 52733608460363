import { atom } from "recoil";
import {
  APPLICATIONSTATES,
  DEFAULT_IDADHESION,
  USER_ID_IN_LOCALSTORAGE,
} from "context/global.types";

export interface GlobalState {
  idAdhesion: string | null;
  userEmail: string;
  listContratAhesion: string[];
  auth0UserId: string | null;
}

export const globalContextStateInitValue: GlobalState = {
  idAdhesion: DEFAULT_IDADHESION,
  userEmail: "",
  listContratAhesion: [],
  auth0UserId: "",
};

const getIdAdhesionFormStorage = (key: string) => {
  const localIdAdhesion = localStorage.getItem(key);
  const sessionIdAdhesion = sessionStorage.getItem(key);
  if (!sessionIdAdhesion && localIdAdhesion) {
    return sessionIdAdhesion;
  }
  return localIdAdhesion;
};

const localStorageEffect =
  (key: string) =>
  // @ts-ignore
  ({ onSet, setSelf }) => {
    const savedValue = getIdAdhesionFormStorage(key);
    if (savedValue != null) {
      setSelf((defaultValue: GlobalState) => {
        return { ...defaultValue, idAdhesion: savedValue };
      });
    }
    onSet((newValue: GlobalState) => {
      if (newValue.idAdhesion) {
        localStorage.setItem(key, newValue.idAdhesion);
        sessionStorage.setItem(key, newValue.idAdhesion);
      }
    });
  };

export const globalState = atom<GlobalState>({
  key: APPLICATIONSTATES.GlobalState,
  default: globalContextStateInitValue,
  effects: [localStorageEffect(USER_ID_IN_LOCALSTORAGE)],
});
