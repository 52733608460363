import { DropDownItem, Text } from "@roole/components-library";
import { FC } from "react";

import styles from "./AbstractContactUs.module.scss";

type AbstractContactUsProps = {
  subject: DropDownItem | null;
  reason: DropDownItem | null;
};

export const AbstractContactUs: FC<AbstractContactUsProps> = ({ subject, reason }) => {
  return (
    <Text className={styles.abstractContactUs__Summary}>
      Je vous contacte pour le{" "}
      <span className={styles.abstractContactUs__Highlight}>motif "{subject?.name}"</span> et plus
      précisément pour le{" "}
      <span className={styles.abstractContactUs__Highlight}>choix "{reason?.name}"</span>
    </Text>
  );
};
