import {
  AccordionTile,
  ButtonV2,
  Drawer,
  DrawerMobile,
  IconV2,
  Message,
  ModalV2,
  TextV2,
  Tile,
} from "@roole/components-library";
import { UploadModal } from "./Upload/UploadModal";
import { UploadDrawer } from "./Upload/UploadDrawer";
import { declarationDataState } from "context/declarationState/atom";
import { monContratWithEcheanceState } from "context/currentContratWithEcheanceState/atom";
import { GarantieV2, ReferentielsPiecesJustificatives } from "domain/api-schemas";
import { FC, useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from "recoil";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import styled from "styled-components";
import { listReferentielsPiecesJustificativesQuery } from "context/referentielsPiecesJustificativesState/selector";
import {
  stripHtmlTags,
  UploadedFile,
  handleViewDocument,
} from "domain/service/Sinistre/useDossier";
import { useStepperContext } from "./StepperContext";

const HelperWrapper = styled.div`
  &,
  & * {
    display: inline;
  }
`;

type RenderPJProps = {
  Message?: {
    message: JSX.Element;
    type: "information" | "information2" | "alert" | "error" | "warning" | "validation";
    icon?: string;
  };

  pj: {
    nom?: string;
    description?: string;
    crmId?: string;
    code?: string;
  };
  onClickHelper?: () => void;
  addClick: () => void;
  renderPjPieces: (typeId: string) => JSX.Element;
};

const RenderPJ: React.FC<RenderPJProps> = ({
  Message: MessageProps,
  pj,
  onClickHelper,
  addClick,
  renderPjPieces,
}) => {
  return (
    <div className={classNames("flex flex-col", !!MessageProps ? "gap-2XS" : "gap-3XS")}>
      <div className={classNames("flex justify-between items-start")}>
        <div className="flex-1">
          <TextV2 textVariant="body-medium-em" extraCss={{ display: "inline" }}>
            {pj.nom}{" "}
            {onClickHelper !== undefined && (
              <>
                <HelperWrapper>
                  <IconV2
                    name="help-circle"
                    size={28}
                    onClick={onClickHelper}
                    color="dark"
                    extraCss={{ cursor: "pointer" }}
                  />
                </HelperWrapper>{" "}
              </>
            )}
          </TextV2>
        </div>

        <ButtonV2 variant="tertiary-brand" icon="plus" onClick={() => addClick()} size="small">
          Ajouter
        </ButtonV2>
      </div>

      {MessageProps && <Message {...MessageProps} />}

      <div className="flex flex-col gap-8">
        <div>{renderPjPieces(pj.crmId ?? "")}</div>
      </div>
    </div>
  );
};

export const PJs: FC = () => {
  const { setActions, prevStep, nextStep } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const monContrat = useRecoilValue(monContratWithEcheanceState);
  const { idAdhesion } = monContrat ?? {};
  const [openModal, setOpenModal] = useState(false);
  const [pjInfo, setPjInfo] = useState<{ name: string; description: string } | undefined>(
    undefined,
  );
  const [selectedGarantie, setSelectedGarantie] = useState<GarantieV2 | null>(null);
  const [garantiesEligibles, setGarantiesEligibles] = useState<string[]>([]);
  const [garantiesNonEligibles, setGarantiesNonEligibles] = useState<string[]>([]);
  const garanties = useRecoilValueLoadable(
    listReferentielsPiecesJustificativesQuery({
      garantiesIds: declarationData!.typeEvenement!.garanties!.map((_) => _.crmId),
      typeEvenementId: declarationData!.typeEvenement!.crmId,
      isVehiculeRetrouve: !!declarationData?.isVehiculeRetrouve,
      isVehiculeReparable: !!declarationData?.isVehiculeReparable,
    }),
  );
  const [selectedPJ, setSelectedPJ] = useState<{
    nom: string;
    crmId: string;
  } | null>(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const handleClose = () => {
    setSelectedGarantie(null);
    setOpenModal(false);
  };

  const checkEligible = () => {
    if (selectedGarantie) {
      const isEligible = false;
      if (isEligible) {
        setGarantiesEligibles([...garantiesEligibles, selectedGarantie?.crmId]);
      } else {
        setGarantiesNonEligibles([...garantiesNonEligibles, selectedGarantie?.crmId]);
      }
      setOpenModal(false);
    }
  };

  const handleAddingPJ = (pj: { nom: string; crmId: string }, garantie: any) => {
    setSelectedPJ(pj);
    if (garantie) {
      setSelectedGarantie(garantie);
    }

    if (declarationData) {
      setAddModalOpen(true);
    }
  };

  const handleAddingPjClose = () => {
    setAddModalOpen(false);
  };

  const handleFilesUpload = (files: UploadedFile[]) => {
    if (!selectedGarantie || !selectedPJ || !declarationData) {
      return;
    }

    const updatedGaranties = [...(declarationData.garanties || [])];

    const garantieIndex = updatedGaranties.findIndex(
      (garantie) => garantie.id === selectedGarantie.crmId,
    );

    if (garantieIndex === -1) {
      updatedGaranties.push({
        id: selectedGarantie.crmId,
        isDossierComplet: true,
        piecesJointes: files.map((file) => ({
          typeId: selectedPJ.crmId,
          nom: file.name,
          blobNom: file.blobNom,
          size: file.size,
          date: file.date,
          base64: file.base64,
        })),
      });
    } else {
      const updatedPiecesJointes = [
        ...(updatedGaranties[garantieIndex].piecesJointes || []),
        ...files.map((file) => ({
          typeId: selectedPJ.crmId,
          nom: file.name,
          blobNom: file.blobNom,
          size: file.size,
          date: file.date,
          base64: file.base64,
        })),
      ];

      updatedGaranties[garantieIndex] = {
        ...updatedGaranties[garantieIndex],
        isDossierComplet: true,
        piecesJointes: updatedPiecesJointes,
      };
    }

    setDeclarationData({
      ...declarationData,
      garanties: updatedGaranties,
    });

    setAddModalOpen(false);
  };

  const renderPjPieces = (typeId: string, garantieId: string) => {
    if (!declarationData?.garanties) {
      return (
        <TextV2 textVariant="body-small" color="secondary">
          Aucun document ajouté
        </TextV2>
      );
    }

    const pieces = declarationData.garanties
      .filter((garantie) => garantie.id === garantieId)
      .flatMap((garantie) => garantie.piecesJointes || [])
      .filter((pj) => pj.typeId?.toLowerCase() === typeId.toLowerCase());

    if (pieces.length === 0) {
      return (
        <TextV2 textVariant="body-small" color="secondary">
          Aucun document ajouté
        </TextV2>
      );
    }

    return (
      <>
        {pieces.map((file, index) => (
          <div key={`${file.nom}-${index}`}>
            <Tile
              title={file.nom || ""}
              subtitle={{ text: `Ajouté le ${file.date}` }}
              avatar={{
                icon: "file",
              }}
              button={{
                icon: "eye",
                label: isMobile ? "" : "Voir",
                onClick: () => handleViewDocument(file as UploadedFile),
              }}
            />
          </div>
        ))}
      </>
    );
  };

  const modalContent = (
    <div>
      <TextV2 textVariant="heading-3" as="h3">
        Complétez les informations
      </TextV2>

      <div className="flex flex-col-reverse gap-XS mt-M lg:flex-row">
        <ButtonV2 variant="secondary-dark" onClick={handleClose}>
          Annuler
        </ButtonV2>
        <ButtonV2 variant="primary-brand" onClick={() => checkEligible()}>
          Vérifier mon éligibilité
        </ButtonV2>
      </div>
    </div>
  );

  useEffect(() => {
    if (!declarationData) {
      return;
    }

    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Continuer",
        onClick: () => {
          if (
            !!declarationData.garantiesEligibles?.length ||
            declarationData.garantiesNonEligibles?.length ===
              declarationData?.typeEvenement?.garanties?.length
          ) {
            nextStep();
          } else {
            alert("Il faut avoir au moins une garantie eligible");
          }
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => prevStep(),
      },
    });
  }, [declarationData, nextStep, prevStep, setActions]);

  let garantiesDisplay =
    garanties.state === "hasValue" &&
    (garanties.contents as ReferentielsPiecesJustificatives[]).map((g) => ({
      ...g,
      piecesJustificatives: g.piecesJustificatives || [],
    }));

  if (garantiesDisplay) {
    garantiesDisplay =
      garantiesDisplay.filter((g) =>
        declarationData?.garantiesEligibles?.find((eligible) => eligible === g.garantie.crmId),
      ) || [];
  }

  return (
    <div className="flex flex-col gap-M">
      <div className="flex flex-col gap-XS">
        <TextV2 textVariant="heading-2" as="h2">
          Pièces justificatives
        </TextV2>

        <TextV2>
          Voici la liste des pièces justificatives obligatoires qui vous seront demandées avant
          l'étude de votre dossier par nos conseillers.
          <br /> Vous pouvez d'ores et déjà les ajouter ou compléter votre dossier{" "}
          <b>plus tard, depuis le menu "Mes sinistres".</b>{" "}
        </TextV2>

        <Message
          icon="info"
          message={
            <TextV2 textVariant="body-small">
              Si vous ajoutez toutes vos pièces justificatives maintenant, votre dossier passera en
              étude dès la fin de votre déclaration.
            </TextV2>
          }
          type="information"
        />
      </div>

      <div className="flex flex-col gap-XS">
        {garantiesDisplay &&
          garantiesDisplay.map((garantie: ReferentielsPiecesJustificatives) => (
            <AccordionTile
              title={garantie.garantie.nom}
              caption=""
              textZone={`Justificatifs ajoutés : ${
                garantie.piecesJustificatives.filter((pj) =>
                  declarationData?.garanties
                    ?.find((g) => g.id === garantie.garantie.crmId)
                    ?.piecesJointes?.some((pieceJointe) => pieceJointe.typeId === pj.crmId),
                ).length
              }/${garantie.piecesJustificatives.length ?? 0}`}
              key={garantie.garantie.nom}
              isOpenProp={
                (garantiesDisplay as ReferentielsPiecesJustificatives[])[0]?.garantie.nom ===
                garantie.garantie.nom
              }
              hasIcon={Array.isArray(garantiesDisplay) && garantiesDisplay.length > 1}
            >
              <div
                className="flex flex-col gap-M cursor-default pt-XS"
                onClick={(e) => e.stopPropagation()}
              >
                {garantie.piecesJustificatives.map((pj) => (
                  <RenderPJ
                    pj={pj}
                    onClickHelper={() =>
                      setPjInfo({
                        name: pj.nom,
                        description: pj.description,
                      })
                    }
                    key={`${garantie.garantie.nom}_${pj.nom}}`}
                    addClick={() => handleAddingPJ(pj, garantie.garantie)}
                    renderPjPieces={(typeId) => renderPjPieces(typeId, garantie.garantie.crmId)}
                  />
                ))}
              </div>
            </AccordionTile>
          ))}
      </div>

      {isMobile ? (
        <DrawerMobile
          title={pjInfo?.name ?? ""}
          isOpen={pjInfo !== undefined}
          content={stripHtmlTags(pjInfo?.description ?? "")}
          handleClose={() => setPjInfo(undefined)}
        />
      ) : (
        <Drawer
          title={pjInfo?.name ?? ""}
          isOpen={pjInfo !== undefined}
          content={stripHtmlTags(pjInfo?.description ?? "")}
          handleClose={() => setPjInfo(undefined)}
        />
      )}

      {isMobile ? (
        <DrawerMobile
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      ) : (
        <ModalV2
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      )}
      {isMobile ? (
        <UploadDrawer
          isOpen={addModalOpen}
          handleClose={handleAddingPjClose}
          selectedPJ={selectedPJ}
          onFilesUpload={handleFilesUpload}
          dossierData={declarationData || undefined}
          idAdhesion={idAdhesion || ""}
        />
      ) : (
        <UploadModal
          isOpen={addModalOpen}
          handleClose={handleAddingPjClose}
          selectedPJ={selectedPJ}
          onFilesUpload={handleFilesUpload}
          dossierData={declarationData || undefined}
          idAdhesion={idAdhesion || ""}
        />
      )}
    </div>
  );
};
