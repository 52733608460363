import { FC, HTMLAttributes } from "react";
import { createPortal } from "react-dom";

import { classNames } from "utils/classNames";
import { noDelegation } from "utils/noDelegation";
import { useLockScroll } from "hooks/useLockScroll";
import { useFocusTrap } from "hooks/useFocusTrap";

import styles from "./Modal.module.scss";

export type ModalProps = {
  onOverlayClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const Modal: FC<ModalProps> = ({ children, className, onOverlayClick, ...props }) => {
  useLockScroll();
  const modalRef = useFocusTrap<HTMLDivElement>();
  return createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        styles.modalOverlay,
        className,
        onOverlayClick && styles.closeOnOverlayClick,
      )}
      onClick={noDelegation(onOverlayClick)}
    >
      <div
        role="dialog"
        {...props}
        ref={modalRef}
        aria-modal="true"
        className={styles.modalContainer}
      >
        {children}
      </div>
    </div>,
    document.body,
  );
};

// NOTE Check support of dialog HTML https://caniuse.com/dialog 06/2021 it's still too experimental
// See https://keithjgrant.com/posts/2018/01/meet-the-new-dialog-element/
