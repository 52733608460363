import { WizardInitHandler, WizardStepStatus } from "@roole/components-library";
import { ResiliationRoutePath } from "./ResiliationRoutePath";

export const initWizard: WizardInitHandler = () =>
  Promise.resolve({
    [ResiliationRoutePath.IMMAT_STEP]: {
      label: "Ma demande de résiliation",
      description: "",
      status: WizardStepStatus.STARTED,
    },
    [ResiliationRoutePath.CONFIRMATION_STEP]: {
      label: "Confirmation",
      description: "",
      status: WizardStepStatus.UNSTARTED,
    },
  });

export const immatValid = (inputImmat: string, dossierImmat: string) =>
  inputImmat !== "" && inputImmat.replaceAll("-", "").trim() === dossierImmat.replaceAll("-", "");
