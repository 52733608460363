import { FC } from "react";

import { Box } from "components/atoms/Box/Box";
import { Button } from "components/atoms/Button/Button";
import { formatDate } from "utils/formatDate";
import { Title } from "components/atoms/Title/Title";

import styles from "./Message.module.scss";

type MessageProps = {
  endDate: Date;
  confirmSent?: boolean;
  onClose: () => void;
  statutMembre?: string;
};
export const Message: FC<MessageProps> = ({
  endDate,
  confirmSent,
  statutMembre = "Premium",
  onClose,
}) => (
  <div className={styles.resiliationMessageWrapper}>
    <Box className={styles.resiliationMessage}>
      <Title>Votre demande de résiliation a bien été prise en compte</Title>
      {confirmSent && (
        <>
          <p>Merci de votre retour, nos conseillers vont en prendre connaissance.</p>
          <p>
            Vous recevrez d’ici quelques instants un email confirmant la résiliation de votre
            contrat.
          </p>
        </>
      )}
      <p>
        {statutMembre === "Premium" ? (
          <b>
            Vous restez membre Roole et pouvez bénéficier de vos garanties et Bons Plans jusqu'à la
            fin de votre contrat le&nbsp;
            {formatDate(endDate)}.
          </b>
        ) : (
          <b>
            Vous restez couvert par votre contrat jusqu'au {formatDate(endDate)}. Passé cette date,
            vous deviendrez membre Roole Free. Vous bénéficierez ainsi d’une offre gratuite mais
            restreinte. Vous ne serez donc plus couvert par vos garanties et services en cas de
            sinistres.
          </b>
        )}
      </p>
      <Button data-selector="terminer" variant="primary" onClick={() => onClose()}>
        Terminer
      </Button>
    </Box>
  </div>
);
