import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import type { Blocker } from "history";

// From https://github.com/remix-run/react-router/issues/8139
export function useBlocker(blocker: Blocker, when = true): void {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }
    // @ts-ignore
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}
