import { Reducer } from "react";
import { ResiliationAction, ResiliationState } from "./ResiliationTypes";

export const resiliationReducer: Reducer<ResiliationState, ResiliationAction> = (
  state,
  { step, data = state.data },
) => {
  if (state.step !== step) {
    window.scrollTo(0, 0);
  }
  return {
    ...state,
    step,
    data,
    completion: data?.motifResiliationId ? 66 : state.completion,
  };
};
