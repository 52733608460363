import { DropDown, DropDownItem, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { FC, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Appointment, DEFAULT_RULES, POSSIBLE_DAYS, POSSIBLE_REASONS } from "./Appointment";
import { AppointmentDate } from "./AppointmentDate";
import { ReactComponent as TipsIcon } from "images/icons/tips.svg";
import ScheduledImg from "images/mail.png";
import { CommonProps } from "utils/CommonProps";
import styles from "./AppointmentSchedule.module.scss";

interface AppointmentScheduleProps extends CommonProps {
  onSubmit(value: Appointment): void;
  onCancel(): void;
  scheduled?: boolean;
}

export const AppointmentSchedule: FC<AppointmentScheduleProps> = ({
  onSubmit,
  onCancel,
  scheduled,
}) => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Appointment>({ mode: "all" });
  const [days, setDays] = useState<DropDownItem[]>();

  const submitHandler = useCallback(
    (formValues: Appointment) => {
      if (isValid) {
        onSubmit(formValues);
      }
    },
    [isValid, onSubmit],
  );

  return scheduled ? (
    <div className={styles.appointmentSchedule__success}>
      <img src={ScheduledImg} alt="Scheduled..." />
      <Text textVariant="body">
        Votre demande de rendez-vous a bien été transmise au garage. Votre garage vous recontactera
        dans les meilleurs délais.
      </Text>
      <Button variant="primary" size="small" onClick={onCancel}>
        Continuer
      </Button>
    </div>
  ) : (
    <form
      className={styles.appointmentSchedule}
      onSubmit={handleSubmit(submitHandler)}
      autoComplete="off"
      noValidate
    >
      <Controller
        name="reason"
        control={control}
        rules={DEFAULT_RULES}
        render={({ field, fieldState: { error } }) => (
          <div className={styles.appointmentSchedule__dropdownGroup}>
            <DropDown
              possibleValues={POSSIBLE_REASONS}
              selectedValue={field.value}
              setSelectedValue={field.onChange}
              onBlur={() => field.onBlur()}
              error={error?.message}
            />
          </div>
        )}
      />

      <div>
        <div className={styles.appointmentSchedule__tips}>
          <TipsIcon />
          <Text textVariant="ui-1" as="p">
            Afin de vous recevoir au mieux, l’équipe de votre garage vous contactera par téléphone
            afin de convenir d’une date de rendez-vous avec vous.
          </Text>
        </div>
        <Controller
          name="reminder"
          control={control}
          rules={DEFAULT_RULES}
          render={({ field, fieldState: { error } }) => (
            <AppointmentDate {...field} errorMessage={error?.message} />
          )}
        />
      </div>

      <Controller
        name="days"
        control={control}
        rules={DEFAULT_RULES}
        render={({ field, fieldState: { error } }) => (
          <div className={styles.appointmentSchedule__dropdownGroup}>
            <DropDown
              possibleValues={POSSIBLE_DAYS}
              selectedValue={days}
              setSelectedValue={(e) => {
                field.onChange(e);
                setDays(e);
              }}
              onBlur={() => field.onBlur()}
              error={error?.message}
              multiple
            />
          </div>
        )}
      />

      <div className={styles.appointmentSchedule__controls}>
        <Button
          variant="outlined-light"
          size="small"
          type="button"
          className="center-label"
          onClick={onCancel}
        >
          Annuler
        </Button>
        <Button variant="primary" size="small" disabled={!isValid} className="center-label">
          Valider
        </Button>
      </div>
    </form>
  );
};
