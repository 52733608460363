import { WizardInitHandler, WizardStepStatus } from "components/molecules/Wizard";
import { RegularisationRoutePath } from "./RegularisationRoutePath";

export const initWizard: WizardInitHandler = () =>
  Promise.resolve({
    [RegularisationRoutePath.INFO_STEP]: {
      label: "Informations",
      description: "",
      status: WizardStepStatus.STARTED,
    },
    [RegularisationRoutePath.REGUL_STEP]: {
      label: "Régularisation",
      description: "",
      status: WizardStepStatus.STARTED,
    },
    [RegularisationRoutePath.CONFIRMATION_STEP]: {
      label: "Confirmation",
      description: "",
      status: WizardStepStatus.UNSTARTED,
    },
  });
